import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons';
import { usePopperTooltip } from 'react-popper-tooltip';
import DefaultLoader from '../../DefaultLoader';
import ZenCustomSwitch from './ZenCustomSwitch';

interface ZenToggleRowProps {
  value: boolean;
  onChange(value: boolean): void;
  title?: string;
  tooltip?: string;
  loading?: boolean;
  rightIcon?: string | React.ReactElement;
  leftIcon?: string | React.ReactElement;
  disabled?: boolean;
}

const ZenToggleRow: React.FC<ZenToggleRowProps> = ({
  value,
  onChange,
  title,
  tooltip,
  loading,
  leftIcon,
  rightIcon,
  disabled,
}) => {
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip();

  return (
    <div className='flex justify-between items-center'>
      <div className='flex flex-row py-1 items-center font-zen-body font-normal text-base space-x-3 px-2'>
        <ZenCustomSwitch
          leftIcon={leftIcon}
          rightIcon={rightIcon}
          toggle={value}
          onClick={() => !loading && onChange(!value)}
          disabled={disabled}
        />
        {title && <p>{title}</p>}
        {tooltip && (
          <div>
            <div ref={setTriggerRef}>
              <FontAwesomeIcon icon={faCircleInfo} size='lg' />
            </div>
            {visible && (
              <div
                ref={setTooltipRef}
                {...getTooltipProps({ className: 'tooltip' })}
              >
                <div {...getArrowProps({ className: 't-arrow' })} />
                {tooltip}
              </div>
            )}
          </div>
        )}
      </div>
      {loading && (
        <div className='pr-3'>
          <DefaultLoader noPadding iconSize='default' />
        </div>
      )}
    </div>
  );
};

export default ZenToggleRow;
