import * as Arrakis from '../../openapi/arrakis';
import * as Hermes from '../../openapi/hermes';
import * as Memento from '../../openapi/memento';
import * as Wallet from '../../openapi/wallet';
import * as Yada from '../../openapi/yada';
import {
  getArrakisConfiguration,
  getHermesConfiguration,
  getMementoConfiguration,
  getWalletConfiguration,
  getYadaConfiguration,
} from '../../utils/OpenapiConfigurationUtils';
import { KafkaControllerWrapper } from './KafkaController';
import { KafkaControllers } from './KafkaControllerTypes';

export const ConsumerService = {
  arrakis: 'arrakis',
  hermes: 'hermes',
  memento: 'memento',
  wallet: 'wallet',
  yada: 'yada',
} as const;
export type ConsumerServiceKeys = keyof typeof ConsumerService;

export const consumerControllers: KafkaControllers<ConsumerServiceKeys> = {
  arrakis: new KafkaControllerWrapper(
    new Arrakis.KafkaRetryConsumerControllerApi(getArrakisConfiguration()),
    {
      batchUpdateFailedMessageStatus: 'batchUpdateFailedMessageStatus1',
      getFailedMessages: 'getFailedMessages1',
      retryFailedMessage: 'retryFailedMessage1',
      retryFailedMessages: 'getFailedMessages1',
    },
  ),
  hermes: new KafkaControllerWrapper(
    new Hermes.KafkaRetryConsumerControllerApi(getHermesConfiguration()),
    {
      batchUpdateFailedMessageStatus: 'batchUpdateFailedMessageStatus1',
      getFailedMessages: 'getFailedMessages1',
      retryFailedMessage: 'retryFailedMessage1',
      retryFailedMessages: 'getFailedMessages1',
    },
  ),
  memento: new KafkaControllerWrapper(
    new Memento.KafkaRetryConsumerControllerApi(getMementoConfiguration()),
    {
      batchUpdateFailedMessageStatus: 'batchUpdateFailedMessageStatus1',
      getFailedMessages: 'getFailedMessages1',
      retryFailedMessage: 'retryFailedMessage1',
      retryFailedMessages: 'getFailedMessages1',
    },
  ),
  wallet: new KafkaControllerWrapper(
    new Wallet.KafkaRetryConsumerControllerApi(getWalletConfiguration()),
    {
      batchUpdateFailedMessageStatus: 'batchUpdateFailedMessageStatus1',
      getFailedMessages: 'getFailedMessages1',
      retryFailedMessage: 'retryFailedMessage1',
      retryFailedMessages: 'getFailedMessages1',
    },
  ),
  yada: new KafkaControllerWrapper(
    new Yada.KafkaRetryConsumerControllerApi(getYadaConfiguration()),
    {
      batchUpdateFailedMessageStatus: 'batchUpdateFailedMessageStatus1',
      getFailedMessages: 'getFailedMessages1',
      retryFailedMessage: 'retryFailedMessage1',
      retryFailedMessages: 'getFailedMessages1',
    },
  ),
};
