/* tslint:disable */
/* eslint-disable */
/**
 * Wanderer API
 * Wanderer Main Application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AbbreviatedListing
 */
export interface AbbreviatedListing {
    /**
     * 
     * @type {string}
     * @memberof AbbreviatedListing
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AbbreviatedListing
     */
    'market'?: string;
    /**
     * 
     * @type {string}
     * @memberof AbbreviatedListing
     */
    'mlsId'?: string;
    /**
     * 
     * @type {Address}
     * @memberof AbbreviatedListing
     */
    'address'?: Address;
    /**
     * 
     * @type {LatLonLocation}
     * @memberof AbbreviatedListing
     */
    'coordinates'?: LatLonLocation;
    /**
     * 
     * @type {number}
     * @memberof AbbreviatedListing
     */
    'relativeDistanceIndicator'?: number;
    /**
     * 
     * @type {number}
     * @memberof AbbreviatedListing
     */
    'listingDate'?: number;
    /**
     * 
     * @type {string}
     * @memberof AbbreviatedListing
     */
    'propertyType'?: string;
    /**
     * 
     * @type {string}
     * @memberof AbbreviatedListing
     */
    'listingType'?: AbbreviatedListingListingTypeEnum;
    /**
     * 
     * @type {Baths}
     * @memberof AbbreviatedListing
     */
    'baths'?: Baths;
    /**
     * 
     * @type {number}
     * @memberof AbbreviatedListing
     */
    'beds'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof AbbreviatedListing
     */
    'images'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof AbbreviatedListing
     */
    'listPrice'?: number;
    /**
     * 
     * @type {string}
     * @memberof AbbreviatedListing
     */
    'attributionContact'?: string;
    /**
     * 
     * @type {AgentInfo}
     * @memberof AbbreviatedListing
     */
    'agentInfo'?: AgentInfo;
    /**
     * 
     * @type {ListingOffice}
     * @memberof AbbreviatedListing
     */
    'listingOffice'?: ListingOffice;
    /**
     * 
     * @type {Array<LegaleseElement>}
     * @memberof AbbreviatedListing
     */
    'legalese'?: Array<LegaleseElement>;
}

/**
    * @export
    * @enum {string}
    */
export enum AbbreviatedListingListingTypeEnum {
    Commercial = 'Commercial',
    Farm = 'Farm',
    Land = 'Land',
    Multifamily = 'Multifamily',
    Rental = 'Rental',
    Residential = 'Residential'
}

/**
 * 
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'deliveryLine'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'zip'?: string;
}
/**
 * 
 * @export
 * @interface AgentInfo
 */
export interface AgentInfo {
    /**
     * 
     * @type {string}
     * @memberof AgentInfo
     */
    'slug'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentInfo
     */
    'avatar'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentInfo
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentInfo
     */
    'lastName'?: string;
}
/**
 * 
 * @export
 * @interface Asset
 */
export interface Asset {
    /**
     * 
     * @type {number}
     * @memberof Asset
     */
    'height'?: number;
    /**
     * 
     * @type {number}
     * @memberof Asset
     */
    'width'?: number;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'url'?: string;
}
/**
 * 
 * @export
 * @interface Baths
 */
export interface Baths {
    /**
     * 
     * @type {number}
     * @memberof Baths
     */
    'total'?: number;
    /**
     * 
     * @type {number}
     * @memberof Baths
     */
    'full'?: number;
    /**
     * 
     * @type {number}
     * @memberof Baths
     */
    'half'?: number;
}
/**
 * 
 * @export
 * @interface Features
 */
export interface Features {
    /**
     * 
     * @type {Array<string>}
     * @memberof Features
     */
    'property'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Features
     */
    'location'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Features
     */
    'fence'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Features
     */
    'listing'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Features
     */
    'schools'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Features
     */
    'amenities'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Features
     */
    'appliancesAndEquipment'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Features
     */
    'bathrooms'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Features
     */
    'bedrooms'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Features
     */
    'cooling'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Features
     */
    'disabilityFeatures'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Features
     */
    'disclosures'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Features
     */
    'exteriorFeatures'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Features
     */
    'fireplaces'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Features
     */
    'foundation'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Features
     */
    'garage'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Features
     */
    'heating'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Features
     */
    'homeOwnersAssociation'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Features
     */
    'house'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Features
     */
    'interiorFeatures'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Features
     */
    'laundry'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Features
     */
    'livingRoom'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Features
     */
    'lot'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Features
     */
    'parking'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Features
     */
    'roof'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Features
     */
    'securitySystem'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Features
     */
    'spa'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Features
     */
    'utilities'?: Array<string>;
}
/**
 * 
 * @export
 * @interface FullListing
 */
export interface FullListing {
    /**
     * 
     * @type {Address}
     * @memberof FullListing
     */
    'address'?: Address;
    /**
     * 
     * @type {string}
     * @memberof FullListing
     */
    'area'?: string;
    /**
     * 
     * @type {Baths}
     * @memberof FullListing
     */
    'baths'?: Baths;
    /**
     * 
     * @type {number}
     * @memberof FullListing
     */
    'beds'?: number;
    /**
     * 
     * @type {string}
     * @memberof FullListing
     */
    'buildingName'?: string;
    /**
     * 
     * @type {ListingAgent}
     * @memberof FullListing
     */
    'coListingAgent'?: ListingAgent;
    /**
     * 
     * @type {ListingOffice}
     * @memberof FullListing
     */
    'coListingOffice'?: ListingOffice;
    /**
     * 
     * @type {LatLonLocation}
     * @memberof FullListing
     */
    'coordinates'?: LatLonLocation;
    /**
     * 
     * @type {string}
     * @memberof FullListing
     */
    'county'?: string;
    /**
     * 
     * @type {string}
     * @memberof FullListing
     */
    'daysOnHJI'?: string;
    /**
     * 
     * @type {string}
     * @memberof FullListing
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof FullListing
     */
    'distressed'?: string;
    /**
     * 
     * @type {Features}
     * @memberof FullListing
     */
    'features'?: Features;
    /**
     * 
     * @type {string}
     * @memberof FullListing
     */
    'floors'?: string;
    /**
     * 
     * @type {string}
     * @memberof FullListing
     */
    'fullListingAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof FullListing
     */
    'geoType'?: string;
    /**
     * 
     * @type {string}
     * @memberof FullListing
     */
    'id'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof FullListing
     */
    'images'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof FullListing
     */
    'lastUpdated'?: number;
    /**
     * 
     * @type {number}
     * @memberof FullListing
     */
    'leaseAvailability'?: number;
    /**
     * 
     * @type {string}
     * @memberof FullListing
     */
    'leaseTerm'?: string;
    /**
     * 
     * @type {ListingAgent}
     * @memberof FullListing
     */
    'listingAgent'?: ListingAgent;
    /**
     * 
     * @type {number}
     * @memberof FullListing
     */
    'listingDate'?: number;
    /**
     * 
     * @type {ListingOffice}
     * @memberof FullListing
     */
    'listingOffice'?: ListingOffice;
    /**
     * 
     * @type {string}
     * @memberof FullListing
     */
    'listingType'?: string;
    /**
     * 
     * @type {number}
     * @memberof FullListing
     */
    'listPrice'?: number;
    /**
     * 
     * @type {LotSize}
     * @memberof FullListing
     */
    'lotSize'?: LotSize;
    /**
     * 
     * @type {number}
     * @memberof FullListing
     */
    'modifiedDate'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof FullListing
     */
    'newConstruction'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FullListing
     */
    'pendingDate'?: number;
    /**
     * 
     * @type {string}
     * @memberof FullListing
     */
    'propertyType'?: string;
    /**
     * 
     * @type {Schools}
     * @memberof FullListing
     */
    'schools'?: Schools;
    /**
     * 
     * @type {number}
     * @memberof FullListing
     */
    'size'?: number;
    /**
     * 
     * @type {string}
     * @memberof FullListing
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof FullListing
     */
    'style'?: string;
    /**
     * 
     * @type {string}
     * @memberof FullListing
     */
    'subdivision'?: string;
    /**
     * 
     * @type {string}
     * @memberof FullListing
     */
    'tourURL'?: string;
    /**
     * 
     * @type {number}
     * @memberof FullListing
     */
    'units'?: number;
    /**
     * 
     * @type {number}
     * @memberof FullListing
     */
    'yearBuilt'?: number;
    /**
     * 
     * @type {string}
     * @memberof FullListing
     */
    'attributionContact'?: string;
    /**
     * 
     * @type {string}
     * @memberof FullListing
     */
    'market'?: string;
    /**
     * 
     * @type {string}
     * @memberof FullListing
     */
    'mlsId'?: string;
}
/**
 * 
 * @export
 * @interface GetListingsByCityStateRequest
 */
export interface GetListingsByCityStateRequest {
    /**
     * 
     * @type {string}
     * @memberof GetListingsByCityStateRequest
     */
    'sortBasis'?: GetListingsByCityStateRequestSortBasisEnum;
    /**
     * 
     * @type {string}
     * @memberof GetListingsByCityStateRequest
     */
    'sortOrder'?: GetListingsByCityStateRequestSortOrderEnum;
    /**
     * 
     * @type {number}
     * @memberof GetListingsByCityStateRequest
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetListingsByCityStateRequest
     */
    'pageSize'?: number;
    /**
     * 
     * @type {PropertyFilters}
     * @memberof GetListingsByCityStateRequest
     */
    'propertyFilters'?: PropertyFilters;
    /**
     * 
     * @type {string}
     * @memberof GetListingsByCityStateRequest
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetListingsByCityStateRequest
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetListingsByCityStateRequest
     */
    'agentId'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum GetListingsByCityStateRequestSortBasisEnum {
    Price = 'PRICE',
    ListingDate = 'LISTING_DATE',
    Distance = 'DISTANCE'
}
/**
    * @export
    * @enum {string}
    */
export enum GetListingsByCityStateRequestSortOrderEnum {
    Ascending = 'ASCENDING',
    Decending = 'DECENDING'
}

/**
 * 
 * @export
 * @interface GetListingsByLatLonRequest
 */
export interface GetListingsByLatLonRequest {
    /**
     * 
     * @type {string}
     * @memberof GetListingsByLatLonRequest
     */
    'sortBasis': GetListingsByLatLonRequestSortBasisEnum;
    /**
     * 
     * @type {string}
     * @memberof GetListingsByLatLonRequest
     */
    'sortOrder': GetListingsByLatLonRequestSortOrderEnum;
    /**
     * 
     * @type {number}
     * @memberof GetListingsByLatLonRequest
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetListingsByLatLonRequest
     */
    'pageSize'?: number;
    /**
     * 
     * @type {PropertyFilters}
     * @memberof GetListingsByLatLonRequest
     */
    'propertyFilters'?: PropertyFilters;
    /**
     * 
     * @type {number}
     * @memberof GetListingsByLatLonRequest
     */
    'latitude': number;
    /**
     * 
     * @type {number}
     * @memberof GetListingsByLatLonRequest
     */
    'longitude': number;
    /**
     * 
     * @type {number}
     * @memberof GetListingsByLatLonRequest
     */
    'radius': number;
    /**
     * 
     * @type {string}
     * @memberof GetListingsByLatLonRequest
     */
    'agentId'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum GetListingsByLatLonRequestSortBasisEnum {
    Price = 'PRICE',
    ListingDate = 'LISTING_DATE',
    Distance = 'DISTANCE'
}
/**
    * @export
    * @enum {string}
    */
export enum GetListingsByLatLonRequestSortOrderEnum {
    Ascending = 'ASCENDING',
    Decending = 'DECENDING'
}

/**
 * 
 * @export
 * @interface GetListingsByZipCodeRequest
 */
export interface GetListingsByZipCodeRequest {
    /**
     * 
     * @type {string}
     * @memberof GetListingsByZipCodeRequest
     */
    'sortBasis'?: GetListingsByZipCodeRequestSortBasisEnum;
    /**
     * 
     * @type {string}
     * @memberof GetListingsByZipCodeRequest
     */
    'sortOrder'?: GetListingsByZipCodeRequestSortOrderEnum;
    /**
     * 
     * @type {number}
     * @memberof GetListingsByZipCodeRequest
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetListingsByZipCodeRequest
     */
    'pageSize'?: number;
    /**
     * 
     * @type {PropertyFilters}
     * @memberof GetListingsByZipCodeRequest
     */
    'propertyFilters'?: PropertyFilters;
    /**
     * 
     * @type {string}
     * @memberof GetListingsByZipCodeRequest
     */
    'zipCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetListingsByZipCodeRequest
     */
    'agentId'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum GetListingsByZipCodeRequestSortBasisEnum {
    Price = 'PRICE',
    ListingDate = 'LISTING_DATE',
    Distance = 'DISTANCE'
}
/**
    * @export
    * @enum {string}
    */
export enum GetListingsByZipCodeRequestSortOrderEnum {
    Ascending = 'ASCENDING',
    Decending = 'DECENDING'
}

/**
 * 
 * @export
 * @interface GetListingsResponse
 */
export interface GetListingsResponse {
    /**
     * 
     * @type {Array<AbbreviatedListing>}
     * @memberof GetListingsResponse
     */
    'listings'?: Array<AbbreviatedListing>;
    /**
     * 
     * @type {Array<LegaleseElement>}
     * @memberof GetListingsResponse
     */
    'legalese'?: Array<LegaleseElement>;
    /**
     * 
     * @type {number}
     * @memberof GetListingsResponse
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetListingsResponse
     */
    'pageSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetListingsResponse
     */
    'hasNextPage'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetListingsResponse
     */
    'error'?: string;
}
/**
 * 
 * @export
 * @interface GetRealListingsWithFilterRequest
 */
export interface GetRealListingsWithFilterRequest {
    /**
     * 
     * @type {number}
     * @memberof GetRealListingsWithFilterRequest
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetRealListingsWithFilterRequest
     */
    'pageSize'?: number;
    /**
     * 
     * @type {PropertyFilters}
     * @memberof GetRealListingsWithFilterRequest
     */
    'propertyFilters'?: PropertyFilters;
    /**
     * 
     * @type {string}
     * @memberof GetRealListingsWithFilterRequest
     */
    'stateCode'?: string;
}
/**
 * 
 * @export
 * @interface LatLonLocation
 */
export interface LatLonLocation {
    /**
     * 
     * @type {number}
     * @memberof LatLonLocation
     */
    'latitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof LatLonLocation
     */
    'longitude'?: number;
}
/**
 * 
 * @export
 * @interface LegaleseElement
 */
export interface LegaleseElement {
    /**
     * 
     * @type {string}
     * @memberof LegaleseElement
     */
    'text'?: string;
    /**
     * 
     * @type {Asset}
     * @memberof LegaleseElement
     */
    'asset'?: Asset;
    /**
     * 
     * @type {string}
     * @memberof LegaleseElement
     */
    'mlsId'?: string;
    /**
     * 
     * @type {string}
     * @memberof LegaleseElement
     */
    'disclaimerType'?: string;
}
/**
 * 
 * @export
 * @interface ListingAgent
 */
export interface ListingAgent {
    /**
     * 
     * @type {string}
     * @memberof ListingAgent
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListingAgent
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListingAgent
     */
    'licenseNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListingAgent
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListingAgent
     */
    'phone'?: string;
}
/**
 * 
 * @export
 * @interface ListingDetailMlsInfo
 */
export interface ListingDetailMlsInfo {
    /**
     * 
     * @type {string}
     * @memberof ListingDetailMlsInfo
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListingDetailMlsInfo
     */
    'name'?: string;
    /**
     * 
     * @type {Array<MlsLexicon>}
     * @memberof ListingDetailMlsInfo
     */
    'lexiconItems'?: Array<MlsLexicon>;
}
/**
 * 
 * @export
 * @interface ListingDetailResponse
 */
export interface ListingDetailResponse {
    /**
     * 
     * @type {FullListing}
     * @memberof ListingDetailResponse
     */
    'fullListing'?: FullListing;
    /**
     * 
     * @type {Array<LegaleseElement>}
     * @memberof ListingDetailResponse
     */
    'legalese'?: Array<LegaleseElement>;
    /**
     * 
     * @type {ListingDetailMlsInfo}
     * @memberof ListingDetailResponse
     */
    'mlsInfo'?: ListingDetailMlsInfo;
}
/**
 * 
 * @export
 * @interface ListingOffice
 */
export interface ListingOffice {
    /**
     * 
     * @type {string}
     * @memberof ListingOffice
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListingOffice
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ListingOffice
     */
    'phone'?: string;
}
/**
 * 
 * @export
 * @interface LotSize
 */
export interface LotSize {
    /**
     * 
     * @type {number}
     * @memberof LotSize
     */
    'sqft'?: number;
    /**
     * 
     * @type {number}
     * @memberof LotSize
     */
    'acres'?: number;
    /**
     * 
     * @type {number}
     * @memberof LotSize
     */
    'frontage'?: number;
    /**
     * 
     * @type {number}
     * @memberof LotSize
     */
    'depth'?: number;
}
/**
 * 
 * @export
 * @interface MlsLexicon
 */
export interface MlsLexicon {
    /**
     * 
     * @type {string}
     * @memberof MlsLexicon
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof MlsLexicon
     */
    'content'?: string;
}
/**
 * 
 * @export
 * @interface PropertyFilters
 */
export interface PropertyFilters {
    /**
     * 
     * @type {number}
     * @memberof PropertyFilters
     */
    'minPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof PropertyFilters
     */
    'maxPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof PropertyFilters
     */
    'minBaths'?: number;
    /**
     * 
     * @type {number}
     * @memberof PropertyFilters
     */
    'minBedrooms'?: number;
    /**
     * 
     * @type {string}
     * @memberof PropertyFilters
     */
    'buyRent'?: PropertyFiltersBuyRentEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof PropertyFilters
     */
    'propertyTypes'?: Array<PropertyFiltersPropertyTypesEnum>;
    /**
     * 
     * @type {boolean}
     * @memberof PropertyFilters
     */
    'flagDistressed'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PropertyFilters
     */
    'flagNewConstruction'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PropertyFilters
     */
    'flagHasPhotos'?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum PropertyFiltersBuyRentEnum {
    Buy = 'BUY',
    Rent = 'RENT'
}
/**
    * @export
    * @enum {string}
    */
export enum PropertyFiltersPropertyTypesEnum {
    House = 'HOUSE',
    Townhouse = 'TOWNHOUSE',
    Condo = 'CONDO',
    Multifamily = 'MULTIFAMILY',
    Land = 'LAND'
}

/**
 * 
 * @export
 * @interface Schools
 */
export interface Schools {
    /**
     * 
     * @type {string}
     * @memberof Schools
     */
    'district'?: string;
    /**
     * 
     * @type {string}
     * @memberof Schools
     */
    'elementary'?: string;
    /**
     * 
     * @type {string}
     * @memberof Schools
     */
    'middle'?: string;
    /**
     * 
     * @type {string}
     * @memberof Schools
     */
    'high'?: string;
}

/**
 * WandererControllerApi - axios parameter creator
 * @export
 */
export const WandererControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get the MLS  listing detail for a given property
         * @param {string} mlsId 
         * @param {string} listingId 
         * @param {string} [agentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListingDetail: async (mlsId: string, listingId: string, agentId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mlsId' is not null or undefined
            assertParamExists('getListingDetail', 'mlsId', mlsId)
            // verify required parameter 'listingId' is not null or undefined
            assertParamExists('getListingDetail', 'listingId', listingId)
            const localVarPath = `/api/v1/propertysearch/listingdetail/{mlsId}/{listingId}`
                .replace(`{${"mlsId"}}`, encodeURIComponent(String(mlsId)))
                .replace(`{${"listingId"}}`, encodeURIComponent(String(listingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (agentId !== undefined) {
                localVarQueryParameter['agentId'] = agentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the MLS  listings for a given city and state
         * @param {GetListingsByCityStateRequest} getListingsByCityStateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListingsByCityAndState: async (getListingsByCityStateRequest: GetListingsByCityStateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getListingsByCityStateRequest' is not null or undefined
            assertParamExists('getListingsByCityAndState', 'getListingsByCityStateRequest', getListingsByCityStateRequest)
            const localVarPath = `/api/v1/propertysearch/listings/city`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getListingsByCityStateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the MLS  listings for a given latitude, longitude and radius
         * @param {GetListingsByLatLonRequest} getListingsByLatLonRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListingsByLatLon: async (getListingsByLatLonRequest: GetListingsByLatLonRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getListingsByLatLonRequest' is not null or undefined
            assertParamExists('getListingsByLatLon', 'getListingsByLatLonRequest', getListingsByLatLonRequest)
            const localVarPath = `/api/v1/propertysearch/listings/latlon`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getListingsByLatLonRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the MLS  listings for a given zip code
         * @param {GetListingsByZipCodeRequest} getListingsByZipCodeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListingsByZip: async (getListingsByZipCodeRequest: GetListingsByZipCodeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getListingsByZipCodeRequest' is not null or undefined
            assertParamExists('getListingsByZip', 'getListingsByZipCodeRequest', getListingsByZipCodeRequest)
            const localVarPath = `/api/v1/propertysearch/listings/zip`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getListingsByZipCodeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the property types (Condominium, Townhouse...) for an MLS, for mapping purposes.
         * @param {string} mlsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPropertyTypeValuesForMLS: async (mlsId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mlsId' is not null or undefined
            assertParamExists('getPropertyTypeValuesForMLS', 'mlsId', mlsId)
            const localVarPath = `/api/v1/propertysearch/propertyTypes/{mlsId}`
                .replace(`{${"mlsId"}}`, encodeURIComponent(String(mlsId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the listing of the most recently listed houses that are listed by Real agents.
         * @param {string} pageNumber 
         * @param {string} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRealListings: async (pageNumber: string, pageSize: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('getRealListings', 'pageNumber', pageNumber)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('getRealListings', 'pageSize', pageSize)
            const localVarPath = `/api/v1/propertysearch/realListings/{pageSize}/{pageNumber}`
                .replace(`{${"pageNumber"}}`, encodeURIComponent(String(pageNumber)))
                .replace(`{${"pageSize"}}`, encodeURIComponent(String(pageSize)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the listing of the most recently listed houses that are listed by Real agents for a given state.
         * @param {string} pageNumber 
         * @param {string} pageSize 
         * @param {string} stateCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRealListingsForState: async (pageNumber: string, pageSize: string, stateCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('getRealListingsForState', 'pageNumber', pageNumber)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('getRealListingsForState', 'pageSize', pageSize)
            // verify required parameter 'stateCode' is not null or undefined
            assertParamExists('getRealListingsForState', 'stateCode', stateCode)
            const localVarPath = `/api/v1/propertysearch/realListings/{pageSize}/{pageNumber}/{stateCode}`
                .replace(`{${"pageNumber"}}`, encodeURIComponent(String(pageNumber)))
                .replace(`{${"pageSize"}}`, encodeURIComponent(String(pageSize)))
                .replace(`{${"stateCode"}}`, encodeURIComponent(String(stateCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the listing of the most recently listed houses that are listed by Real agents.
         * @param {GetRealListingsWithFilterRequest} getRealListingsWithFilterRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRealListingsWithFiltering: async (getRealListingsWithFilterRequest: GetRealListingsWithFilterRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getRealListingsWithFilterRequest' is not null or undefined
            assertParamExists('getRealListingsWithFiltering', 'getRealListingsWithFilterRequest', getRealListingsWithFilterRequest)
            const localVarPath = `/api/v1/propertysearch/realListings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getRealListingsWithFilterRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WandererControllerApi - functional programming interface
 * @export
 */
export const WandererControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WandererControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get the MLS  listing detail for a given property
         * @param {string} mlsId 
         * @param {string} listingId 
         * @param {string} [agentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getListingDetail(mlsId: string, listingId: string, agentId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListingDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getListingDetail(mlsId, listingId, agentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the MLS  listings for a given city and state
         * @param {GetListingsByCityStateRequest} getListingsByCityStateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getListingsByCityAndState(getListingsByCityStateRequest: GetListingsByCityStateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetListingsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getListingsByCityAndState(getListingsByCityStateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the MLS  listings for a given latitude, longitude and radius
         * @param {GetListingsByLatLonRequest} getListingsByLatLonRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getListingsByLatLon(getListingsByLatLonRequest: GetListingsByLatLonRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetListingsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getListingsByLatLon(getListingsByLatLonRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the MLS  listings for a given zip code
         * @param {GetListingsByZipCodeRequest} getListingsByZipCodeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getListingsByZip(getListingsByZipCodeRequest: GetListingsByZipCodeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetListingsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getListingsByZip(getListingsByZipCodeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the property types (Condominium, Townhouse...) for an MLS, for mapping purposes.
         * @param {string} mlsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPropertyTypeValuesForMLS(mlsId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: Array<string>; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPropertyTypeValuesForMLS(mlsId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the listing of the most recently listed houses that are listed by Real agents.
         * @param {string} pageNumber 
         * @param {string} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRealListings(pageNumber: string, pageSize: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetListingsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRealListings(pageNumber, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the listing of the most recently listed houses that are listed by Real agents for a given state.
         * @param {string} pageNumber 
         * @param {string} pageSize 
         * @param {string} stateCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRealListingsForState(pageNumber: string, pageSize: string, stateCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetListingsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRealListingsForState(pageNumber, pageSize, stateCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the listing of the most recently listed houses that are listed by Real agents.
         * @param {GetRealListingsWithFilterRequest} getRealListingsWithFilterRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRealListingsWithFiltering(getRealListingsWithFilterRequest: GetRealListingsWithFilterRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetListingsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRealListingsWithFiltering(getRealListingsWithFilterRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WandererControllerApi - factory interface
 * @export
 */
export const WandererControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WandererControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Get the MLS  listing detail for a given property
         * @param {string} mlsId 
         * @param {string} listingId 
         * @param {string} [agentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListingDetail(mlsId: string, listingId: string, agentId?: string, options?: any): AxiosPromise<ListingDetailResponse> {
            return localVarFp.getListingDetail(mlsId, listingId, agentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the MLS  listings for a given city and state
         * @param {GetListingsByCityStateRequest} getListingsByCityStateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListingsByCityAndState(getListingsByCityStateRequest: GetListingsByCityStateRequest, options?: any): AxiosPromise<GetListingsResponse> {
            return localVarFp.getListingsByCityAndState(getListingsByCityStateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the MLS  listings for a given latitude, longitude and radius
         * @param {GetListingsByLatLonRequest} getListingsByLatLonRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListingsByLatLon(getListingsByLatLonRequest: GetListingsByLatLonRequest, options?: any): AxiosPromise<GetListingsResponse> {
            return localVarFp.getListingsByLatLon(getListingsByLatLonRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the MLS  listings for a given zip code
         * @param {GetListingsByZipCodeRequest} getListingsByZipCodeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListingsByZip(getListingsByZipCodeRequest: GetListingsByZipCodeRequest, options?: any): AxiosPromise<GetListingsResponse> {
            return localVarFp.getListingsByZip(getListingsByZipCodeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the property types (Condominium, Townhouse...) for an MLS, for mapping purposes.
         * @param {string} mlsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPropertyTypeValuesForMLS(mlsId: string, options?: any): AxiosPromise<{ [key: string]: Array<string>; }> {
            return localVarFp.getPropertyTypeValuesForMLS(mlsId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the listing of the most recently listed houses that are listed by Real agents.
         * @param {string} pageNumber 
         * @param {string} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRealListings(pageNumber: string, pageSize: string, options?: any): AxiosPromise<GetListingsResponse> {
            return localVarFp.getRealListings(pageNumber, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the listing of the most recently listed houses that are listed by Real agents for a given state.
         * @param {string} pageNumber 
         * @param {string} pageSize 
         * @param {string} stateCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRealListingsForState(pageNumber: string, pageSize: string, stateCode: string, options?: any): AxiosPromise<GetListingsResponse> {
            return localVarFp.getRealListingsForState(pageNumber, pageSize, stateCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the listing of the most recently listed houses that are listed by Real agents.
         * @param {GetRealListingsWithFilterRequest} getRealListingsWithFilterRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRealListingsWithFiltering(getRealListingsWithFilterRequest: GetRealListingsWithFilterRequest, options?: any): AxiosPromise<GetListingsResponse> {
            return localVarFp.getRealListingsWithFiltering(getRealListingsWithFilterRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WandererControllerApi - object-oriented interface
 * @export
 * @class WandererControllerApi
 * @extends {BaseAPI}
 */
export class WandererControllerApi extends BaseAPI {
    /**
     * 
     * @summary Get the MLS  listing detail for a given property
     * @param {string} mlsId 
     * @param {string} listingId 
     * @param {string} [agentId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WandererControllerApi
     */
    public getListingDetail(mlsId: string, listingId: string, agentId?: string, options?: AxiosRequestConfig) {
        return WandererControllerApiFp(this.configuration).getListingDetail(mlsId, listingId, agentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the MLS  listings for a given city and state
     * @param {GetListingsByCityStateRequest} getListingsByCityStateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WandererControllerApi
     */
    public getListingsByCityAndState(getListingsByCityStateRequest: GetListingsByCityStateRequest, options?: AxiosRequestConfig) {
        return WandererControllerApiFp(this.configuration).getListingsByCityAndState(getListingsByCityStateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the MLS  listings for a given latitude, longitude and radius
     * @param {GetListingsByLatLonRequest} getListingsByLatLonRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WandererControllerApi
     */
    public getListingsByLatLon(getListingsByLatLonRequest: GetListingsByLatLonRequest, options?: AxiosRequestConfig) {
        return WandererControllerApiFp(this.configuration).getListingsByLatLon(getListingsByLatLonRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the MLS  listings for a given zip code
     * @param {GetListingsByZipCodeRequest} getListingsByZipCodeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WandererControllerApi
     */
    public getListingsByZip(getListingsByZipCodeRequest: GetListingsByZipCodeRequest, options?: AxiosRequestConfig) {
        return WandererControllerApiFp(this.configuration).getListingsByZip(getListingsByZipCodeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the property types (Condominium, Townhouse...) for an MLS, for mapping purposes.
     * @param {string} mlsId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WandererControllerApi
     */
    public getPropertyTypeValuesForMLS(mlsId: string, options?: AxiosRequestConfig) {
        return WandererControllerApiFp(this.configuration).getPropertyTypeValuesForMLS(mlsId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the listing of the most recently listed houses that are listed by Real agents.
     * @param {string} pageNumber 
     * @param {string} pageSize 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WandererControllerApi
     */
    public getRealListings(pageNumber: string, pageSize: string, options?: AxiosRequestConfig) {
        return WandererControllerApiFp(this.configuration).getRealListings(pageNumber, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the listing of the most recently listed houses that are listed by Real agents for a given state.
     * @param {string} pageNumber 
     * @param {string} pageSize 
     * @param {string} stateCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WandererControllerApi
     */
    public getRealListingsForState(pageNumber: string, pageSize: string, stateCode: string, options?: AxiosRequestConfig) {
        return WandererControllerApiFp(this.configuration).getRealListingsForState(pageNumber, pageSize, stateCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the listing of the most recently listed houses that are listed by Real agents.
     * @param {GetRealListingsWithFilterRequest} getRealListingsWithFilterRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WandererControllerApi
     */
    public getRealListingsWithFiltering(getRealListingsWithFilterRequest: GetRealListingsWithFilterRequest, options?: AxiosRequestConfig) {
        return WandererControllerApiFp(this.configuration).getRealListingsWithFiltering(getRealListingsWithFilterRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


