import { values } from 'lodash';
import qs from 'qs';
import config from '../config';
import {
  AgentFlexTeamMembershipResponse,
  AgentResponse,
  AgentTeamMembershipResponse,
  AgentTeamMembershipResponseRolesEnum,
  FlexTeamConfigDtoCountryEnum,
  FlexTeamConfigDtoTeamTypeEnum,
  FlexTeamDto,
  FlexTeammateDtoRolesEnum,
  GenericTeamInvitationApplicationResponseStatusEnum,
  TeamInvitationDto,
  TeamInvitationDtoStatusEnum,
  TeamInvitationSimplifiedDtoInvitationStatusEnum,
  TeamMemberResponseRolesEnum,
  TeamResponse,
  TeamResponseTypeEnum,
  UpdateTeamMembersRequest,
  UserPreviewResponseAgentAccountCountryEnum,
  ValidateGenericTeamInvitationResponse,
} from '../openapi/yenta';
import { EnumMap, FlexTeamRoles, ISelectOption } from '../types';
import { safeEnumMapGet } from './EnumHelper';
import { getMemberSplit } from './ProTeams/ProTeamsHelper';
import { NUMBER_REGEX, capitalizeEnum } from './StringUtils';
import { isEmailInvite, isLinkInvite } from './TypeUtils';

export const getCombinedMembershipsTeam = (
  agentResponse: AgentResponse | undefined,
): AgentTeamMembershipResponse[] => {
  if (!agentResponse) {
    return [];
  }

  const flexTeamMemberships = agentResponse.flexTeamMemberships || [];
  const agentId = agentResponse.id;

  let allTeamMemberships: AgentTeamMembershipResponse[] = [];

  if (flexTeamMemberships.length) {
    // Team Leaders
    const teamLeaderMemberships = flexTeamMemberships.filter((team) =>
      team.leaders?.some((a) => a?.agentId === agentId),
    );

    if (teamLeaderMemberships.length) {
      teamLeaderMemberships.map((leader) =>
        allTeamMemberships.push({
          team: {
            id: leader?.teamId,
            name: leader?.teamName,
            type: leader?.teamType,
          },
          roles: [AgentTeamMembershipResponseRolesEnum.Leader],
          memberCommissionSplit: 100, // This is a default value.
        }),
      );
    }

    // Team Members
    const teamMateMemberships = flexTeamMemberships!
      .map((team) => ({
        ...team,
        teammates: team?.teammates?.filter(
          (teammate) => teammate.agentId === agentId,
        ),
      }))
      .filter((team) => team?.teammates!.length > 0);

    if (teamMateMemberships.length) {
      teamMateMemberships.map((membership) =>
        allTeamMemberships.push({
          team: {
            id: membership?.teamId,
            name: membership?.teamName,
            type: membership?.teamType,
          },
          roles: [
            membership?.teammates![0]?.roles!.length > 0
              ? AgentTeamMembershipResponseRolesEnum.Admin
              : AgentTeamMembershipResponseRolesEnum.Member,
          ],
          memberCommissionSplit: getMemberSplit(membership?.teammates![0]),
        }),
      );
    }
  }

  return allTeamMemberships;
};

export const teamTypeToDisplayName = (
  type: TeamResponseTypeEnum | FlexTeamConfigDtoTeamTypeEnum,
) => {
  if (type === TeamResponseTypeEnum.Group) {
    return 'Marketing';
  }

  return capitalizeEnum(type);
};

export const getTeamTypeSelectOptions = (): ISelectOption[] => {
  return values(TeamResponseTypeEnum).map((key) => ({
    label: teamTypeToDisplayName(key),
    value: key,
  }));
};

export const mapTeamRoleToType = (teamType: TeamResponseTypeEnum) => {
  let roleArray: ISelectOption[] = [];
  if (
    teamType === TeamResponseTypeEnum.Domestic ||
    teamType === TeamResponseTypeEnum.Group
  ) {
    roleArray = values(TeamMemberResponseRolesEnum)
      .filter((role) => role !== TeamMemberResponseRolesEnum.Leader)
      .map((role) => ({
        value: role,
        label: capitalizeEnum(role),
      }));
  } else {
    roleArray = values(TeamMemberResponseRolesEnum).map((role) => ({
      value: role,
      label: capitalizeEnum(role),
    }));
  }

  return roleArray;
};

export const getCommissionValidation = () => {
  let minValue = 0;
  let maxValue = 100;

  return {
    required: 'Please enter commission split',
    min: {
      value: minValue,
      message: `Number cannot be less than ${minValue}`,
    },
    max: {
      value: maxValue,
      message: `Number cannot be greater than ${maxValue}`,
    },
    pattern: {
      value: NUMBER_REGEX,
      message: 'Please enter a valid number',
    },
  };
};

export enum TeamActionStatus {
  SUCCESS_TOAST = 'SUCCESS_TOAST',
  ERROR_SERVICE = 'ERROR_SERVICE',
  ERROR_TOAST = 'ERROR_TOAST',
}

export type teamAction = 'create' | 'update' | 'delete';

export const messageMapToTeamAction = (
  actionType: TeamActionStatus,
  req: UpdateTeamMembersRequest,
) => {
  const statusMapToTeamAction: EnumMap<
    TeamActionStatus,
    { [key in teamAction]: string }
  > = {
    SUCCESS_TOAST: { create: 'created', update: 'updated', delete: 'deleted' },
    ERROR_TOAST: { create: 'creating', update: 'updating', delete: 'deleting' },
    ERROR_SERVICE: { create: 'create', update: 'update', delete: 'delete' },
  };

  if (req.createRequests?.length) {
    return statusMapToTeamAction[actionType].create;
  } else if (req.updateRequests?.length) {
    return statusMapToTeamAction[actionType].update;
  } else if (req.userIdsToDelete?.length) {
    return statusMapToTeamAction[actionType].delete;
  }

  return undefined;
};

export const isTeamTypeEligibleForInstantPayments = (
  type: TeamResponseTypeEnum | FlexTeamConfigDtoTeamTypeEnum,
) => {
  const eligibleToggleMapToInstantPayment: EnumMap<
    TeamResponseTypeEnum | FlexTeamConfigDtoTeamTypeEnum,
    boolean
  > = {
    DOMESTIC: false,
    GROUP: false,
    NORMAL: true,
    PLATINUM: true,
    PRO: false,
  };

  return safeEnumMapGet(eligibleToggleMapToInstantPayment, type, true);
};

export const getRemainingPercentage = (value: number | undefined | null) => {
  return (100 - (value ?? 0)).toString();
};

export const isTeamAdminOrTeamLeader = (userDetails: AgentResponse) => {
  const isLeader = userDetails?.flexTeamMemberships?.some((membership) => {
    return membership.leaders?.some(
      (leader) => leader?.agentId === userDetails.id,
    );
  });

  const isAdmin = userDetails?.flexTeamMemberships?.some((membership) => {
    const agentTeammate =
      membership.teammates?.filter(
        (teammate) =>
          teammate.agentId === userDetails.id &&
          teammate.roles?.includes(FlexTeammateDtoRolesEnum.Admin),
      ) || [];

    return agentTeammate.length > 0;
  });

  return isLeader || isAdmin;
};

export const isTeamLeaderOrAdminPerTeam = (
  team: AgentFlexTeamMembershipResponse,
  id: string,
): boolean => {
  const isTeamAdmin = team.teammates?.find(
    (member) =>
      id === member.agentId &&
      member?.roles?.includes(FlexTeammateDtoRolesEnum.Admin),
  )!;
  const isLeader = team.leaders?.find((leader) => id === leader.agentId!)!;

  return !!isTeamAdmin || !!isLeader;
};

export const getTeamsWithAdminOrLeaderRole = (
  userDetail: AgentResponse,
): AgentFlexTeamMembershipResponse[] => {
  const teams: AgentFlexTeamMembershipResponse[] = userDetail.flexTeamMemberships?.filter(
    (team) => isTeamLeaderOrAdminPerTeam(team, userDetail?.id!),
  )!;

  return teams;
};

export const getTeamIds = (userDetail: AgentResponse): string[] => {
  const flexTeamMemberships = userDetail.flexTeamMemberships || [];

  if (!!flexTeamMemberships.length) {
    const teamIds = flexTeamMemberships.filter((team) =>
      isTeamLeaderOrAdminPerTeam(team, userDetail?.id!),
    );

    return teamIds.map((team) => team.teamId!);
  }
  return [];
};

export const isInvitationModificationAllowed = (
  status:
    | TeamInvitationSimplifiedDtoInvitationStatusEnum
    | GenericTeamInvitationApplicationResponseStatusEnum,
): boolean => {
  const revokableStatusEnumMap: Record<
    | TeamInvitationSimplifiedDtoInvitationStatusEnum
    | GenericTeamInvitationApplicationResponseStatusEnum,
    boolean
  > = {
    [TeamInvitationSimplifiedDtoInvitationStatusEnum.Cancelled]: false,
    [TeamInvitationSimplifiedDtoInvitationStatusEnum.Redeemed]: false,
    [TeamInvitationSimplifiedDtoInvitationStatusEnum.SignIca]: false,
    [TeamInvitationSimplifiedDtoInvitationStatusEnum.IcaSigned]: false,
    [TeamInvitationSimplifiedDtoInvitationStatusEnum.PayFees]: false,
    [TeamInvitationSimplifiedDtoInvitationStatusEnum.FeesPaid]: false,
    [TeamInvitationSimplifiedDtoInvitationStatusEnum.TransferLicense]: false,
    [TeamInvitationSimplifiedDtoInvitationStatusEnum.LicenseTransferred]: false,
    [TeamInvitationSimplifiedDtoInvitationStatusEnum.JoinMls]: false,
    [TeamInvitationSimplifiedDtoInvitationStatusEnum.MlsJoined]: false,
    [TeamInvitationSimplifiedDtoInvitationStatusEnum.JoinBoard]: false,
    [TeamInvitationSimplifiedDtoInvitationStatusEnum.BoardJoined]: false,
    [TeamInvitationSimplifiedDtoInvitationStatusEnum.PendingApproval]: false,
    [TeamInvitationSimplifiedDtoInvitationStatusEnum.Approved]: false,
    [TeamInvitationSimplifiedDtoInvitationStatusEnum.Rejected]: false,
    [TeamInvitationSimplifiedDtoInvitationStatusEnum.SignLetterOfIntent]: false,
    [TeamInvitationSimplifiedDtoInvitationStatusEnum.LetterOfIntentSigned]: false,
    [TeamInvitationSimplifiedDtoInvitationStatusEnum.FeesWaived]: false,
    [TeamInvitationSimplifiedDtoInvitationStatusEnum.Created]: false,
    [TeamInvitationSimplifiedDtoInvitationStatusEnum.Started]: false,
    [TeamInvitationSimplifiedDtoInvitationStatusEnum.WaitingOnLicenseTransfer]: false,
    [TeamInvitationSimplifiedDtoInvitationStatusEnum.EmailClicked]: true,
    [TeamInvitationSimplifiedDtoInvitationStatusEnum.Emailed]: true,
    [TeamInvitationSimplifiedDtoInvitationStatusEnum.Pending]: true,
    [TeamInvitationSimplifiedDtoInvitationStatusEnum.LinkClicked]: false,
  };

  return safeEnumMapGet(revokableStatusEnumMap, status, false);
};

export const getPersonalizedEmailInvitationLink = (
  invitationId: string,
  firstName: string,
  lastName: string,
  email: string,
  team: string,
  cap: number | undefined,
  country:
    | UserPreviewResponseAgentAccountCountryEnum
    | FlexTeamConfigDtoCountryEnum
    | undefined,
) => {
  return `${config.reactAppHostUrl}/join/email/${invitationId}?${qs.stringify({
    firstName,
    lastName,
    email,
    team,
    cap,
    country,
  })}`;
};

export const getPersonalizedInvitationLink = (
  invitationId: string,
  team: string,
  cap: number | undefined,
  country:
    | FlexTeamConfigDtoCountryEnum
    | UserPreviewResponseAgentAccountCountryEnum
    | undefined,
) => {
  return `${config.reactAppHostUrl}/join/link/${invitationId}?${qs.stringify({
    team,
    cap,
    country,
  })}`;
};

export const getTeamCountry = (team: TeamResponse | null) => {
  return (
    team?.config?.country || team?.agents?.[0]?.agent?.agentAccountCountry!
  );
};

export const isApplicationCreated = (
  invitation:
    | TeamInvitationDto
    | ValidateGenericTeamInvitationResponse
    | undefined,
): boolean => {
  if (isEmailInvite(invitation)) {
    // If the applicationId is present, then the application has been created.
    return !!invitation.applicationId;
  }

  return false;
};

export const isInvitationExpired = (
  invitation:
    | TeamInvitationDto
    | ValidateGenericTeamInvitationResponse
    | undefined,
): boolean => {
  if (isEmailInvite(invitation)) {
    const isInvitationCancelled =
      invitation.status === TeamInvitationDtoStatusEnum.Cancelled;

    return isApplicationCreated(invitation) || isInvitationCancelled;
  }

  if (isLinkInvite(invitation)) {
    return !invitation.valid;
  }

  return false;
};

export const getTeamMemberRole = (
  role: AgentTeamMembershipResponseRolesEnum,
  isProTeam?: boolean,
) => {
  if (!!isProTeam && role === AgentTeamMembershipResponseRolesEnum.Member) {
    return FlexTeamRoles.Teammate;
  } else {
    return role;
  }
};

export const getTeamMembersTotalCount = (
  isProTeam: boolean,
  isSelfServiceEnabled: boolean,
  teamDetail: TeamResponse | FlexTeamDto,
) => {
  if (isProTeam) {
    const teamDetailOverview = teamDetail as FlexTeamDto;

    if (isSelfServiceEnabled) {
      return (
        (teamDetailOverview?.teammates?.length || 0) +
        (teamDetailOverview?.leaders?.length || 0) +
        (teamDetailOverview?.teamInvitations?.length || 0) +
        (teamDetailOverview?.pendingGenericTeamApplications?.length || 0)
      );
    }

    return (
      (teamDetailOverview?.teammates?.length || 0) +
      (teamDetailOverview?.leaders?.length || 0)
    );
  }

  const teamDetailOverview = teamDetail as TeamResponse;

  if (isSelfServiceEnabled) {
    return (
      (teamDetailOverview?.agents?.length || 0) +
      (teamDetailOverview?.teamInvitations?.length || 0) +
      (teamDetailOverview?.pendingGenericTeamApplications?.length || 0)
    );
  }

  return teamDetailOverview?.agents?.length || 0;
};
