import config from '../config';
import { Configuration as ArrakisConfiguration } from '../openapi/arrakis';
import { Configuration as AvalonConfiguration } from '../openapi/avalon';
import { Configuration as BffConfiguration } from '../openapi/bff';
import { Configuration as DropboxConfiguration } from '../openapi/dropbox';
import { Configuration as HermesConfiguration } from '../openapi/hermes';
import { Configuration as KeymakerConfiguration } from '../openapi/keymaker';
import { Configuration as LeoConfiguration } from '../openapi/leo';
import { Configuration as MementoConfiguration } from '../openapi/memento';
import { Configuration as MercuryConfiguration } from '../openapi/mercury';
import { Configuration as SherlockConfiguration } from '../openapi/sherlock';
import { Configuration as WandererConfiguration } from '../openapi/wanderer';
import { Configuration as YadaConfiguration } from '../openapi/yada';
import { Configuration as YentaConfiguration } from '../openapi/yenta';
import { Configuration as AtlantisConfiguration } from '../openapi/atlantis';
import { Configuration as SignatureConfiguration } from '../openapi/signature-api';
import { Configuration as WealthManagementConfiguration } from '../openapi/plutus';
import { Configuration as InsigniaConfiguration } from '../openapi/insignia';
import { Configuration as WalletConfiguration } from '../openapi/wallet';
import { Configuration as SiriusConfiguration } from '../openapi/sirius';
import { getAuthCookie } from './AuthUtils';

const baseOptions = {
  headers: {
    'X-real-app-name': 'bolt-web',
    'X-real-app-version': config.deployVersion,
  },
};

export const getYentaConfiguration = () =>
  new YentaConfiguration({
    accessToken: getAuthCookie(),
    basePath: config.apiBasePath.yenta,
    baseOptions,
  });

export const getKeymakerConfiguration = () =>
  new KeymakerConfiguration({
    accessToken: getAuthCookie(),
    basePath: config.apiBasePath.keymaker,
    baseOptions,
  });

export const getArrakisConfiguration = () =>
  new ArrakisConfiguration({
    accessToken: getAuthCookie(),
    basePath: config.apiBasePath.arrakis,
    baseOptions,
  });

export const getHermesConfiguration = () =>
  new HermesConfiguration({
    accessToken: getAuthCookie(),
    basePath: config.apiBasePath.hermes,
    baseOptions,
  });

export const getWandererConfiguration = () =>
  new WandererConfiguration({
    accessToken: getAuthCookie(),
    basePath: config.apiBasePath.wanderer,
    baseOptions,
  });

export const getSherlockConfiguration = () =>
  new SherlockConfiguration({
    accessToken: getAuthCookie(),
    basePath: config.apiBasePath.sherlock,
    baseOptions,
  });

export const getYadaConfiguration = () =>
  new YadaConfiguration({
    accessToken: getAuthCookie(),
    basePath: config.apiBasePath.yada,
    baseOptions,
  });

export const getMementoConfiguration = () =>
  new MementoConfiguration({
    accessToken: getAuthCookie(),
    basePath: config.apiBasePath.memento,
    baseOptions,
  });

export const getBffConfiguration = () =>
  new BffConfiguration({
    accessToken: getAuthCookie(),
    basePath: config.apiBasePath.bff,
    baseOptions,
  });

export const getDropboxConfiguration = () =>
  new DropboxConfiguration({
    accessToken: getAuthCookie(),
    basePath: config.apiBasePath.dropbox,
    baseOptions,
  });

export const getAvalonConfiguration = () =>
  new AvalonConfiguration({
    accessToken: getAuthCookie(),
    basePath: config.apiBasePath.avalon,
    baseOptions,
  });

export const getMercuryConfiguration = () =>
  new MercuryConfiguration({
    accessToken: getAuthCookie(),
    basePath: config.apiBasePath.mercury,
    baseOptions,
  });

export const getLeoConfiguration = () =>
  new LeoConfiguration({
    accessToken: getAuthCookie(),
    basePath: config.apiBasePath.leo,
    baseOptions,
  });

export const getAtlantisConfiguration = () =>
  new AtlantisConfiguration({
    accessToken: getAuthCookie(),
    basePath: config.apiBasePath.atlantis,
    baseOptions,
  });

export const getSignatureApiConfiguration = () =>
  new SignatureConfiguration({
    accessToken: getAuthCookie(),
    basePath: config.apiBasePath.signature,
    baseOptions,
  });

export const getWealthManagementConfiguration = () =>
  new WealthManagementConfiguration({
    accessToken: getAuthCookie(),
    basePath: config.apiBasePath.plutus,
    baseOptions,
  });

export const getInsigniaConfiguration = () =>
  new InsigniaConfiguration({
    accessToken: getAuthCookie(),
    basePath: config.apiBasePath.insignia,
    baseOptions,
  });

export const getWalletConfiguration = () =>
  new WalletConfiguration({
    accessToken: getAuthCookie(),
    basePath: config.apiBasePath.wallet,
    baseOptions,
  });

export const getSiriusConfiguration = () =>
  new SiriusConfiguration({
    accessToken: getAuthCookie(),
    basePath: config.apiBasePath.sirius,
    baseOptions,
  });
