import parsePhoneNumber from 'libphonenumber-js';
import { capitalize } from 'lodash';
import { Address, AddressCountryEnum } from '../openapi/arrakis';
import {
  AddressRequestCountryEnum,
  AddressResponse,
  AdministrativeAreaRequestCountryEnum,
  AgentResponseAccountCountryEnum,
  TaxValueTypeEnum,
  UserResponse,
} from '../openapi/yenta';
import { EnumMap, PartialEnumMap } from '../types';
import { safeEnumMapGet } from './EnumHelper';

export const WEBSITE_REGEX = /^((https?):\/\/)?((www.)?)[a-zA-Z0-9]+([-/_.]{1}[a-zA-Z0-9]+)*\.[a-zA-Z]{2,}(:[0-9]{1,5})?(\/.*)?\s*$/;

export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const PHONE_REGEX = /^1[0-9]{10}/;

export const ALPHA_NUMERIC_REGEX_NO_SPACE = /^[a-zA-Z0-9]*$/;

export const ALPHA_NUMERIC_REGEX = /[a-zA-Z0-9]/;

export const NAME_REGEX = /^[a-z0-9 ,.'-]+$/i;

export const NUMBER_REGEX = /^[0-9]+$/;

export const WHITESPACE_REGEX = /\s/;

// 100.12, 100.1 and 100 are acceptable. 100.111 is not.
export const MONEY_AMOUNT_REGEX = /^\d+(\.\d{1,2})?$/;

// 100.12, 100.1 and 100 are acceptable. 100.111 is not. Plus negatives.
export const MONEY_AMOUNT_REGEX_ALLOW_NEGATIVES = /^-?\d+(\.\d{1,2})?$/;

export const US_ROUTING_NUMBER = /^[0-9]{9}$/;

export const CA_ROUTING_NUMBER = /^[0-9]{5}$/;

export const GOOGLE_MEET_REGEX = /https:\/\/meet\.google\.com\//i;

export const ZOOM_MEET_REGEX = /https:\/\/[\w-]*\.?zoom.us\/(j|my)\/[\d\w?=-]+/g;

export const PERCENT_REGEX = /(^100(\.0{1,2})?$)|(^([1-9]([0-9])?|0)(\.[0-9]{1,2})?$)/g;

export const YEAR_REGEX = /^[0-9]{4}$/;

export const US_POSTAL_CODE = /(^\d{5}$)|(^\d{5}-\d{4}$)/;

export const CA_POSTAL_CODE = /^[A-Za-z]\d[A-Za-z] \d[A-Za-z]\d$/;

export const CA_BANK_INSTITUTION_NUMBER = /^[0-9]{3}$/;

export const CA_BANK_TRANSIT_NUMBER = /^[0-9]{5}$/;

export const US_EIN_NUMBER = /^\d{2}-\d{7}$/;
export const CA_BUSINESS_NUMBER = /^\d{9}$/;
export const CA_GST_NUMBER = /^\d{9}RT\d{4}$/;
export const CA_HST_NUMBER = CA_GST_NUMBER;
export const CA_QST_NUMBER = /^\d{10}TQ\d{4}$/;

export const DATE_REGEX = /^(0[1-9]|1[0-2])\/(0[1-9]|[1-2][0-9]|3[0-1])\/\d{4}$/;

export const getRoutingNumberForCountry = (
  country:
    | AddressRequestCountryEnum
    | AgentResponseAccountCountryEnum
    | undefined,
): RegExp => {
  if (!country) {
    return US_ROUTING_NUMBER;
  }

  const map: EnumMap<AddressRequestCountryEnum, RegExp> = {
    CANADA: CA_ROUTING_NUMBER,
    UNITED_STATES: US_ROUTING_NUMBER,
  };

  return map[country];
};

export const getPostalCodeForCountry = (
  country:
    | AddressRequestCountryEnum
    | AgentResponseAccountCountryEnum
    | AddressCountryEnum
    | undefined,
): RegExp => {
  if (!country) {
    return US_POSTAL_CODE;
  }

  const map: EnumMap<AddressRequestCountryEnum, RegExp> = {
    CANADA: CA_POSTAL_CODE,
    UNITED_STATES: US_POSTAL_CODE,
  };

  return map[country];
};
export const UUID_REGEX =
  '[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}';

export const UUID_RE = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

export const SYMBOLS_REGEX = /[!@#$%*()"';:,<>|\\\/=\-_^&]/;

export const formatAddressObject = (address: AddressResponse): string => {
  return formatAddress(
    address.streetAddress1 ?? '',
    address.streetAddress2,
    address.city ?? '',
    capitalizeEnum(address.stateOrProvince ?? ''),
    address.zipOrPostalCode ?? '',
  );
};

export const formatTaxDocAddress = (address: Address) => {
  if (!address) {
    return '';
  }
  return formatAddress(
    address.street ?? '',
    address.street2 ?? undefined,
    address.city ?? '',
    capitalizeEnum(address?.state ?? ''),
    address.zip ?? '',
  );
};

export const formatAddress = (
  addressLine1: string,
  addressLine2: string | undefined,
  city: string,
  state: string,
  zip: string,
) => {
  return `${addressLine1},${
    addressLine2 ? ` ${addressLine2},` : ''
  } ${city}, ${state} ${zip}`;
};

export const parseInitials = (name: string | null | undefined): string => {
  if (name === null || name === undefined) return 'N/A';
  return name
    .split(' ')
    .map((n) => n && n[0])
    .filter((n) => ALPHA_NUMERIC_REGEX.test(n))
    .slice(0, 2)
    .join('')
    .toUpperCase();
};

export const stringToNumber = (string: string): number => {
  return string.toUpperCase().charCodeAt(0);
};

const taxTypeEnumLabelMap: PartialEnumMap<TaxValueTypeEnum, string> = {
  [TaxValueTypeEnum.BAndO]: 'B&O',
  [TaxValueTypeEnum.LaCbt]: 'LA CBT',
};

export const capitalizeEnum = (label: string): string => {
  if (label in taxTypeEnumLabelMap) {
    return taxTypeEnumLabelMap[label as TaxValueTypeEnum]!;
  }
  return label
    ?.split('_')
    ?.map((str) => prettyEnum(str))
    ?.join(' ');
};

export const enumToCamelCase = (str: string) => {
  return str
    .toLowerCase()
    .replace(/_./g, (match) => match.charAt(1).toUpperCase());
};

const prettyEnum = (str: string) => {
  const lower = str.toLowerCase();
  if (lower === 'ica') return 'ICA';
  if (lower === 'mls') return 'MLS';
  if (lower === 'gci') return 'GCI';
  if (lower === 'gst') return 'GST';
  if (lower === 'hst') return 'HST';
  if (lower === 'pst') return 'PST';
  if (lower === 'qst') return 'QST';
  if (lower === 'ssn') return 'SSN';
  if (lower === 'id') return 'ID';
  if (lower === 'us') return 'US';
  if (lower === 'llc') return 'LLC';
  if (lower === 'grt') return 'GRT';
  if (lower === 'get') return 'GET';
  if (lower === 'cbj') return 'CBJ';
  if (lower === 'loi') return 'LOI';
  if (lower === 'ca') return 'CA';

  return capitalize(str);
};

export const cleanPhoneNumber = (phoneNumberString: string | undefined) => {
  return ('' + phoneNumberString).replace(/\D/g, '');
};

export const capitalizeFirstLetter = (str: string) => {
  return str
    .replace(/_/g, ' ')
    .replace(/(\B)[^ ]*/g, (match: any) => match.toLowerCase())
    .replace(/^[^ ]/g, (match: any) => match.toUpperCase())
    .replace(/\s/g, '');
};

export const formatPhoneNumber = (
  phoneNumberString: string | undefined,
): string | undefined => {
  if (!phoneNumberString) {
    return undefined;
  }

  const phoneNumber = parsePhoneNumber('+' + phoneNumberString);

  // Check if the number is valid and if it's a US/Canadian number
  if (phoneNumber?.isValid() && phoneNumber.countryCallingCode === '1') {
    // Custom formatting for US and Canadian phone numbers
    const nationalNumber = phoneNumber.nationalNumber;
    // Format: (XXX) XXX-XXXX
    const formatted = nationalNumber.replace(
      /(\d{3})(\d{3})(\d{4})/,
      '($1) $2-$3',
    );
    return `+${phoneNumber.countryCallingCode} ${formatted}`;
  }

  if (phoneNumber?.isValid()) {
    // For other international numbers, use the default international format
    return phoneNumber.formatInternational();
  }

  // Edge case - numbers without any country calling code
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    const intlCode = match[1] ? '+1 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }

  const parsedNumber = parseInt(cleaned, 10);
  if (!isNaN(parsedNumber)) {
    return phoneNumberString;
  }

  return undefined;
};

export const formatFaxNumber = (
  faxNumberString: string | undefined,
): string | undefined => {
  if (!faxNumberString) {
    return undefined;
  }

  const cleaned = faxNumberString.replace(/\D/g, '');

  // Handle the first part: international code and area code
  let result = `+${cleaned[0]} (${cleaned.slice(1, 4)})`;

  // Handle the second part: first set of numbers after area code
  if (cleaned.length > 4) {
    result += `-${cleaned.slice(4, 7)}`;
  }

  // Handle the third part: second set of numbers after area code
  if (cleaned.length > 7) {
    result += `-${cleaned.slice(7)}`;
  }

  return result;
};

export const fullName = (user: UserResponse) =>
  `${user.firstName} ${user.middleName} ${user.lastName}`;

export const getFileNameFromUrl = (url: string) =>
  url?.split('/').pop()?.split('#')[0].split('?')[0];

export const isStringPresent = (
  str: string | undefined | null,
): str is string => {
  return !isStringEmpty(str);
};

export const isStringEmpty = (str: string | undefined | null): boolean => {
  return !str || str.length === 0;
};

export const isEqualAndNotEmpty = (str1: string, str2: string): boolean => {
  if (isStringEmpty(str1) || isStringEmpty(str2)) {
    return false;
  }

  return str1 === str2;
};

export const displayStringOrNA = (str?: string) => str || 'N/A';

export const displayStringOrNotSelected = (str?: string) =>
  str || 'Not Selected';

export const splitNameIntoFirstAndLast = (
  name: string,
): { firstName: string; lastName: string } => {
  if (!name) {
    return { firstName: '', lastName: '' };
  }

  const nameArray = name.split(' ').filter((letter) => letter);
  const isLengthOdd = nameArray.length % 2 !== 0;
  let indexToSplit = Math.floor(nameArray.length / 2);

  if (isLengthOdd) {
    indexToSplit += 1;
  }

  const firstName = nameArray.slice(0, indexToSplit).join(' ');
  const lastName = nameArray.slice(indexToSplit).join(' ');

  return { firstName, lastName };
};

export const isEmptyEditor = (text: string): boolean => {
  return (
    typeof text === 'undefined' ||
    text.replace(/<(.|\n)*?>/g, '').trim().length === 0
  );
};

export const stringToHexCode = (str: string) => {
  if (str) {
    const hashCode = str
      .toUpperCase()
      .split('')
      .reduce((sum, s) => s.charCodeAt(0) + ((sum << 3) + sum), 0);
    const color = Math.abs(hashCode).toString(16).substring(0, 6);
    return '#' + color;
  }
  return '#3B82F6';
};

export const getValueOrEmptyString = (value: string | undefined): string => {
  return value ? value : '';
};

export const getNumberBasedOnString = (str: string, modulus: number) => {
  return (
    str
      .toUpperCase()
      .split('')
      .map((s) => s.charCodeAt(0))
      .reduce((sum, a) => sum + a, 0) % modulus
  );
};

export const getCountryFlagCode = (
  country: AdministrativeAreaRequestCountryEnum | undefined,
) => {
  if (country === 'CANADA') {
    return 'ca';
  }
  return 'us';
};

export const getCountryCode = (
  isAdmin: boolean,
  country?: AgentResponseAccountCountryEnum,
) => {
  if (isAdmin) {
    return 'us';
  }

  if (!country) {
    return 'us';
  }

  const countryToCodeMap: EnumMap<AgentResponseAccountCountryEnum, string> = {
    UNITED_STATES: 'us',
    CANADA: 'ca',
  };

  return safeEnumMapGet(countryToCodeMap, country, 'us');
};
