import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import {
  EarningType,
  WealthPlanEventEnum,
} from '../components/WeathManagement/WealthManagementTypes';
import { RunwayControllerApi } from '../openapi/bff';
import {
  AgentWealthManagementApi,
  GoalDetails,
  MoneyValueCurrencyEnum,
  ProspectiveAgentInfoCountryEnum,
  SetCommsPreferencesRequest,
  SubmitProspectiveAgentReferralRequest,
} from '../openapi/plutus';
import {
  AgentResponseAccountCountryEnum,
  CalendarEventResponse,
} from '../openapi/yenta';
import ErrorService from '../services/ErrorService';
import { AppThunk, WealthManagementSliceState } from '../types';
import {
  getBffConfiguration,
  getWealthManagementConfiguration,
} from '../utils/OpenapiConfigurationUtils';
import { getGoalUpdateDefaultValues } from '../utils/WealthPlanUtils';
import { showErrorToast, showSuccessToast } from './ToastNotificationSlice';

export const initialState: WealthManagementSliceState = {
  currentWealthResponse: {
    loading: false,
    name: 'current wealth',
  },
  wealthGoalsResponse: {
    loading: false,
    name: 'wealth goals',
  },
  defaultWealthGoalsResponse: {
    loading: false,
    name: 'default wealth goals',
  },
  setWealthGoalsResponse: {
    loading: false,
    name: 'set wealth goals',
  },
  earningsOverviewResponse: {
    loading: false,
    name: 'earnings overview',
  },
  rateLimitResponse: {
    loading: false,
    name: 'rate limit',
  },
  commissionIncomeResponse: {
    loading: false,
    name: 'commission income',
  },
  revenueShareIncomeResponse: {
    loading: false,
    name: 'revenue share income',
  },
  preCapSppResponse: {
    loading: false,
    name: 'pre cap spp',
  },
  postCapSppResponse: {
    loading: false,
    name: 'post cap spp',
  },
  eliteAgentProductionAwardResponse: {
    loading: false,
    name: 'elite agent production award',
  },
  eliteAgentCulturalAwardResponse: {
    loading: false,
    name: 'elite agent cultural award',
  },
  cappingAwardResponse: {
    loading: false,
    name: 'capping award',
  },
  attractingStockAwardResponse: {
    loading: false,
    name: 'attracting stock award',
  },
  referralInfo: {
    loading: false,
    name: 'referral info',
  },
  officialPlanAndAwardResponse: {
    loading: false,
    name: 'official and award plan',
  },
  commsSettings: {
    loading: false,
    name: 'comms settings',
  },
  readonlyMode: false,
  selectedEarningsOverviewType: [],
  wealthPlanEvents: {},
};

type WealthManagementStateKeys = keyof Omit<
  WealthManagementSliceState,
  | 'tempKnowlegeLandingPage'
  | 'selectedEarningsOverviewType'
  | 'wealthPlanReferralToken'
  | 'readonlyMode'
>;
type AllResponses = WealthManagementSliceState[WealthManagementStateKeys];

type ApiResponse = Partial<
  Record<
    WealthManagementStateKeys,
    {
      data?: AllResponses['data'];
      error?: AllResponses['error'];
    }
  >
>;

interface WealthManagementPayload extends ApiResponse {
  apiName: WealthManagementStateKeys;
}

const WealthManagementSlice = createSlice({
  name: 'wealthManagementSlice',
  initialState,
  reducers: {
    fetchingWealthManagementApi(
      state,
      action: PayloadAction<WealthManagementPayload>,
    ) {
      state[action.payload.apiName].loading = true;
      state[action.payload.apiName].error = null;
    },
    fetchedWealthManagementApi(
      state,
      action: PayloadAction<WealthManagementPayload>,
    ) {
      state[action.payload.apiName].loading = false;
      state[action.payload.apiName].data =
        action.payload[action.payload.apiName]?.data;
      state[action.payload.apiName].error = null;
    },
    failedToFetchWealthManagementApi(
      state,
      action: PayloadAction<WealthManagementPayload>,
    ) {
      state[action.payload.apiName].loading = false;
      state[action.payload.apiName].error =
        action.payload[action.payload.apiName]?.error;
    },
    setReadonlyMode(state, action: PayloadAction<boolean>) {
      state.readonlyMode = action.payload;
    },
    setSelectedEarningsOverviewType(
      state,
      action: PayloadAction<EarningType | undefined>,
    ) {
      if (action.payload === undefined) {
        state.selectedEarningsOverviewType.pop();
      } else {
        state.selectedEarningsOverviewType.push(action.payload);
      }
    },
    setWealthKnowledgeLandingPage(
      state,
      action: PayloadAction<EarningType | undefined>,
    ) {
      state.tempKnowlegeLandingPage = action.payload;
    },
    setWealthPlanReferralToken(state, action: PayloadAction<string>) {
      state.wealthPlanReferralToken = action.payload;
    },
    fetchWealthPlanCommsSettings(
      state,
      action: PayloadAction<WealthManagementPayload>,
    ) {
      state[action.payload.apiName].loading = false;
      state[action.payload.apiName].data =
        action.payload[action.payload.apiName]?.data;
      state[action.payload.apiName].error = null;
    },
    updateWealthGoalsData(state, action: PayloadAction<GoalDetails>) {
      state.wealthGoalsResponse.data = {
        currentWealthValue: {},
        stockPriceDetails: {
          realStockPrice: {},
        },
        ...state.wealthGoalsResponse.data,
        goalDetails: action.payload,
      };
    },
    setWealthPlanEvents(
      state,
      action: PayloadAction<{
        eventType: WealthPlanEventEnum;
        data: CalendarEventResponse[];
      }>,
    ) {
      state.wealthPlanEvents = {
        ...state.wealthPlanEvents,
        [action.payload.eventType]: action.payload.data,
      };
    },
  },
});

export const {
  fetchingWealthManagementApi,
  fetchedWealthManagementApi,
  failedToFetchWealthManagementApi,
  setReadonlyMode,
  setSelectedEarningsOverviewType,
  setWealthPlanReferralToken,
  setWealthKnowledgeLandingPage,
  updateWealthGoalsData,
  setWealthPlanEvents,
} = WealthManagementSlice.actions;

const fetchWealthManagementApi = (
  apiName: WealthManagementStateKeys,
  apiCall: () => Promise<AxiosResponse>,
  options: Partial<{
    showToastOnError: boolean;
    showToastOnSuccess: boolean;
    toastErrorMessage: string;
    toastSuccessMessage: string;
    logError: boolean;
    logErrorMessage: string;
    metadata?: any;
  }> = {
    showToastOnError: true,
    showToastOnSuccess: false,
    toastErrorMessage: `Unable to complete [${apiName}] request. Please try again later.`,
    toastSuccessMessage: `Successfully completed [${apiName}] request`,
    logError: true,
    logErrorMessage: `Unable to complete [${apiName}] request`,
  },
): AppThunk => async (dispatch) => {
  dispatch(fetchingWealthManagementApi({ apiName }));
  try {
    const response = await apiCall();
    dispatch(
      fetchedWealthManagementApi({
        apiName,
        [apiName]: {
          data: response.data,
        },
      }),
    );
    if (options.showToastOnSuccess) {
      dispatch(showSuccessToast(options.toastSuccessMessage!));
    }
  } catch (e) {
    dispatch(
      failedToFetchWealthManagementApi({
        apiName,
        [apiName]: { error: `${e}` },
      }),
    );
    if (options.showToastOnError) {
      dispatch(showErrorToast(options.toastErrorMessage!));
    }
    if (options.logError) {
      ErrorService.notify(options.logErrorMessage!, e, options.metadata);
    }
  }
};

export const fetchCurrentWealth = (): AppThunk => async (
  dispatch,
  getState,
) => {
  const { auth } = getState();
  const id = auth.userDetail?.id!;

  dispatch(
    fetchWealthManagementApi(
      'currentWealthResponse',
      async () =>
        new AgentWealthManagementApi(
          await getWealthManagementConfiguration(),
        ).getCurrentWealth(id),
      {
        showToastOnError: false,
        logError: false,
      },
    ),
  );
};

export const fetchWealthGoals = (): AppThunk => async (dispatch, getState) => {
  const { auth, wealthManagement } = getState();
  const id = auth.userDetail?.id!;
  const token = wealthManagement.wealthPlanReferralToken;

  dispatch(setReadonlyMode(false));

  if (token) {
    dispatch(
      fetchWealthManagementApi(
        'wealthGoalsResponse',
        async () =>
          new AgentWealthManagementApi(
            await getWealthManagementConfiguration(),
          ).getReferralWealthGoals(token),
        {
          showToastOnError: false,
          logError: false,
        },
      ),
    );
    return;
  }

  dispatch(
    fetchWealthManagementApi(
      'wealthGoalsResponse',
      async () =>
        new AgentWealthManagementApi(
          await getWealthManagementConfiguration(),
        ).getWealthGoals(id),
      {
        showToastOnError: false,
        logError: false,
      },
    ),
  );
};

export const fetchDefaultWealthGoals = (): AppThunk => async (
  dispatch,
  getState,
) => {
  const { auth } = getState();
  const id = auth.userDetail?.id!;

  dispatch(
    fetchWealthManagementApi(
      'defaultWealthGoalsResponse',
      async () =>
        new AgentWealthManagementApi(
          await getWealthManagementConfiguration(),
        ).getWealthGoalDefaults(id),
      {
        showToastOnError: false,
        logErrorMessage: `Unable to fetch default wealth goals [userId: ${id}]`,
      },
    ),
  );
};

export const fetchWealthPlanCommsSettings = (): AppThunk => async (
  dispatch,
  getState,
) => {
  const { auth } = getState();
  const id = auth.userDetail?.id!;

  dispatch(
    fetchWealthManagementApi(
      'commsSettings',
      async () =>
        new AgentWealthManagementApi(
          await getWealthManagementConfiguration(),
        ).getAgentCommunicationsPreferences(id),
      {
        showToastOnError: false,
        logErrorMessage: `Unable to fetch wealth plan comms settings [userId: ${id}]`,
      },
    ),
  );
};

export const setWealthGoals = (goals: GoalDetails): AppThunk => async (
  dispatch,
  getState,
) => {
  const { auth, wealthManagement } = getState();
  const id = auth.userDetail?.id!;
  const token = wealthManagement.wealthPlanReferralToken;

  const updatedGoals: GoalDetails = {
    ...goals,
    realStockFuturePrice: {
      ...goals.realStockFuturePrice,
      currency: MoneyValueCurrencyEnum.Usd,
    },
  };

  if (token) {
    dispatch(
      fetchWealthManagementApi(
        'setWealthGoalsResponse',
        async () => {
          const response = await new AgentWealthManagementApi(
            await getWealthManagementConfiguration(),
          ).saveReferralWealthGoals(token, updatedGoals);
          dispatch(updateWealthGoalsData(response.data));
          return response;
        },
        {
          toastErrorMessage: 'Unable to set wealth goals',
          logErrorMessage: `Unable to set wealth goals [userId: ${id}]`,
        },
      ),
    );
    return;
  }

  dispatch(
    fetchWealthManagementApi(
      'setWealthGoalsResponse',
      async () => {
        const response = await new AgentWealthManagementApi(
          await getWealthManagementConfiguration(),
        ).setWealthGoals(id, updatedGoals);
        dispatch(updateWealthGoalsData(response.data));
        return response;
      },
      {
        toastErrorMessage: 'Unable to set wealth goals',
        logErrorMessage: `Unable to set wealth goals [userId: ${id}]`,
      },
    ),
  );
};

export const submitWealthPlanReferral = (
  goals: SubmitProspectiveAgentReferralRequest,
): AppThunk => async (dispatch, getState) => {
  const { auth } = getState();
  const id = auth.userDetail!.id!;

  dispatch(
    fetchWealthManagementApi(
      'setWealthGoalsResponse',
      async () =>
        await new AgentWealthManagementApi(
          await getWealthManagementConfiguration(),
        ).submitAgentReferral(id, goals),
      {
        toastErrorMessage: 'Unable to send referral',
        toastSuccessMessage: 'Successfully sent referral',
        showToastOnSuccess: true,
        logErrorMessage: `Unable to send referral [userId: ${id}]`,
      },
    ),
  );
};

export const getEarningsOverview = (): AppThunk => async (
  dispatch,
  getState,
) => {
  const { auth, wealthManagement } = getState();
  const id = auth.userDetail?.id!;
  const token = wealthManagement.wealthPlanReferralToken;

  setReadonlyMode(false);

  if (token) {
    dispatch(
      fetchWealthManagementApi(
        'earningsOverviewResponse',
        async () =>
          new AgentWealthManagementApi(
            await getWealthManagementConfiguration(),
          ).getReferralEarningsOverview1(token),
        {
          showToastOnError: false,
          logError: false,
        },
      ),
    );
    return;
  }

  dispatch(
    fetchWealthManagementApi(
      'earningsOverviewResponse',
      async () =>
        new AgentWealthManagementApi(
          await getWealthManagementConfiguration(),
        ).getEarningsOverview1(id),
      {
        showToastOnError: false,
        logError: false,
      },
    ),
  );
};

export const getEarningsOverviewV2 = (): AppThunk => async (
  dispatch,
  getState,
) => {
  const { auth, wealthManagement } = getState();
  const id = auth.userDetail?.id!;
  const token = wealthManagement.wealthPlanReferralToken;

  setReadonlyMode(false);

  if (token) {
    dispatch(
      fetchWealthManagementApi(
        'earningsOverviewResponse',
        async () =>
          new AgentWealthManagementApi(
            await getWealthManagementConfiguration(),
          ).getReferralEarningsOverview2(token),
        {
          showToastOnError: false,
          logError: false,
        },
      ),
    );
    return;
  }

  dispatch(
    fetchWealthManagementApi(
      'earningsOverviewResponse',
      async () =>
        new AgentWealthManagementApi(
          await getWealthManagementConfiguration(),
        ).getEarningsOverview2(id),
      {
        showToastOnError: false,
        logError: false,
      },
    ),
  );
};

export const getRateLimit = (): AppThunk => async (dispatch, getState) => {
  const { auth } = getState();
  const id = auth.userDetail!.id!;

  dispatch(
    fetchWealthManagementApi(
      'rateLimitResponse',
      async () =>
        new AgentWealthManagementApi(
          await getWealthManagementConfiguration(),
        ).getRateLimitInfo(id),
      {
        showToastOnError: false,
        logError: false,
      },
    ),
  );
};

export const getCommissionIncome = (): AppThunk => async (
  dispatch,
  getState,
) => {
  const { auth, wealthManagement } = getState();
  const id = auth.userDetail?.id!;
  const token = wealthManagement.wealthPlanReferralToken;

  if (token) {
    dispatch(
      fetchWealthManagementApi(
        'commissionIncomeResponse',
        async () =>
          new AgentWealthManagementApi(
            await getWealthManagementConfiguration(),
          ).getReferralCommissionIncome(token),
        {
          toastErrorMessage: 'Unable to get commission income',
          logErrorMessage: `Unable to get commission income [userId: ${id}]`,
        },
      ),
    );
    return;
  }

  dispatch(
    fetchWealthManagementApi(
      'commissionIncomeResponse',
      async () =>
        new AgentWealthManagementApi(
          await getWealthManagementConfiguration(),
        ).getCommissionIncome(id),
      {
        toastErrorMessage: 'Unable to get commission income',
        logErrorMessage: `Unable to get commission income [userId: ${id}]`,
      },
    ),
  );
};

export const getRevenueShareIncome = (): AppThunk => async (
  dispatch,
  getState,
) => {
  const { auth, wealthManagement } = getState();
  const id = auth.userDetail?.id!;
  const token = wealthManagement.wealthPlanReferralToken;

  if (token) {
    dispatch(
      fetchWealthManagementApi(
        'revenueShareIncomeResponse',
        async () =>
          new AgentWealthManagementApi(
            await getWealthManagementConfiguration(),
          ).getReferralRevenueShareEarnings1(token),
        {
          toastErrorMessage: 'Unable to get revenue share income',
          logErrorMessage: `Unable to get revenue share income [userId: ${id}]`,
        },
      ),
    );
    return;
  }

  dispatch(
    fetchWealthManagementApi(
      'revenueShareIncomeResponse',
      async () =>
        new AgentWealthManagementApi(
          await getWealthManagementConfiguration(),
        ).getRevenueShareEarnings1(id),
      {
        toastErrorMessage: 'Unable to get revenue share income',
        logErrorMessage: `Unable to get revenue share income [userId: ${id}]`,
      },
    ),
  );
};

export const getRevenueShareIncomeV2 = (): AppThunk => async (
  dispatch,
  getState,
) => {
  const { auth, wealthManagement } = getState();
  const id = auth.userDetail?.id!;
  const token = wealthManagement.wealthPlanReferralToken;

  if (token) {
    dispatch(
      fetchWealthManagementApi(
        'revenueShareIncomeResponse',
        async () =>
          new AgentWealthManagementApi(
            await getWealthManagementConfiguration(),
          ).getReferralRevenueShareEarnings2(token),
        {
          toastErrorMessage: 'Unable to get revenue share income',
          logErrorMessage: `Unable to get revenue share income [userId: ${id}]`,
        },
      ),
    );
    return;
  }

  dispatch(
    fetchWealthManagementApi(
      'revenueShareIncomeResponse',
      async () =>
        new AgentWealthManagementApi(
          await getWealthManagementConfiguration(),
        ).getRevenueShareEarnings2(id),
      {
        toastErrorMessage: 'Unable to get revenue share income',
        logErrorMessage: `Unable to get revenue share income [userId: ${id}]`,
      },
    ),
  );
};

export const getPreCapSpp = (): AppThunk => async (dispatch, getState) => {
  const { auth, wealthManagement } = getState();
  const id = auth.userDetail?.id!;
  const token = wealthManagement.wealthPlanReferralToken;

  if (token) {
    dispatch(
      fetchWealthManagementApi(
        'preCapSppResponse',
        async () =>
          new AgentWealthManagementApi(
            await getWealthManagementConfiguration(),
          ).getReferralPreCapSPPIncome(token),
        {
          toastErrorMessage: 'Unable to get pre cap spp income',
          logErrorMessage: `Unable to get pre cap spp income [userId: ${id}]`,
        },
      ),
    );
    return;
  }

  dispatch(
    fetchWealthManagementApi(
      'preCapSppResponse',
      async () =>
        new AgentWealthManagementApi(
          await getWealthManagementConfiguration(),
        ).getPreCapSPPIncome(id),
      {
        toastErrorMessage: 'Unable to get pre cap spp income',
        logErrorMessage: `Unable to get pre cap spp income [userId: ${id}]`,
      },
    ),
  );
};

export const getPostCapSpp = (): AppThunk => async (dispatch, getState) => {
  const { auth, wealthManagement } = getState();
  const id = auth.userDetail?.id!;
  const token = wealthManagement.wealthPlanReferralToken;

  if (token) {
    dispatch(
      fetchWealthManagementApi(
        'postCapSppResponse',
        async () =>
          new AgentWealthManagementApi(
            await getWealthManagementConfiguration(),
          ).getReferralPostCapSPPEarnings(token),
        {
          toastErrorMessage: 'Unable to get post cap spp income',
          logErrorMessage: `Unable to get post cap spp income [userId: ${id}]`,
        },
      ),
    );
    return;
  }

  dispatch(
    fetchWealthManagementApi(
      'postCapSppResponse',
      async () =>
        new AgentWealthManagementApi(
          await getWealthManagementConfiguration(),
        ).getPostCapSPPEarnings(id),
      {
        toastErrorMessage: 'Unable to get post cap spp income',
        logErrorMessage: `Unable to get post cap spp income [userId: ${id}]`,
      },
    ),
  );
};

export const getEliteAgentProductionAward = (): AppThunk => async (
  dispatch,
  getState,
) => {
  const { auth, wealthManagement } = getState();
  const id = auth.userDetail?.id!;
  const token = wealthManagement.wealthPlanReferralToken;

  if (token) {
    dispatch(
      fetchWealthManagementApi(
        'eliteAgentProductionAwardResponse',
        async () =>
          new AgentWealthManagementApi(
            await getWealthManagementConfiguration(),
          ).getReferralEliteAgentProductionAwardEarnings(token),
        {
          toastErrorMessage:
            'Unable to get elite agent production award income',
          logErrorMessage: `Unable to get elite agent production award income [userId: ${id}]`,
        },
      ),
    );
    return;
  }

  dispatch(
    fetchWealthManagementApi(
      'eliteAgentProductionAwardResponse',
      async () =>
        new AgentWealthManagementApi(
          await getWealthManagementConfiguration(),
        ).getEliteAgentProductionAwardEarnings(id),
      {
        toastErrorMessage: 'Unable to get elite agent production award income',
        logErrorMessage: `Unable to get elite agent production award income [userId: ${id}]`,
      },
    ),
  );
};

export const getEliteAgentCulturalAward = (): AppThunk => async (
  dispatch,
  getState,
) => {
  const { auth, wealthManagement } = getState();
  const id = auth.userDetail?.id!;
  const token = wealthManagement.wealthPlanReferralToken;

  if (token) {
    dispatch(
      fetchWealthManagementApi(
        'eliteAgentCulturalAwardResponse',
        async () =>
          new AgentWealthManagementApi(
            await getWealthManagementConfiguration(),
          ).getReferralEliteAgentCulturalAwardsEarnings(token),
        {
          toastErrorMessage: 'Unable to get elite agent cultural award income',
          logErrorMessage: `Unable to get elite agent cultural award income [userId: ${id}]`,
        },
      ),
    );
    return;
  }

  dispatch(
    fetchWealthManagementApi(
      'eliteAgentCulturalAwardResponse',
      async () =>
        new AgentWealthManagementApi(
          await getWealthManagementConfiguration(),
        ).getEliteAgentCulturalAwardsEarnings(id),
      {
        toastErrorMessage: 'Unable to get elite agent cultural award income',
        logErrorMessage: `Unable to get elite agent cultural award income [userId: ${id}]`,
      },
    ),
  );
};

export const getCappingAward = (): AppThunk => async (dispatch, getState) => {
  const { auth, wealthManagement } = getState();
  const id = auth.userDetail?.id!;
  const token = wealthManagement.wealthPlanReferralToken;

  if (token) {
    dispatch(
      fetchWealthManagementApi(
        'cappingAwardResponse',
        async () =>
          new AgentWealthManagementApi(
            await getWealthManagementConfiguration(),
          ).getReferralCappingAwardEarnings(token),
        {
          toastErrorMessage: 'Unable to get capping award income',
          logErrorMessage: `Unable to get capping award income [userId: ${id}]`,
        },
      ),
    );
    return;
  }

  dispatch(
    fetchWealthManagementApi(
      'cappingAwardResponse',
      async () =>
        new AgentWealthManagementApi(
          await getWealthManagementConfiguration(),
        ).getCappingAwardEarnings(id),
      {
        toastErrorMessage: 'Unable to get capping award income',
        logErrorMessage: `Unable to get capping award income [userId: ${id}]`,
      },
    ),
  );
};

export const getAttractingStockAward = (): AppThunk => async (
  dispatch,
  getState,
) => {
  const { auth, wealthManagement } = getState();
  const id = auth.userDetail?.id!;
  const token = wealthManagement.wealthPlanReferralToken;

  if (token) {
    dispatch(
      fetchWealthManagementApi(
        'attractingStockAwardResponse',
        async () =>
          new AgentWealthManagementApi(
            await getWealthManagementConfiguration(),
          ).getReferralAttractingStockAwardsEarnings(token),
        {
          toastErrorMessage: 'Unable to get attracting stock award income',
          logErrorMessage: `Unable to get attracting stock award income [userId: ${id}]`,
        },
      ),
    );
    return;
  }

  dispatch(
    fetchWealthManagementApi(
      'attractingStockAwardResponse',
      async () =>
        new AgentWealthManagementApi(
          await getWealthManagementConfiguration(),
        ).getAttractingStockAwardsEarnings(id),
      {
        toastErrorMessage: 'Unable to get attracting stock award income',
        logErrorMessage: `Unable to get attracting stock award income [userId: ${id}]`,
      },
    ),
  );
};

export const updateWealthGoals = (
  updates: Partial<GoalDetails>,
): AppThunk => async (dispatch, getState) => {
  const { wealthManagement } = getState();

  if (!wealthManagement.wealthGoalsResponse.data) {
    return;
  }

  const goalDetails = wealthManagement.wealthGoalsResponse.data?.goalDetails;

  const goals: GoalDetails = {
    ...goalDetails,
    // safe update for old accounts
    ...getGoalUpdateDefaultValues(goalDetails),
    ...updates,
    planStartDate: new Date().toISOString(),
  };

  dispatch(setWealthGoals(goals));
};

export const fetchReferralGoalsPreview = (
  goals: GoalDetails,
): AppThunk => async (dispatch) => {
  dispatch(setReadonlyMode(true));

  dispatch(updateWealthGoalsData(goals));
  dispatch(
    fetchWealthManagementApi(
      'earningsOverviewResponse',
      async () =>
        new AgentWealthManagementApi(
          await getWealthManagementConfiguration(),
        ).getPlanPreview1(goals),
      {
        toastErrorMessage: 'Unable to get plan preview',
        logErrorMessage: `Unable to get plan preview`,
      },
    ),
  );
};

export const fetchReferralGoalsPreviewV2 = (
  goals: GoalDetails,
): AppThunk => async (dispatch) => {
  dispatch(setReadonlyMode(true));

  dispatch(updateWealthGoalsData(goals));
  dispatch(
    fetchWealthManagementApi(
      'earningsOverviewResponse',
      async () =>
        new AgentWealthManagementApi(
          await getWealthManagementConfiguration(),
        ).getPlanPreview2(goals),
      {
        toastErrorMessage: 'Unable to get plan preview',
        logErrorMessage: `Unable to get plan preview`,
      },
    ),
  );
};

export const fetchReferralInfo = (): AppThunk => async (dispatch, getState) => {
  const { wealthManagement } = getState();
  const token = wealthManagement.wealthPlanReferralToken;

  if (!token) {
    return;
  }

  dispatch(
    fetchWealthManagementApi(
      'referralInfo',
      async () =>
        new AgentWealthManagementApi(
          await getWealthManagementConfiguration(),
        ).getReferralInfo(token),
      {
        logError: false,
        toastErrorMessage: 'Unable to get referral info',
      },
    ),
  );
};

export const getOfficialPlan = (): AppThunk => async (dispatch, getState) => {
  const { auth, wealthManagement } = getState();
  const id = auth.userDetail?.id;
  const token = wealthManagement.wealthPlanReferralToken;

  let country = auth.userDetail?.accountCountry!;

  if (token) {
    country =
      wealthManagement.referralInfo?.data?.country ===
      ProspectiveAgentInfoCountryEnum.Canada
        ? AgentResponseAccountCountryEnum.Canada
        : AgentResponseAccountCountryEnum.UnitedStates;
  }

  dispatch(
    fetchWealthManagementApi(
      'officialPlanAndAwardResponse',
      async () =>
        new RunwayControllerApi(
          await getBffConfiguration(),
        ).getOfficialPlanByCountry(
          country ?? AgentResponseAccountCountryEnum.UnitedStates,
        ),
      {
        toastErrorMessage: 'Unable to get commission plan',
        logErrorMessage: `Unable to get commission plan [userId: ${id}]`,
      },
    ),
  );
};

export const updateWealthPlanCommsSettings = (
  settings: SetCommsPreferencesRequest,
): AppThunk => async (dispatch, getState) => {
  const { auth } = getState();
  const id = auth.userDetail?.id!;
  try {
    const { data } = await new AgentWealthManagementApi(
      await getWealthManagementConfiguration(),
    ).updateAgentCommunicationsPreferences(id, settings);

    dispatch(
      showSuccessToast('Successfully updated WealthPlan Notification Settings'),
    );
    return data;
  } catch (e) {
    ErrorService.notify(
      'Unable to update the WealthPlan Notification Settings',
      e,
    );
  }
};

export default WealthManagementSlice.reducer;
