import { createAction, Priority } from 'kbar';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFeatureFlag } from '../../../hooks/useFeatureFlag';
import {
  openSidebar,
  saveBreakRoomType,
  saveCardInFocus,
} from '../../../slices/KBarSlice';
import { createTransactionBuilder } from '../../../slices/TransactionBuilderSlice';
import {
  BreakRoomEnum,
  FeatureFlagTypeEnum,
  KBarActionEnum,
  RootState,
} from '../../../types';
import { getTaxDocumentName } from '../../../utils/TaxDocumentHelper';
import useKbarRedirect from '../useKbarRedirect';

export default function useDefaultActions() {
  const { redirectTo } = useKbarRedirect();
  const dispatch = useDispatch();
  const {
    auth: { userDetail, isAdmin, isBroker },
  } = useSelector((state: RootState) => state);

  const isMSDynamicsOnboardingEnabled = useFeatureFlag(
    FeatureFlagTypeEnum.MS_DYNAMICS_ONBOARDING,
  );

  const adminOrBrokerNavigationActions = [
    {
      id: 'home',
      name: 'Home',
      shortcut: ['Shift+h'],
      keywords: 'home',
      section: 'Navigation',
      perform: () => {
        redirectTo('/people');
      },
    },
    {
      id: 'inbox',
      name: 'Inbox',
      shortcut: ['Shift+i'],
      keywords: 'inbox',
      section: 'Navigation',
      perform: () => {
        window.open('/inbox?brokerQueue=true');
      },
    },
    {
      id: 'transactions',
      name: 'Transactions',
      shortcut: ['Shift+t'],
      keywords: 'transactions',
      section: 'Navigation',
      perform: () => {
        redirectTo('/transactions');
      },
    },
    {
      id: 'listings',
      name: 'Listings',
      shortcut: ['Shift+l'],
      keywords: 'listings',
      section: 'Navigation',
      perform: () => {
        redirectTo('/listings');
      },
    },
    {
      id: 'events',
      name: 'Events',
      shortcut: ['Shift+e'],
      keywords: 'events',
      section: 'Navigation',
      perform: () => {
        redirectTo('/my-events');
      },
    },
  ];

  const agentNavigationActions = [
    {
      id: 'myProfile',
      name: 'My Profile',
      shortcut: ['p'],
      keywords: 'myProfile',
      section: 'Navigation',
      perform: () => {
        redirectTo(`/people/${userDetail?.id}`);
      },
    },
    {
      id: 'inbox',
      name: 'My Inbox',
      shortcut: ['Shift+i'],
      keywords: 'inbox',
      section: 'Navigation',
      perform: () => {
        window.open('/inbox?brokerQueue=true');
      },
    },
    {
      id: 'myListings',
      name: 'My Listings',
      shortcut: ['Shift+l'],
      keywords: 'my listings',
      section: 'Navigation',
      perform: () => {
        redirectTo('/listings');
      },
    },
    {
      id: 'myTransactions',
      name: 'My Transactions',
      shortcut: ['Shift+t'],
      keywords: 'my transactions',
      section: 'Navigation',
      perform: () => {
        redirectTo('/transactions');
      },
    },
    {
      id: 'myEvents',
      name: 'My Events',
      shortcut: ['Shift+e'],
      keywords: 'events',
      section: 'Navigation',
      perform: () => {
        redirectTo('/my-events');
      },
    },
    {
      id: 'myCommission',
      name: 'My Commission',
      shortcut: ['m', 's'],
      keywords: 'commission',
      section: 'Navigation',
      perform: () => {
        dispatch(saveCardInFocus(KBarActionEnum.MY_COMMISSION));
        redirectTo(`/people/${userDetail?.id}`);
      },
    },
    {
      id: 'myCap',
      name: 'My Cap',
      shortcut: ['m', 'c'],
      keywords: 'cap',
      section: 'Navigation',
      perform: () => {
        dispatch(saveCardInFocus(KBarActionEnum.CURRENT_CAP_CYCLE));
        redirectTo(`/people/${userDetail?.id}`);
      },
    },
    {
      id: 'myIncome',
      name: 'My Income',
      shortcut: ['m', 'i'],
      keywords: 'income',
      section: 'Navigation',
      perform: () => {
        dispatch(saveCardInFocus(KBarActionEnum.INCOME));
        redirectTo(`/people/${userDetail?.id}`);
      },
    },
    {
      id: 'my1099',
      name: `My ${getTaxDocumentName(userDetail?.accountCountry!)}`,
      shortcut: ['m', '9'],
      keywords: `${getTaxDocumentName(userDetail?.accountCountry!)}`,
      section: 'Navigation',
      perform: () => {
        dispatch(saveCardInFocus(KBarActionEnum.TAX_FORM));
        // commenting this line out because tax response is changed to an array.
        // dispatch(saveSidebarToOpen(KBarActionEnum.TAX_FORM));
        // dont reset action to undefined here since sidebar opening is condition here.
        // Since it depends on api data. action is reset in the called component after data is fetched
        redirectTo(`/people/${userDetail?.id}`);
      },
    },
    {
      id: 'myUpline',
      name: 'My Upline',
      shortcut: ['m', 'u'],
      keywords: 'upline',
      section: 'Navigation',
      perform: () => {
        dispatch(saveCardInFocus(KBarActionEnum.UPLINE));
        redirectTo(`/people/${userDetail?.id}`);
      },
    },
    {
      id: 'myNetwork',
      name: 'My Network',
      shortcut: ['m', 'n'],
      keywords: 'network',
      section: 'Navigation',
      perform: () => {
        dispatch(saveCardInFocus(KBarActionEnum.NETWORK));
        redirectTo(`/people/${userDetail?.id}`);
      },
    },
    {
      id: 'myEquity',
      name: 'My Equity',
      shortcut: ['m', 'q'],
      keywords: 'equity',
      section: 'Navigation',
      perform: () => {
        dispatch(saveCardInFocus(KBarActionEnum.MY_EQUITY));
        redirectTo(`/people/${userDetail?.id}`);
      },
    },
    {
      id: 'myStocks',
      name: 'My Stocks',
      shortcut: ['m', 'q'],
      keywords: 'stocks',
      section: 'Navigation',
      perform: () => {
        dispatch(saveCardInFocus(KBarActionEnum.MY_EQUITY));
        redirectTo(`/people/${userDetail?.id}`);
      },
    },
    {
      id: 'payments',
      name: 'Payments',
      shortcut: ['m', 'p'],
      keywords: 'payments',
      section: 'Navigation',
      perform: () => {
        redirectTo(`/people/${userDetail?.id}/outgoing-payments`);
      },
    },
    {
      id: 'getPaid',
      name: 'Get Paid',
      shortcut: ['m', 'p'],
      keywords: 'get paid',
      section: 'Navigation',
      perform: () => {
        redirectTo(`/people/${userDetail?.id}/outgoing-payments`);
      },
    },
    {
      id: 'myRevenueShare',
      name: 'My Revenue Share',
      shortcut: ['m', 'v'],
      keywords: 'My Revenue Share',
      section: 'Navigation',
      perform: () => {
        redirectTo(`/people/${userDetail?.id}/revshare/payments`);
      },
    },
    {
      id: 'myRevShare',
      name: 'My RevShare',
      shortcut: ['m', 'v'],
      keywords: 'My RevShare',
      section: 'Navigation',
      perform: () => {
        redirectTo(`/people/${userDetail?.id}/revshare/payments`);
      },
    },
  ];

  const navigationActions =
    isAdmin || isBroker
      ? adminOrBrokerNavigationActions
      : agentNavigationActions;

  const commonNavigationActions = [
    {
      id: 'notificationSettings',
      name: 'Notification Settings',
      shortcut: ['n', 's'],
      keywords: 'notification settings',
      section: 'Navigation',
      perform: () => {
        redirectTo(`/people/${userDetail?.id}/profile`);
        dispatch(openSidebar(KBarActionEnum.NOTIFICATION_SETTINGS));
      },
    },
    ...(isMSDynamicsOnboardingEnabled
      ? [
          {
            id: 'paymentSettings',
            name: 'Payment Settings',
            shortcut: ['p', 's'],
            keywords: 'Payment Settings',
            section: 'Navigation',
            perform: () => {
              redirectTo(`/people/${userDetail?.id}/payment-settings`);
            },
          },
        ]
      : []),
    {
      id: 'securitySettings',
      name: 'Security Settings',
      shortcut: ['s', 'y'],
      keywords: 'security settings',
      section: 'Navigation',
      perform: () => {
        redirectTo(`/people/${userDetail?.id}/security-settings`);
      },
    },
    {
      id: 'marketingCenter',
      name: 'Marketing Center',
      shortcut: ['Shift+m', 'c'],
      keywords: 'marketing center',
      section: 'Navigation',
      perform: () => {
        redirectTo('/resources/marketing-center');
      },
    },
    {
      id: 'realSignature',
      name: 'Real Signature',
      shortcut: ['r', 's'],
      keywords: 'real signature',
      section: 'Navigation',
      perform: () => {
        window.open('https://signature.therealbrokerage.com/dashboard');
      },
    },
    {
      id: 'academy',
      name: 'Real Academy',
      shortcut: ['r', 'a'],
      keywords: 'realAcademy',
      section: 'Navigation',
      perform: () => {
        window.open('https://www.real.academy/start');
      },
    },
    {
      id: 'playAGame',
      name: 'Play a Game',
      shortcut: ['Shift+g'],
      keywords: 'snake break room breakroom',
      section: 'Navigation',
      perform: () => dispatch(saveBreakRoomType(BreakRoomEnum.SNAKE_GAME)),
    },
    // [KBar_TODO] Uncomment when title and mortgage are ready
    // {
    //   id: 'realTitle',
    //   name: 'Real Title',
    //   shortcut: ['r', 't'],
    //   keywords: 'realTitle',
    //   section: 'Navigation',
    //   perform: () => {
    //     redirectTo('/real-title');
    //   },
    // },
    // {
    //   id: 'realMortgage',
    //   name: 'Real Mortgage',
    //   shortcut: ['r', 'm'],
    //   keywords: 'realMortgage',
    //   section: 'Navigation',
    //   perform: () => {
    //     redirectTo('/real-mortgage');
    //   },
    // },
  ];

  const transactionActions = [
    {
      id: 'createTransactions',
      name: 'Create Transaction',
      shortcut: ['t', 'y'],
      keywords: 'create transactions',
      section: 'Transactions',
      perform: async () => {
        const transactionBuilderId = await dispatch(createTransactionBuilder());
        if (transactionBuilderId) {
          redirectTo(`/transaction/create/${transactionBuilderId}`);
        }
      },
    },
    {
      id: 'activeTransactions',
      name: 'Active Transactions',
      shortcut: ['Shift+t'],
      keywords: 'active transactions',
      section: 'Transactions',
      perform: () => {
        redirectTo('/transactions');
      },
    },
    {
      id: 'closedTransactions',
      name: 'Closed Transactions',
      shortcut: ['t', 'c'],
      keywords: 'closed transactions',
      section: 'Transactions',
      perform: () => {
        redirectTo('/transactions/closed');
      },
    },
    {
      id: 'terminatedTransactions',
      name: 'Terminated Transactions',
      shortcut: ['t', 'z'],
      keywords: 'terminated transactions',
      section: 'Transactions',
      perform: () => {
        redirectTo('/transactions/terminated');
      },
    },
    {
      id: 'draftTransactions',
      name: 'Draft Transactions',
      shortcut: ['t', 'k'],
      keywords: 'draft transactions',
      section: 'Transactions',
      perform: () => {
        redirectTo('/transactions/draft');
      },
    },
  ];

  const listingActions = [
    {
      id: 'createListing',
      name: 'Create Listing',
      shortcut: ['l', 'y'],
      keywords: 'create listing',
      section: 'Listings',
      perform: async () => {
        const transactionBuilderId = await dispatch(
          createTransactionBuilder('LISTING'),
        );
        if (transactionBuilderId) {
          redirectTo(`/listing/create/${transactionBuilderId}`);
        }
      },
    },
    {
      id: 'activeListings',
      name: 'Active Listing',
      shortcut: ['Shift+l'],
      keywords: 'active listings',
      section: 'Listings',
      perform: () => {
        redirectTo('/listings');
      },
    },
    {
      id: 'closedListings',
      name: 'Closed Listings',
      shortcut: ['l', 'c'],
      keywords: 'closed listings',
      section: 'Listings',
      perform: () => {
        redirectTo('/listings/closed');
      },
    },
    {
      id: 'terminatedListings',
      name: 'Terminated Listings',
      shortcut: ['l', 't'],
      keywords: 'terminate listings',
      section: 'Listings',
      perform: () => {
        redirectTo('/listings/terminated');
      },
    },
    {
      id: 'draftListings',
      name: 'Draft Listings',
      shortcut: ['l', 'd'],
      keywords: 'draft listings',
      section: 'Listings',
      perform: () => {
        redirectTo('/listings/draft');
      },
    },
  ];

  const officeActions = useMemo(() => {
    if (isAdmin) {
      return [
        {
          id: 'offices',
          name: 'Offices',
          shortcut: ['Shift+o'],
          keywords: 'offices',
          section: 'Office',
          perform: () => {
            redirectTo('/offices');
          },
        },
        {
          id: 'broker',
          name: 'Broker',
          shortcut: ['Shift+o'],
          keywords: 'broker',
          section: 'Office',
          perform: () => {
            redirectTo('/offices');
          },
        },
      ];
    }
    return [
      {
        id: 'myOffice',
        name: 'My Office',
        shortcut: ['Shift+o'],
        keywords: 'my office',
        section: 'Office',
        perform: () => redirectTo('/offices'),
      },
      {
        id: 'myBroker',
        name: 'My Broker',
        shortcut: ['Shift+o'],
        keywords: 'my broker',
        section: 'Office',
        perform: () => {
          redirectTo('/offices');
        },
      },
    ];
  }, [isAdmin, redirectTo]);

  const teamActions = useMemo(() => {
    if (isAdmin) {
      return [
        {
          id: 'teams',
          name: 'Teams',
          shortcut: ['m', 't'],
          keywords: 'teams',
          section: 'Teams',
          perform: () => {
            redirectTo('/teams');
          },
        },
      ];
    }

    if (!isBroker && !!userDetail?.flexTeamMemberships?.length) {
      return [
        {
          id: 'myTeams',
          name: 'My Teams',
          shortcut: ['m', 't'],
          keywords: 'my teams',
          section: 'Teams',
          perform: () => redirectTo('/teams'),
        },
      ];
    }
    return [];
  }, [isAdmin, isBroker, redirectTo, userDetail?.flexTeamMemberships]);

  const referralActions = [
    {
      id: 'createReferral',
      name: 'Create Referral',
      shortcut: ['t', 'r'],
      keywords: 'create referral',
      section: 'Referral',
      perform: () => {
        redirectTo('/transaction/referral');
      },
    },
    {
      id: 'createExternalReferral',
      name: 'External Referral',
      shortcut: ['t', 'r'],
      keywords: 'external referral',
      section: 'Referral',
      perform: () => {
        redirectTo('/transaction/referral');
      },
    },
  ];

  const reportActions = [
    {
      id: 'report',
      name: 'Report',
      shortcut: ['Shift+r'],
      keywords: 'report',
      section: 'Reports',
      perform: () => redirectTo('/reports'),
    },
    {
      id: 'createReport',
      name: 'Create Report',
      shortcut: ['Shift+r'],
      keywords: 'create report',
      section: 'Reports',
      perform: () => redirectTo('/reports'),
    },
  ];

  const supportActions = [
    {
      id: 'contact',
      name: 'Contact Support',
      shortcut: ['c', 's'],
      keywords: 'contact support',
      section: 'Support',
      perform: () => {
        window.open('mailto:support@therealbrokerage.com');
      },
    },
  ];

  const defaultActions = [
    ...navigationActions,
    ...commonNavigationActions,
    ...listingActions,
    ...transactionActions,
    ...officeActions,
    ...teamActions,
    ...referralActions,
    ...reportActions,
    ...supportActions,
  ];

  const defaultKBarActions = defaultActions.map((action) =>
    createAction({
      ...action,
      priority: Priority.NORMAL,
    }),
  );

  return { defaultActions, defaultKBarActions };
}
