import { useDispatch } from 'react-redux';
import { useBaseMutation } from '../base/useBaseMutation';
import {
  showErrorToast,
  showSuccessToast,
} from '../../slices/ToastNotificationSlice';
import {
  KafkaController,
  KafkaFailedMessage,
  KafkaUpdateMessageStatusEnum,
} from '../../components/KafkaDashboard/KafkaControllerTypes';
import { queryKeys } from '../base/queryKeys';

type UseBulkStatusUpdateProps = {
  controller: KafkaController;
  onSuccess(): void;
  messageIds: string[];
  status?: KafkaUpdateMessageStatusEnum;
};

export const useBulkStatusUpdate = ({
  controller,
  onSuccess,
  messageIds,
  status,
}: UseBulkStatusUpdateProps) => {
  const dispatch = useDispatch();

  return useBaseMutation({
    queryKey: queryKeys.kafkaDashboard.updateFailedMessageStatus.queryKey,
    mutationFn: async () => {
      if (!status) return;
      await controller.batchUpdateFailedMessageStatus({
        ids: messageIds,
        status,
      });
    },
    onSuccess: () => {
      dispatch(showSuccessToast('Successfully updated messages'));
      onSuccess();
    },
    onError: (error) => {
      console.error(error);
      dispatch(showErrorToast('Failed to update messages'));
    },
  });
};

type UseFailedMessagesProps = {
  controller: KafkaController;
  refresh(): void;
  setSelectedMessage(message: KafkaFailedMessage | undefined): void;
};

export const useFailedMessages = ({
  controller,
  refresh,
  setSelectedMessage,
}: UseFailedMessagesProps) => {
  const dispatch = useDispatch();

  return useBaseMutation({
    queryKey: queryKeys.kafkaDashboard.fetchFailedMessages.queryKey,
    mutationFn: async (failedMessage: KafkaFailedMessage) => {
      setSelectedMessage(failedMessage);
      await controller.retryFailedMessage({
        id: failedMessage.id!,
      });
    },
    onSuccess: () => {
      setSelectedMessage(undefined);
      dispatch(showSuccessToast('Message has been retried successfully'));
      refresh();
    },
    onError: (e) => {
      dispatch(showErrorToast('Failed to retry message', `${e}`));
    },
  });
};
