import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import pluralize from 'pluralize';
import { ChecklistType } from '../components/Zen/RoadToSuccess/ZenVerticalJourneyStepProgress';
import { LOAD_MORE_COMMENT_COUNT } from '../components/Zen/Table/Cell/ZenChecklistItemCommentsCell';
import {
  ChecklistApi,
  ChecklistProgressResponse,
  ChecklistResponse,
  FileReferenceInfo,
  ItemApi,
  ItemRequest,
  ItemResponse,
  ItemResponseStatusEnum,
} from '../openapi/sherlock';
import {
  CommentControllerApi,
  CommentDto,
  LatestCommentsResponse,
  PostCommentParamsAuthorTypeEnum,
  QueryCommentsRs,
  RezenObject,
  RezenObjectTypeEnum,
  TargetCommentCount,
} from '../openapi/yada';
import ErrorService from '../services/ErrorService';
import {
  AppDispatch,
  AppThunk,
  AsyncResponse,
  CheckListState,
  ErrorCode,
} from '../types';
import Logger from '../utils/Logger';
import {
  getSherlockConfiguration,
  getYadaConfiguration,
} from '../utils/OpenapiConfigurationUtils';
import { uploadFile } from './DropboxSlice';
import { showApiErrorModal } from './ErrorSlice';
import { showErrorToast, showSuccessToast } from './ToastNotificationSlice';
import { performAsyncRequest } from './utils/SliceUtil';

export const initialState: CheckListState = {
  checklistLoading: false,
  checklistsById: {},
  checklistItem: null,
  checklistErrorCode: null,
  isChecklistItemLoading: false,
  documentUploadLoading: {},
  journey: null,
  checklistProgressById: {},
  journeyProgressById: {},
  checklistCommentsById: {},
  latestCommentsByChecklistItemId: {
    data: {},
    loading: false,
    name: 'latest comments',
  },
  checklistItemCountById: {},
  isAgentOnboardingCompleteModalShown: false,
};

interface SaveChecklistItemPayload {
  checklistId: string;
  checklistItemRes: ItemResponse;
}

interface SaveChecklistItemCommentsPayload {
  checklistItemId: string;
  queryCommentsRs: QueryCommentsRs;
}

const CheckListSlice = createSlice({
  name: 'checklist',
  initialState,
  reducers: {
    changeCheckListLoading(state, action: PayloadAction<boolean>) {
      state.checklistLoading = action.payload;
    },
    changeDocumentUploadLoading(
      state,
      action: PayloadAction<{ checklistItemId: string; loading: boolean }>,
    ) {
      state.documentUploadLoading[action.payload.checklistItemId] =
        action.payload.loading;
    },
    saveCheckList(
      state,
      action: PayloadAction<AsyncResponse<ChecklistResponse, { id: string }>>,
    ) {
      state.checklistsById[action.payload.additionalProps?.id!] =
        action.payload;
    },
    errorFetchingCheckLists(state, action: PayloadAction<ErrorCode>) {
      state.checklistErrorCode = action.payload;
    },
    isLoadingChecklistItem(state, action: PayloadAction<boolean>) {
      state.isChecklistItemLoading = action.payload;
    },
    saveChecklistItem(state, action: PayloadAction<SaveChecklistItemPayload>) {
      const index = state.checklistsById[
        action.payload.checklistId
      ]!.data?.items?.findIndex(
        (checklistItem) =>
          checklistItem.id === action.payload.checklistItemRes.id,
      );

      if (typeof index !== 'undefined' && index !== -1) {
        state.checklistsById[action.payload.checklistId]!.data!.items![index] =
          action.payload.checklistItemRes;
      }
    },
    saveJourney(state, action: PayloadAction<ChecklistResponse | null>) {
      state.journey = action.payload;
    },
    saveJourneyItem(state, action: PayloadAction<ItemResponse>) {
      const itemIndex = (state.journey?.items || [])?.findIndex(
        (item) => item?.id === action.payload.id!,
      );

      if (itemIndex !== -1) {
        state.journey!.items![itemIndex!] = action.payload;
      }
    },
    saveChecklistProgressByIds(
      state,
      action: PayloadAction<Array<ChecklistProgressResponse>>,
    ) {
      action.payload.forEach((progressInfo) => {
        state.checklistProgressById[progressInfo.checklistId!] = progressInfo;
      });
    },
    saveJourneyProgressByIds(
      state,
      action: PayloadAction<Array<ChecklistProgressResponse>>,
    ) {
      action.payload.forEach((progressInfo) => {
        state.journeyProgressById[progressInfo.checklistId!] = progressInfo;
      });
    },
    saveChecklistItemCommentsById(
      state,
      action: PayloadAction<SaveChecklistItemCommentsPayload>,
    ) {
      state.checklistCommentsById[action.payload.checklistItemId] =
        action.payload.queryCommentsRs;
    },
    saveLatestComments(
      state,
      action: PayloadAction<AsyncResponse<LatestCommentsResponse>>,
    ) {
      const { loading, name, data, error } = action.payload;
      state.latestCommentsByChecklistItemId.loading = loading;
      state.latestCommentsByChecklistItemId.error = error;
      state.latestCommentsByChecklistItemId.name = name;

      data?.comments?.forEach((comment) => {
        if (comment.comments?.length) {
          state.latestCommentsByChecklistItemId.data![comment.owner?.id!] =
            comment.comments[0];
        }
      });
    },
    saveLatestComment(
      state,
      action: PayloadAction<{ checklistItemId: string; comment: CommentDto }>,
    ) {
      state.latestCommentsByChecklistItemId.data![
        action.payload.checklistItemId
      ] = action.payload.comment;
    },
    saveCheckListItemsCountByIds(
      state,
      action: PayloadAction<Array<TargetCommentCount>>,
    ) {
      action.payload.forEach((item) => {
        state.checklistItemCountById[item?.target?.id!] = item?.commentCount!;
      });
    },
    changeAgentOnboardingCompleteModalStatus(
      state,
      action: PayloadAction<boolean>,
    ) {
      state.isAgentOnboardingCompleteModalShown = action.payload;
    },
  },
});

export const {
  changeCheckListLoading,
  changeDocumentUploadLoading,
  saveCheckList,
  errorFetchingCheckLists,
  isLoadingChecklistItem,
  saveChecklistItem,
  saveJourney,
  saveJourneyItem,
  saveChecklistProgressByIds,
  saveJourneyProgressByIds,
  saveChecklistItemCommentsById,
  saveLatestComments,
  saveLatestComment,
  saveCheckListItemsCountByIds,
  changeAgentOnboardingCompleteModalStatus,
} = CheckListSlice.actions;

export const fetchCheckLists = (
  checklistId: string,
  loading: boolean = true,
): AppThunk => async (dispatch) => {
  const fetch = () =>
    new ChecklistApi(getSherlockConfiguration()).getChecklistById(checklistId);

  await performAsyncRequest<ChecklistResponse, { id: string }>(
    dispatch as AppDispatch,
    'checklist',
    saveCheckList,
    fetch,
    {
      changeLoading: loading,
      additionalProps: { id: checklistId },
    },
  );
};

export const createChecklistItem = (
  checklistId: string,
  itemRequest: ItemRequest,
): AppThunk<Promise<void>> => async (dispatch) => {
  try {
    await new ChecklistApi(getSherlockConfiguration()).createChecklistItem(
      checklistId,
      itemRequest,
    );
    dispatch(showSuccessToast('Successfully created the new checklist item.'));
    await dispatch(fetchCheckLists(checklistId));
  } catch (e) {
    dispatch(
      showErrorToast(
        'We had a problem creating new checklist item.',
        'Please try again in a few moments.',
      ),
    );
    ErrorService.notify('Unable to create new checklist item', e, {
      checkList: { id: checklistId },
      itemRequest: { ...itemRequest },
    });
  }
};

export const updateChecklistItem = (
  checklistId: string,
  checklistItemId: string,
  itemRequest: ItemRequest,
  loading: boolean = true,
): AppThunk<Promise<void>> => async (dispatch) => {
  try {
    await new ChecklistApi(getSherlockConfiguration()).updateChecklistItem(
      checklistItemId,
      itemRequest,
    );
    dispatch(showSuccessToast('Successfully updated the checklist item.'));
    await dispatch(fetchCheckLists(checklistId, loading));
  } catch (e) {
    dispatch(
      showErrorToast(
        'We had a problem updating the checklist item.',
        'Please try again in a few moments.',
      ),
    );
    ErrorService.notify('Unable to update the checklist item', e, {
      checkList: { id: checklistId, itemId: checklistItemId },
      itemRequest: { ...itemRequest },
    });
  }
};

export const fetchChecklistItemById = (
  checklistId: string,
  checklistItemId: string,
): AppThunk<Promise<void>> => async (dispatch) => {
  dispatch(isLoadingChecklistItem(true));
  try {
    const { data } = await new ChecklistApi(
      getSherlockConfiguration(),
    ).getChecklistItemById(checklistItemId);
    dispatch(saveChecklistItem({ checklistId, checklistItemRes: data }));
  } catch (e) {
    dispatch(isLoadingChecklistItem(false));
  }
};

export const uploadChecklistDocument = (
  checklistItemId: string,
  name: string,
  description: string,
  uploaderId: string,
  file: File,
  transactionId: string,
  checklistId: string,
): AppThunk<Promise<boolean>> => async (dispatch) => {
  dispatch(changeDocumentUploadLoading({ checklistItemId, loading: true }));
  try {
    Logger.log('Uploading item document...');

    await new ChecklistApi(getSherlockConfiguration()).uploadNewDocument(
      checklistItemId,
      name,
      description,
      uploaderId,
      file,
      transactionId,
    );

    Logger.log('Successfully uploaded document.');
  } catch (e) {
    dispatch(showErrorToast('Unable to upload document'));
    ErrorService.notifyIgnoreAuthErrors(
      'Unable to upload document to checklist item',
      e,
      {
        transaction: { id: transactionId },
        checklist: { id: checklistId, itemId: checklistItemId },
      },
    );
    return false;
  } finally {
    dispatch(changeDocumentUploadLoading({ checklistItemId, loading: false }));
  }
  return true;
};

export const fetchJourney = (
  journey: string,
): AppThunk<Promise<ChecklistResponse | undefined>> => async (dispatch) => {
  dispatch(changeCheckListLoading(true));
  try {
    const { data } = await new ChecklistApi(
      getSherlockConfiguration(),
    ).getChecklistById(journey);
    dispatch(saveJourney(data));
    return data;
  } catch (e) {
    dispatch(errorFetchingCheckLists(ErrorService.getErrorCode(e)));
    dispatch(
      showErrorToast(
        'We had a problem fetching journey check lists.',
        'Please try again in a few moments.',
      ),
    );
    ErrorService.notify('Unable to fetch journey check lists', e, {
      journey: { journey },
    });
    return undefined;
  } finally {
    dispatch(changeCheckListLoading(false));
  }
};

export const markChecklistItemAsCompleted = (
  checklistItemId: string,
  completed: boolean,
  checklistType: ChecklistType = ChecklistType.ROAD_TO_SUCCESS,
): AppThunk => async (dispatch, getState) => {
  try {
    const { data } = await new ChecklistApi(
      getSherlockConfiguration(),
    ).updateComplete(checklistItemId, completed);
    const {
      checklist: { journey },
    } = getState();
    const totalItemsPending = (journey?.items || [])?.filter(
      (item) => item.status !== ItemResponseStatusEnum.Accepted,
    ).length;
    if (
      checklistType === ChecklistType.AGENT_JOURNEY &&
      completed &&
      totalItemsPending === 1
    ) {
      await dispatch(changeAgentOnboardingCompleteModalStatus(true));
    }
    dispatch(saveJourneyItem(data));
  } catch (e) {
    dispatch(errorFetchingCheckLists(ErrorService.getErrorCode(e)));
    dispatch(
      showErrorToast(
        'We had a problem updating journey status.',
        'Please try again in a few moments.',
      ),
    );
    ErrorService.notify('Unable to update the journey status', e, {
      journey: { itemId: checklistItemId, completed },
    });
  }
};

export const deleteChecklistItem = (
  checklistId: string,
  checklistItemId: string,
): AppThunk => async (dispatch) => {
  try {
    await new ChecklistApi(getSherlockConfiguration()).deletedChecklistItem(
      checklistItemId,
    );
    await dispatch(fetchCheckLists(checklistId));
    dispatch(showSuccessToast('Successfully deleted checklist item.'));
  } catch (e) {
    dispatch(errorFetchingCheckLists(ErrorService.getErrorCode(e)));
    dispatch(
      showErrorToast(
        'We had a problem deleting the checklist item',
        'Please try again in a few moments.',
      ),
    );
    ErrorService.notify('Unable to delete the checklist item', e, {
      checklist: { id: checklistId, itemId: checklistItemId },
    });
  }
};

export const fetchChecklistProgressById = (
  checklistIds: string[],
): AppThunk => async (dispatch) => {
  try {
    const { data } = await new ChecklistApi(
      getSherlockConfiguration(),
    ).getChecklistProgressByIds(checklistIds);
    dispatch(saveChecklistProgressByIds(data || []));
  } catch (e) {
    ErrorService.notifyIgnoreAuthErrors(
      'Unable to fetch checklist progress info',
      e,
      {
        checklistId: { checklistIds },
      },
    );
  }
};

export const downloadChecklistDocument = (
  id: string,
): AppThunk<Promise<void>> => async (dispatch) => {
  try {
    const { data } = await new ChecklistApi(
      getSherlockConfiguration(),
    ).getVersionUrl(id);
    window.open(data, '_blank');
  } catch (e) {
    dispatch(showErrorToast('Unable to download the file'));
    ErrorService.notify('Unable to download the checklist document', e, {
      checklistDocument: { id },
    });
  }
};

export const fetchChecklistItemComments = (
  checklistItemId: string,
): AppThunk<Promise<void>> => async (dispatch) => {
  try {
    const { data } = await new CommentControllerApi(
      getYadaConfiguration(),
    ).getCommentsByReference(
      RezenObjectTypeEnum.ChecklistItem,
      checklistItemId,
      LOAD_MORE_COMMENT_COUNT,
      undefined,
      'USER',
    );

    dispatch(
      saveChecklistItemCommentsById({
        checklistItemId,
        queryCommentsRs: data,
      }),
    );
  } catch (e) {
    dispatch(showErrorToast('Unable to fetch checklist item comments'));
    ErrorService.notify('Unable to fetch checklist item comments', e, {
      checklistDocument: { id: checklistItemId },
    });
  }
};

export const fetchChecklistItemCommentsCount = (
  checklistItemIds: string[],
): AppThunk => async (dispatch) => {
  try {
    const { data } = await new CommentControllerApi(
      getYadaConfiguration(),
    ).getCommentsByReferenceCount(checklistItemIds);
    dispatch(saveCheckListItemsCountByIds(data?.counts || []));
  } catch (e) {
    ErrorService.notify('Unable to fetch checklist items comments count', e, {
      checklistItems: { checklistItemIds },
    });
  }
};

export const fetchLatestCommentForChecklistIds = (
  checklistItemIds: string[],
  authorType?: PostCommentParamsAuthorTypeEnum,
  limit?: number,
): AppThunk => async (dispatch) => {
  const rezenObject: RezenObject[] = checklistItemIds.map((checklistId) => ({
    id: checklistId,
    type: RezenObjectTypeEnum.ChecklistItem,
  }));

  const fetch = () =>
    new CommentControllerApi(
      getYadaConfiguration(),
    ).getLatestCommentsByReference(rezenObject, limit, authorType);

  await performAsyncRequest(
    dispatch as AppDispatch,
    'latest comments',
    saveLatestComments,
    fetch,
    {
      errorMetadata: {
        checklistItems: { ids: checklistItemIds },
      },
    },
  );
};

export const addFileReferencesToChecklistItem = (
  checklistId: string,
  checklistItemId: string,
  dropboxFileReferences: FileReferenceInfo[],
): AppThunk<Promise<boolean>> => async (dispatch) => {
  try {
    await new ItemApi(getSherlockConfiguration()).addFileReferences(
      checklistItemId,
      {
        references: dropboxFileReferences,
      },
    );
    const count = dropboxFileReferences?.length;
    dispatch(
      showSuccessToast(`${pluralize('Document', count)} uploaded successfully`),
    );
    return true;
  } catch (e) {
    dispatch(showApiErrorModal(e));
    dispatch(
      showErrorToast(
        'Unable to attach the document to checklist item.',
        'Please try again after sometime.',
      ),
    );
    ErrorService.notifyIgnoreAuthErrors(
      'Unable to attach the dropbox document references to checklist item',
      e,
      {
        data: { checklistId, checklistItemId, dropboxFileReferences },
      },
    );
    return false;
  }
};

export const removeFileReferencesFromChecklistItem = (
  checklistId: string,
  checklistItemId: string,
  dropboxFileReferences: FileReferenceInfo[],
): AppThunk<Promise<boolean>> => async (dispatch) => {
  try {
    await new ItemApi(getSherlockConfiguration()).removeFileReferences(
      checklistItemId,
      {
        references: dropboxFileReferences,
      },
    );
    dispatch(showSuccessToast('Document deleted successfully'));
    return true;
  } catch (e) {
    dispatch(showApiErrorModal(e));
    dispatch(
      showErrorToast(
        'Unable to remove the document from checklist item.',
        'Please try again after sometime.',
      ),
    );
    ErrorService.notifyIgnoreAuthErrors(
      'Unable to remove the dropbox document references from checklist item',
      e,
      {
        data: { checklistId, checklistItemId, dropboxFileReferences },
      },
    );
    return false;
  }
};

export const uploadToDropboxAndAddFileReferences = (
  checklistId: string,
  checklistItemId: string,
  dropboxId: string,
  userId: string,
  files: File[],
): AppThunk<Promise<boolean>> => async (dispatch) => {
  try {
    const data = await Promise.all(
      files.map(async (fileToUpload) =>
        dispatch(uploadFile(dropboxId!, userId!, fileToUpload)),
      ),
    );
    const dropboxFileIds: FileReferenceInfo[] = data.map((file) => ({
      fileId: file?.id!,
      filename: file?.filename!,
    }));
    if (dropboxFileIds.length > 0) {
      await dispatch(
        addFileReferencesToChecklistItem(
          checklistId,
          checklistItemId!,
          dropboxFileIds,
        ),
      );
    }
    return true;
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notifyIgnoreAuthErrors(
      'Unable to upload and add references of the document to checklist item',
      e,
      {
        data: {
          checklistId,
          checklistItemId,
          dropboxId,
          userId,
          files,
        },
      },
    );
    return false;
  }
};

export default CheckListSlice.reducer;
