import { isEmpty, isEqual, isNil, isObject, uniqBy, values } from 'lodash';
import { DateTime } from 'luxon';
import { GooglePlaceLocationType } from '../components/ControlledGoogleAutocompleteSearchInputV7';
import { CreateListingFormState } from '../components/transactions/CreateListing/withCreateListingProgress';
import {
  CreateTransactionFormState,
  OtherAgentTypeEnum,
} from '../components/Zen/Transaction/CreateTransaction/ZenCreateTransactionSteps';
import { OPCITY_KEY } from '../components/Zen/Transaction/ZenAddParticipantsForm';
import {
  I_DONT_KNOW_YET,
  I_DONT_KNOW_YET_EMAIL,
  NA_100_PERCENT_PAID_FOR_WITH_DEPOSIT_EMAIL,
} from '../constants/TransactionConstants';
import {
  Address,
  AddressCountryEnum,
  AddressStateEnum,
  AgentParticipantInfoRoleEnum,
  AttachedFeeRequestFeeTypeEnum,
  AttachedFeeValue,
  AttachedFeeValueFeeTypeEnum,
  AttachedFeeValueRecipientTypeEnum,
  BankAccountDto,
  BankAccountDtoBankAccountTypeEnum,
  BuilderParticipant,
  BuilderParticipantRoleEnum,
  CommissionPayerDisplayValue,
  CommissionPayerInfo,
  ExternalParticipantInfoRoleEnum,
  LedgerItemValueLedgerItemTypeEnum,
  LocationInfoRequestStateEnum,
  MoneyValue,
  MoneyValueCurrencyEnum,
  ParticipantResponse,
  ParticipantResponseParticipantRoleEnum,
  ParticipantValue,
  ParticipantValueRoleEnum,
  PaymentParticipantValue,
  PaymentParticipantValueRoleEnum,
  PriceAndDateInfoRequestRepresentationTypeEnum,
  SentimentDisplayResponse,
  SentimentDisplayResponseContextTypeEnum,
  TransactionBuilderAddressStateEnum,
  TransactionBuilderResponse,
  TransactionBuilderResponseDealTypeEnum,
  TransactionLifecycleStateValue,
  TransactionLifecycleStateValueStateEnum,
  TransactionLiteResponse,
  TransactionOwnerValue,
  TransactionResponse,
  TransactionResponseCountryEnum,
  TransactionResponseTransactionTypeEnum,
  UpdateDealRequest,
} from '../openapi/arrakis';
import { IdentityRoleResponse } from '../openapi/keymaker';
import {
  ChecklistResponse,
  ItemResponseRequiredForEnum,
  ItemResponseStatusEnum,
} from '../openapi/sherlock';
import {
  AddressRequestStateOrProvinceEnum,
  AddressResponseStateOrProvinceEnum,
  AdministrativeAreaResponseStateOrProvinceEnum,
  AgentCommentDetails,
  AgentInfo,
  AgentResponse,
  AgentResponseAccountCountryEnum,
  ApplicationResponse,
  OfficePreviewResponse,
  OfficeResponse,
} from '../openapi/yenta';
import {
  AddressComponentType,
  AdminRoles,
  BreadcrumbMatcherType,
  CreateTransactionParticipantType,
  EnumMap,
  FeatureFlagTypeEnum,
  GooglePlaceAddressType,
  ISelectOption,
  Mapping,
  PartialEnumMap,
  ParticipantType,
  TransactionPrintTypeEnum,
  YesNoType,
} from '../types';
import { isBroker } from './AuthUtils';
import {
  displayAmount,
  displayFormattedAmountWithCurrency,
  getFixedDecimalMoneyValue,
  numberWithCommas,
} from './CurrencyUtils';
import { safeEnumMapGet, safePartialEnumMapGet } from './EnumHelper';
import { getISelectOptionDefaultValue } from './FormUtils';
import { getParticipantName } from './ParticipantHelper';
import { capitalizeEnum, formatAddress } from './StringUtils';
import { getPriorityWiseSortedData } from './TableUtils';

interface TransactionReadOnlyOptions {
  skipBrokerCheck?: boolean;
}

export const isSaleTransaction = (
  transactionType:
    | TransactionResponseTransactionTypeEnum
    | TransactionBuilderResponseDealTypeEnum,
): boolean => {
  const map: EnumMap<TransactionResponseTransactionTypeEnum, boolean> = {
    COMMERCIAL_LEASE: false,
    LEASE: false,
    OTHER: false,
    PLOT: true,
    SALE: true,
    COMPENSATING: true,
    REFERRAL: true,
    INTERNAL_REFERRAL: true,
  };

  return map[transactionType];
};

export const isLeaseTransaction = (
  transactionType:
    | TransactionResponseTransactionTypeEnum
    | TransactionBuilderResponseDealTypeEnum,
): boolean => {
  return !isSaleTransaction(transactionType);
};

type Transition = TransactionLifecycleStateValueStateEnum;

export const isTransitionActionable = (lifecycleState: Transition): boolean => {
  const map: EnumMap<Transition, boolean> = {
    APPROVED_FOR_CLOSING: true,
    COMMISSION_DOCUMENT_APPROVED: true,
    COMMISSION_DOCUMENT_GENERATED: false,
    COMMISSION_DOCUMENT_SENT: false,
    CLOSED: true,
    CALCULATE_LEDGER: false,
    COMMISSION_VALIDATED: true,
    NEEDS_COMMISSION_VALIDATION: false,
    NEW: true,
    PAYMENT_ACCEPTED: true,
    PAYMENT_SCHEDULED: false,
    READY_FOR_COMMISSION_DOCUMENT_GENERATION: true,
    SETTLED: false,
    WAITING_ON_PAYMENT: false,
    TERMINATED: true,
    LISTING_ACTIVE: false,
    LISTING_CLOSED: false,
    LISTING_IN_CONTRACT: false,
    TERMINATION_REQUESTED: true,
  };

  return map[lifecycleState];
};

export const getTransactionTransitionToReadableName = (
  transaction: TransactionResponse,
  transition: Transition,
): string => {
  const country = transaction?.country!;

  const transactionTransitionToReadableName: EnumMap<Transition, string> = {
    APPROVED_FOR_CLOSING: 'Approve For Closing',
    COMMISSION_DOCUMENT_APPROVED: `Approve ${getCommissionDocumentName(
      country,
    )}`,
    COMMISSION_DOCUMENT_GENERATED: `Generate ${getCommissionDocumentName(
      country,
    )}`,
    CALCULATE_LEDGER: 'Calculate Ledger',
    COMMISSION_DOCUMENT_SENT: isCanadaTransaction(transaction)
      ? 'Confirm Trade Record Sheet'
      : 'Send Commission Document',
    CLOSED: 'Close',
    COMMISSION_VALIDATED: 'Validate Commission',
    NEEDS_COMMISSION_VALIDATION: 'Need Commission Validation',
    NEW: 'New',
    PAYMENT_ACCEPTED: 'Accept Payment',
    PAYMENT_SCHEDULED: 'Schedule Payment',
    READY_FOR_COMMISSION_DOCUMENT_GENERATION: `Ready For ${getCommissionDocumentName(
      country,
    )} Generation`,
    SETTLED: 'Settle',
    WAITING_ON_PAYMENT: 'Waiting On Payment',
    TERMINATED: 'Terminate',
    LISTING_ACTIVE: 'Active',
    LISTING_CLOSED: 'Close',
    LISTING_IN_CONTRACT: 'In Contract',
    TERMINATION_REQUESTED: 'Request Termination',
  };

  return transactionTransitionToReadableName[transition];
};

export const getTransactionTransitionToButtonText = (
  transaction: TransactionResponse,
  transition: Transition,
): string => {
  const country = transaction.country!;

  const transactionTransitionToButtonText: EnumMap<Transition, string> = {
    APPROVED_FOR_CLOSING: 'Approve For Closing',
    COMMISSION_DOCUMENT_APPROVED: isCanadaTransaction(transaction)
      ? 'Confirm Trade Record Sheet'
      : 'Send Commission Document',
    COMMISSION_DOCUMENT_GENERATED: `Generate ${getCommissionDocumentName(
      country,
    )}`,
    CALCULATE_LEDGER: 'Calculate Ledger',
    COMMISSION_DOCUMENT_SENT: isCanadaTransaction(transaction)
      ? 'Confirm Trade Record Sheet'
      : 'Send Commission Document',
    CLOSED: 'Close',
    COMMISSION_VALIDATED: 'Approve Commission Calculation',
    NEEDS_COMMISSION_VALIDATION: 'Need Commission Validation',
    NEW: 'New',
    PAYMENT_ACCEPTED: 'Accept Payment',
    PAYMENT_SCHEDULED: 'Schedule Payment',
    READY_FOR_COMMISSION_DOCUMENT_GENERATION: `Broker Approved For ${getCommissionDocumentName(
      country,
    )} Generation`,
    SETTLED: 'Settle',
    WAITING_ON_PAYMENT: 'Waiting On Payment',
    TERMINATED: 'Terminate',
    LISTING_ACTIVE: 'Active',
    LISTING_CLOSED: 'Close',
    LISTING_IN_CONTRACT: 'In Contract',
    TERMINATION_REQUESTED: 'Request Termination',
  };

  return transactionTransitionToButtonText[transition];
};

export const renderTransactionStatusByCountry = (
  transition: Transition,
  country: TransactionResponseCountryEnum,
  transactionType?: TransactionResponseTransactionTypeEnum,
): string => {
  const isCanadaTransaction = country === TransactionResponseCountryEnum.Canada;

  const transactionTransitionToButtonText: EnumMap<Transition, string> = {
    APPROVED_FOR_CLOSING: 'Approved For Closing',
    COMMISSION_DOCUMENT_APPROVED: `${getCommissionDocumentName(
      country,
    )} Approved`,
    COMMISSION_DOCUMENT_GENERATED: `${getCommissionDocumentName(
      country,
    )} Generated`,
    CALCULATE_LEDGER: 'Calculate Ledger',
    COMMISSION_DOCUMENT_SENT: isCanadaTransaction
      ? 'Trade Record Sheet Confirmed'
      : isUSInternalReferralTransaction(country, transactionType!)
      ? 'Commission Document Approved'
      : 'Commission Document Sent',
    CLOSED: 'Closed',
    COMMISSION_VALIDATED: 'Commission Validated',
    NEEDS_COMMISSION_VALIDATION: 'Needs Commission Validation',
    NEW: 'New',
    PAYMENT_ACCEPTED: 'Payment Accepted',
    PAYMENT_SCHEDULED: 'Payment Scheduled',
    READY_FOR_COMMISSION_DOCUMENT_GENERATION: `Broker Approved For ${getCommissionDocumentName(
      country,
    )} Generation`,
    SETTLED: 'Settled',
    WAITING_ON_PAYMENT: 'Waiting On Payment',
    TERMINATED: 'Terminated',
    LISTING_ACTIVE: 'Active',
    LISTING_CLOSED: 'Closed',
    LISTING_IN_CONTRACT: 'In Contract',
    TERMINATION_REQUESTED: 'Termination Requested',
  };

  return safeEnumMapGet(
    transactionTransitionToButtonText,
    transition,
    capitalizeEnum(transition),
  );
};

export const isTransactionReadOnly = (
  transaction: TransactionResponse,
  currentUser: AgentResponse,
  options?: TransactionReadOnlyOptions,
): boolean => {
  if (transaction.listing) {
    return (
      !!transaction.terminated ||
      transaction.lifecycleState?.state ===
        TransactionLifecycleStateValueStateEnum.ListingClosed
    );
  }

  if (!options?.skipBrokerCheck && isBroker(currentUser)) {
    return true;
  }

  const map: EnumMap<TransactionLifecycleStateValueStateEnum, boolean> = {
    APPROVED_FOR_CLOSING: false,
    COMMISSION_DOCUMENT_APPROVED: false,
    CALCULATE_LEDGER: false,
    COMMISSION_DOCUMENT_GENERATED: false,
    COMMISSION_DOCUMENT_SENT: false,
    CLOSED: true,
    COMMISSION_VALIDATED: false,
    NEEDS_COMMISSION_VALIDATION: false,
    NEW: true,
    PAYMENT_ACCEPTED: true,
    PAYMENT_SCHEDULED: true,
    READY_FOR_COMMISSION_DOCUMENT_GENERATION: false,
    SETTLED: true,
    WAITING_ON_PAYMENT: true,
    TERMINATED: true,
    LISTING_ACTIVE: false,
    LISTING_CLOSED: false,
    LISTING_IN_CONTRACT: false,
    TERMINATION_REQUESTED: false,
  };

  return safeEnumMapGet(map, transaction.lifecycleState!.state!, false);
};

export const isTransactionClosed = (
  transaction: TransactionResponse,
): boolean => {
  const map: EnumMap<TransactionLifecycleStateValueStateEnum, boolean> = {
    APPROVED_FOR_CLOSING: false,
    COMMISSION_DOCUMENT_APPROVED: false,
    CALCULATE_LEDGER: false,
    COMMISSION_DOCUMENT_GENERATED: false,
    COMMISSION_DOCUMENT_SENT: false,
    COMMISSION_VALIDATED: false,
    NEEDS_COMMISSION_VALIDATION: false,
    NEW: false,
    READY_FOR_COMMISSION_DOCUMENT_GENERATION: false,
    CLOSED: true,
    PAYMENT_ACCEPTED: true,
    PAYMENT_SCHEDULED: true,
    SETTLED: true,
    WAITING_ON_PAYMENT: true,
    TERMINATED: true,
    LISTING_ACTIVE: false,
    LISTING_CLOSED: true,
    LISTING_IN_CONTRACT: false,
    TERMINATION_REQUESTED: false,
  };

  return safeEnumMapGet(map, transaction.lifecycleState!.state!, true);
};

export const isParticipantTransactionOwner = (
  transactionOwner: TransactionOwnerValue | null,
  participant: PaymentParticipantValue | ParticipantValue,
): boolean => {
  return (
    !!transactionOwner &&
    !!participant &&
    transactionOwner.id === participant.yentaId
  );
};

export const isParticipantRoleValid = (
  role: PaymentParticipantValueRoleEnum,
  transactionType:
    | TransactionResponseTransactionTypeEnum
    | TransactionBuilderResponseDealTypeEnum,
  representationType?: string,
  paymentParticipantType?: ParticipantType,
): boolean => {
  const AGENT_ENUM_MAP: EnumMap<PaymentParticipantValueRoleEnum, boolean> = {
    [PaymentParticipantValueRoleEnum.Admin]: false,
    [PaymentParticipantValueRoleEnum.RealAdmin]: false,
    [PaymentParticipantValueRoleEnum.BuyersLawyer]: false,
    [PaymentParticipantValueRoleEnum.SellersLawyer]: false,
    [PaymentParticipantValueRoleEnum.Trustee]: false,
    [PaymentParticipantValueRoleEnum.Buyer]: false,
    [PaymentParticipantValueRoleEnum.Appraiser]: false,
    [PaymentParticipantValueRoleEnum.Attorney]: false,
    [PaymentParticipantValueRoleEnum.BuyersAgent]:
      representationType !== 'Listing Side Representation',
    [PaymentParticipantValueRoleEnum.DomesticLead]: false,
    [PaymentParticipantValueRoleEnum.Escrow]: false,
    [PaymentParticipantValueRoleEnum.ExternalAgent]: false,
    [PaymentParticipantValueRoleEnum.HomeWarranty]: false,
    [PaymentParticipantValueRoleEnum.Inspection]: false,
    [PaymentParticipantValueRoleEnum.Landlord]: false,
    [PaymentParticipantValueRoleEnum.Lender]: false,
    [PaymentParticipantValueRoleEnum.ManagementCompany]: false,
    [PaymentParticipantValueRoleEnum.Other]: false,
    [PaymentParticipantValueRoleEnum.OtherAgent]: false,
    [PaymentParticipantValueRoleEnum.Real]: false,
    [PaymentParticipantValueRoleEnum.ReferralPayer]: false,
    [PaymentParticipantValueRoleEnum.ReferringAgent]: true,
    [PaymentParticipantValueRoleEnum.Seller]: false,
    [PaymentParticipantValueRoleEnum.SellersAgent]:
      representationType !== 'Buy Side Representation',
    [PaymentParticipantValueRoleEnum.TeamLeader]: true,
    [PaymentParticipantValueRoleEnum.TeamMember]: true,
    [PaymentParticipantValueRoleEnum.Tenant]: false,
    [PaymentParticipantValueRoleEnum.TenantAgent]: false,
    [PaymentParticipantValueRoleEnum.Title]: false,
    [PaymentParticipantValueRoleEnum.TransactionCoordinator]: false,
    [PaymentParticipantValueRoleEnum.Unknown]: false,
    [PaymentParticipantValueRoleEnum.Builder]: false,
    [PaymentParticipantValueRoleEnum.ProTeamLeader]: false,
  };

  const EXTERNAL_AGENT_ENUM_MAP: EnumMap<
    PaymentParticipantValueRoleEnum,
    boolean
  > = {
    [PaymentParticipantValueRoleEnum.Admin]: false,
    [PaymentParticipantValueRoleEnum.RealAdmin]: false,
    [PaymentParticipantValueRoleEnum.BuyersLawyer]: true,
    [PaymentParticipantValueRoleEnum.SellersLawyer]: true,
    [PaymentParticipantValueRoleEnum.Trustee]: true,
    [PaymentParticipantValueRoleEnum.Buyer]: true,
    [PaymentParticipantValueRoleEnum.Appraiser]: false,
    [PaymentParticipantValueRoleEnum.Attorney]: true,
    [PaymentParticipantValueRoleEnum.BuyersAgent]: false,
    [PaymentParticipantValueRoleEnum.DomesticLead]: false,
    [PaymentParticipantValueRoleEnum.Escrow]: true,
    [PaymentParticipantValueRoleEnum.ExternalAgent]: false,
    [PaymentParticipantValueRoleEnum.HomeWarranty]: false,
    [PaymentParticipantValueRoleEnum.Inspection]: false,
    [PaymentParticipantValueRoleEnum.Landlord]: true,
    [PaymentParticipantValueRoleEnum.Lender]: false,
    [PaymentParticipantValueRoleEnum.ManagementCompany]: true,
    [PaymentParticipantValueRoleEnum.Other]: false,
    [PaymentParticipantValueRoleEnum.OtherAgent]: true,
    [PaymentParticipantValueRoleEnum.Real]: false,
    [PaymentParticipantValueRoleEnum.ReferralPayer]:
      transactionType === TransactionResponseTransactionTypeEnum.Referral,
    [PaymentParticipantValueRoleEnum.ReferringAgent]: true,
    [PaymentParticipantValueRoleEnum.ProTeamLeader]: false,
    [PaymentParticipantValueRoleEnum.Seller]: true,
    [PaymentParticipantValueRoleEnum.SellersAgent]: false,
    [PaymentParticipantValueRoleEnum.TeamLeader]: false,
    [PaymentParticipantValueRoleEnum.TeamMember]: false,
    [PaymentParticipantValueRoleEnum.Tenant]: true,
    [PaymentParticipantValueRoleEnum.TenantAgent]: false,
    [PaymentParticipantValueRoleEnum.Title]: true,
    [PaymentParticipantValueRoleEnum.TransactionCoordinator]: true,
    [PaymentParticipantValueRoleEnum.Unknown]: false,
    [PaymentParticipantValueRoleEnum.Builder]: false,
  };

  return paymentParticipantType === ParticipantType.AGENT
    ? safeEnumMapGet(AGENT_ENUM_MAP, role, false)
    : safeEnumMapGet(EXTERNAL_AGENT_ENUM_MAP, role, false);
};

const PARTICIPANT_ROLE_TO_DISPLAY_NAME_MAP: PartialEnumMap<
  ParticipantValueRoleEnum,
  string
> = {
  BUYERS_AGENT: 'Buyer / Tenant Agent',
  BUYERS_LAWYER: "Buyer's Lawyer",
  REFERRING_AGENT: 'Referral',
  SELLERS_AGENT: 'Seller / Landlord Agent',
  SELLERS_LAWYER: "Seller's Lawyer",
};

export const participantRoleDisplayName = (
  role:
    | PaymentParticipantValueRoleEnum
    | ParticipantValueRoleEnum
    | ParticipantResponseParticipantRoleEnum
    | BuilderParticipantRoleEnum,
): string => {
  return PARTICIPANT_ROLE_TO_DISPLAY_NAME_MAP[role] ?? capitalizeEnum(role);
};

export const ParticipantRoleSortingOrder = [
  ParticipantValueRoleEnum.Seller,
  ParticipantValueRoleEnum.Buyer,
  ParticipantValueRoleEnum.Landlord,
  ParticipantValueRoleEnum.Tenant,
  ParticipantValueRoleEnum.Title,
  ParticipantValueRoleEnum.Escrow,
  ParticipantValueRoleEnum.Attorney,
  ParticipantValueRoleEnum.ReferralPayer,
  ParticipantValueRoleEnum.SellersLawyer,
  ParticipantValueRoleEnum.BuyersLawyer,
  ParticipantValueRoleEnum.Trustee,
  ParticipantValueRoleEnum.OtherAgent,
  ParticipantValueRoleEnum.ManagementCompany,
  ParticipantValueRoleEnum.TeamLeader,
  ParticipantValueRoleEnum.TeamMember,
  ParticipantValueRoleEnum.BuyersAgent,
  ParticipantValueRoleEnum.SellersAgent,
  ParticipantValueRoleEnum.TransactionCoordinator,
  ParticipantValueRoleEnum.ReferringAgent,
  ParticipantValueRoleEnum.Lender,
  ParticipantValueRoleEnum.HomeWarranty,
  ParticipantValueRoleEnum.Real,
  ParticipantValueRoleEnum.Admin,
];

export const getValidParticipantRoles = (
  transactionType:
    | TransactionResponseTransactionTypeEnum
    | TransactionBuilderResponseDealTypeEnum,
  representationType?: string,
  paymentParticipantType?: ParticipantType,
): ISelectOption[] => {
  return Object.values(PaymentParticipantValueRoleEnum)
    .filter((role) =>
      isParticipantRoleValid(
        role,
        transactionType,
        representationType,
        paymentParticipantType,
      ),
    )
    .sort((a, b) =>
      getPriorityWiseSortedData(a, b, ParticipantRoleSortingOrder),
    )
    .map((role) => {
      return {
        label: participantRoleDisplayName(role),
        value: role,
      };
    });
};

interface ActionableTransitions {
  primaryTransitions: TransactionLifecycleStateValue[];
  secondaryTransitions: TransactionLifecycleStateValue[];
}

export const getTerminatedTransition = (
  transaction: TransactionResponse,
): TransactionLifecycleStateValue | undefined => {
  const transitions = getActionableTransitions(transaction, true);

  let terminatedTransition = transitions.primaryTransitions.find(
    (item) => item.state === TransactionLifecycleStateValueStateEnum.Terminated,
  );

  if (!terminatedTransition) {
    terminatedTransition = transitions.secondaryTransitions.find(
      (item) =>
        item.state === TransactionLifecycleStateValueStateEnum.Terminated,
    );
  }

  return terminatedTransition;
};

export const getActionableTransitions = (
  transaction: TransactionResponse,
  isAdmin: boolean,
): ActionableTransitions => {
  let primaryTransitionStates: TransactionLifecycleStateValue[];
  let secondaryTransitionStates: TransactionLifecycleStateValue[];
  if (isAdmin) {
    primaryTransitionStates = [
      transaction.nextPrimaryAdminTransition!,
      transaction.nextPrimaryUserTransition!,
    ];
    secondaryTransitionStates = [
      ...(transaction.nextSecondaryAdminTransitions || []),
      ...(transaction.nextSecondaryUserTransitions || []),
    ];
  } else {
    primaryTransitionStates = [transaction.nextPrimaryUserTransition!];
    secondaryTransitionStates = transaction.nextSecondaryUserTransitions || [];
  }

  const primaryTransitions = uniqBy<TransactionLifecycleStateValue>(
    primaryTransitionStates.filter(
      (transition: TransactionLifecycleStateValue) =>
        transition && isTransitionActionable(transition.state!),
    ),
    'state',
  );

  const secondaryTransitions = uniqBy<TransactionLifecycleStateValue>(
    secondaryTransitionStates.filter(
      (transition: TransactionLifecycleStateValue) =>
        transition && isTransitionActionable(transition.state!),
    ),
    'state',
  );

  const transitions: ActionableTransitions = {
    primaryTransitions,
    secondaryTransitions,
  };

  return transitions;
};

export const isCanadaTransaction = (
  transaction: TransactionResponse | undefined,
) => {
  return transaction?.country === TransactionResponseCountryEnum.Canada;
};

export const isReferralTransaction = (transaction: TransactionResponse) => {
  return (
    transaction?.transactionType ===
    TransactionResponseTransactionTypeEnum.Referral
  );
};

export const isUSInternalReferralTransaction = (
  country: TransactionResponseCountryEnum,
  transactionType: TransactionResponseTransactionTypeEnum,
): boolean => {
  const isUSCountry = country === TransactionResponseCountryEnum.UnitedStates;

  const isInternalReferral =
    transactionType === TransactionResponseTransactionTypeEnum.InternalReferral;

  return isUSCountry && isInternalReferral;
};

export const isAgentUSInternalReferralTransaction = (
  transaction: TransactionResponse,
  isAgent: boolean,
) => {
  return (
    isUSInternalReferralTransaction(
      transaction?.country!,
      transaction?.transactionType!,
    ) && isAgent
  );
};

export const getAllTransactionParticipants = (
  transaction: TransactionResponse,
) => {
  const allParticipants = [
    ...(transaction.paymentParticipants || []),
    ...(transaction.otherParticipants || []),
  ];

  return allParticipants;
};

export const getAllTransactionParticipantsByRole = (
  transaction: TransactionResponse,
  role: PaymentParticipantValueRoleEnum | ParticipantValueRoleEnum,
) => {
  const allParticipants = getAllTransactionParticipants(transaction);

  const allParticipantsMatchingRole = allParticipants.filter(
    (participant) => participant.role === role,
  );

  return allParticipantsMatchingRole;
};

export const getTransactionAttachedFeeByType = (
  transaction: TransactionResponse,
  feeType: AttachedFeeValueFeeTypeEnum,
) => {
  const foundAttachedFee = transaction?.attachedFees?.filter(
    (attachedFee) => attachedFee.feeType === feeType,
  );

  return foundAttachedFee;
};

export const getTransactionOwner = (transaction: TransactionResponse) => {
  const allParticipants = getAllTransactionParticipants(transaction);

  const transactionOwner = allParticipants.find((participant) =>
    isParticipantTransactionOwner(transaction.transactionOwner!, participant),
  );

  return transactionOwner;
};

export const getTransactionParticipantById = (
  transaction: TransactionResponse,
  id: string,
) => {
  const participant = transaction.participants?.find((p) => p.id === id);

  return participant;
};

export const getTransactionParticipantByEmail = (
  transaction: TransactionResponse,
  email: string,
) => {
  const participant = transaction.participants?.find(
    (p) => p.emailAddress === email,
  );

  return participant;
};

export const getAttachedFeeRowSelectionLabel = (
  transaction: TransactionResponse,
  attachedFee: AttachedFeeValue | undefined,
) => {
  const participant = getTransactionParticipantById(
    transaction!,
    attachedFee!.counterpartyId!,
  );

  const fullName =
    attachedFee!.feeType === AttachedFeeValueFeeTypeEnum.TransactionCoordinator
      ? `${attachedFee!.description?.replace('Transaction Coordinator: ', '')}`
      : getParticipantName(participant);

  return `${fullName} (${displayAmount(attachedFee!.amount)})`;
};

export const getAllTransactionPaymentParticipantsText = (
  transaction: TransactionResponse,
): string => {
  const allParticipants = transaction.possiblePayableParticipants!.map(
    (participant) => {
      const name = getParticipantName(participant);

      if (!participant.payment?.amount) {
        return `${name} - ${(participant.payment?.percent! * 100).toFixed()}%`;
      }

      return `${name} - ${displayAmount(participant.payment.amount)}`;
    },
  );

  const allParticipantsText = allParticipants.join('\n');

  return allParticipantsText;
};

export const getTransactionPrintType = (
  transactionType: TransactionResponseTransactionTypeEnum,
): TransactionPrintTypeEnum =>
  isSaleTransaction(transactionType!)
    ? TransactionPrintTypeEnum.CDA
    : TransactionPrintTypeEnum.INVOICE;

export const canHaveApprovedCDAs = (
  state: TransactionLifecycleStateValueStateEnum,
): boolean => {
  const CDA_ENUM_MAP: EnumMap<
    TransactionLifecycleStateValueStateEnum,
    boolean
  > = {
    [TransactionLifecycleStateValueStateEnum.ApprovedForClosing]: true,
    [TransactionLifecycleStateValueStateEnum.Closed]: true,
    [TransactionLifecycleStateValueStateEnum.CommissionDocumentApproved]: true,
    [TransactionLifecycleStateValueStateEnum.CommissionDocumentGenerated]: false,
    [TransactionLifecycleStateValueStateEnum.CommissionDocumentSent]: true,
    [TransactionLifecycleStateValueStateEnum.PaymentAccepted]: true,
    [TransactionLifecycleStateValueStateEnum.PaymentScheduled]: true,
    [TransactionLifecycleStateValueStateEnum.Settled]: true,
    [TransactionLifecycleStateValueStateEnum.Terminated]: false,
    [TransactionLifecycleStateValueStateEnum.WaitingOnPayment]: true,
    [TransactionLifecycleStateValueStateEnum.CalculateLedger]: false,
    [TransactionLifecycleStateValueStateEnum.CommissionValidated]: false,
    [TransactionLifecycleStateValueStateEnum.NeedsCommissionValidation]: false,
    [TransactionLifecycleStateValueStateEnum.New]: false,
    [TransactionLifecycleStateValueStateEnum.ReadyForCommissionDocumentGeneration]: false,
    [TransactionLifecycleStateValueStateEnum.ListingActive]: false,
    [TransactionLifecycleStateValueStateEnum.ListingClosed]: false,
    [TransactionLifecycleStateValueStateEnum.ListingInContract]: false,
    [TransactionLifecycleStateValueStateEnum.TerminationRequested]: false,
  };
  return CDA_ENUM_MAP[state];
};

export const encodeTransactionAccountNumber = (
  accountNumber: string,
  symbol: string = '*',
) => {
  const accountNo = accountNumber.substring(0, accountNumber.length - 4);

  return accountNumber.replace(accountNo, accountNo.replace(/./g, symbol));
};

export const getInstantCommissionName = (
  country: TransactionResponseCountryEnum | AgentResponseAccountCountryEnum,
) => {
  return country === TransactionResponseCountryEnum.UnitedStates
    ? 'Instant Commission'
    : 'Breakaway Payments';
};

export const getCommissionDocumentsName = (
  country: TransactionResponseCountryEnum | AgentResponseAccountCountryEnum,
) => {
  return country === TransactionResponseCountryEnum.UnitedStates
    ? 'Commission Documents'
    : 'Trade Record Sheets';
};

export const getCommissionDocumentName = (
  country: TransactionResponseCountryEnum | AgentResponseAccountCountryEnum,
) => {
  return country === TransactionResponseCountryEnum.UnitedStates
    ? 'Commission Document'
    : 'Trade Record Sheet';
};

export const getCDAName = (
  country: TransactionResponseCountryEnum | AgentResponseAccountCountryEnum,
) => {
  return country === TransactionResponseCountryEnum.UnitedStates
    ? 'CDA'
    : 'Trade Record Sheet';
};

export const isDoingBusinessInState = (
  state: AdministrativeAreaResponseStateOrProvinceEnum,
  application: ApplicationResponse | undefined,
): boolean | undefined => {
  return application?.doesBusinessInExtended?.some(
    (business) =>
      business.licenseResponse?.administrativeArea?.stateOrProvince === state,
  );
};

export const getBreadcrumbMatched = (
  path: string,
  breadcrumbMatchers: BreadcrumbMatcherType[],
): BreadcrumbMatcherType | undefined => {
  const matchedBreadcrumb = breadcrumbMatchers.find((breadcrumb) =>
    path.includes(breadcrumb.url),
  );

  return matchedBreadcrumb;
};

export const shouldRedirectToDetailsTab = (
  transaction: TransactionResponse,
  path: string,
  isFlagEnabled: boolean,
  pathname: string,
): boolean => {
  return (
    (!transaction?.journeyId || !isFlagEnabled) &&
    path === `/${pathname}/${transaction?.id}`
  );
};

export const getBankAccountFormattedString = (
  bankAccount: BankAccountDto,
  hideAmount = false,
) => {
  if (!bankAccount) {
    return 'N/A';
  }

  if (hideAmount) {
    return `${bankAccount.bankName} ${capitalizeEnum(
      bankAccount.bankAccountType!,
    )} *${bankAccount.accountNumber?.slice(-4)}`;
  }

  return `${bankAccount.bankName} ${capitalizeEnum(
    bankAccount.bankAccountType!,
  )} *${bankAccount.accountNumber?.slice(
    -4,
  )} | ${displayFormattedAmountWithCurrency(bankAccount.balance)}`;
};

export const bankAccountTypeVariant: {
  [type in BankAccountDtoBankAccountTypeEnum]: string;
} = {
  COMMISSION: 'bg-success',
  TRUST: 'bg-warning',
  OPERATING: 'bg-primary',
};

export const bankAccountTypeOptionBarColor: {
  [type in BankAccountDtoBankAccountTypeEnum]: string;
} = {
  COMMISSION: '#439775',
  TRUST: '#FAAD14',
  OPERATING: '#05C3F9',
};

export const feeTypeParticipants = (feeType: AttachedFeeRequestFeeTypeEnum) => {
  const mapFeeTypeToParticipants: EnumMap<
    AttachedFeeRequestFeeTypeEnum,
    boolean
  > = {
    ADDITIONAL_COMMISSION: true,
    REBATE: true,
    MLS: true,
    REIMBURSEMENT: true,
    TRANSACTION_COORDINATOR: true,
    ONE_REAL_IMPACT_FUND: true,
  };

  return mapFeeTypeToParticipants[feeType];
};

export const getParticipantsBasedOnFeeType = (
  transaction: TransactionResponse,
) => {
  return (transaction.paymentParticipants || [])
    ?.filter((p) => p?.role !== PaymentParticipantValueRoleEnum.OtherAgent)
    .filter((p) => p?.id !== undefined)
    .map((participant) => ({
      value: `${participant.id}`,
      label: `${getParticipantName(
        participant,
      )} - (${participantRoleDisplayName(
        participant.role || ParticipantResponseParticipantRoleEnum.Unknown,
      )})`,
    }));
};

export const isFTNISettlementIdAvailable = (officeDetails?: OfficeResponse) => {
  return !!officeDetails?.bankAccount?.ftniSettlementId;
};

export const isFTNISettlementIdAvailableForTrustAccount = (
  officeDetails?: OfficeResponse,
) => {
  return !!officeDetails?.trustAccount?.ftniSettlementId;
};

export enum RequiredField {
  FIRST_NAME = 'FIRST_NAME',
  LAST_NAME = 'LAST_NAME',
  COMPANY_NAME = 'COMPANY_NAME',
  ADDRESS = 'ADDRESS',
  EMAIL = 'EMAIL',
}

export const getFieldsMessage = (fieldType: RequiredField) => {
  const map: EnumMap<RequiredField, string> = {
    FIRST_NAME: 'First Name is required',
    LAST_NAME: 'Last Name is required',
    EMAIL: 'Email is required',
    ADDRESS: 'Address is required',
    COMPANY_NAME: 'Company Name is required',
  };

  return map[fieldType];
};

export const isTransactionTypeSaleOrLease = (
  type: TransactionResponseTransactionTypeEnum,
) => {
  return [
    TransactionResponseTransactionTypeEnum.Sale,
    TransactionResponseTransactionTypeEnum.Lease,
  ].includes(type);
};

export const isParticipantRoleSellerOrBuyer = (
  role: ParticipantValueRoleEnum,
) => {
  return [
    ParticipantValueRoleEnum.Seller,
    ParticipantValueRoleEnum.Buyer,
  ].includes(role);
};

export const isParticipantRoleBuyerLawyerOrSellerLawyerOrOtherAgent = (
  role: ParticipantValueRoleEnum,
) => {
  return [
    ParticipantValueRoleEnum.BuyersLawyer,
    ParticipantValueRoleEnum.SellersLawyer,
    ParticipantValueRoleEnum.OtherAgent,
  ].includes(role);
};

export const isTransactionTypeSale = (
  type: TransactionResponseTransactionTypeEnum,
) => {
  return type === TransactionResponseTransactionTypeEnum.Sale;
};

export const isTransactionTypeLease = (
  type: TransactionResponseTransactionTypeEnum,
) => {
  return type === TransactionResponseTransactionTypeEnum.Lease;
};

export const validateFields = (
  fieldType: RequiredField,
  transaction: TransactionResponse,
  obj: {
    firstName: string;
    lastName: string;
    companyName: string;
    participantRole: ParticipantValueRoleEnum | ISelectOption;
    ein: string;
  },
) => {
  const type = transaction.transactionType!;
  const isCanada =
    transaction.country === TransactionResponseCountryEnum.Canada;

  const participantRole = isObject(obj.participantRole)
    ? (obj.participantRole.value as ParticipantValueRoleEnum)
    : obj.participantRole;

  const isBuyer = participantRole === ParticipantValueRoleEnum.Buyer;

  const isSeller = participantRole === ParticipantValueRoleEnum.Seller;

  const isSellerLawyer =
    participantRole === ParticipantValueRoleEnum.SellersLawyer;

  const transactionSaleOrLeaseTypeAndSellerOrBuyerParticipantRoleCriteria =
    isTransactionTypeSaleOrLease(type) &&
    isParticipantRoleSellerOrBuyer(participantRole);
  const transactionSaleTypeAndBuyerOrSellerLawyerParticipantRoleCriteria =
    isTransactionTypeSale(type) &&
    isParticipantRoleBuyerLawyerOrSellerLawyerOrOtherAgent(participantRole);
  const transactionLeaseTypeAndOtherAgentParticipantRoleCriteria =
    isTransactionTypeLease(type) &&
    participantRole === ParticipantValueRoleEnum.OtherAgent;
  const isBuyerRoleAndListingSide =
    isBuyer && !!transaction?.listingSideRepresentation;
  const isSellerRoleAndBuySideRepresentation =
    isSeller && !!transaction?.buySideRepresentation;
  const isSellerLawyerRoleAndBuySideRepresentation =
    isSellerLawyer && !!transaction?.buySideRepresentation;
  const isAlbertaOrOntarioStateOrProvince =
    transaction?.address?.state === AddressStateEnum.Alberta ||
    transaction?.address?.state === AddressStateEnum.Ontario;
  const isAlbertaStateOrProvince =
    transaction?.address?.state === AddressStateEnum.Alberta;

  const isSellerWithBuySideInAlbertaOrOntarioState =
    isSellerRoleAndBuySideRepresentation && isAlbertaOrOntarioStateOrProvince;

  const isSellerLawyerWithBuySideInAlbertaState =
    isSellerLawyerRoleAndBuySideRepresentation && isAlbertaStateOrProvince;

  const mapFieldsToError: EnumMap<
    RequiredField,
    {
      validate: (value: string) => string | undefined;
    }
  > = {
    FIRST_NAME: {
      validate: (value: string) => {
        if (
          isCanada &&
          !value &&
          (transactionSaleTypeAndBuyerOrSellerLawyerParticipantRoleCriteria ||
            transactionLeaseTypeAndOtherAgentParticipantRoleCriteria)
        ) {
          return getFieldsMessage(RequiredField.FIRST_NAME);
        }

        if (!obj.firstName && !obj.lastName && !obj.companyName) {
          return 'You must provide a first and last name OR company name';
        }

        if (!obj.companyName && !value) {
          return 'Please enter your first name';
        }

        if (obj.lastName && !value) {
          return 'First name is required';
        }

        return undefined;
      },
    },
    LAST_NAME: {
      validate: (value: string) => {
        if (
          isCanada &&
          !value &&
          (transactionSaleTypeAndBuyerOrSellerLawyerParticipantRoleCriteria ||
            transactionLeaseTypeAndOtherAgentParticipantRoleCriteria)
        ) {
          return getFieldsMessage(RequiredField.LAST_NAME);
        }

        if (!value && !obj.firstName && !obj.companyName) {
          return 'You must provide a first and last name OR company name';
        }

        if (!obj.companyName && !value) {
          return 'Please enter your last name';
        }

        if (obj.firstName && !value) {
          return 'Last name is required';
        }

        return undefined;
      },
    },
    EMAIL: {
      validate: (value: string) => {
        if (
          isCanada &&
          !value &&
          (transactionSaleOrLeaseTypeAndSellerOrBuyerParticipantRoleCriteria ||
            transactionSaleTypeAndBuyerOrSellerLawyerParticipantRoleCriteria ||
            transactionLeaseTypeAndOtherAgentParticipantRoleCriteria) &&
          !isBuyerRoleAndListingSide &&
          !isSellerWithBuySideInAlbertaOrOntarioState &&
          !isSellerLawyerWithBuySideInAlbertaState
        ) {
          return getFieldsMessage(RequiredField.EMAIL);
        }

        return undefined;
      },
    },
    ADDRESS: {
      validate: (value: string) => {
        if (
          isCanada &&
          !value &&
          (transactionSaleOrLeaseTypeAndSellerOrBuyerParticipantRoleCriteria ||
            transactionSaleTypeAndBuyerOrSellerLawyerParticipantRoleCriteria ||
            transactionLeaseTypeAndOtherAgentParticipantRoleCriteria) &&
          !isBuyerRoleAndListingSide &&
          !isSellerWithBuySideInAlbertaOrOntarioState &&
          !isSellerLawyerWithBuySideInAlbertaState
        ) {
          return getFieldsMessage(RequiredField.ADDRESS);
        }

        return undefined;
      },
    },
    COMPANY_NAME: {
      validate: (value: string) => {
        if (
          isCanada &&
          !value &&
          (transactionSaleTypeAndBuyerOrSellerLawyerParticipantRoleCriteria ||
            transactionLeaseTypeAndOtherAgentParticipantRoleCriteria)
        ) {
          return getFieldsMessage(RequiredField.COMPANY_NAME);
        }

        if (!value && !obj.firstName && !obj.lastName) {
          return 'You must provide a first and last name OR company name';
        }

        if (!value && obj.ein) {
          return 'Company name is required with EIN';
        }

        return undefined;
      },
    },
  };
  return mapFieldsToError[fieldType];
};

export const isAddParticipantLabelRequired = (
  transaction: TransactionResponse,
  participantRole: ParticipantValueRoleEnum | ISelectOption,
) => {
  const type = transaction.transactionType!;
  const isCanada =
    transaction.country === TransactionResponseCountryEnum.Canada;
  const role = isObject(participantRole)
    ? (participantRole.value as ParticipantValueRoleEnum)
    : participantRole;

  const isBuyer = role === ParticipantValueRoleEnum.Buyer;
  const isSeller = role === ParticipantValueRoleEnum.Seller;
  const isSellerLawyer = role === ParticipantValueRoleEnum.SellersLawyer;

  const transactionSaleOrLeaseTypeAndSellerOrBuyerParticipantRoleCriteria =
    isTransactionTypeSaleOrLease(type) && isParticipantRoleSellerOrBuyer(role);
  const transactionSaleTypeAndBuyerOrSellerLawyerParticipantRoleCriteria =
    isTransactionTypeSale(type) &&
    isParticipantRoleBuyerLawyerOrSellerLawyerOrOtherAgent(role);
  const transactionLeaseTypeAndOtherAgentParticipantRoleCriteria =
    isTransactionTypeLease(type) &&
    role === ParticipantValueRoleEnum.OtherAgent;
  const isBuyerRoleAndListingSide =
    isBuyer && !!transaction?.listingSideRepresentation;

  const isSellerRoleAndBuySideRepresentation =
    isSeller && !!transaction?.buySideRepresentation;

  const isSellerLawyerRoleAndBuySideRepresentation =
    isSellerLawyer && !!transaction?.buySideRepresentation;

  const isAlbertaOrOntarioStateOrProvince =
    transaction?.address?.state === AddressStateEnum.Alberta ||
    transaction?.address?.state === AddressStateEnum.Ontario;

  const isAlbertaStateOrProvince =
    transaction?.address?.state === AddressStateEnum.Alberta;

  const isSellerWithBuysideInAlbertaOrOntarioState =
    isSellerRoleAndBuySideRepresentation && isAlbertaOrOntarioStateOrProvince;

  const isSellerLawyerWithBuySideInAlbertaState =
    isSellerLawyerRoleAndBuySideRepresentation && isAlbertaStateOrProvince;

  return (
    isCanada &&
    (transactionSaleOrLeaseTypeAndSellerOrBuyerParticipantRoleCriteria ||
      transactionSaleTypeAndBuyerOrSellerLawyerParticipantRoleCriteria ||
      transactionLeaseTypeAndOtherAgentParticipantRoleCriteria) &&
    !isBuyerRoleAndListingSide &&
    !isSellerWithBuysideInAlbertaOrOntarioState &&
    !isSellerLawyerWithBuySideInAlbertaState
  );
};

export const getParticipantScreenTitle = (
  participant: ParticipantValue | PaymentParticipantValue | null,
  isExternalParticipant = false,
  isReadOnly = false,
) => {
  if (isExternalParticipant) {
    return isReadOnly
      ? `View External Participant: ${getParticipantName(participant!)}`
      : `Edit External Participant: ${getParticipantName(participant!)}`;
  }

  return isReadOnly
    ? `View Participant: ${getParticipantName(participant!)}`
    : `Edit Participant: ${getParticipantName(participant!)}`;
};

export const getNameFromAddressComponent = (
  addressComponents: AddressComponentType[],
  level: string,
): string => {
  const addressComponentFound = addressComponents?.find((addressComponent) =>
    addressComponent.types.includes(level),
  );

  if (addressComponentFound) {
    return addressComponentFound?.long_name!;
  }

  return '';
};

export const convertStringToEnums = <D>(txt: string): D =>
  (txt.toUpperCase().replaceAll(' ', '_') as unknown) as D;

export const getStreet1 = (
  addressComponents: AddressComponentType[],
): string => {
  const address = [];
  const streetNumber = getNameFromAddressComponent(
    addressComponents,
    'street_number',
  );
  if (streetNumber) {
    address.push(streetNumber);
  }

  const route = getNameFromAddressComponent(addressComponents, 'route');
  if (route) {
    address.push(route);
  }

  const unit_no = getNameFromAddressComponent(addressComponents, 'subpremise');
  if (unit_no) {
    address.push(`#${unit_no}`);
  }

  return address.join(' ');
};

export const parseStateComponent = (
  state: AddressRequestStateOrProvinceEnum | string,
): AddressRequestStateOrProvinceEnum => {
  if (state === 'QUÉBEC') {
    return AddressRequestStateOrProvinceEnum.Quebec;
  }
  return state as AddressRequestStateOrProvinceEnum;
};

export const getAddressFromAddressComponent = <
  K extends keyof GooglePlaceAddressType
>(
  addressComponent: AddressComponentType[],
  addressOmitProperties: K[] = [],
): GooglePlaceAddressType => {
  const county: string = convertStringToEnums(
    getNameFromAddressComponent(
      addressComponent,
      'administrative_area_level_2',
    ),
  );

  const address: GooglePlaceAddressType = {
    streetAddress1: getStreet1(addressComponent),
    streetAddress2: getNameFromAddressComponent(
      addressComponent,
      'neighborhood',
    ),
    city:
      getNameFromAddressComponent(addressComponent, 'locality') ||
      getNameFromAddressComponent(addressComponent, 'sublocality'), // the 5 boroughs of NYC are considered sublocalities.
    county: !isEmpty(county) ? county : undefined,
    stateOrProvince: parseStateComponent(
      convertStringToEnums(
        getNameFromAddressComponent(
          addressComponent,
          'administrative_area_level_1',
        ),
      ),
    ),
    country: convertStringToEnums(
      getNameFromAddressComponent(addressComponent, 'country'),
    ),
    zipOrPostalCode: getNameFromAddressComponent(
      addressComponent,
      'postal_code',
    ),
  };

  // Omit properties from the address object
  addressOmitProperties.forEach((property) => {
    delete address[property];
  });

  return address;
};

export const getParticipantRole = (
  representationType: PriceAndDateInfoRequestRepresentationTypeEnum,
) => {
  const representationTypeMapToRole: EnumMap<
    PriceAndDateInfoRequestRepresentationTypeEnum,
    AgentParticipantInfoRoleEnum
  > = {
    BUYER: AgentParticipantInfoRoleEnum.BuyersAgent,
    SELLER: AgentParticipantInfoRoleEnum.SellersAgent,
    LANDLORD: AgentParticipantInfoRoleEnum.SellersAgent,
    TENANT: AgentParticipantInfoRoleEnum.BuyersAgent,
    DUAL: AgentParticipantInfoRoleEnum.BuyersAgent,
  };

  return representationTypeMapToRole[representationType];
};

export const isParticipantRoleOwner = (
  representationType: AgentParticipantInfoRoleEnum,
) => {
  return [
    AgentParticipantInfoRoleEnum.BuyersAgent,
    AgentParticipantInfoRoleEnum.SellersAgent,
  ].includes(representationType);
};

export const getRepresentTypeOptions = (
  transactionType: TransactionResponseTransactionTypeEnum = TransactionResponseTransactionTypeEnum.Sale,
): ISelectOption[] => {
  if (isSaleTransaction(transactionType)) {
    return [
      {
        label: 'Seller',
        value: PriceAndDateInfoRequestRepresentationTypeEnum.Seller,
      },
      {
        label: 'Buyer',
        value: PriceAndDateInfoRequestRepresentationTypeEnum.Buyer,
      },
      {
        label: 'Dual (Seller & Buyer)',
        value: PriceAndDateInfoRequestRepresentationTypeEnum.Dual,
      },
    ];
  }

  return [
    {
      label: 'Tenant',
      value: PriceAndDateInfoRequestRepresentationTypeEnum.Tenant,
    },
    {
      label: 'Landlord',
      value: PriceAndDateInfoRequestRepresentationTypeEnum.Landlord,
    },
    {
      label: 'Dual (Landlord & Tenant)',
      value: PriceAndDateInfoRequestRepresentationTypeEnum.Dual,
    },
  ];
};

export const isShowHappyModel = (
  sentimentDetails: SentimentDisplayResponse | undefined,
  transactionDetail: TransactionResponse,
) => {
  return (
    sentimentDetails?.displaySentiment &&
    transactionDetail.errors?.length === 0 &&
    sentimentDetails.contextType ===
      SentimentDisplayResponseContextTypeEnum.Transaction
  );
};

export const showHappyBrokerFeedbackModal = (
  sentimentDetails: SentimentDisplayResponse | undefined,
  contextType: SentimentDisplayResponseContextTypeEnum,
): boolean => {
  return (
    !!sentimentDetails?.displaySentiment &&
    sentimentDetails.contextType === contextType
  );
};

export const getOfficesInPropertyState = (
  offices: OfficePreviewResponse[],
  propertyState:
    | AddressResponseStateOrProvinceEnum
    | LocationInfoRequestStateEnum
    | AddressStateEnum
    | TransactionBuilderAddressStateEnum
    | AdministrativeAreaResponseStateOrProvinceEnum,
): ISelectOption[] => {
  return offices
    .filter(
      (office) => office.administrativeArea?.stateOrProvince === propertyState,
    )
    .map((office) => ({
      label: office?.name!,
      value: office?.id!,
    }));
};

export const isLeaseOptionVisible = (
  offices: OfficePreviewResponse[],
  propertyState: TransactionBuilderAddressStateEnum,
) => {
  const officesInProperty = getOfficesInPropertyState(
    offices || [],
    propertyState,
  );
  const officeIDs = (officesInProperty || [])
    ?.filter((office) => !!office?.value)
    ?.map((office) => office?.value);

  const isLeaseAllowed = offices
    ?.filter((office) => officeIDs?.includes(office?.id!))
    ?.some((office) => !!office?.allowsLease);

  return isLeaseAllowed;
};

export const getTransactionCountry = (
  address: Address,
  location?: GooglePlaceLocationType,
) => {
  return (
    getAddressFromAddressComponent(location?.address_components! || [])
      ?.country || address.country
  );
};

export const getCommissionPayerDisplayName = (
  commissionPayerRoles: CommissionPayerDisplayValue[],
  commissionPayerInfo: CommissionPayerInfo | BuilderParticipant | undefined,
) => {
  if (
    // @ts-ignore
    commissionPayerInfo?.email === NA_100_PERCENT_PAID_FOR_WITH_DEPOSIT_EMAIL
  ) {
    return 'N/A - 100% paid for with deposit';
  }

  return (
    commissionPayerRoles
      ?.filter(
        (role) => role.displayName !== 'N/A - 100% paid for with deposit',
      )
      .find((role) => role.role === commissionPayerInfo?.role)?.displayName ||
    ''
  );
};

export const isIDontKnowChecked = (
  commissionPayerInfo: CommissionPayerInfo | BuilderParticipant | undefined,
) => {
  return [
    I_DONT_KNOW_YET_EMAIL,
    NA_100_PERCENT_PAID_FOR_WITH_DEPOSIT_EMAIL,
    // @ts-ignore
  ].includes(commissionPayerInfo?.email!);
};

export const getcreateTransactionDefaultFormValues = (
  agentById: Mapping<AgentInfo>,
  userDetail: AgentResponse | null,
  commissionPayerRoles: CommissionPayerDisplayValue[],
  transactionBuilder?: TransactionBuilderResponse,
  flags: Partial<Record<FeatureFlagTypeEnum, boolean>> = {},
): Omit<
  CreateTransactionFormState,
  | 'firstName'
  | 'lastName'
  | 'companyName'
  | 'email'
  | 'phoneNumber'
  | 'transactionOwnerName'
> => {
  const buyerLawyerInfo = transactionBuilder?.otherParticipants?.find(
    (p) => p.role === ExternalParticipantInfoRoleEnum.BuyersLawyer,
  );
  const sellerLawyerInfo = transactionBuilder?.otherParticipants?.find(
    (p) => p.role === ExternalParticipantInfoRoleEnum.SellersLawyer,
  );
  const otherBrokerageInfo = transactionBuilder?.otherParticipants?.find(
    (p) => p.role === ExternalParticipantInfoRoleEnum.OtherAgent,
  );

  const isDoubleEnderEnabled = flags[FeatureFlagTypeEnum.DOUBLE_ENDER];
  const doubleEnderAgentId =
    transactionBuilder?.doubleEnderInfo?.doubleEnderAgentId;
  const doubleEnderAgentInfo = agentById[doubleEnderAgentId!];
  const otherBrokerInfoRealAgent = doubleEnderAgentId
    ? getISelectOptionDefaultValue(
        doubleEnderAgentId,
        `${doubleEnderAgentInfo?.firstName} ${doubleEnderAgentInfo?.lastName} - ${doubleEnderAgentInfo?.emailAddress}`,
      )
    : undefined;

  const commissionPayerInfo = transactionBuilder?.commissionPayerInfo
    ?.participantId
    ? transactionBuilder?.allParticipants?.find(
        (p) => p.id === transactionBuilder.commissionPayerInfo?.participantId,
      )
    : transactionBuilder?.commissionPayerInfo;

  const isIDontKnowYetOption = isIDontKnowChecked(commissionPayerInfo);

  const isPopulateCommissionPayerInfo =
    // @ts-ignore
    commissionPayerInfo?.role === ExternalParticipantInfoRoleEnum.OtherAgent ||
    !transactionBuilder?.commissionPayerInfo?.participantId;

  const isManualAddressType =
    !!transactionBuilder?.address &&
    !transactionBuilder?.address?.city &&
    !transactionBuilder?.address?.state &&
    !transactionBuilder?.address?.zip;

  const oneLineAddress = !!Object.keys(transactionBuilder?.address! || {})
    .length
    ? `${formatAddress(
        transactionBuilder?.address?.street!,
        transactionBuilder?.address?.street2,
        transactionBuilder?.address?.city!,
        capitalizeEnum(transactionBuilder?.address?.state! || ''),
        transactionBuilder?.address?.zip!,
      )} ${capitalizeEnum(userDetail?.accountCountry!)}`
    : '';

  const role = getCommissionPayerDisplayName(
    commissionPayerRoles,
    commissionPayerInfo,
  );

  return {
    acceptanceDate: (transactionBuilder?.acceptanceDate! as unknown) as Date,
    yearBuilt: transactionBuilder?.yearBuilt!,
    closingDate: (transactionBuilder?.estimatedClosingDate! as unknown) as Date,
    firmDate: (transactionBuilder?.firmDate! as unknown) as Date,
    financingConditionsExpirationDate: (transactionBuilder?.financingConditionsExpirationDate! as unknown) as Date,
    propertyInspectionExpirationDate: (transactionBuilder?.propertyInspectionExpirationDate! as unknown) as Date,
    condoDocumentsExpirationDate: (transactionBuilder?.condoDocumentsExpirationDate! as unknown) as Date,
    otherConditionsExpirationDate: (transactionBuilder?.otherConditionsExpirationDate! as unknown) as Date,
    saleOfBuyersPropertyExpirationDate: (transactionBuilder?.saleOfBuyersPropertyExpirationDate! as unknown) as Date,
    conditionalDates: YesNoType.YES,
    mlsNumber: transactionBuilder?.mlsNumber || '',
    hasRebate: !!transactionBuilder?.additionalFeesInfo
      ?.additionalFeesParticipantInfos?.length
      ? YesNoType.YES
      : YesNoType.NO,
    officeId: transactionBuilder?.agentsInfo?.officeId || '',
    personalDeal: transactionBuilder?.personalDeal
      ? YesNoType.YES
      : YesNoType.NO,
    hasRealTitle: !isNil(transactionBuilder?.titleInfo?.useRealTitle)
      ? transactionBuilder?.titleInfo?.useRealTitle
        ? YesNoType.YES
        : YesNoType.NO
      : undefined,
    price: transactionBuilder?.salePrice?.amount
      ? (transactionBuilder?.salePrice as MoneyValue)
      : {
          currency:
            userDetail?.accountCountry ===
            AgentResponseAccountCountryEnum.Canada
              ? MoneyValueCurrencyEnum.Cad
              : MoneyValueCurrencyEnum.Usd,
        },
    escrowNumber: transactionBuilder?.escrowNumber || '',
    representedByRealEstateAgent: transactionBuilder?.representedByAgent
      ? YesNoType.YES
      : YesNoType.NO,
    transactionType: (transactionBuilder?.dealType as unknown) as TransactionResponseTransactionTypeEnum,
    buyerOrTenant: !!transactionBuilder?.buyers?.length
      ? transactionBuilder?.buyers.map((p) => ({
          companyName: p?.companyName || '',
          email: p?.email!,
          firstName: p?.firstName!,
          lastName: p?.lastName!,
          phoneNumber: p?.phoneNumber!,
          participantType: p?.companyName
            ? CreateTransactionParticipantType.COMPANY
            : CreateTransactionParticipantType.PERSON,
          address: p?.address!,
        }))
      : [
          {
            companyName: '',
            email: '',
            firstName: '',
            lastName: '',
            phoneNumber: '',
            participantType: CreateTransactionParticipantType.PERSON,
            address: '',
          },
        ],
    sellerOrLandlord: !!transactionBuilder?.sellers?.length
      ? transactionBuilder?.sellers.map((p) => ({
          companyName: p?.companyName || '',
          email: p?.email!,
          firstName: p?.firstName!,
          lastName: p?.lastName!,
          participantType: p?.companyName
            ? CreateTransactionParticipantType.COMPANY
            : CreateTransactionParticipantType.PERSON,
          phoneNumber: p?.phoneNumber!,
          address: p?.address!,
          usePropertyAddress: isEqual(p?.address!, oneLineAddress!)
            ? [YesNoType.YES]
            : [],
        }))
      : [
          {
            companyName: '',
            email: '',
            firstName: '',
            lastName: '',
            phoneNumber: '',
            participantType: CreateTransactionParticipantType.PERSON,
            address: '',
            usePropertyAddress: [],
          },
        ],
    realCoAgents: !!transactionBuilder?.agentsInfo?.coAgents?.length
      ? transactionBuilder?.agentsInfo?.coAgents.map((p) => ({
          ...p,
          avatar: agentById[p?.agentId]?.avatar,
          firstName: agentById[p?.agentId]?.firstName,
          lastName: agentById[p?.agentId]?.lastName,
        }))
      : [],
    commissionParticipant: !!transactionBuilder?.allCommissionRecipient?.length
      ? transactionBuilder?.allCommissionRecipient?.map((p) => {
          const splitInfo = transactionBuilder.commissionSplitsInfo?.find(
            (splitInfo) => splitInfo.participantId === p.id,
          );

          return {
            money: {
              amount: splitInfo?.commission.commissionAmount?.amount!,
              currency: splitInfo?.commission.commissionAmount
                ?.currency as MoneyValueCurrencyEnum,
            },
            percent: {
              value: splitInfo?.commission.commissionPercent!,
              string: `${splitInfo?.commission.commissionPercent! || 0}%`,
            },
            isDollar: !splitInfo?.commission.percentEnabled,
            id: p.id,
            //@ts-ignore
            agentId: p?.agentId,
            //@ts-ignore
            firstName: agentById[p?.agentId]?.firstName,
            //@ts-ignore
            lastName: agentById[p?.agentId]?.lastName,
            //@ts-ignore
            participantRole: p?.role,
            //@ts-ignore
            paidViaBusinessEntity: p?.companyName,
          };
        })
      : [],
    additionalFeesAndRebates: !!transactionBuilder?.additionalFeesInfo
      ?.additionalFeesParticipantInfos?.length
      ? transactionBuilder?.additionalFeesInfo?.additionalFeesParticipantInfos?.map(
          (p) => {
            const participant = transactionBuilder.allParticipants?.find(
              (participant) => participant.id === p.participantId,
            );

            return {
              name: getParticipantName(
                //@ts-ignore
                participant?.agentId
                  ? // @ts-ignore
                    agentById[participant?.agentId!]
                  : participant,
              ),
              amount: p?.amount,
              feeType: (p?.feeType! as unknown) as AttachedFeeValueFeeTypeEnum,
              recipientType: (p?.recipientType! as unknown) as AttachedFeeValueRecipientTypeEnum,
              description: p?.description,
              id: p?.participantId,
              addedBySystem: p.addedBySystem ?? false,
            };
          },
        )
      : [],
    commission: !!transactionBuilder?.saleCommission
      ? {
          money: transactionBuilder?.saleCommission
            ?.commissionAmount as MoneyValue,
          isDollar: !transactionBuilder?.saleCommission?.percentEnabled,
          percent: {
            string: transactionBuilder?.saleCommission?.commissionPercent?.toString(),
            value: transactionBuilder?.saleCommission?.commissionPercent!,
          },
        }
      : {},
    listingCommission: !!transactionBuilder?.listingCommission
      ? {
          money: transactionBuilder?.listingCommission
            ?.commissionAmount as MoneyValue,
          isDollar: !transactionBuilder?.listingCommission?.percentEnabled,
          percent: {
            string: transactionBuilder?.listingCommission?.commissionPercent?.toString(),
            value: transactionBuilder?.listingCommission?.commissionPercent!,
          },
        }
      : {},
    zeroCommissionDeal: !!transactionBuilder?.zeroCommissionDeal,
    transactionOwnerRepresent: (transactionBuilder?.agentsInfo
      ?.representationType as unknown) as PriceAndDateInfoRequestRepresentationTypeEnum,
    buyerLawyerInfo: {
      firstName: buyerLawyerInfo?.firstName,
      lastName: buyerLawyerInfo?.lastName,
      email: buyerLawyerInfo?.email,
      address: buyerLawyerInfo?.address,
      companyName: buyerLawyerInfo?.companyName,
      phoneNumber: buyerLawyerInfo?.phoneNumber,
    },
    sellerLawyerInfo: {
      firstName: sellerLawyerInfo?.firstName,
      lastName: sellerLawyerInfo?.lastName,
      email: sellerLawyerInfo?.email,
      address: sellerLawyerInfo?.address,
      companyName: sellerLawyerInfo?.companyName,
      phoneNumber: sellerLawyerInfo?.phoneNumber,
    },
    commissionPayerInfo: {
      companyName: isPopulateCommissionPayerInfo
        ? // @ts-ignore
          commissionPayerInfo?.companyName || ''
        : '',
      email: isPopulateCommissionPayerInfo
        ? // @ts-ignore
          commissionPayerInfo?.email || ''
        : '',
      firstName: isPopulateCommissionPayerInfo
        ? // @ts-ignore
          commissionPayerInfo?.firstName || ''
        : '',
      lastName: isPopulateCommissionPayerInfo
        ? // @ts-ignore
          commissionPayerInfo?.lastName || ''
        : '',
      phoneNumber: isPopulateCommissionPayerInfo
        ? // @ts-ignore
          commissionPayerInfo?.phoneNumber || ''
        : '',
      role: role,
      isInfoUnknown: isIDontKnowYetOption ? [I_DONT_KNOW_YET] : [],
      participant: transactionBuilder?.commissionPayerInfo?.participantId,
    },
    isOtherSideUnrepresented: YesNoType.NO,
    otherBrokerageInfo: {
      address: otherBrokerageInfo?.address || '',
      companyName: otherBrokerageInfo?.companyName || '',
      email: otherBrokerageInfo?.email || '',
      firstName: otherBrokerageInfo?.firstName || '',
      lastName: otherBrokerageInfo?.lastName || '',
      phoneNumber: otherBrokerageInfo?.phoneNumber || '',
      otherAgentType: isDoubleEnderEnabled
        ? doubleEnderAgentId
          ? OtherAgentTypeEnum.REAL_AGENT
          : OtherAgentTypeEnum.EXTERNAL_AGENT
        : undefined,
      realAgent: isDoubleEnderEnabled ? otherBrokerInfoRealAgent : undefined,
    },
    referralFee: !!transactionBuilder?.referralInfo?.allReferralParticipantInfo
      ?.length
      ? YesNoType.YES
      : YesNoType.NO,
    manualAddressYearBuilt: transactionBuilder?.yearBuilt || 0,
    isManualAddress: isManualAddressType,
    address: {
      street: transactionBuilder?.address?.street,
      street2: transactionBuilder?.address?.street2,
      city: transactionBuilder?.address?.city,
      state: (transactionBuilder?.address
        ?.state as unknown) as AddressStateEnum,
      country: (userDetail?.accountCountry as unknown) as AddressCountryEnum,
      oneLine: oneLineAddress,
      valid: true,
      zip: transactionBuilder?.address?.zip,
    },
    location: {
      address_components: [],
      formatted_address: !isManualAddressType ? oneLineAddress! : '',
      geometry: {
        location: {
          lat: undefined,
          lng: undefined,
        },
      },
      place_id: '',
    },
    realCoAgent: {},
    realParticipant: {},
    externalParticipant: {},
    additionalFeesAndRebate: {},
    manualAddressUnitNo: '',
    unitNo: transactionBuilder?.address?.unit!,
  };
};

export const getcreateListingDefaultFormValues = (
  agentById: Mapping<AgentInfo>,
  userDetail: AgentResponse | null,
  transactionBuilder?: TransactionBuilderResponse,
): Partial<CreateListingFormState> => {
  const isManualAddressType =
    !!transactionBuilder?.address &&
    !transactionBuilder?.address?.city &&
    !transactionBuilder?.address?.state &&
    !transactionBuilder?.address?.zip;

  const oneLineAddress = !!Object.keys(transactionBuilder?.address! || {})
    .length
    ? `${formatAddress(
        transactionBuilder?.address?.street!,
        transactionBuilder?.address?.street2,
        transactionBuilder?.address?.city!,
        capitalizeEnum(transactionBuilder?.address?.state! || ''),
        transactionBuilder?.address?.zip!,
      )} ${capitalizeEnum(userDetail?.accountCountry!)}`
    : '';

  return {
    listingDate: (transactionBuilder?.listingDate! as unknown) as Date,
    yearBuilt: transactionBuilder?.yearBuilt!,
    listingExpirationDate: (transactionBuilder?.listingExpirationDate! as unknown) as Date,
    firmDate: (transactionBuilder?.firmDate! as unknown) as Date,
    mlsNumber: transactionBuilder?.mlsNumber || '',
    officeId: transactionBuilder?.agentsInfo?.officeId || '',
    price: transactionBuilder?.salePrice?.amount
      ? (transactionBuilder?.salePrice as MoneyValue)
      : {
          currency:
            userDetail?.accountCountry ===
            AgentResponseAccountCountryEnum.Canada
              ? MoneyValueCurrencyEnum.Cad
              : MoneyValueCurrencyEnum.Usd,
        },
    propertyType: transactionBuilder?.propertyType
      ? {
          label: capitalizeEnum(transactionBuilder?.propertyType),
          value: transactionBuilder?.propertyType,
        }
      : undefined,
    transactionType: (transactionBuilder?.dealType as unknown) as TransactionResponseTransactionTypeEnum,
    buyerOrTenant: !!transactionBuilder?.buyers?.length
      ? transactionBuilder?.buyers.map((p) => ({
          companyName: p?.companyName || '',
          email: p?.email!,
          firstName: p?.firstName!,
          lastName: p?.lastName!,
          phoneNumber: p?.phoneNumber!,
          participantType: p?.companyName
            ? CreateTransactionParticipantType.COMPANY
            : CreateTransactionParticipantType.PERSON,
          address: p?.address!,
        }))
      : [
          {
            companyName: '',
            email: '',
            firstName: '',
            lastName: '',
            phoneNumber: '',
            participantType: CreateTransactionParticipantType.PERSON,
            address: '',
          },
        ],
    sellerOrLandlord: !!transactionBuilder?.sellers?.length
      ? transactionBuilder?.sellers.map((p) => ({
          companyName: p?.companyName || '',
          email: p?.email!,
          firstName: p?.firstName!,
          lastName: p?.lastName!,
          participantType: p?.companyName
            ? CreateTransactionParticipantType.COMPANY
            : CreateTransactionParticipantType.PERSON,
          phoneNumber: p?.phoneNumber!,
          address: p?.address!,
        }))
      : [
          {
            companyName: '',
            email: '',
            firstName: '',
            lastName: '',
            phoneNumber: '',
            participantType: CreateTransactionParticipantType.PERSON,
            address: '',
          },
        ],
    realCoAgents: !!transactionBuilder?.agentsInfo?.coAgents?.length
      ? transactionBuilder?.agentsInfo?.coAgents.map((p) => ({
          ...p,
          avatar: agentById[p?.agentId]?.avatar,
          firstName: agentById[p?.agentId]?.firstName,
          lastName: agentById[p?.agentId]?.lastName,
        }))
      : [],
    commission: !!transactionBuilder?.saleCommission
      ? {
          money: transactionBuilder?.saleCommission
            ?.commissionAmount as MoneyValue,
          isDollar: !transactionBuilder?.saleCommission?.percentEnabled,
          percent: {
            string: transactionBuilder?.saleCommission?.commissionPercent?.toString(),
            value: transactionBuilder?.saleCommission?.commissionPercent!,
          },
        }
      : {},
    listingCommission: !!transactionBuilder?.listingCommission
      ? {
          money: transactionBuilder?.listingCommission
            ?.commissionAmount as MoneyValue,
          isDollar: !transactionBuilder?.listingCommission?.percentEnabled,
          percent: {
            string: transactionBuilder?.listingCommission?.commissionPercent?.toString(),
            value: transactionBuilder?.listingCommission?.commissionPercent!,
          },
        }
      : {},
    manualAddressYearBuilt: transactionBuilder?.yearBuilt || 0,
    isManualAddress: isManualAddressType,
    address: {
      street: transactionBuilder?.address?.street,
      street2: transactionBuilder?.address?.street2,
      city: transactionBuilder?.address?.city,
      state: (transactionBuilder?.address
        ?.state as unknown) as AddressStateEnum,
      country: (userDetail?.accountCountry as unknown) as AddressCountryEnum,
      oneLine: oneLineAddress,
      valid: true,
      zip: transactionBuilder?.address?.zip,
    },
    location: {
      address_components: [],
      formatted_address: !isManualAddressType ? oneLineAddress! : '',
      geometry: {
        location: {
          lat: undefined,
          lng: undefined,
        },
      },
      place_id: '',
    },
    unitNo: transactionBuilder?.address?.unit!,
    transactionOwnerName: !!transactionBuilder?.agentsInfo?.ownerAgent?.length
      ? {
          label: `${agentById[
            transactionBuilder.agentsInfo.ownerAgent[0].agentId
          ]?.firstName!} ${agentById[
            transactionBuilder.agentsInfo.ownerAgent[0].agentId
          ]?.lastName!} - ${agentById[
            transactionBuilder.agentsInfo.ownerAgent[0].agentId
          ]?.emailAddress!}`,
          value: transactionBuilder.agentsInfo.ownerAgent[0].agentId,
        }
      : {
          label: `${userDetail?.firstName} ${userDetail?.lastName} - ${userDetail?.emailAddress}`,
          value: userDetail?.id!,
        },
  };
};

export const getTransactionPriceLabel = (
  transactionType:
    | TransactionResponseTransactionTypeEnum
    | TransactionBuilderResponseDealTypeEnum,
): string => {
  const labelMap: EnumMap<TransactionResponseTransactionTypeEnum, string> = {
    SALE: 'Sale Price',
    LEASE: 'Lease Price',
    REFERRAL: 'Referral Amount',
    COMMERCIAL_LEASE: 'Lease Price',
    COMPENSATING: 'Sale Price',
    OTHER: 'Lease Price',
    PLOT: 'Sale Price',
    INTERNAL_REFERRAL: 'Internal Referral Amount',
  };
  const defaultValue = labelMap[TransactionResponseTransactionTypeEnum.Sale];

  return safeEnumMapGet<
    | TransactionResponseTransactionTypeEnum
    | TransactionBuilderResponseDealTypeEnum,
    string
  >(labelMap, transactionType, defaultValue);
};

export const getFormattedAmountOrPercent = (
  percent: any,
  amount: any,
  price: any,
  isDollar: boolean,
) => {
  if (!isDollar) return numberWithCommas(((percent * price) / 100).toFixed(2));
  return numberWithCommas(Math.abs(amount).toFixed(2));
};

export const getTransitionModalSubTitle = (
  transaction: TransactionResponse,
  activeTransition: TransactionLifecycleStateValue | undefined,
) => {
  if (
    transaction.listing &&
    activeTransition?.state ===
      TransactionLifecycleStateValueStateEnum.Terminated
  ) {
    return 'Withdraw the listing?';
  } else {
    return activeTransition?.descriptionToTransition;
  }
};

export const getDealInfoWithUpdatedEstimatedClosingDate = (
  transaction: TransactionResponse,
  estimatedClosingDate: string,
): UpdateDealRequest => {
  return {
    acceptanceDate: transaction.contractAcceptanceDate,
    actualClosingDate: transaction.skySlopeActualClosingDate,
    salePrice: getFixedDecimalMoneyValue(transaction.price!),
    listingCommission: {
      commissionAmount:
        getFixedDecimalMoneyValue(transaction.listingCommissionAmount!) ||
        undefined,
      commissionPercent: transaction.listingCommissionPercent,
      percentEnabled: !!transaction.listingCommissionPercent,
    },
    saleCommission: {
      commissionAmount:
        getFixedDecimalMoneyValue(transaction.saleCommissionAmount!) ||
        undefined,
      commissionPercent: transaction.saleCommissionPercent,
      percentEnabled: !!transaction.saleCommissionPercent,
    },
    estimatedClosingDate,
  };
};

export const isCommissionDocumentGenerated = (
  transaction: TransactionResponse,
) => {
  const map: EnumMap<TransactionLifecycleStateValueStateEnum, boolean> = {
    APPROVED_FOR_CLOSING: true,
    COMMISSION_DOCUMENT_APPROVED: true,
    CALCULATE_LEDGER: false,
    COMMISSION_DOCUMENT_GENERATED: true,
    COMMISSION_DOCUMENT_SENT: true,
    CLOSED: true,
    COMMISSION_VALIDATED: false,
    NEEDS_COMMISSION_VALIDATION: false,
    NEW: false,
    PAYMENT_ACCEPTED: true,
    PAYMENT_SCHEDULED: true,
    READY_FOR_COMMISSION_DOCUMENT_GENERATION: false,
    SETTLED: true,
    WAITING_ON_PAYMENT: true,
    TERMINATED: false,
    LISTING_ACTIVE: false,
    LISTING_CLOSED: false,
    LISTING_IN_CONTRACT: false,
    TERMINATION_REQUESTED: false,
  };

  return safeEnumMapGet(map, transaction.lifecycleState!.state!, false);
};

const JOURNEY_STATUS_TO_DISPLAY_NAME_MAP: PartialEnumMap<
  ItemResponseStatusEnum,
  string
> = {
  ACCEPTED: 'Marked as completed',
};

export const journeyStatusToDisplayName = (
  status: ItemResponseStatusEnum,
): string => {
  return (
    safePartialEnumMapGet(
      JOURNEY_STATUS_TO_DISPLAY_NAME_MAP,
      status,
      'Marked as completed',
    ) ?? capitalizeEnum(status)
  );
};

export const getAttachFeeParticipantOption = (
  transaction: TransactionResponse,
  participantId?: string,
) => {
  if (participantId) {
    const paymentParticipant = (transaction?.paymentParticipants || [])?.find(
      (participant) => participant?.id === participantId,
    )!;

    if (paymentParticipant === undefined) {
      return undefined;
    }

    return {
      value: `${paymentParticipant?.id}`,
      label: `${getParticipantName(
        paymentParticipant,
      )} - (${participantRoleDisplayName(
        paymentParticipant?.role ||
          ParticipantResponseParticipantRoleEnum.Unknown,
      )})`,
    };
  }

  return undefined;
};

export const isGenerateTradeRecordSheetDisabled = (
  transaction: TransactionResponse,
): boolean => {
  const map: EnumMap<TransactionLifecycleStateValueStateEnum, boolean> = {
    APPROVED_FOR_CLOSING: false,
    COMMISSION_DOCUMENT_APPROVED: false,
    CALCULATE_LEDGER: false,
    COMMISSION_DOCUMENT_GENERATED: false,
    COMMISSION_DOCUMENT_SENT: false,
    COMMISSION_VALIDATED: false,
    READY_FOR_COMMISSION_DOCUMENT_GENERATION: false,
    PAYMENT_ACCEPTED: false,
    PAYMENT_SCHEDULED: false,
    SETTLED: false,
    WAITING_ON_PAYMENT: false,
    TERMINATED: true,
    CLOSED: false,
    NEEDS_COMMISSION_VALIDATION: true,
    NEW: true,
    LISTING_ACTIVE: true,
    LISTING_CLOSED: true,
    LISTING_IN_CONTRACT: true,
    TERMINATION_REQUESTED: true,
  };

  return safeEnumMapGet(map, transaction.lifecycleState!.state!, true);
};

export const isNotOPCityParticipantType = (
  type: ParticipantType,
  agentType?: ParticipantType,
) => {
  if (type && agentType) {
    return type !== OPCITY_KEY;
  }

  return type !== OPCITY_KEY && agentType !== OPCITY_KEY;
};

export const getTransactionOtherParticipants = (
  transaction: TransactionResponse,
): ParticipantValue[] => {
  if (isCanadaTransaction(transaction)) {
    return (transaction.otherParticipants || [])?.filter(
      (p) =>
        p.role !== ParticipantValueRoleEnum.RealAdmin &&
        p.role !== ParticipantValueRoleEnum.Real &&
        isParticipantValid(p),
    );
  }

  return (transaction.otherParticipants || []).filter(
    (item: ParticipantValue) =>
      item.role !== ParticipantValueRoleEnum.Real && isParticipantValid(item),
  );
};

export const dateInPast = (firstDate: string, secondDate: string) =>
  firstDate < secondDate;

export const isLedgerItemDebt = (item?: LedgerItemValueLedgerItemTypeEnum) => {
  const ledgerItemToBooleanMap: EnumMap<
    LedgerItemValueLedgerItemTypeEnum,
    boolean
  > = {
    BEOP_FEE: false,
    BROKERAGE_FEE: false,
    B_AND_O_TAX: false,
    CBJ_TAX: false,
    COMMISSION: false,
    COMMISSION_ADVANCE_REPAYMENT: true,
    GARNISHMENT_REPAYMENT: true,
    GET_TAX: false,
    GRT_TAX: false,
    GST_TAX: false,
    HST_TAX: false,
    INSTANT_PAYMENT_REPAYMENT: true,
    LA_CBT_TAX: false,
    MLS_FEE: false,
    ONE_REAL_IMPACT_FUND_CONTRIBUTION: false,
    LEADER_SPLIT: false,
    TEAM_POST_CAP_FEE: false,
    MLS_FEE_REPAYMENT: true,
    OTHER: false,
    PERSONAL_DEAL_FEE: false,
    POST_CAP_EQUITY_CONTRIBUTION: false,
    PRE_CAP_EQUITY_CONTRIBUTION: false,
    PST_TAX: false,
    QST_TAX: false,
    REAL_SPLIT: false,
    REBATE: false,
    REIMBURSEMENT: false,
    STOCK_AWARD: false,
    TRANSACTION_COORDINATOR_FEE: false,
    TRANSACTION_FEE: false,
    TRANSACTION_REPAYMENT: true,
    BOUNCED_CHECK_REPAYMENT: true,
    CREDIT_REPAYMENT: true,
    TEAM_PRE_CAP_FEE: false,
    OPCITY_REPAYMENT: true,
    TAXES_REPAYMENT: true,
    FINES_REPAYMENT: false,
    LUXURY_REPAYMENT: false,
    LATE_FEE_REPAYMENT: false,
  };

  if (!item) {
    return false;
  }

  return safeEnumMapGet(ledgerItemToBooleanMap, item, false);
};

export const getTransactionSystemActualClosingDate = (
  transaction: TransactionResponse | TransactionLiteResponse,
  format: string = 'MM/dd/yyyy',
) => {
  return !!transaction?.rezenClosedAt
    ? DateTime.fromMillis(transaction.rezenClosedAt).toUTC().toFormat(format)
    : !!transaction?.skySlopeActualClosingDate
    ? DateTime.fromISO(transaction.skySlopeActualClosingDate)
        .toFormat(format)
        .toLocaleString()
    : 'no date yet';
};

export const getTransactionActualClosingDate = (
  transaction: TransactionResponse | TransactionLiteResponse,
) => {
  return transaction?.closedAt
    ? DateTime.fromMillis(transaction.closedAt).toUTC().toFormat('MM/dd/yyyy')
    : transaction?.skySlopeActualClosingDate
    ? DateTime.fromISO(transaction.skySlopeActualClosingDate).toLocaleString()
    : 'no date yet';
};

export const isParticipantValid = (
  participant: ParticipantValue | PaymentParticipantValue,
) => {
  return !participant?.hidden;
};

// Function to get the power audit sorted contacts
export const getSortedContacts = (participants: ParticipantResponse[]) => {
  const col1sortOrder: ParticipantResponseParticipantRoleEnum[] = [
    ParticipantResponseParticipantRoleEnum.TeamLeader,
    ParticipantResponseParticipantRoleEnum.TeamMember,
    ParticipantResponseParticipantRoleEnum.SellersAgent,
    ParticipantResponseParticipantRoleEnum.BuyersAgent,
    ParticipantResponseParticipantRoleEnum.ReferringAgent,
  ];

  const col2sortOrder: ParticipantResponseParticipantRoleEnum[] = [
    ParticipantResponseParticipantRoleEnum.Seller,
    ParticipantResponseParticipantRoleEnum.Landlord,
    ParticipantResponseParticipantRoleEnum.Buyer,
    ParticipantResponseParticipantRoleEnum.Tenant,
  ];

  const col1Contacts: { [key: string]: ParticipantResponse[] } = {};
  const col2Contacts: { [key: string]: ParticipantResponse[] } = {};
  let otherAgent: ParticipantResponse | null = null;
  let commissionPayer: ParticipantResponse | null = null;

  participants?.forEach((participant) => {
    const role = participant?.participantRole;
    if (role) {
      if (participant?.commissionDocumentPayer && !commissionPayer) {
        commissionPayer = participant;
      } else if (
        role === ParticipantResponseParticipantRoleEnum.OtherAgent &&
        !otherAgent
      ) {
        otherAgent = participant;
      } else if (col1sortOrder.includes(role)) {
        if ((col1Contacts[role] || [])?.length < 1) {
          col1Contacts[role] = (col1Contacts[role] || []).concat(participant);
        }
      } else if (col2sortOrder.includes(role)) {
        if ((col2Contacts[role] || [])?.length < 2) {
          col2Contacts[role] = (col2Contacts[role] || []).concat(participant);
        }
      }
    }
  });

  // Sort col1Contacts
  let sortedCol1Contacts = values(col1Contacts)
    .flat()
    .sort(
      (a, b) =>
        col1sortOrder.indexOf(a?.participantRole!) -
        col1sortOrder.indexOf(b?.participantRole!),
    );

  // Sort col2Contacts
  let sortedCol2Contacts = values(col2Contacts)
    .flat()
    .sort(
      (a, b) =>
        col2sortOrder.indexOf(a?.participantRole!) -
        col2sortOrder.indexOf(b?.participantRole!),
    );

  // Add commission payer participant to col2 at 4th index
  if (commissionPayer) {
    if (sortedCol2Contacts.length > 4) {
      sortedCol2Contacts.splice(4, 0, commissionPayer);
    } else {
      sortedCol2Contacts.push(commissionPayer);
    }
  }

  // Add other agent participant to col1 at 4th index
  if (otherAgent) {
    if (sortedCol1Contacts.length > 4) {
      sortedCol1Contacts.splice(4, 0, otherAgent);
    } else {
      sortedCol1Contacts.push(otherAgent);
    }
  }

  // Reduce col participants to 5
  if (sortedCol1Contacts.length > 5) {
    sortedCol1Contacts = sortedCol1Contacts.slice(0, 5);
  }
  if (sortedCol2Contacts.length > 5) {
    sortedCol2Contacts = sortedCol2Contacts.slice(0, 5);
  }

  return [sortedCol1Contacts, sortedCol2Contacts];
};

export const getActivityUserFullName = (
  agentInfo?: AgentCommentDetails,
  createdByExternal?: string,
  showDefaultName: boolean = true,
) => {
  let fullName = showDefaultName ? 'Agent Name' : 'N/A';
  if (createdByExternal) {
    const nameArr = createdByExternal.split(' ');
    if (nameArr?.length >= 2) {
      fullName = nameArr[0] + ' ' + nameArr[1];
    } else {
      fullName = createdByExternal;
    }
  }
  if (agentInfo) {
    fullName = agentInfo?.firstName + ' ' + agentInfo?.lastName;
  }

  return fullName;
};

export const isPdfOrImg = (fileName: string): boolean => {
  const allowedExtensions: string[] = [
    '.png',
    '.jpg',
    '.jpeg',
    '.img',
    '.pdf',
    '.heic',
  ];
  const lowerCaseFileName: string = fileName.toLowerCase();

  return allowedExtensions.some((extension) =>
    lowerCaseFileName.endsWith(extension),
  );
};

export const isImg = (fileName: string): boolean => {
  const allowedExtensions: string[] = [
    '.png',
    '.jpg',
    '.jpeg',
    '.img',
    '.heic',
  ];
  const lowerCaseFileName: string = fileName.toLowerCase();

  return allowedExtensions.some((extension) =>
    lowerCaseFileName.endsWith(extension),
  );
};

export const isHeic = (fileName: string): boolean => {
  const allowedExtensions: string[] = ['.heic'];
  const lowerCaseFileName: string = fileName.toLowerCase();

  return allowedExtensions.some((extension) =>
    lowerCaseFileName.endsWith(extension),
  );
};
export const isTransactionOrFinanceAdmin = (
  activeRoles: IdentityRoleResponse[],
) => {
  const allowedRoles = [
    AdminRoles.FINANCE1,
    AdminRoles.FINANCE2,
    AdminRoles.TRANSACTIONS,
  ];

  return activeRoles.some(
    (item) => item.role && allowedRoles.includes(item.role as AdminRoles),
  );
};

export const isTransactionInClosedState = (
  transaction: TransactionResponse,
) => {
  const map: EnumMap<TransactionLifecycleStateValueStateEnum, boolean> = {
    APPROVED_FOR_CLOSING: false,
    COMMISSION_DOCUMENT_APPROVED: false,
    CALCULATE_LEDGER: false,
    COMMISSION_DOCUMENT_GENERATED: false,
    COMMISSION_DOCUMENT_SENT: false,
    COMMISSION_VALIDATED: false,
    NEEDS_COMMISSION_VALIDATION: false,
    NEW: false,
    READY_FOR_COMMISSION_DOCUMENT_GENERATION: false,
    CLOSED: true,
    PAYMENT_ACCEPTED: true,
    PAYMENT_SCHEDULED: true,
    SETTLED: true,
    WAITING_ON_PAYMENT: true,
    TERMINATED: true,
    LISTING_ACTIVE: false,
    LISTING_CLOSED: false,
    LISTING_IN_CONTRACT: false,
    TERMINATION_REQUESTED: false,
  };

  return safeEnumMapGet(map, transaction?.lifecycleState?.state!, false);
};

export const getEnumOptions = (
  enumData: Record<string, string>,
): ISelectOption[] => {
  return values(enumData).map((value) => ({
    label: capitalizeEnum(value),
    value,
  }));
};

export const isCompliantForCommissionDocGeneration = (
  checklistDetails: ChecklistResponse,
  isBroker: boolean = false,
) => {
  if (checklistDetails) {
    if (!isBroker) {
      return !checklistDetails?.items?.filter(
        (checklist) =>
          checklist.required &&
          checklist.status !== ItemResponseStatusEnum.Accepted &&
          checklist.required,
      ).length;
    } else {
      return !checklistDetails?.items?.filter(
        (checklist) =>
          checklist.required &&
          isBroker &&
          checklist.requiredFor !== ItemResponseRequiredForEnum.Transactions &&
          checklist.status !== ItemResponseStatusEnum.Accepted &&
          checklist.required,
      ).length;
    }
  }
  return false;
};
