import { faMoneyBillTransfer } from '@fortawesome/pro-light-svg-icons';
import {
  faChevronDown,
  faChevronUp,
  faCircleCheck,
  faEye,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import ZenButton from '../../Zen/ZenButton';

interface WalletMyCreditDetailsCardProps {
  accountId: string;
  showViewDetails?: boolean;
}

const WalletMyCreditDetailsCard: React.FC<WalletMyCreditDetailsCardProps> = ({
  accountId,
  showViewDetails = false,
}) => {
  const [showSummary, setShowSummary] = useState<boolean>();

  return (
    <div className='mb-7 flex flex-col gap-6 p-6 rounded-xl shadow-pop-over bg-white'>
      {/* Header */}
      <div className='flex justify-between items-center'>
        <p className='font-inter font-medium text-xl'>My Credit</p>
        {showViewDetails && (
          <Link to={`/wallet/new/${accountId}/credit`}>
            <div className='flex items-center gap-1 py-2.5'>
              <FontAwesomeIcon
                icon={faEye}
                className='text-rezen-blue-600 text-sm'
              />
              <p className='font-inter font-medium text-base text-rezen-blue-600'>
                View Details
              </p>
            </div>
          </Link>
        )}
      </div>

      {/* Available Credit Section */}
      <div className='flex flex-col gap-4'>
        <div className='flex flex-col gap-1'>
          <p className='font-inter font-normal text-base text-grey-600'>
            Available
          </p>
          <div className='flex flex-col xl:flex-row items-center gap-2'>
            <p className='font-poppins font-semibold text-3xl'>$50,815.00</p>
            <p className='text-regent-900 font-inter font-normal text-lg'>
              out of $100,815.00
            </p>
          </div>
        </div>

        <div className='flex flex-col gap-2 w-full'>
          <div className='w-full bg-gray-200 rounded-full h-2.5'>
            <div
              className='bg-primary-dark h-2.5 rounded-full'
              style={{ width: '50%' }}
            />
          </div>

          <div className='flex justify-between'>
            <p className='font-inter font-normal text-sm text-grey-600'>
              50% available to spend
            </p>
            <p className='font-inter font-normal text-sm text-regent-600'>
              updated today
            </p>
          </div>
        </div>
      </div>

      {/* Credit Utilization Tips */}
      <div className='flex flex-col p-4 border rounded-xl border-grey-300 w-full'>
        <p className='font-inter font-medium text-base mb-4'>
          Utilize your credit more efficiently
        </p>

        <div className='flex items-center gap-2 mb-3'>
          <FontAwesomeIcon icon={faCircleCheck} />
          <p className='font-inter font-normal text-sm'>
            Manage cash flow seamlessly.
          </p>
        </div>

        <div className='flex items-center gap-2 mb-6'>
          <FontAwesomeIcon icon={faCircleCheck} />
          <p className='font-inter font-normal text-sm'>Close deals faster.</p>
        </div>

        <div className='flex justify-center'>
          <ZenButton
            label='Request Funds'
            className='w-full bg-primary-dark text-white font-inter font-medium text-base'
            variant='primary-borderless'
            LeftIconComponent={
              <FontAwesomeIcon
                icon={faMoneyBillTransfer}
                className='text-white'
              />
            }
          />
        </div>
      </div>

      {/* Divider */}
      <div className='border bg-grey-300 self-stretch' />

      {/* Credit Bill Section */}
      <div className='flex flex-col'>
        <div className='flex items-center mb-5'>
          <p className='font-inter font-medium text-lg'>Credit Bill</p>
          <div className='ml-4 flex px-3 py-1 bg-green-100 rounded-3xl'>
            <p className='font-inter font-medium text-xs text-midnight-green-900'>
              Paid on 23 Aug 2024
            </p>
          </div>
        </div>

        <div className='flex justify-between flex-wrap gap-6'>
          <div className='flex flex-col gap-2'>
            <p className='font-inter font-normal text-base text-grey-600'>
              Amount due
            </p>
            <p className='font-inter font-medium text-lg'>$0.00</p>
          </div>

          <div className='flex flex-col gap-2'>
            <p className='font-inter font-normal text-base text-grey-600'>
              Due Date
            </p>
            <p className='font-inter font-medium text-lg'>30 Aug 2024</p>
          </div>

          <div className='flex flex-col gap-2'>
            <p className='font-inter font-normal text-base text-grey-600'>
              Statement Date
            </p>
            <p className='font-inter font-medium text-lg'>02 Sept 2024</p>
          </div>
        </div>
      </div>

      {/* Summary Section */}
      <div className='flex flex-col gap-4 p-4 bg-regent-200 rounded-xl'>
        <div className='flex justify-between items-center'>
          <p className='font-inter font-medium text-base'>Summary</p>
          <div
            className='flex items-center gap-2 cursor-pointer'
            onClick={() => setShowSummary(!showSummary)}
          >
            <p className='font-inter font-medium text-base text-rezen-blue-600'>
              {showSummary ? 'Hide' : 'Show'}
            </p>
            <FontAwesomeIcon
              icon={showSummary ? faChevronUp : faChevronDown}
              className='text-rezen-blue-600'
            />
          </div>
        </div>

        {showSummary && (
          <div className='flex flex-col gap-3'>
            <div className='flex justify-between pb-2 border-b border-regent-400'>
              <p className='font-inter text-sm text-grey-600'>Statement Date</p>
              <p className='font-inter text-sm text-end text-primary-dark'>
                November 1, 2024
              </p>
            </div>

            <div className='flex justify-between pb-2 border-b border-regent-400'>
              <p className='font-inter text-sm text-grey-600'>Due Date</p>
              <p className='font-inter text-sm text-end'>November 21, 2024</p>
            </div>

            <div className='flex justify-between pb-2 border-b border-regent-400'>
              <p className='font-inter text-sm text-grey-600'>
                Minimum Balance Due
              </p>
              <p className='font-inter text-sm text-end'>$30.00</p>
            </div>

            <div className='flex justify-between pb-2 border-b border-regent-400'>
              <p className='font-inter text-sm text-grey-600'>
                Interest Charges
              </p>
              <p className='font-inter text-sm text-end'>$139.42</p>
            </div>

            <div className='flex justify-between pb-2 border-b border-regent-400'>
              <p className='font-inter text-sm text-grey-600'>Late Fees</p>
              <p className='font-inter text-sm text-end'>$30.00</p>
            </div>

            <div className='flex justify-between pb-2'>
              <p className='font-inter text-sm text-primary-navy'>
                Total Balance Due
              </p>
              <p className='font-inter text-base font-semibold text-end'>
                $9,426.82
              </p>
            </div>
          </div>
        )}
      </div>

      {/* Pay Bill */}
      <div className='flex justify-center'>
        <ZenButton
          label='Pay Bill'
          variant='primary-outline'
          className='w-full font-inter font-medium text-base'
          LeftIconComponent={
            <FontAwesomeIcon
              icon={faMoneyBillTransfer}
              className='text-blue-600'
            />
          }
        />
      </div>
    </div>
  );
};

export default WalletMyCreditDetailsCard;
