import parsePhoneNumber from 'libphonenumber-js';
import { isEmpty } from 'lodash';
import { DateTime } from 'luxon';
import { ControllerProps } from 'react-hook-form-v7';
import { GooglePlaceLocationType } from '../components/Zen/Input/ZenControlledGoogleAutocompleteSearchInput';
import { MAX_DOC_SIZE, MAX_DOC_SIZE_100MB } from '../constants/FilesConstants';
import {
  AddressCountryEnum,
  AddressRequestCountryEnum,
  AgentResponseAccountCountryEnum,
  MoneyValue,
} from '../openapi/yenta';
import { getAge } from './commonUtils';
import {
  CA_BANK_INSTITUTION_NUMBER,
  CA_BANK_TRANSIT_NUMBER,
  DATE_REGEX,
  EMAIL_REGEX,
  getPostalCodeForCountry,
  getRoutingNumberForCountry,
  MONEY_AMOUNT_REGEX,
  MONEY_AMOUNT_REGEX_ALLOW_NEGATIVES,
  NUMBER_REGEX,
  PERCENT_REGEX,
  SYMBOLS_REGEX,
  WEBSITE_REGEX,
  YEAR_REGEX,
} from './StringUtils';
import { getAddressFromAddressComponent } from './TransactionHelper';

type Validation = ControllerProps['rules'];

export const PASSWORD_VALIDATIONS: Validation = {
  minLength: {
    value: 8,
    message: 'Password must have a minimum of 8 characters',
  },
  maxLength: {
    value: 24,
    message: 'Password must have a maximum of 24 characters',
  },
  required: 'Password is required',
  validate: (value: string) => {
    if (/[\s]/.test(value)) {
      return 'Password cannot contain any spaces';
    }

    const errors = [];
    if (!/[a-z]/.test(value)) {
      errors.push('1 lower-case character');
    }

    if (!/[A-Z]/.test(value)) {
      errors.push('1 upper-case character');
    }

    if (!/[0-9]/.test(value)) {
      errors.push('1 digit');
    }

    if (!SYMBOLS_REGEX.test(value)) {
      errors.push('1 symbol');
    }

    if (errors.length === 0) {
      return undefined;
    }

    return `Password must contain at least: ${errors.join(', ')}`;
  },
};

export const PHONE_NUMBER_VALIDATIONS: Validation = {
  validate: (value: string) => {
    if (isEmpty(value)) {
      return undefined;
    }
    const phoneNumber = parsePhoneNumber('+' + value);
    if (value.startsWith('0') || !phoneNumber?.isPossible()) {
      return 'Please enter a valid phone number';
    }
    return undefined;
  },
};

export const EMAIL_VALIDATIONS: Validation = {
  pattern: {
    value: EMAIL_REGEX,
    message: 'Invalid email address',
  },
};

export const FILE_VALIDATIONS: Validation = {
  validate: {
    fileMaxSize: (files) =>
      files && files[0]?.size > MAX_DOC_SIZE
        ? 'File size exceeds maximum limit of 30 MB'
        : undefined,
  },
};

export const MAX_FILE_VALIDATIONS: Validation = {
  validate: {
    fileMaxSize: (files) =>
      files && files[0]?.size > MAX_DOC_SIZE_100MB
        ? 'File size exceeds maximum limit of 100 MB'
        : undefined,
  },
};

export const MONEY_VALIDATIONS: Validation = {
  pattern: {
    value: MONEY_AMOUNT_REGEX,
    message: 'Please enter valid amount',
  },
};

export const MONEY_VALUE_VALIDATIONS: Validation = {
  validate(v: MoneyValue) {
    if (!v?.amount) {
      return 'Amount is required';
    }
    if (!v?.currency) {
      return 'Please select currency';
    }
    if (!MONEY_AMOUNT_REGEX.test(v.amount?.toString()!)) {
      return 'Please enter valid amount';
    }
    return undefined;
  },
};

export const getRangeMoneyValueValidationRules = ({
  min,
  max,
}: {
  min?: number;
  max?: number;
}): Validation => ({
  validate(v: MoneyValue) {
    if (!v?.amount) {
      return 'Amount is required';
    }
    if (min && v?.amount < min) {
      return `Amount cannot be lesser than $${min}`;
    }
    if (max && v?.amount > max) {
      return `Amount cannot be greater than $${max}`;
    }
    if (!v?.currency) {
      return 'Please select currency';
    }
    if (!MONEY_AMOUNT_REGEX.test(v.amount?.toString()!)) {
      return 'Please enter valid amount';
    }
    return undefined;
  },
});

export const getRangeAmountValidationRules = ({
  min,
  max,
}: {
  min?: number;
  max?: number;
}): Validation => ({
  validate(amount: number) {
    if (!amount) {
      return 'Amount is required';
    }
    if (min && amount < min) {
      return `Amount cannot be lesser than $${min}`;
    }
    if (max && amount > max) {
      return `Amount cannot be greater than $${max}`;
    }
    if (!MONEY_AMOUNT_REGEX.test(amount?.toString()!)) {
      return 'Please enter valid amount';
    }
    return undefined;
  },
});

export const MONEY_NON_ZERO_VALUE_VALIDATIONS: Validation = {
  validate(v: MoneyValue) {
    if (!v?.amount) {
      return 'Amount is required';
    }
    if (!v?.currency) {
      return 'Please select currency';
    }
    if (v?.amount! <= 0) {
      return 'Amount should be greater than 0';
    }
    if (!MONEY_AMOUNT_REGEX.test(v.amount?.toString()!)) {
      return 'Please enter valid amount';
    }
    return undefined;
  },
};

export const URL_VALIDATIONS: Validation = {
  pattern: {
    value: WEBSITE_REGEX,
    message: 'Please enter a valid url',
  },
};

export const MONEY_VALUE_ALLOW_NEGATIVE_VALIDATIONS: Validation = {
  validate(v: MoneyValue) {
    if (!v?.amount) {
      return 'Amount is required';
    }
    if (!v?.currency) {
      return 'Please select currency';
    }
    if (!MONEY_AMOUNT_REGEX_ALLOW_NEGATIVES.test(v.amount?.toString())) {
      return 'Please enter valid amount';
    }
    return undefined;
  },
};

export const TEXT_CONTENT_VALIDATIONS: Validation = {
  maxLength: {
    value: 255,
    message: 'The text must be under 255 characters',
  },
};

export const PERCENT_VALIDATION: Validation = {
  pattern: {
    value: PERCENT_REGEX,
    message: 'Please enter valid percentage',
  },
};

export const NUMBER_VALIDATIONS: Validation = {
  pattern: {
    value: NUMBER_REGEX,
    message: 'Please input a valid number.',
  },
};

export const YEAR_VALIDATIONS: Validation = {
  pattern: {
    value: YEAR_REGEX,
    message: 'Please enter a valid year.',
  },
};

export const US_ROUTING_NUMBER_VALIDATIONS: Validation = {
  pattern: {
    value: getRoutingNumberForCountry(
      AgentResponseAccountCountryEnum.UnitedStates,
    ),
    message: 'Please enter a valid bank routing number',
  },
};

export const CA_INSTITUTION_NUMBER_VALIDATIONS: Validation = {
  pattern: {
    value: CA_BANK_INSTITUTION_NUMBER,
    message: 'Please enter a valid bank institution number',
  },
};

export const CA_TRANSIT_NUMBER_VALIDATIONS: Validation = {
  pattern: {
    value: CA_BANK_TRANSIT_NUMBER,
    message: 'Please enter a valid bank transit number',
  },
};

export const getPostalCodeValidation = (
  country:
    | AddressRequestCountryEnum
    | AgentResponseAccountCountryEnum
    | AddressCountryEnum
    | undefined,
): Validation => {
  const regExp = getPostalCodeForCountry(country);

  return {
    validate: (val: string) => {
      if (!regExp.test(val)) {
        return 'Please enter a valid code';
      }
      return undefined;
    },
  };
};

export const AGENT_AGE_VALIDATIONS: Validation = {
  validate(dob: string) {
    const age = getAge(dob);
    if (age < 18) {
      return 'Age should be 18 years or more';
    }
    return undefined;
  },
};

export const GOOGLE_AUTO_COMPLETE_VALIDATIONS: Validation = {
  validate: (value: GooglePlaceLocationType | undefined) => {
    if (!value?.formatted_address) {
      return 'Location is required.';
    }

    const address = getAddressFromAddressComponent(value.address_components!);

    const isNotValidAddress =
      (!address.city || !address.zipOrPostalCode || !address.streetAddress1) &&
      !!value?.place_id;

    if (isNotValidAddress) {
      return 'Please enter a valid address';
    }

    return undefined;
  },
};

export const GOOGLE_AUTO_COMPLETE_VALIDATIONS_OPTIONAL: Validation = {
  validate: (value: GooglePlaceLocationType | undefined) => {
    if (value?.place_id) {
      const address = getAddressFromAddressComponent(value.address_components!);

      const isNotValidAddress =
        !address.city || !address.zipOrPostalCode || !address.streetAddress1;

      if (isNotValidAddress) {
        return 'Please enter a valid address';
      }
    }

    return undefined;
  },
};

export const DATE_FORMAT_VALIDATIONS: Validation = {
  pattern: {
    value: DATE_REGEX,
    message: 'Please enter a valid date in MM/DD/YYYY format',
  },
};

export const DATE_SELECT_VALIDATIONS: Validation = {
  validate: (value) => {
    if (value) {
      if (DateTime.fromFormat(value, 'LL/dd/yyyy').isValid) {
        return DateTime.fromFormat(value, 'LL/dd/yyyy').toISODate() >
          DateTime.local().toISODate()
          ? 'Please enter a valid date in past'
          : undefined;
      } else {
        return 'Please enter a valid date';
      }
    }

    return undefined;
  },
};
