import React, { lazy } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import AuthenticatedAdminRoute from '../components/AuthenticatedAdminRoute';
import AuthenticatedRoute from '../components/AuthenticatedRoute';
import AuthenticatedSidebarRoute from '../components/AuthenticatedSidebarRoute';
import GuestRoute from '../components/GuestRoute';
import WalletMyCreditRoute from '../components/Wallet/WalletCreditDashboard/WalletMyCreditRoute';
import { useFeatureFlag } from '../hooks/useFeatureFlag';
import { FeatureFlagTypeEnum, RootState, UserType } from '../types';
import { isCanadianUser } from '../utils/AgentHelper';
import { isGeminiUIEnabled } from '../utils/switchToGeminiUtils';
import ErrorRoutes from './ErrorRoutes';
import KafkaDashboardRoute from './KafkaDashboardRoute';

const AboutRoute = lazy(() => import('./AboutRoute'));
const AdminMortgagesRoute = lazy(() => import('./AdminMortgagesRoute'));
const AgentEventsRoute = lazy(() => import('./AgentEventsRoute'));
const AgentMortgagesRoute = lazy(() => import('./AgentMortgagesRoute'));
const ApiDocsRoute = lazy(() => import('./ApiDocsRoute'));
const ApiGenerationTermsOfServices = lazy(
  () => import('./ApiGenerationTermsOfServices'),
);
const BankAccountsRoute = lazy(() => import('./BankAccountsRoute'));
const BrokerDashboardDecider = lazy(() => import('./BrokerDashboardDecider'));
const BrokerQueueEmptyRoute = lazy(
  () => import('../components/BrokerQueueEmptyRoute'),
);
const BrokerPowerAuditRoute = lazy(() => import('./BrokerPowerAuditRoute'));
const CalendarsRoute = lazy(() => import('./CalendarsRoute'));
const CDAFormRoute = lazy(() => import('./CDAFormRoute'));
const ChangePasswordRoute = lazy(
  () => import('./ChangePassword/ChangePasswordRoute'),
);
const CommissionDepositReceiptRoute = lazy(
  () =>
    import(
      '../components/transactions/CommissionDeposits/CommissionDepositReceiptRoute'
    ),
);
const CreateJointVentureSteps = lazy(
  () => import('../components/JointVenture/CreateJointVentureSteps'),
);
const CreateWebhookRoute = lazy(() => import('./CreateWebhookRoute'));
const DecideProfileDetailRoute = lazy(
  () => import('./DecideProfileDetailRoute'),
);
const DesignCenterRedirect = lazy(
  () => import('../components/DesignCenter/DesignCenterRedirect'),
);
const EnableTwoFactorAuthenticationRoute = lazy(
  () => import('./EnableTwoFactorAuthenticationRoute'),
);
const ErrorIndex = lazy(() => import('./secret/ErrorsRoute'));
const EventsRoute = lazy(() => import('./EventsRoute'));
const FeatureFlagsRoute = lazy(() => import('./FeatureFlagsRoute'));
const FinanceRoute = lazy(() => import('./FinanceRoute'));
const FlagsIndex = lazy(() => import('./secret/FlagsIndexRoute'));
const GeminiAdminOrBrokerListingsIndexRoute = lazy(
  () => import('./GeminiAdminOrBrokerListingsIndexRoute'),
);
const GeminiAdminOrBrokerTransactionsIndexRoute = lazy(
  () => import('./GeminiAdminOrBrokerTransactionsIndexRoute'),
);
const GeminiAgentListingsIndexRoute = lazy(
  () => import('./GeminiAgentListingsIndexRoute'),
);
const GeminiAgentTransactionsIndexRoute = lazy(
  () => import('./GeminiAgentTransactionsIndexRoute'),
);
const GeminiOfficeDetailRoute = lazy(() => import('./GeminiOfficeDetailRoute'));
const InstantPaymentsRoute = lazy(() => import('./InstantPaymentsRoute'));
const InvoiceFormRoute = lazy(() => import('./InvoiceFormRoute'));
const JointVentureSignUpSteps = lazy(
  () => import('../components/JointVenture/SignUp/JointVentureSignUpSteps'),
);
const LearnMore = lazy(() => import('../components/JointVenture/LearnMore'));
const LearnworldsSsoRedirect = lazy(
  () => import('../components/Learnworlds/LearnworldsSsoRedirect'),
);
const LeoQuestionManagerRoute = lazy(() => import('./LeoQuestionManagerRoute'));
const MortgageDetailsRoute = lazy(() => import('./MortgageDetailsRoute'));
const NewBrokerPowerAuditRoute = lazy(
  () => import('./NewBrokerPowerAuditRoute'),
);
const OneRealMortgageRouteV2 = lazy(() => import('./OneRealMortgageRouteV2'));
const OperationsIndexRoute = lazy(() => import('./OperationsIndexRoute'));
const RealClosingServicesRoute = lazy(
  () => import('./RealClosingServicesRoute'),
);
const RealTitleIndexRoute = lazy(() => import('./RealTitleIndexRoute'));
const RealTitleRoute = lazy(() => import('./RealTitleRoute'));
const ReconcileTransactionsRoute = lazy(
  () => import('./ReconcileTransactionsRoute'),
);
const ReferralApplicants = lazy(
  () => import('./referral/ReferralApplicantsRoute'),
);
const ReferralRoute = lazy(() => import('./referral/ReferralRoute'));
const ReportsRoute = lazy(() => import('./ReportsRoute'));
const ResetPasswordRoute = lazy(() => import('./ResetPasswordRoute'));
const Route404 = lazy(() => import('./Route404'));
const SplitAndAttachPDFToChecklistRoute = lazy(
  () => import('./SplitAndAttachPDFToChecklistRoute'),
);
const SuperAdminPeopleSearchRoute = lazy(
  () => import('./SuperAdminPeopleSearchRoute'),
);
const SupportIndexRoute = lazy(() => import('./SupportIndexRoute'));
const TransactionCommissionReview = lazy(
  () => import('../components/transactions/TransactionCommissionReview'),
);
const TransactionSetup = lazy(
  () => import('./transaction/setup/TransactionSetup'),
);
const TransactionDetailsByCodeRoute = lazy(
  () => import('./TransactionDetailsByCodeRoute'),
);
const TransactionTradeRecordSheetRoute = lazy(
  () => import('./TransactionTradeRecordSheetRoute'),
);
const TrustDepositReceiptRoute = lazy(
  () => import('./TrustDepositReceiptRoute'),
);
const WalletApplicationRoute = lazy(() => import('./WalletApplicationRoute'));
const WalletApplicationSteps = lazy(
  () => import('../components/Wallet/Application/WalletApplicationSteps'),
);
const WalletDashboardRoute = lazy(
  () => import('../components/Wallet/WalletDashboard/WalletDashboardRoute'),
);
const WalletOfferings = lazy(
  () => import('../components/Wallet/WalletOfferings'),
);
const WalletRoute = lazy(() => import('./WalletRoute'));

const WalletDebitDashboardRoute = lazy(
  () =>
    import(
      '../components/Wallet/WalletDebitDashboard/WalletDebitDashboardRoute'
    ),
);
const WalletUnitComponentsRoute = lazy(
  () => import('./WalletUnitComponentsRoute'),
);
const WealthManagementRouteV2 = lazy(() => import('./WealthManagementRouteV2'));
const WealthPlanCRMDashboard = lazy(() => import('./WealthPlanCRMDashboard'));
const WealthPlanReferralRouteV2 = lazy(
  () => import('./WealthPlanReferralRouteV2'),
);
const ZenAgreementDetailsRoute = lazy(
  () => import('../components/Zen/Engineering/ZenAgreementDetailsRoute'),
);
const ZenAgreementIndexRoute = lazy(() => import('./ZenAgreementIndexRoute'));
const ZenAmendmentsDetailsRoute = lazy(
  () => import('../components/Zen/Engineering/ZenAmendmentsDetailsRoute'),
);
const ZenAnnouncementRoute = lazy(() => import('./ZenAnnouncementRoute'));
const ZenApplicationFormDetailRoute = lazy(
  () => import('./ZenApplicationFormDetailRoute'),
);
const ZenApplicationFormIndex = lazy(() => import('./ZenApplicationFormIndex'));
const ZenBoardDetailRoute = lazy(() => import('./ZenBoardDetailRoute'));
const ZenBoardSearchRoute = lazy(() => import('./ZenBoardSearchRoute'));
const ZenBrokerListingsRoute = lazy(() => import('./ZenBrokerListingsRoute'));
const ZenBrokerPeopleRoute = lazy(() => import('./ZenBrokerPeopleRoute'));
const ZenBrokerTransactionsRoute = lazy(
  () => import('./ZenBrokerTransactionsRoute'),
);
const ZenCompaniesIndexRoute = lazy(() => import('./ZenCompaniesIndexRoute'));
const ZenCreateTransactionSteps = lazy(
  () =>
    import(
      '../components/Zen/Transaction/CreateTransaction/ZenCreateTransactionSteps'
    ),
);
const ZenCronJobsHistoryRoute = lazy(() => import('./ZenCronJobsHistoryRoute'));
const ZenCronJobsRoute = lazy(() => import('./ZenCronJobsRoute'));
const ZenDirectoryRoute = lazy(() => import('./ZenDirectoryRoute'));
const ZenEngineeringIndexRoute = lazy(
  () => import('./ZenEngineeringIndexRoute'),
);
const ZenExperimentDetailRoute = lazy(
  () => import('./ZenExperimentDetailRoute'),
);
const ZenExternalTrustDepositCheckUploadRoute = lazy(
  () => import('./ZenExternalTrustDepositCheckUploadRoute'),
);
const ZenInvoicesIndexRoute = lazy(() => import('./ZenInvoicesIndexRoute'));
const ZenLegalIndexRoute = lazy(() => import('./ZenLegalIndexRoute'));
const ZenListingsDetailRoute = lazy(() => import('./ZenListingsDetailRoute'));
const ZenListingsRoute = lazy(() => import('./ZenListingsRoute'));
const ZenLoginRoute = lazy(() => import('./ZenLoginRoute'));
const ZenLoginTwoFactorAuthenticationRoute = lazy(
  () => import('./ZenLoginTwoFactorAuthenticationRoute'),
);
const ZenMarketingCenterRoute = lazy(() => import('./ZenMarketingCenterRoute'));
const ZenMLSDetailRoute = lazy(() => import('./ZenMLSDetailRoute'));
const ZenMLSSearchRoute = lazy(() => import('./ZenMLSSearchRoute'));
const ZenMyListingsIndexRoute = lazy(() => import('./ZenMyListingsIndexRoute'));
const ZenMyOfficesIndexRoute = lazy(() => import('./ZenMyOfficesIndexRoute'));
const ZenMyTeamsIndexRoute = lazy(() => import('./ZenMyTeamsIndexRoute'));
const ZenMyTransactionsIndexRoute = lazy(
  () => import('./ZenMyTransactionsIndexRoute'),
);
const ZenOfficeDetailRoute = lazy(() => import('./ZenOfficeDetailRoute'));
const ZenOfficeIndexRoute = lazy(() => import('./ZenOfficeIndexRoute'));
const ZenOutgoingPaymentsIndexRoute = lazy(
  () => import('./ZenOutgoingPaymentsIndexRoute'),
);
const ZenPeopleSearchRoute = lazy(() => import('./ZenPeopleSearchRoute'));
const ZenRealTitleOfficerTransactionDetailRoute = lazy(
  () => import('./ZenRealTitleOfficerTransactionDetailRoute'),
);
const ZenRegisterRoute = lazy(() => import('./ZenRegisterRoute'));
const ZenResourcesLinksRoute = lazy(() => import('./ZenResourcesLinksRoute'));
const ZenResourcesTemplateItemsRoute = lazy(
  () => import('./ZenResourcesTemplateItemsRoute'),
);
const ZenResourcesTemplatesRoute = lazy(
  () => import('./ZenResourcesTemplatesRoute'),
);
const ZenSuperAdminIndexRoute = lazy(() => import('./ZenSuperAdminIndexRoute'));
const ZenTeamDecideRoute = lazy(() => import('./ZenTeamDecideRoute'));
const ZenTeamsSearchRoute = lazy(() => import('./ZenTeamsSearchRoute'));
const ZenTransactionDepositCheckRoute = lazy(
  () => import('./ZenTransactionDepositCheckRoute'),
);
const ZenTransactionDetailRoute = lazy(
  () => import('./ZenTransactionDetailRoute'),
);
const ZenTransactionsRoute = lazy(() => import('./ZenTransactionsRoute'));
const ZenTransactionCommissionDocumentReview = lazy(
  () =>
    import(
      '../components/transactions/CommissionDocumentOnboarding/ZenTransactionCommissionDocumentReview'
    ),
);
const ZenCreateListingSteps = lazy(
  () =>
    import('../components/transactions/ZenCreateListing/ZenCreateListingSteps'),
);
const ZenReferralTransactionSteps = lazy(
  () =>
    import(
      '../components/Zen/Transaction/ReferralTransaction/ZenReferralTransactionSteps'
    ),
);
const ZenTransactionConveyanceDocumentPreviewRoute = lazy(
  () =>
    import(
      '../components/Zen/ConveyanceDocuments/ZenTransactionConveyanceDocumentPreviewRoute'
    ),
);

interface AuthRoutesProps {}

const MainRoutes: React.FC<AuthRoutesProps> = () => {
  const {
    auth: {
      userDetail,
      isAdmin,
      isBroker,
      isSuperAdmin,
      isAnnouncer,
      isLeoExpert,
      isMortgageUserRole,
    },
  } = useSelector((state: RootState) => state);

  let homeRedirectPath = '';
  if (isSuperAdmin || isAdmin || isBroker) {
    homeRedirectPath = '/people';
  } else if (isMortgageUserRole) {
    homeRedirectPath = '/mortgages';
  } else {
    homeRedirectPath = '/transactions';
  }

  const isNewPowerAuditEnabled = useFeatureFlag(
    FeatureFlagTypeEnum.NEW_POWER_AUDIT_IMPROVEMENTS,
  );

  const isLeoQuestionManagerEnabled = useFeatureFlag(
    FeatureFlagTypeEnum.LEO_QUESTION_MANAGER,
  );

  const isWebhooksFeatureEnabled = useFeatureFlag(FeatureFlagTypeEnum.WEBHOOKS);
  const isWalletFeatureEnabled = useFeatureFlag(FeatureFlagTypeEnum.WALLET);

  const isGeminiFeatureFlagEnabled = useFeatureFlag(
    FeatureFlagTypeEnum.GEMINI_REDESIGN,
  );
  const geminiRedesignFlagEnabled =
    isGeminiFeatureFlagEnabled && isGeminiUIEnabled();

  const isRealTitleImprovementsEnabled = useFeatureFlag(
    FeatureFlagTypeEnum.REAL_TITLE_PAGE_IMPROVEMENTS,
  );
  const isReferralCentralFeatureEnabled = useFeatureFlag(
    FeatureFlagTypeEnum.REFERRAL_CENTRAL,
  );

  const renderRealTitleRoute = () => {
    if (isCanadianUser(userDetail?.accountCountry!)) {
      return Route404;
    }
    if (isRealTitleImprovementsEnabled) {
      return RealClosingServicesRoute;
    }
    return RealTitleRoute;
  };

  return (
    <Switch>
      <Redirect
        path='/'
        from='/'
        to={!!userDetail ? homeRedirectPath : '/login'}
        exact
      />
      <GuestRoute
        path='/login/2fa'
        component={ZenLoginTwoFactorAuthenticationRoute}
        exact
      />
      <Route
        path='/enable-2fa'
        component={EnableTwoFactorAuthenticationRoute}
        exact
      />
      <GuestRoute path='/login' component={ZenLoginRoute} exact />
      <GuestRoute path='/register' component={ZenRegisterRoute} exact />
      <GuestRoute path='/reset-password' component={ResetPasswordRoute} exact />
      <Route
        path='/transactions/token/:id/check-deposits'
        component={ZenExternalTrustDepositCheckUploadRoute}
        exact
      />
      <GuestRoute
        path='/change-password'
        component={ChangePasswordRoute}
        exact
      />
      <AuthenticatedSidebarRoute
        path='/people'
        config={{
          [UserType.SUPER_ADMIN]: SuperAdminPeopleSearchRoute,
          [UserType.ADMIN]: ZenPeopleSearchRoute,
          [UserType.BROKER]: ZenBrokerPeopleRoute,
        }}
        exact
      />
      <AuthenticatedSidebarRoute
        path='/people/:id'
        component={DecideProfileDetailRoute}
      />
      <AuthenticatedSidebarRoute
        path='/offices'
        config={{
          [UserType.ADMIN]: ZenOfficeIndexRoute,
          [UserType.BROKER]: ZenMyOfficesIndexRoute,
          [UserType.AGENT]: ZenMyOfficesIndexRoute,
        }}
        exact
      />
      <AuthenticatedSidebarRoute
        path='/offices/:id/:type(documents)?'
        component={
          isGeminiFeatureFlagEnabled
            ? GeminiOfficeDetailRoute
            : ZenOfficeDetailRoute
        }
        exact
      />
      <AuthenticatedAdminRoute
        path='/outgoing-payments'
        component={ZenOutgoingPaymentsIndexRoute}
      />
      <AuthenticatedAdminRoute
        path='/invoices'
        component={ZenInvoicesIndexRoute}
      />
      <AuthenticatedSidebarRoute
        path='/teams'
        config={{
          [UserType.ADMIN]: ZenTeamsSearchRoute,
          [UserType.AGENT]: ZenMyTeamsIndexRoute,
        }}
        exact
      />
      <AuthenticatedSidebarRoute
        path='/instant-payments'
        component={InstantPaymentsRoute}
        exact
      />
      <AuthenticatedSidebarRoute
        path='/resources/links'
        component={ZenResourcesLinksRoute}
        exact
      />
      <AuthenticatedSidebarRoute
        path='/resources/marketing-center'
        component={ZenMarketingCenterRoute}
        exact
      />
      <AuthenticatedSidebarRoute
        path='/resources/templates'
        component={ZenResourcesTemplatesRoute}
        exact
      />
      <AuthenticatedSidebarRoute
        path='/agreement/:id'
        component={ZenAgreementDetailsRoute}
        exact
      />
      <AuthenticatedSidebarRoute
        path='/agreement/:id/amendment/:amendmentId'
        component={ZenAmendmentsDetailsRoute}
        exact
      />
      <AuthenticatedSidebarRoute
        path='/resources/templates/:templateItemId'
        component={ZenResourcesTemplateItemsRoute}
        exact
      />
      <AuthenticatedSidebarRoute
        path='/teams/:id'
        component={ZenTeamDecideRoute}
        exact
      />
      <AuthenticatedSidebarRoute
        path='/mls'
        config={{
          [UserType.ADMIN]: ZenMLSSearchRoute,
          [UserType.BROKER]: ZenMLSSearchRoute,
        }}
        exact
      />
      <AuthenticatedSidebarRoute
        path='/mls/:id'
        component={ZenMLSDetailRoute}
        exact
      />
      <AuthenticatedSidebarRoute
        path='/boards'
        config={{
          [UserType.ADMIN]: ZenBoardSearchRoute,
          [UserType.BROKER]: ZenBoardSearchRoute,
        }}
        exact
      />
      <AuthenticatedSidebarRoute
        path='/boards/:id'
        component={ZenBoardDetailRoute}
        exact
      />
      <AuthenticatedSidebarRoute
        path='/transactions/:type(all|sale|lease|referrals)?/:lifecycle(active|closed|terminated|draft)?'
        config={{
          [UserType.ADMIN]: geminiRedesignFlagEnabled
            ? GeminiAdminOrBrokerTransactionsIndexRoute
            : ZenTransactionsRoute,
          [UserType.REAL_TITLE]: geminiRedesignFlagEnabled
            ? GeminiAdminOrBrokerTransactionsIndexRoute
            : ZenTransactionsRoute,
          [UserType.AGENT]: geminiRedesignFlagEnabled
            ? GeminiAgentTransactionsIndexRoute
            : ZenMyTransactionsIndexRoute,
          [UserType.BROKER]: geminiRedesignFlagEnabled
            ? GeminiAdminOrBrokerTransactionsIndexRoute
            : ZenBrokerTransactionsRoute,
        }}
        exact
      />
      <AuthenticatedSidebarRoute
        path='/listings/:type(all|sale|lease|referrals)?/:lifecycle(active|closed|terminated|draft)?'
        config={{
          [UserType.ADMIN]: geminiRedesignFlagEnabled
            ? GeminiAdminOrBrokerListingsIndexRoute
            : ZenListingsRoute,
          [UserType.AGENT]: geminiRedesignFlagEnabled
            ? GeminiAgentListingsIndexRoute
            : ZenMyListingsIndexRoute,
          [UserType.BROKER]: geminiRedesignFlagEnabled
            ? GeminiAdminOrBrokerListingsIndexRoute
            : ZenBrokerListingsRoute,
        }}
        exact
      />
      <AuthenticatedSidebarRoute
        path='/mortgages'
        config={{
          [UserType.ADMIN]: AdminMortgagesRoute,
          [UserType.MORTGAGE_ADMIN]: AdminMortgagesRoute,
          [UserType.LOAN_OFFICER]: AdminMortgagesRoute,
          [UserType.LOAN_PROCESSOR]: AdminMortgagesRoute,
          [UserType.REAL_ORIGINATE_LOAN_OFFICER]: AdminMortgagesRoute,
          [UserType.AGENT]: AgentMortgagesRoute,
        }}
        exact
      />
      <AuthenticatedSidebarRoute
        path='/mortgages/:id/:type(borrower)?/:borrowerId?'
        component={MortgageDetailsRoute}
      />
      <AuthenticatedSidebarRoute
        path='/dashboard'
        config={{
          [UserType.BROKER]: BrokerDashboardDecider,
        }}
        exact
      />

      <AuthenticatedSidebarRoute
        path='/transactions/code/:code'
        component={TransactionDetailsByCodeRoute}
      />
      <AuthenticatedSidebarRoute
        path='/transactions/:id/setup'
        component={TransactionSetup}
        exact
      />
      <AuthenticatedSidebarRoute
        path='/transactions/:id/commission-validate'
        component={TransactionCommissionReview}
        exact
      />
      <AuthenticatedSidebarRoute
        path='/transaction/create/:transactionBuilderId'
        component={ZenCreateTransactionSteps}
        exact
      />
      <AuthenticatedSidebarRoute
        path='/listing/create/:transactionBuilderId'
        component={ZenCreateListingSteps}
        exact
      />
      <AuthenticatedSidebarRoute
        path='/transaction/referral'
        component={ZenReferralTransactionSteps}
        exact
      />
      <AuthenticatedSidebarRoute
        path='/webhook/create/:webhookId?'
        component={isWebhooksFeatureEnabled ? CreateWebhookRoute : Route404}
        exact
      />
      <AuthenticatedSidebarRoute
        path='/wallet'
        component={isWalletFeatureEnabled ? WalletRoute : Route404}
        exact
      />
      <AuthenticatedSidebarRoute
        path='/wallet/offerings'
        component={isWalletFeatureEnabled ? WalletOfferings : Route404}
        exact
      />
      <AuthenticatedSidebarRoute
        path='/wallet/signup/:type(credit|debit)/:offeringId'
        component={isWalletFeatureEnabled ? WalletApplicationSteps : Route404}
        exact
      />
      <AuthenticatedSidebarRoute
        path='/wallet/:accountId?'
        component={isWalletFeatureEnabled ? WalletDashboardRoute : Route404}
        exact
      />
      <AuthenticatedSidebarRoute
        path='/wallet/new/:accountId?'
        component={
          isWalletFeatureEnabled ? WalletDebitDashboardRoute : Route404
        }
        exact
      />
      <AuthenticatedSidebarRoute
        path='/wallet/new/:accountId/credit'
        component={isWalletFeatureEnabled ? WalletMyCreditRoute : Route404}
        exact
      />
      <AuthenticatedSidebarRoute
        path='/wallet/unit/:accountId?'
        component={
          isWalletFeatureEnabled ? WalletUnitComponentsRoute : Route404
        }
        exact
      />
      <AuthenticatedSidebarRoute
        path='/wallet/application'
        component={isWalletFeatureEnabled ? WalletApplicationRoute : Route404}
        exact
      />
      <Route
        path='/transactions/:id/approve-commission-document'
        component={ZenTransactionCommissionDocumentReview}
        exact
      />
      <Route
        path='/transactions/:id/deposit-check'
        component={ZenTransactionDepositCheckRoute}
        exact
      />
      <Route
        path='/transactions/:id/deposit-check/escrows/:escrowId'
        component={ZenTransactionDepositCheckRoute}
        exact
      />
      <AuthenticatedSidebarRoute
        path='/transactions/:transactionId/trust-deposit-receipt/:id'
        component={TrustDepositReceiptRoute}
      />
      <AuthenticatedSidebarRoute
        path='/transactions/:transactionId/commission-deposit-receipt/:id'
        component={CommissionDepositReceiptRoute}
      />
      <AuthenticatedSidebarRoute
        path='/transactions/:id/conveyance-documents/:revisionId/preview'
        component={ZenTransactionConveyanceDocumentPreviewRoute}
      />
      <AuthenticatedRoute
        path='/:containerType/:containerId/checklist/:checklistId/dropbox/:dropboxId/split-and-attach-pdf'
        component={SplitAndAttachPDFToChecklistRoute}
      />
      <Route path='/learnworlds/sso' component={LearnworldsSsoRedirect} />
      <Route path='/designcenter/sso' component={DesignCenterRedirect} />
      <AuthenticatedSidebarRoute
        path='/transactions/:transactionId/power-audit'
        config={{
          [UserType.ADMIN]: isNewPowerAuditEnabled
            ? NewBrokerPowerAuditRoute
            : BrokerPowerAuditRoute,
          [UserType.AGENT]: Route404,
          [UserType.BROKER]: isNewPowerAuditEnabled
            ? NewBrokerPowerAuditRoute
            : BrokerPowerAuditRoute,
        }}
      />
      <AuthenticatedSidebarRoute
        path='/transactions/:id'
        config={{
          [UserType.ADMIN]: ZenTransactionDetailRoute,
          [UserType.REAL_TITLE]: ZenRealTitleOfficerTransactionDetailRoute,
          [UserType.AGENT]: ZenTransactionDetailRoute,
          [UserType.BROKER]: ZenTransactionDetailRoute,
        }}
      />
      <AuthenticatedSidebarRoute
        path='/listings/:id'
        component={ZenListingsDetailRoute}
      />
      {isReferralCentralFeatureEnabled && (
        <AuthenticatedSidebarRoute
          path='/referrals'
          component={ReferralRoute}
          exact
        />
      )}
      {isReferralCentralFeatureEnabled && (
        <AuthenticatedSidebarRoute
          path='/referrals/:id/applicants'
          component={ReferralApplicants}
        />
      )}

      <AuthenticatedSidebarRoute
        path='/invoice/:id'
        component={InvoiceFormRoute}
        exact
      />
      <AuthenticatedSidebarRoute
        path='/cda/:id'
        component={CDAFormRoute}
        exact
      />
      <AuthenticatedSidebarRoute
        path='/trade-record-sheets/:id'
        component={TransactionTradeRecordSheetRoute}
        exact
      />
      <AuthenticatedSidebarRoute
        path='/trade-record-sheets/:id/participants/:participantId'
        component={TransactionTradeRecordSheetRoute}
        exact
      />
      <AuthenticatedSidebarRoute
        path='/inbox'
        component={BrokerQueueEmptyRoute}
        exact
      />
      <AuthenticatedSidebarRoute
        path='/my-events/:eventId?'
        config={{
          [UserType.ADMIN]: EventsRoute,
          [UserType.AGENT]: AgentEventsRoute,
          [UserType.BROKER]: AgentEventsRoute,
        }}
        exact
      />
      <AuthenticatedSidebarRoute
        path='/directory'
        config={{
          [UserType.ADMIN]: ZenDirectoryRoute,
          [UserType.AGENT]: ZenDirectoryRoute,
        }}
        exact
      />
      <AuthenticatedAdminRoute
        path='/applications'
        component={ZenApplicationFormIndex}
        exact
      />
      <AuthenticatedAdminRoute
        path='/applications/:id'
        component={ZenApplicationFormDetailRoute}
      />
      <AuthenticatedAdminRoute path='/secret/flags' component={FlagsIndex} />
      <AuthenticatedAdminRoute path='/secret/errors' component={ErrorIndex} />
      <AuthenticatedAdminRoute
        path='/jobs'
        component={ZenCronJobsRoute}
        exact
      />
      <AuthenticatedAdminRoute
        path='/jobs/:jobName/history'
        component={ZenCronJobsHistoryRoute}
        exact
      />
      <AuthenticatedAdminRoute
        path='/engineering'
        component={ZenEngineeringIndexRoute}
      />
      <AuthenticatedAdminRoute path='/legal' component={ZenLegalIndexRoute} />
      <AuthenticatedAdminRoute
        path='/companies'
        component={ZenCompaniesIndexRoute}
        exact
      />
      <AuthenticatedAdminRoute
        path='/bank-accounts/:id/reconcile-transactions/:type(reconciled)?'
        component={ReconcileTransactionsRoute}
        exact
      />
      <AuthenticatedAdminRoute
        path='/bank-accounts'
        component={BankAccountsRoute}
        exact
      />
      <AuthenticatedAdminRoute
        path='/feature-flags/:type(archived)?'
        component={FeatureFlagsRoute}
        exact
      />
      <AuthenticatedAdminRoute
        path='/kafka-dashboard/:type(consumer|producer)'
        component={KafkaDashboardRoute}
      />
      <AuthenticatedAdminRoute
        path='/feature-flags/:id'
        component={ZenExperimentDetailRoute}
        exact
      />
      <AuthenticatedAdminRoute
        path='/agreements'
        component={ZenAgreementIndexRoute}
      />
      <AuthenticatedAdminRoute path='/finance' component={FinanceRoute} />
      <AuthenticatedAdminRoute path='/calendars' component={CalendarsRoute} />
      <AuthenticatedAdminRoute
        path='/superadmin'
        component={ZenSuperAdminIndexRoute}
      />
      <AuthenticatedRoute path='/api/docs' component={ApiDocsRoute} />
      <AuthenticatedAdminRoute path='/support' component={SupportIndexRoute} />
      <AuthenticatedAdminRoute
        path='/operations'
        component={OperationsIndexRoute}
      />
      <AuthenticatedRoute
        path='/real-title/:inviteId/signup'
        component={JointVentureSignUpSteps}
      />
      <AuthenticatedRoute
        path='/real-title/:inviteId/learn-more'
        component={LearnMore}
      />
      <AuthenticatedAdminRoute
        path='/real-title/create-jv'
        component={CreateJointVentureSteps}
      />
      <AuthenticatedAdminRoute
        path='/real-title/joint-ventures'
        component={RealTitleIndexRoute}
      />
      <AuthenticatedSidebarRoute
        path='/real-title'
        component={renderRealTitleRoute()}
        exact
      />
      <AuthenticatedSidebarRoute
        path='/onereal-mortgage'
        component={
          !isCanadianUser(userDetail?.accountCountry!)
            ? OneRealMortgageRouteV2
            : Route404
        }
        exact
      />
      {/** This route can be accessed both by authenticated and unauthenticated users */}
      <Route
        path='/wealthplan/referral'
        component={WealthPlanReferralRouteV2}
        exact
      />
      <AuthenticatedSidebarRoute
        path='/wealthplan'
        component={WealthManagementRouteV2}
        exact
      />
      <AuthenticatedSidebarRoute
        path='/wealthplan/referral/dashboard'
        component={WealthPlanCRMDashboard}
        exact
      />
      <AuthenticatedSidebarRoute path='/about' component={AboutRoute} />
      <AuthenticatedSidebarRoute
        path='/reports'
        component={ReportsRoute}
        exact
      />
      <Route
        path='/terms/api-generation'
        component={ApiGenerationTermsOfServices}
        exact
      />
      <AuthenticatedSidebarRoute
        path='/announcement'
        component={isAnnouncer ? ZenAnnouncementRoute : Route404}
      />
      <AuthenticatedSidebarRoute
        path='/leo-question-manager'
        component={
          isLeoQuestionManagerEnabled && isLeoExpert
            ? LeoQuestionManagerRoute
            : Route404
        }
      />
      <ErrorRoutes />
    </Switch>
  );
};

export default MainRoutes;
