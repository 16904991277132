import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isEmpty, last } from 'lodash';
import {
  ApproveForClosingRequest,
  AttachedFeeRequest,
  CheckDepositTokenResponse,
  ConfirmedCommissionDepositRequest,
  CreateParticipantRequest,
  CreateSentimentRequest,
  CreateSentimentRequestSatisfactionRateEnum,
  DoubleEnderControllerApi,
  DoubleEnderLinkRequest,
  DoubleEnderResponse,
  EscrowDepositRequest,
  EscrowRequest,
  EscrowResponse,
  ExternalCheckDepositsControllerApi,
  FeaturesResponse,
  FmlsInfoRequest,
  LeaderSplitDto,
  ListingControllerApi,
  OutgoingPaymentsControllerApi,
  OutgoingPaymentWithBankInfoResponse,
  PagedTransactionResponse,
  ParticipantResponse,
  SentimentControllerApi,
  SentimentDisplayRequest,
  SentimentDisplayResponse,
  SetPaymentsRequest,
  TitleProgress,
  TransactionBuilderControllerApi,
  TransactionCommentResponse,
  TransactionControllerApi,
  TransactionLifecycleStateValueStateEnum,
  TransactionPermissionsResponse,
  TransactionResponse,
  TransactionsOverviewResponse,
  UpdateOfficeTaxPaidFromCommissionRequest,
  UpdateParticipantInstantPaymentEligibilityRequest,
  UpdateParticipantLiteRequest,
  UpdateParticipantRequest,
  UpdatePaymentParticipantTaxWithheldStatusRequest,
  UpdateProTeammateFeeSplitsRequest,
  UpdateProTeammateTeamFeesRequest,
  UpdateTransactionTaxesRequest,
  UpdateTransactionTaxExemptStatusRequest,
  UpdateTransactionVerifiedRequest,
} from '../openapi/arrakis';
import {
  PublisherNotificationPreferencesDto,
  UpdatePublisherNotificationPreferencesDto,
  UserControllerApi,
} from '../openapi/hermes';
import {
  CommentTemplateResponse,
  MentionBlock,
  VoiceCallBlock,
} from '../openapi/yada';
import { AgentControllerApi, AgentResponse } from '../openapi/yenta';
import AnalyticsService from '../services/AnalyticsService';
import ErrorService from '../services/ErrorService';
import {
  AnalyticsEventEnum,
  AppDispatch,
  AppThunk,
  AsyncResponse,
  ErrorCode,
  FeatureFlagTypeEnum,
  LifecycleGroupEnum,
  ListingCountsByLifecycleGroupType,
  PaginatedResultsByIdWithFiltersResponse,
  PublisherTypeEnum,
  TransactionsCountByLifecycleGroupType,
  TransactionState,
} from '../types';
import {
  getArrakisConfiguration,
  getHermesConfiguration,
  getYentaConfiguration,
} from '../utils/OpenapiConfigurationUtils';
import { capitalizeEnum } from '../utils/StringUtils';
import { getInvoiceTableFetchData } from '../utils/TableUtils';
import {
  getDealInfoWithUpdatedEstimatedClosingDate,
  getTransactionTransitionToReadableName,
} from '../utils/TransactionHelper';
import { showApiErrorModal } from './ErrorSlice';
import {
  showErrorToast,
  showErrorToastForErrorCode,
  showSuccessToast,
} from './ToastNotificationSlice';
import { performAsyncRequest } from './utils/SliceUtil';

type Options = {
  refreshTx?: boolean;
  silent?: boolean;
};

export const DOUBLE_ENDER_TRANSACTION_NAME = 'double ender transaction';

export const initialState: TransactionState = {
  loadingTransactionOverview: false,
  fetchTransactionOverviewErrorCode: null,
  loadingTransactionDetail: false,
  transactionDetailResponse: { loading: false, name: 'transactionDetail' },
  fetchTransactionDetailErrorCode: null,
  loadingCommissionParticipants: false,
  fetchCommissionParticipantsErrorCode: null,
  escrowDetails: undefined,
  currentTransactionOverview: undefined,
  transactionPermissions: undefined,
  pagedOpenTransactions: undefined,
  pagedClosedTransactions: undefined,
  pagedTerminatedTransactions: undefined,
  pagedOpenListingTransactions: undefined,
  pagedClosedListingTransactions: undefined,
  pagedTerminatedListingTransactions: undefined,
  hasPaymentInvoices: false,
  transactionOutgoingPayments: [],
  sentimentDetails: undefined,
  brokerSentimentDetails: undefined,
  showClosedModal: false,
  notificationSetting: undefined,
  transactionsCountByLifecycleGroup: {
    [LifecycleGroupEnum.OPEN]: 0,
    [LifecycleGroupEnum.CLOSED]: 0,
    [LifecycleGroupEnum.TERMINATED]: 0,
    [LifecycleGroupEnum.DRAFT]: 0,
  },
  listingCountByLifecycleGroup: {
    [LifecycleGroupEnum.OPEN]: 0,
    [LifecycleGroupEnum.CLOSED]: 0,
    [LifecycleGroupEnum.TERMINATED]: 0,
    [LifecycleGroupEnum.DRAFT]: 0,
  },
  features: undefined,
  loadingTitleProgress: false,
  titleProgress: undefined,
  tokenTransactionDetail: undefined,
  doubleEnderTransaction: {
    loading: false,
    name: DOUBLE_ENDER_TRANSACTION_NAME,
  },
  doubleEnderAgent: { loading: false, name: 'double ender agent' },
  commentsMentionUsers: undefined,
  transactionPaginatedResultsByIdWithFilters: undefined,
  recordingDataById: {},
  commentTemplates: [],
};

const TransactionSlice = createSlice({
  name: 'transaction',
  initialState,
  reducers: {
    changeLoadingTransactionOverview(state, action: PayloadAction<boolean>) {
      state.loadingTransactionOverview = action.payload;
    },
    errorFetchingTransactionOverview(state, action: PayloadAction<ErrorCode>) {
      state.fetchTransactionOverviewErrorCode = action.payload;
    },
    changeLoadingTransactionDetail(state, action: PayloadAction<boolean>) {
      state.loadingTransactionDetail = action.payload;
    },
    errorFetchingTransactionDetail(state, action: PayloadAction<ErrorCode>) {
      state.fetchTransactionDetailErrorCode = action.payload;
    },
    saveTransactionDetailResponse(
      state,
      action: PayloadAction<AsyncResponse<TransactionResponse>>,
    ) {
      state.transactionDetailResponse = action.payload;
    },
    changeLoadingCommissionParticipants(state, action: PayloadAction<boolean>) {
      state.loadingCommissionParticipants = action.payload;
    },
    errorFetchingCommissionParticipants(
      state,
      action: PayloadAction<ErrorCode>,
    ) {
      state.fetchCommissionParticipantsErrorCode = action.payload;
    },
    saveEscrowDetails(state, action: PayloadAction<EscrowResponse>) {
      const escrowIndex = state.transactionDetailResponse.data?.escrows!.findIndex(
        (a) => a.id === action.payload.id,
      );

      if (escrowIndex !== -1) {
        state.transactionDetailResponse.data!.escrows![escrowIndex!] =
          action.payload;
      } else {
        state.transactionDetailResponse.data?.escrows!.push(action.payload);
      }
      state.fetchTransactionOverviewErrorCode = null;
    },
    saveEscrowDepositDetails(
      state,
      action: PayloadAction<{ escrowId: string; deposit: EscrowResponse }>,
    ) {
      const escrowIndex = state.transactionDetailResponse.data?.escrows!.findIndex(
        (a) => a.id === action.payload.escrowId,
      );

      const escrowDepositIndex = state.transactionDetailResponse.data!.escrows![
        escrowIndex!
      ].deposits!.findIndex((a) => a.id === action.payload.deposit.id);

      if (escrowIndex !== -1) {
        state.transactionDetailResponse.data!.escrows![escrowIndex!].deposits![
          escrowDepositIndex!
        ] = action.payload.deposit;
      }

      state.fetchTransactionOverviewErrorCode = null;
    },
    saveCurrentTransactionDetails(
      state,
      action: PayloadAction<TransactionsOverviewResponse>,
    ) {
      state.currentTransactionOverview = action.payload;
    },
    saveTransactionPermissions(
      state,
      action: PayloadAction<TransactionPermissionsResponse>,
    ) {
      state.transactionPermissions = action.payload;
    },
    savePagedTransaction(
      state,
      action: PayloadAction<{
        data: PagedTransactionResponse;
        lifecycleGroup: 'OPEN' | 'CLOSED' | 'TERMINATED';
      }>,
    ) {
      if (action.payload.lifecycleGroup === 'OPEN') {
        state.pagedOpenTransactions = action.payload.data;
      } else if (action.payload.lifecycleGroup === 'TERMINATED') {
        state.pagedTerminatedTransactions = action.payload.data;
      } else {
        state.pagedClosedTransactions = action.payload.data;
      }
    },
    savePagedListingTransaction(
      state,
      action: PayloadAction<{
        data: PagedTransactionResponse;
        lifecycleGroup: 'OPEN' | 'CLOSED' | 'TERMINATED';
      }>,
    ) {
      if (action.payload.lifecycleGroup === 'OPEN') {
        state.pagedOpenListingTransactions = action.payload.data;
      } else if (action.payload.lifecycleGroup === 'TERMINATED') {
        state.pagedTerminatedListingTransactions = action.payload.data;
      } else {
        state.pagedClosedListingTransactions = action.payload.data;
      }
    },
    saveHasPaymentInvoices(state, action: PayloadAction<boolean>) {
      state.hasPaymentInvoices = action.payload;
    },
    saveTransactionOutgoingPayments(
      state,
      action: PayloadAction<OutgoingPaymentWithBankInfoResponse[]>,
    ) {
      state.transactionOutgoingPayments = action.payload;
    },
    saveSentimentDetails(
      state,
      action: PayloadAction<SentimentDisplayResponse | undefined>,
    ) {
      state.sentimentDetails = action.payload;
    },
    saveBrokerSentimentDetails(
      state,
      action: PayloadAction<SentimentDisplayResponse | undefined>,
    ) {
      state.brokerSentimentDetails = action.payload;
    },
    toggleClosedModal(state, action: PayloadAction<boolean>) {
      state.showClosedModal = action.payload;
    },
    saveMutedPublisherStatus(
      state,
      action: PayloadAction<PublisherNotificationPreferencesDto | undefined>,
    ) {
      state.notificationSetting = action.payload;
    },
    saveTransactionsCountByLifecycleGroup(
      state,
      action: PayloadAction<TransactionsCountByLifecycleGroupType>,
    ) {
      state.transactionsCountByLifecycleGroup = action.payload;
    },
    saveListingCountByLifecycleGroup(
      state,
      action: PayloadAction<ListingCountsByLifecycleGroupType>,
    ) {
      state.listingCountByLifecycleGroup = action.payload;
    },
    saveTransactionFeatures(
      state,
      action: PayloadAction<FeaturesResponse | undefined>,
    ) {
      state.features = action.payload;
    },
    changeLoadingTitleProgress(state, action: PayloadAction<boolean>) {
      state.loadingTitleProgress = action.payload;
    },
    saveTitleProgress(state, action: PayloadAction<TitleProgress | undefined>) {
      state.titleProgress = action.payload;
    },
    saveTokenTransactionDetail(
      state,
      action: PayloadAction<CheckDepositTokenResponse>,
    ) {
      state.tokenTransactionDetail = action.payload;
    },
    saveDoubleEnderTransaction(
      state,
      action: PayloadAction<AsyncResponse<TransactionResponse>>,
    ) {
      state.doubleEnderTransaction = action.payload;
    },
    saveDoubleEnderAgent(
      state,
      action: PayloadAction<AsyncResponse<AgentResponse>>,
    ) {
      state.doubleEnderAgent = action.payload;
    },
    saveCommentsMentionUsers(
      state,
      action: PayloadAction<TransactionCommentResponse | undefined>,
    ) {
      state.commentsMentionUsers = action.payload;
    },
    saveTransactionPaginatedResultsByIdWithFilters(
      state,
      action: PayloadAction<
        PaginatedResultsByIdWithFiltersResponse<TransactionResponse>
      >,
    ) {
      state.transactionPaginatedResultsByIdWithFilters = action.payload;
    },
    saveCommentTemplates(
      state,
      action: PayloadAction<CommentTemplateResponse[]>,
    ) {
      state.commentTemplates = action.payload;
    },
    setRecordingDataById(
      state,
      action: PayloadAction<{
        id: string;
        recordingBlock: VoiceCallBlock;
        mentionBlock: MentionBlock;
      }>,
    ) {
      const { id, recordingBlock, mentionBlock } = action.payload;
      state.recordingDataById[id] = { recordingBlock, mentionBlock };
    },
  },
});

export const {
  changeLoadingTransactionOverview,
  errorFetchingTransactionOverview,
  changeLoadingTransactionDetail,
  errorFetchingTransactionDetail,
  saveTransactionDetailResponse,
  changeLoadingCommissionParticipants,
  errorFetchingCommissionParticipants,
  saveEscrowDetails,
  saveEscrowDepositDetails,
  saveCurrentTransactionDetails,
  saveTransactionPermissions,
  savePagedTransaction,
  savePagedListingTransaction,
  saveHasPaymentInvoices,
  saveTransactionOutgoingPayments,
  saveSentimentDetails,
  saveBrokerSentimentDetails,
  toggleClosedModal,
  saveMutedPublisherStatus,
  saveTransactionsCountByLifecycleGroup,
  saveListingCountByLifecycleGroup,
  saveTransactionFeatures,
  changeLoadingTitleProgress,
  saveTitleProgress,
  saveTokenTransactionDetail,
  saveDoubleEnderTransaction,
  saveDoubleEnderAgent,
  saveCommentsMentionUsers,
  saveTransactionPaginatedResultsByIdWithFilters,
  setRecordingDataById,
  saveCommentTemplates,
} = TransactionSlice.actions;

export const fetchTransactionDetails = (
  id: string,
  changeLoading: boolean = true,
): AppThunk => async (dispatch) => {
  const fetch = () =>
    new TransactionControllerApi(getArrakisConfiguration()).getTransaction(id);
  performAsyncRequest(
    dispatch as AppDispatch,
    'transactionDetail',
    saveTransactionDetailResponse,
    fetch,
    { changeLoading, skipAuthDatadog: true, ignoreStatusCodesDatadog: [404] },
  );
};

export const addAttachedFee = (
  transactionId: string,
  attachedFeeData: AttachedFeeRequest,
): AppThunk => async (dispatch) => {
  try {
    const { data } = await new TransactionControllerApi(
      getArrakisConfiguration(),
    ).createAttachedFee(transactionId, attachedFeeData);
    dispatch(
      saveTransactionDetailResponse({
        name: 'transactionDetail',
        loading: false,
        data,
      }),
    );
    dispatch(
      showSuccessToast('Successfully created the additional fee / rebate.'),
    );
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to create additional fee / rebate', e, {
      escrow: { transactionId, attachedFeeData },
    });
    dispatch(
      showErrorToastForErrorCode(
        "We couldn't create that additional fee / rebate",
        ErrorService.getErrorCode(e),
      ),
    );
  }
};

export const updateAttachedFee = (
  attachedFeeId: string,
  transactionId: string,
  attachedFeeData: AttachedFeeRequest,
): AppThunk => async (dispatch) => {
  try {
    const { data } = await new TransactionControllerApi(
      getArrakisConfiguration(),
    ).updateAttachedFee(transactionId, attachedFeeId, attachedFeeData);
    dispatch(
      saveTransactionDetailResponse({
        name: 'transactionDetail',
        loading: false,
        data,
      }),
    );
    dispatch(
      showSuccessToast(
        'Successfully updated the additional fee / rebate details.',
      ),
    );
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify(
      'Unable to update the additional fee / rebate details',
      e,
      {
        escrow: { attachedFeeId, attachedFeeData },
      },
    );
    dispatch(
      showErrorToastForErrorCode(
        'We were unable to update the additional fee / rebate details.',
        ErrorService.getErrorCode(e),
      ),
    );
  }
};

export const deleteAttachedFee = (
  attachedFeeId: string,
  transactionId: string,
): AppThunk => async (dispatch) => {
  try {
    const { data } = await new TransactionControllerApi(
      getArrakisConfiguration(),
    ).deleteAttachedFee(transactionId, attachedFeeId);
    dispatch(
      saveTransactionDetailResponse({
        name: 'transactionDetail',
        loading: false,
        data,
      }),
    );
    dispatch(
      showSuccessToast('Successfully deleted the additional fee / rebate.'),
    );
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify(
      'Unable to delete the additional fee / rebate details',
      e,
      {
        escrow: { attachedFeeId, transactionId },
      },
    );
    dispatch(
      showErrorToastForErrorCode(
        'We were unable to delete the additional fee / rebate details.',
        ErrorService.getErrorCode(e),
      ),
    );
  }
};

export const createEscrow = (
  transactionId: string,
  escrowDetails: EscrowRequest,
): AppThunk => async (dispatch) => {
  dispatch(changeLoadingTransactionDetail(true));
  try {
    const { data } = await new TransactionControllerApi(
      getArrakisConfiguration(),
    ).createEscrow(transactionId, escrowDetails);
    dispatch(saveEscrowDetails(data));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notifyIgnoreAuthErrors('Unable to create escrow', e, {
      escrow: { transactionId, escrowDetails },
    });
    dispatch(
      showErrorToastForErrorCode(
        "We couldn't create that escrow payment",
        ErrorService.getErrorCode(e),
      ),
    );
  } finally {
    dispatch(changeLoadingTransactionDetail(false));
  }
};

export const updateEscrowDetails = (
  escrowDetails: EscrowRequest,
  escrowId: string,
): AppThunk => async (dispatch) => {
  dispatch(changeLoadingTransactionDetail(true));
  try {
    const { data } = await new TransactionControllerApi(
      getArrakisConfiguration(),
    ).updateEscrow(escrowId, escrowDetails);
    dispatch(saveEscrowDetails(data));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to update escrow details', e, {
      escrow: { escrowId, escrowDetails },
    });
    dispatch(
      showErrorToastForErrorCode(
        'We were unable to update the escrow details.',
        ErrorService.getErrorCode(e),
      ),
    );
  } finally {
    dispatch(changeLoadingTransactionDetail(false));
  }
};

export const addDepositPayment = (
  transactionId: string,
  escrowId: string,
  depositPayment: EscrowDepositRequest,
  receipt?: File,
): AppThunk<Promise<boolean>> => async (dispatch) => {
  try {
    const { data } = await new TransactionControllerApi(
      getArrakisConfiguration(),
    ).receivedEscrowDeposit(escrowId, depositPayment);
    dispatch(saveEscrowDetails(data));

    if (receipt) {
      const depositId = last(data?.deposits)?.id!;

      await dispatch(
        uploadDepositInstallmentReceipt(
          transactionId,
          escrowId,
          depositId,
          receipt,
        ),
      );
    }
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to add the escrow deposit payment', e, {
      escrow: { id: escrowId },
    });
    dispatch(
      showErrorToastForErrorCode(
        'We were unable to add the escrow deposit payment.',
        ErrorService.getErrorCode(e),
      ),
    );
    return false;
  }
  return true;
};

export const updateDepositPayment = (
  transactionId: string,
  escrowId: string,
  depositId: string,
  depositPayment: EscrowDepositRequest,
  receipt?: File,
): AppThunk<Promise<boolean>> => async (dispatch) => {
  try {
    const { data } = await new TransactionControllerApi(
      getArrakisConfiguration(),
    ).updateEscrowDeposit(depositId, depositPayment);

    dispatch(saveEscrowDetails(data));

    if (receipt) {
      await dispatch(
        uploadDepositInstallmentReceipt(
          transactionId,
          escrowId,
          depositId,
          receipt,
        ),
      );
    }
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to update the escrow deposit payment', e, {
      escrow: { id: escrowId, deposit: { id: depositId } },
    });
    dispatch(
      showErrorToastForErrorCode(
        'We were unable to update the escrow deposit payment.',
        ErrorService.getErrorCode(e),
      ),
    );
    return false;
  }
  return true;
};

export const confirmDepositPayment = (
  escrowId: string,
  escrowDepositId: string,
): AppThunk => async (dispatch) => {
  dispatch(changeLoadingTransactionDetail(true));
  try {
    const { data } = await new TransactionControllerApi(
      getArrakisConfiguration(),
    ).confirmEscrowDeposit('escrowId', escrowDepositId);
    dispatch(saveEscrowDepositDetails({ escrowId: escrowId, deposit: data }));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to confirm the escrow deposit payment', e, {
      escrowDeposit: { id: escrowDepositId },
    });
    dispatch(
      showErrorToastForErrorCode(
        'We were unable to confirm the escrow deposit payment.',
        ErrorService.getErrorCode(e),
      ),
    );
  } finally {
    dispatch(changeLoadingTransactionDetail(false));
  }
};

export const disburseEscrow = (id: string): AppThunk => async (dispatch) => {
  dispatch(changeLoadingTransactionDetail(true));
  try {
    const { data } = await new TransactionControllerApi(
      getArrakisConfiguration(),
    ).disburseEscrow(id);
    dispatch(
      saveTransactionDetailResponse({
        name: 'transactionDetail',
        loading: false,
        data,
      }),
    );
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to fetch disburse escrow', e, {
      transaction: { id },
    });
    dispatch(
      showErrorToastForErrorCode(
        'We were unable to disburse the escrow payments',
        ErrorService.getErrorCode(e),
      ),
    );
  } finally {
    dispatch(changeLoadingTransactionDetail(false));
  }
};

export const addCommissionParticipant = (
  id: string,
  participant: CreateParticipantRequest,
): AppThunk<Promise<ParticipantResponse | undefined | void>> => async (
  dispatch,
) => {
  try {
    const { data } = await new TransactionControllerApi(
      getArrakisConfiguration(),
    ).createParticipant(id, participant);
    await dispatch(fetchTransactionDetails(id, false));
    dispatch(showSuccessToast('Participant added successfully.'));
    return data;
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notifyIgnoreHandled('Unable to add participant', e, {
      transaction: { id },
    });
    dispatch(
      showErrorToastForErrorCode(
        'We were unable to update add the participant',
        ErrorService.getErrorCode(e),
      ),
    );
    return undefined;
  }
};

export const addOPCityCommissionParticipant = (id: string): AppThunk => async (
  dispatch,
) => {
  try {
    const { data } = await new TransactionControllerApi(
      getArrakisConfiguration(),
    ).addOpcityParticipant(id);
    dispatch(
      saveTransactionDetailResponse({
        name: 'transactionDetail',
        loading: false,
        data,
      }),
    );
    dispatch(showSuccessToast('Participant added successfully.'));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to add opcity participant', e, {
      transaction: { id },
    });
    dispatch(
      showErrorToastForErrorCode(
        'We were unable to add the participant',
        ErrorService.getErrorCode(e),
      ),
    );
  }
};

export const removeCommissionParticipant = (
  transactionId: string,
  participantId: string,
): AppThunk => async (dispatch) => {
  try {
    const { data } = await new TransactionControllerApi(
      getArrakisConfiguration(),
    ).removeParticipant(transactionId, participantId);
    dispatch(
      saveTransactionDetailResponse({
        name: 'transactionDetail',
        loading: false,
        data,
      }),
    );
    dispatch(showSuccessToast('Participant removed successfully.'));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to remove the commission participant', e, {
      transaction: { id: transactionId },
      participant: { id: participantId },
    });
    dispatch(
      showErrorToastForErrorCode(
        'We were unable to remove the commission participant',
        ErrorService.getErrorCode(e),
      ),
    );
  }
};

export const updateCommissionPayouts = (
  id: string,
  payoutsData: SetPaymentsRequest,
): AppThunk => async (dispatch) => {
  try {
    const { data } = await new TransactionControllerApi(
      getArrakisConfiguration(),
    ).setPayouts(id, payoutsData);
    dispatch(
      saveTransactionDetailResponse({
        name: 'transactionDetail',
        loading: false,
        data,
      }),
    );
    dispatch(showSuccessToast('Commissions updated successfully.'));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notifyIgnoreAuthErrors(
      'Unable to update commission payouts',
      e,
      {
        transaction: { id },
      },
    );
    dispatch(
      showErrorToastForErrorCode(
        'We were unable to update commission payouts',
        ErrorService.getErrorCode(e),
      ),
    );
  }
};

export const transitionTransaction = (
  transaction: TransactionResponse,
  nextState: TransactionLifecycleStateValueStateEnum,
  approvedForClosingRequest: ApproveForClosingRequest | undefined = undefined,
  // forcing the double ender flag value to be required
  flags: { [FeatureFlagTypeEnum.DOUBLE_ENDER]: boolean },
): AppThunk<Promise<void>> => async (dispatch) => {
  const id = transaction.id!;
  const isDoubleEnder =
    flags[FeatureFlagTypeEnum.DOUBLE_ENDER] &&
    !isEmpty(transaction.doubleEnderInfo);

  dispatch(changeLoadingTransactionDetail(true));
  try {
    switch (nextState) {
      // case TransactionResponseNextAdminTransitionsEnum.CancelApproved:
      // case TransactionResponseNextUserTransitionsEnum.CancelApproved:
      //   {
      //     const { data } = await new TransactionControllerApi(
      //       getArrakisConfiguration(),
      //     ).transitionToCancelApprovedUsingPUT(id);
      //     dispatch(saveTransactionDetail(data));
      //   }
      //   break;
      // case TransactionResponseNextAdminTransitionsEnum.CancelPending:
      // case TransactionResponseNextUserTransitionsEnum.CancelPending:
      //   {
      //     const { data } = await new TransactionControllerApi(
      //       getArrakisConfiguration(),
      //     ).transitionToCancelPendingUsingPUT(id);
      //     dispatch(saveTransactionDetail(data));
      //   }
      //   break;
      case TransactionLifecycleStateValueStateEnum.CommissionDocumentApproved:
        {
          const { data } = await new TransactionControllerApi(
            getArrakisConfiguration(),
          ).transitionToCommissionDocumentApproved(id);
          dispatch(
            saveTransactionDetailResponse({
              name: 'transactionDetail',
              loading: false,
              data,
            }),
          );
        }
        break;
      case TransactionLifecycleStateValueStateEnum.ApprovedForClosing:
        {
          const { data } = await new TransactionControllerApi(
            getArrakisConfiguration(),
          ).transitionToApprovedForClosing(id, approvedForClosingRequest!);
          if (isDoubleEnder) {
            AnalyticsService.instance().logEvent(
              AnalyticsEventEnum.DOUBLE_ENDER_APPROVED_FOR_CLOSING_TRANSACTION,
              {
                transactionId: transaction.id,
                linkedTransactionId: transaction.doubleEnderInfo?.otherTxId,
              },
            );
          }
          dispatch(
            saveTransactionDetailResponse({
              name: 'transactionDetail',
              loading: false,
              data,
            }),
          );
        }
        break;
      // case TransactionResponseNextAdminTransitionsEnum.CommissionDocumentGenerated:
      // case TransactionResponseNextUserTransitionsEnum.CommissionDocumentGenerated:
      //   {
      //     const { data } = await new TransactionControllerApi(
      //       getArrakisConfiguration(),
      //     ).transitionToCancelApprovedUsingPUT(id);
      //     dispatch(saveTransactionDetail(data));
      //   }
      //   break;
      // case TransactionResponseNextAdminTransitionsEnum.CommissionDocumentSent:
      // case TransactionResponseNextUserTransitionsEnum.CommissionDocumentSent:
      //   {
      //     const { data } = await new TransactionControllerApi(
      //       getArrakisConfiguration(),
      //     ).transitionToCancelApprovedUsingPUT(id);
      //     dispatch(saveTransactionDetail(data));
      //   }
      //   break;
      case TransactionLifecycleStateValueStateEnum.Closed:
        {
          const { data } = await new TransactionControllerApi(
            getArrakisConfiguration(),
          ).transitionToClosed(id);
          if (isDoubleEnder) {
            AnalyticsService.instance().logEvent(
              AnalyticsEventEnum.DOUBLE_ENDER_CLOSED_TRANSACTION,
              {
                transactionId: transaction.id,
                linkedTransactionId: transaction.doubleEnderInfo?.otherTxId,
              },
            );
          }
          dispatch(
            saveTransactionDetailResponse({
              name: 'transactionDetail',
              loading: false,
              data,
            }),
          );
        }
        break;
      case TransactionLifecycleStateValueStateEnum.CommissionValidated:
        {
          const { data } = await new TransactionControllerApi(
            getArrakisConfiguration(),
          ).transitionToCommissionValidated(id);
          dispatch(
            saveTransactionDetailResponse({
              name: 'transactionDetail',
              loading: false,
              data,
            }),
          );
        }
        break;
      case TransactionLifecycleStateValueStateEnum.New:
        {
          const { data } = await new TransactionControllerApi(
            getArrakisConfiguration(),
          ).transitionToNew(id);
          dispatch(
            saveTransactionDetailResponse({
              name: 'transactionDetail',
              loading: false,
              data,
            }),
          );
        }
        break;
      case TransactionLifecycleStateValueStateEnum.PaymentAccepted:
        {
          const { data } = await new TransactionControllerApi(
            getArrakisConfiguration(),
          ).transitionToPaymentAccepted(id);
          dispatch(
            saveTransactionDetailResponse({
              name: 'transactionDetail',
              loading: false,
              data,
            }),
          );
        }
        break;
      // case TransactionResponseNextAdminTransitionsEnum.PaymentScheduled:
      // case TransactionResponseNextUserTransitionsEnum.PaymentScheduled:
      //   {
      //     const { data } = await new TransactionControllerApi(
      //       getArrakisConfiguration(),
      //     ).transitionToCancelApprovedUsingPUT(id);
      //     dispatch(saveTransactionDetail(data));
      //   }
      //   break;
      case TransactionLifecycleStateValueStateEnum.ReadyForCommissionDocumentGeneration:
        {
          const { data } = await new TransactionControllerApi(
            getArrakisConfiguration(),
          ).transitionToReadyForCommissionDocumentGeneration(id);
          dispatch(
            saveTransactionDetailResponse({
              name: 'transactionDetail',
              loading: false,
              data,
            }),
          );
        }
        break;
      case TransactionLifecycleStateValueStateEnum.Terminated:
        {
          const { data } = await new TransactionControllerApi(
            getArrakisConfiguration(),
          ).transitionToTerminated(id);
          if (isDoubleEnder) {
            AnalyticsService.instance().logEvent(
              AnalyticsEventEnum.DOUBLE_ENDER_TERMINATE_TRANSACTION,
              {
                transactionId: transaction.id,
                linkedTransactionId: transaction.doubleEnderInfo?.otherTxId,
              },
            );
          }
          dispatch(
            saveTransactionDetailResponse({
              name: 'transactionDetail',
              loading: false,
              data,
            }),
          );
        }
        break;
      // case TransactionResponseNextAdminTransitionsEnum.Settled:
      // case TransactionResponseNextUserTransitionsEnum.Settled:
      //   {
      //     const { data } = await new TransactionControllerApi(
      //       getArrakisConfiguration(),
      //     ).transitionToCancelApprovedUsingPUT(id);
      //     dispatch(saveTransactionDetail(data));
      //   }
      //   break;
      // case TransactionResponseNextAdminTransitionsEnum.WaitingOnPayment:
      // case TransactionResponseNextUserTransitionsEnum.WaitingOnPayment:
      //   {
      //     const { data } = await new TransactionControllerApi(
      //       getArrakisConfiguration(),
      //     ).transitionToCancelApprovedUsingPUT(id);
      //     dispatch(saveTransactionDetail(data));
      //   }
      //   break;
    }
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notifyIgnoreAuthErrors(
      `Unable to transition the transaction to ${getTransactionTransitionToReadableName(
        transaction,
        nextState,
      )}`,
      e,
      {
        transaction: { id, nextState },
      },
    );
    dispatch(
      showErrorToastForErrorCode(
        `We were unable to transition the transaction to ${getTransactionTransitionToReadableName(
          transaction,
          nextState,
        )}`,
        ErrorService.getErrorCode(e),
      ),
    );
    // if transition fails, refetch the transaction
    dispatch(fetchTransactionDetails(id, false));
  } finally {
    dispatch(changeLoadingTransactionDetail(false));
  }
};

export const markOutgoingPaymentInvalid = (
  outgoingPaymentId: string,
): AppThunk<Promise<boolean>> => async (dispatch) => {
  try {
    await new OutgoingPaymentsControllerApi(
      getArrakisConfiguration(),
    ).markOutgoingPaymentInvalidPaymentDetails(outgoingPaymentId);
    dispatch(
      showSuccessToast(
        'Successfully notified about the payment method failure',
      ),
    );
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notifyIgnoreAuthErrors(
      'unable to mark outgoing payment details as invalid',
      e,
      {
        outgoingPaymentId: { id: outgoingPaymentId },
      },
    );
    dispatch(
      showErrorToastForErrorCode(
        'We were unable to mark outgoing payment details as invalid',
        ErrorService.getErrorCode(e),
      ),
    );
    return false;
  }
  return true;
};

export const removeTransactionParticipant = (
  transactionId: string,
  participantId: string,
): AppThunk<Promise<boolean>> => async (dispatch) => {
  try {
    const { data } = await new TransactionControllerApi(
      getArrakisConfiguration(),
    ).removeParticipant(transactionId, participantId);
    dispatch(
      saveTransactionDetailResponse({
        name: 'transactionDetail',
        loading: false,
        data,
      }),
    );
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notifyIgnoreAuthErrors(
      'Unable to remove the transaction participant',
      e,
      {
        transaction: { id: transactionId },
        participant: { id: participantId },
      },
    );
    dispatch(
      showErrorToastForErrorCode(
        'We were unable to remove the transaction participant',
        ErrorService.getErrorCode(e),
      ),
    );
    return false;
  }
  return true;
};

export const updateTransactionParticipant = (
  participantId: string,
  transactionId: string,
  values: UpdateParticipantRequest,
): AppThunk<Promise<boolean>> => async (dispatch) => {
  try {
    const { data } = await new TransactionControllerApi(
      getArrakisConfiguration(),
    ).updateParticipant(transactionId, participantId, values);
    dispatch(
      saveTransactionDetailResponse({
        name: 'transactionDetail',
        loading: false,
        data,
      }),
    );
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notifyIgnoreHandled('Error updating the participant', e, {
      transaction: { id: transactionId },
      participant: { yentaId: participantId, values },
    });
    dispatch(
      showErrorToastForErrorCode(
        'We were unable to update the participant',
        ErrorService.getErrorCode(e),
      ),
    );
    return false;
  }
  return true;
};

export const updateTransactionParticipantLite = (
  participantId: string,
  transactionId: string,
  values: UpdateParticipantLiteRequest,
): AppThunk<Promise<boolean>> => async (dispatch) => {
  try {
    const { data } = await new TransactionControllerApi(
      getArrakisConfiguration(),
    ).updateParticipantLite(transactionId, participantId, values);
    dispatch(
      saveTransactionDetailResponse({
        name: 'transactionDetail',
        loading: false,
        data,
      }),
    );
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notifyIgnoreHandled('Error updating the participant', e, {
      transaction: { id: transactionId },
      participant: { yentaId: participantId, values },
    });
    dispatch(
      showErrorToastForErrorCode(
        'We were unable to update the participant',
        ErrorService.getErrorCode(e),
      ),
    );
    return false;
  }
  return true;
};

export const getProcessTransaction = (
  transactionId: string,
): AppThunk => async (dispatch) => {
  const fetch = () =>
    new TransactionControllerApi(getArrakisConfiguration()).processTransaction(
      transactionId,
    );
  performAsyncRequest(
    dispatch as AppDispatch,
    'transactionDetail',
    saveTransactionDetailResponse,
    fetch,
    { ignoreHandledDatadog: true },
  );
};

export const uploadW9Form = (
  participantId: string,
  w9form: any,
): AppThunk => async (dispatch) => {
  try {
    await new TransactionControllerApi(getArrakisConfiguration()).uploadW9(
      participantId,
      w9form,
    );
  } catch (e) {
    ErrorService.notify('Unable to upload W9 form', e, {
      participant: { id: participantId },
    });
    dispatch(
      showErrorToastForErrorCode(
        'We were unable to upload W9 form',
        ErrorService.getErrorCode(e),
      ),
    );
  }
};

export const updateTransactionVerified = (
  transactionId: string,
  values: UpdateTransactionVerifiedRequest,
): AppThunk => async (dispatch) => {
  try {
    const { data } = await new TransactionControllerApi(
      getArrakisConfiguration(),
    ).updateTransactionVerified(transactionId, values);
    dispatch(
      saveTransactionDetailResponse({
        name: 'transactionDetail',
        loading: false,
        data,
      }),
    );
    dispatch(showSuccessToast('Transaction verified updated successfully.'));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Error updating the transaction verified', e, {
      transaction: { transactionId },
    });
    dispatch(
      showErrorToastForErrorCode(
        'We were unable to update the transaction verified',
        ErrorService.getErrorCode(e),
      ),
    );
  }
};
export const recalculateCommission = (
  transactionId: string,
): AppThunk => async (dispatch) => {
  dispatch(changeLoadingTransactionDetail(true));
  try {
    const { data } = await new TransactionControllerApi(
      getArrakisConfiguration(),
    ).recalculateCommission(transactionId);
    dispatch(
      saveTransactionDetailResponse({
        name: 'transactionDetail',
        loading: false,
        data,
      }),
    );
    dispatch(showSuccessToast('Commission recalculated successfully.'));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('unable to recalculate commission', e, {
      transaction: { transactionId },
    });
    dispatch(
      showErrorToastForErrorCode(
        'We encountered an error while recalculating the commission. Please try again in a few moments.',
        ErrorService.getErrorCode(e),
      ),
    );
  } finally {
    dispatch(changeLoadingTransactionDetail(false));
  }
};

export const getTransactionPermission = (
  transactionId: string,
): AppThunk => async (dispatch) => {
  try {
    const { data } = await new TransactionControllerApi(
      getArrakisConfiguration(),
    ).getPermissions(transactionId);
    dispatch(saveTransactionPermissions(data));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notifyIgnoreHandled(
      'unable to get transaction permissions',
      e,
      {
        transaction: { transactionId },
      },
    );
    dispatch(
      showErrorToastForErrorCode(
        'We encountered an error while fetching the transactions permissions. Please try again in a few moments.',
        ErrorService.getErrorCode(e),
      ),
    );
  }
};

export const updateInstantPaymentEligibility = (
  transactionId: string,
  participantId: string,
  req: UpdateParticipantInstantPaymentEligibilityRequest,
): AppThunk<Promise<boolean>> => async (dispatch) => {
  try {
    const { data } = await new TransactionControllerApi(
      getArrakisConfiguration(),
    ).updateParticipantInstantPaymentEligibility(
      transactionId,
      participantId,
      req,
    );
    dispatch(
      saveTransactionDetailResponse({
        name: 'transactionDetail',
        loading: false,
        data,
      }),
    );
    dispatch(
      showSuccessToast('Instant payment eligibility updated successfully.'),
    );
    return true;
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify(
      "unable to update participant's instant payment eligibility",
      e,
      {
        transaction: { transactionId, participantId, req },
      },
    );
    dispatch(
      showErrorToastForErrorCode(
        "We encountered an error while setting the participant's instant payment eligibility.",
        ErrorService.getErrorCode(e),
      ),
    );
    return false;
  }
};

export const markAsCompliant = (
  id: string,
  saveTransactionDetail: boolean = true,
): AppThunk => async (dispatch) => {
  try {
    const { data } = await new TransactionControllerApi(
      getArrakisConfiguration(),
    ).setTransactionCompliant(id);
    if (saveTransactionDetail) {
      dispatch(
        saveTransactionDetailResponse({
          name: 'transactionDetail',
          loading: false,
          data,
        }),
      );
    }
    dispatch(showSuccessToast('Transaction marked as compliant'));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to mark transaction as compliant', e, {
      transaction: { id },
    });
    dispatch(
      showErrorToastForErrorCode(
        'We encountered an error while marking the transaction as compliant. Please try again in a few moments.',
        ErrorService.getErrorCode(e),
      ),
    );
  }
};

export const markAsNonCompliant = (
  id: string,
  saveTransactionDetail: boolean = true,
): AppThunk => async (dispatch) => {
  try {
    const { data } = await new TransactionControllerApi(
      getArrakisConfiguration(),
    ).setTransactionNonCompliant(id);
    if (saveTransactionDetail) {
      dispatch(
        saveTransactionDetailResponse({
          name: 'transactionDetail',
          loading: false,
          data,
        }),
      );
    }
    dispatch(showSuccessToast('Transaction marked as non-compliant'));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to mark transaction as non-compliant', e, {
      transaction: { id },
    });
    dispatch(
      showErrorToastForErrorCode(
        'We encountered an error while marking the transaction as non-compliant. Please try again in a few moments.',
        ErrorService.getErrorCode(e),
      ),
    );
  }
};

export const updateTaxExempt = (
  id: string,
  req: UpdateTransactionTaxExemptStatusRequest,
): AppThunk => async (dispatch) => {
  try {
    const { data } = await new TransactionControllerApi(
      getArrakisConfiguration(),
    ).updateTaxExemptStatus(id, req);
    dispatch(
      saveTransactionDetailResponse({
        name: 'transactionDetail',
        loading: false,
        data,
      }),
    );
    dispatch(showSuccessToast('Updated Tax Exempt Status'));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to update tax exempt status', e, {
      taxExempt: { id, req },
    });
    dispatch(
      showErrorToastForErrorCode(
        'We encountered an error while updating tax exempt status. Please try again in a few moments.',
        ErrorService.getErrorCode(e),
      ),
    );
  }
};

export const toggleTaxWithheld = (
  transactionId: string,
  participantId: string,
  updatePaymentParticipantTaxWithheldStatusRequest: UpdatePaymentParticipantTaxWithheldStatusRequest,
): AppThunk => async (dispatch) => {
  try {
    const { data } = await new TransactionControllerApi(
      getArrakisConfiguration(),
    ).updatePaymentParticipantTaxWithheldStatus(
      transactionId,
      participantId,
      updatePaymentParticipantTaxWithheldStatusRequest,
    );
    dispatch(
      saveTransactionDetailResponse({
        name: 'transactionDetail',
        loading: false,
        data,
      }),
    );
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to toggle tax withheld status', e, {});
  }
};

export const fetchTransactionsCountGroupByLifecycleState = (
  yentaId: string,
  type: 'TRANSACTION' | 'LISTING' = 'TRANSACTION',
): AppThunk<Promise<void>> => async (dispatch) => {
  try {
    const apiMethod =
      type === 'LISTING'
        ? 'getListingCountsByLifecycleGroup'
        : 'getTransactionCountsByLifecycleGroup';
    const response = await new TransactionControllerApi(
      getArrakisConfiguration(),
    )[apiMethod](yentaId);

    const updatedTransactionsCountByLifecycleGroup = {
      OPEN: response.data.countsByLifecycleGroup?.OPEN ?? 0,
      CLOSED: response.data.countsByLifecycleGroup?.CLOSED ?? 0,
      TERMINATED: response.data.countsByLifecycleGroup?.TERMINATED ?? 0,
      DRAFT: response.data.draftCount ?? 0,
    };

    if (type === 'TRANSACTION') {
      dispatch(
        saveTransactionsCountByLifecycleGroup(
          updatedTransactionsCountByLifecycleGroup,
        ),
      );
    } else {
      dispatch(
        saveListingCountByLifecycleGroup(
          updatedTransactionsCountByLifecycleGroup,
        ),
      );
    }
  } catch (error) {
    ErrorService.notifyIgnoreAuthErrors(
      `Error fetching ${capitalizeEnum(type)}`,
      error,
    );
  }
};

export const deleteDraft = (
  id: string,
  agentId: string,
  type: 'TRANSACTION' | 'LISTING' = 'TRANSACTION',
): AppThunk<Promise<void>> => async (dispatch) => {
  try {
    await new TransactionBuilderControllerApi(
      getArrakisConfiguration(),
    ).deleteTransactionBuilder(id);
    dispatch(
      showSuccessToast(
        `Removed draft ${type.toLocaleLowerCase()} successfully.`,
      ),
    );

    dispatch(fetchTransactionsCountGroupByLifecycleState(agentId, type));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify(
      `Unable to remove the draft ${type.toLocaleLowerCase()}`,
      e,
      {
        builderId: { id },
      },
    );
    dispatch(
      showErrorToast(
        `We had a problem removing the draft ${type.toLocaleLowerCase()}`,
        'Please try again in a few moments.',
      ),
    );
  }
};

export const fetchTransactionPaymentInvoices = (
  transaction: TransactionResponse,
): AppThunk<Promise<void>> => async (dispatch) => {
  try {
    const { data } = await getInvoiceTableFetchData({
      page: 0,
      pageSize: 20,
      filter: {
        transactionCode: transaction.code as string,
      },
    });
    dispatch(saveHasPaymentInvoices(!!data?.length));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to fetch the payment invoices', e, {
      transaction: { id: transaction.id, code: transaction.code },
    });
    dispatch(
      showErrorToastForErrorCode(
        'We encountered an error while fetching the payment invoices. Please try again in a few moments.',
        ErrorService.getErrorCode(e),
      ),
    );
  }
};

export const fetchTransactionOutgoingPayments = (
  transactionId: string,
): AppThunk => async (dispatch) => {
  try {
    const { data } = await new OutgoingPaymentsControllerApi(
      getArrakisConfiguration(),
    ).getOutgoingPaymentsForTransaction(transactionId);
    dispatch(saveTransactionOutgoingPayments(data.payments!));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to fetching transaction outgoing payments', e, {
      transaction: { id: transactionId },
    });
    dispatch(errorFetchingTransactionDetail(ErrorService.getErrorCode(e)));
    dispatch(
      showErrorToast(
        'We had a problem fetching transaction transaction.',
        'Please try again in a few moments.',
      ),
    );
  }
};
export const undoClosingTransaction = (
  transactionId: string,
): AppThunk => async (dispatch) => {
  try {
    await new TransactionControllerApi(getArrakisConfiguration()).revertClosing(
      transactionId,
    );
    await dispatch(fetchTransactionDetails(transactionId));
    dispatch(showSuccessToast('Successfully reverted closing'));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to revert transaction', e, {
      transaction: { id: transactionId },
    });
    dispatch(
      showErrorToastForErrorCode(
        "We couldn't revert the transaction",
        ErrorService.getErrorCode(e),
      ),
    );
  }
};

export const approveZeroCommissionDealForTransaction = (
  transactionId: string,
): AppThunk => async (dispatch) => {
  try {
    await new TransactionControllerApi(
      getArrakisConfiguration(),
    ).approveZeroCommissionDeal(transactionId);
    await dispatch(fetchTransactionDetails(transactionId));
    dispatch(
      showSuccessToast(
        'Successfully approved zero commission deal for this transaction',
      ),
    );
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify(
      'Unable to approved zero commission deal for this transaction',
      e,
      {
        transaction: { id: transactionId },
      },
    );
    dispatch(
      showErrorToastForErrorCode(
        "We couldn't approved zero commission deal for this transaction",
        ErrorService.getErrorCode(e),
      ),
    );
  }
};

export const undoTerminationRequestTransaction = (
  transactionId: string,
  isListing: boolean = false,
): AppThunk => async (dispatch) => {
  const title = isListing ? 'listing' : 'transaction';
  try {
    await new TransactionControllerApi(
      getArrakisConfiguration(),
    ).undoTerminationRequest(transactionId);
    await dispatch(fetchTransactionDetails(transactionId));
    dispatch(
      showSuccessToast(`Termination requested ${title} reverted successfully`),
    );
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify(`Unable to revert termination requested ${title}`, e, {
      transaction: { id: transactionId },
    });
    dispatch(
      showErrorToastForErrorCode(
        `We encountered an error while reverting termination requested ${title}. Please try again in a few moments.`,
        ErrorService.getErrorCode(e),
      ),
    );
  }
};

export const getSentimentDisplayStatus = (
  req: SentimentDisplayRequest,
  isBroker: boolean = false,
): AppThunk<Promise<SentimentDisplayResponse | undefined>> => async (
  dispatch,
) => {
  try {
    const { data } = await new SentimentControllerApi(
      getArrakisConfiguration(),
    ).displaySentiment(req);
    if (isBroker) {
      dispatch(saveBrokerSentimentDetails(data));
    } else {
      dispatch(saveSentimentDetails(data));
    }

    return data;
  } catch (e) {
    ErrorService.notifyIgnoreAuthErrors(
      'Unable to get sentiment display status for current context',
      e,
      {
        context: { req },
      },
    );
    return undefined;
  }
};

export const createNewSentimentForCurrentContext = (
  agentId: string,
  req: CreateSentimentRequest,
  isBroker: boolean = false,
): AppThunk => async (dispatch) => {
  try {
    await new SentimentControllerApi(getArrakisConfiguration()).createSentiment(
      agentId,
      req,
    );
    if (isBroker) {
      dispatch(saveBrokerSentimentDetails(undefined));
    } else {
      dispatch(saveSentimentDetails(undefined));
    }

    if (
      req.satisfactionRate !==
      CreateSentimentRequestSatisfactionRateEnum.NotApplicable
    ) {
      dispatch(showSuccessToast('Thanks for your feedback!'));
    }
  } catch (e) {
    dispatch(
      showErrorToast(
        'We could not save your feedback. Please try again in a few moments.',
      ),
    );
    ErrorService.notify('Unable to record happy project feedback', e, {
      agent: { agentId, req },
      sentiment: { ...req },
    });
  }
};

export const getNotificationStatus = (
  realUsername: string,
  publisherType: PublisherTypeEnum,
  publisherId: string,
): AppThunk => async (dispatch) => {
  try {
    const { data } = await new UserControllerApi(
      getHermesConfiguration(),
    ).getPublisherNotificationPreferences(
      realUsername,
      publisherType as any,
      publisherId,
    );
    dispatch(saveMutedPublisherStatus(data));
  } catch (e) {
    dispatch(
      showErrorToast(
        'We could not get your notification setting. Please try again in a few moments.',
      ),
    );
    ErrorService.notify('Unable to fetch users notification setting', e, {
      agent: { realUsername, publisherType, publisherId },
    });
  }
};

export const updateNotificationStatus = (
  realUsername: string,
  mutePublisher: UpdatePublisherNotificationPreferencesDto,
): AppThunk => async (dispatch) => {
  try {
    await new UserControllerApi(getHermesConfiguration()).mutePublisherChannel(
      realUsername,
      mutePublisher,
    );
  } catch (e) {
    dispatch(
      showErrorToast(
        'We could not update the notification setting. Please try again in a few moments.',
      ),
    );
    ErrorService.notify(
      'Unable to update transactions notification setting',
      e,
      {
        req: { realUsername, mutePublisher },
      },
    );
  }
};

export const updatedEstimatedClosingDate = (
  transaction: TransactionResponse,
  estimatedClosingDate: string,
): AppThunk => async (dispatch) => {
  const updateDealInfoReq = getDealInfoWithUpdatedEstimatedClosingDate(
    transaction,
    estimatedClosingDate,
  );

  try {
    const { data } = await new TransactionControllerApi(
      getArrakisConfiguration(),
    ).updateDealInfo(transaction.id!, updateDealInfoReq);
    dispatch(
      saveTransactionDetailResponse({
        name: 'transactionDetail',
        loading: false,
        data,
      }),
    );
  } catch (e) {
    dispatch(
      showErrorToast(
        'We could not update the estimated closing date. Please try again in a few moments.',
      ),
    );
    ErrorService.notify('Unable to update estimated closing date', e, {
      transaction,
      updateDealInfoReq,
    });
  }
};

export const getTransactionFeatures = (
  transactionId: string,
): AppThunk => async (dispatch) => {
  try {
    const { data } = await new TransactionControllerApi(
      getArrakisConfiguration(),
    ).getFeatures(transactionId);
    dispatch(saveTransactionFeatures(data));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notifyIgnoreHandled(
      'Unable to get transaction optional features',
      e,
      {
        transaction: { transactionId },
      },
    );
    dispatch(
      showErrorToastForErrorCode(
        'We encountered an error while getting optional features. Please try again in a few moments.',
        ErrorService.getErrorCode(e),
      ),
    );
  }
};

export const fetchTitleProgress = (
  transactionId: string,
  loading: boolean = true,
): AppThunk<Promise<void>> => async (dispatch) => {
  if (loading) {
    dispatch(changeLoadingTitleProgress(true));
  }
  try {
    const { data } = await new TransactionControllerApi(
      getArrakisConfiguration(),
    ).getTitleProgress(transactionId);
    dispatch(saveTitleProgress(data));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    dispatch(
      showErrorToast(
        'We could not fetch the title progress information. Please try again in a few moments.',
      ),
    );
    ErrorService.notify('Unable to fetch the title progress information', e, {
      transaction: { id: transactionId },
    });
  } finally {
    if (loading) {
      dispatch(changeLoadingTitleProgress(false));
    }
  }
};

export const uploadDepositInstallmentReceipt = (
  transactionId: string,
  escrowId: string,
  escrowDepositId: string,
  receipt: File,
): AppThunk<Promise<void>> => async (dispatch) => {
  try {
    await new TransactionControllerApi(
      getArrakisConfiguration(),
    ).uploadInstallmentReceipt(
      transactionId,
      escrowId,
      escrowDepositId,
      receipt,
    );

    const { data } = await new TransactionControllerApi(
      getArrakisConfiguration(),
    ).getTransaction(transactionId);

    dispatch(
      saveTransactionDetailResponse({
        name: 'transactionDetail',
        loading: false,
        data,
      }),
    );
  } catch (e) {
    dispatch(showApiErrorModal(e));
    dispatch(
      showErrorToast(
        'We could not upload the installment receipt. Please try again in a few moments.',
      ),
    );
    ErrorService.notify('Unable to upload the installment receipt', e, {
      transaction: { id: transactionId, escrowId, escrowDepositId },
    });
  }
};

export const getDepositInstallmentReceipt = (
  transactionId: string,
  escrowId: string,
  escrowDepositId: string,
): AppThunk<Promise<string | undefined>> => async (dispatch) => {
  try {
    const { data } = await new TransactionControllerApi(
      getArrakisConfiguration(),
    ).getInstallmentReceipt(transactionId, escrowId, escrowDepositId);
    return data;
  } catch (e) {
    dispatch(showApiErrorModal(e));
    dispatch(
      showErrorToast(
        'We could not get the installment receipt. Please try again in a few moments.',
      ),
    );
    ErrorService.notify('Unable to get the installment receipt', e, {
      transaction: { id: transactionId, escrowId, escrowDepositId },
    });
    return undefined;
  }
};

export const getTransactionByToken = (id: string): AppThunk => async (
  dispatch,
) => {
  dispatch(changeLoadingTransactionDetail(true));

  try {
    const { data } = await new ExternalCheckDepositsControllerApi(
      getArrakisConfiguration(),
    ).getCheckDepositToken(id);

    dispatch(saveTokenTransactionDetail(data));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to fetch transaction details', e, {
      transaction: { id },
    });
    dispatch(errorFetchingTransactionDetail(ErrorService.getErrorCode(e)));
  } finally {
    dispatch(changeLoadingTransactionDetail(false));
  }
};

export const addConfirmedCommissionDeposit = (
  transactionId: string,
  req: ConfirmedCommissionDepositRequest,
): AppThunk<Promise<void>> => async (dispatch) => {
  try {
    const { data } = await new TransactionControllerApi(
      getArrakisConfiguration(),
    ).createConfirmedCommissionDeposit(transactionId, req);
    dispatch(saveEscrowDetails(data));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    dispatch(
      showErrorToast(
        'We could not create confirmed commission deposit. Please try again in a few moments.',
      ),
    );
    ErrorService.notify('Unable to create confirmed commission deposit', e, {
      transaction: { id: transactionId, request: req },
    });
  }
};

export const updateListingStatus = (
  transactionId: string,
  nextStatus: TransactionLifecycleStateValueStateEnum,
): AppThunk<Promise<void>> => async (dispatch) => {
  try {
    const { data } = await new ListingControllerApi(
      getArrakisConfiguration(),
    ).transitionTransaction(transactionId, nextStatus);
    dispatch(
      saveTransactionDetailResponse({
        name: 'transactionDetail',
        loading: false,
        data,
      }),
    );
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Error updating listing status', e, {
      transaction: { transactionId, nextStatus },
    });
    dispatch(
      showErrorToast(
        'We had a problem updating listing status',
        'Please try again in a few moments.',
      ),
    );
  }
};

export const getTransactionByCode = (
  transactionCode: string,
): AppThunk<Promise<void>> => async (dispatch) => {
  const fetchInfo = () => {
    return new TransactionControllerApi(
      getArrakisConfiguration(),
    ).getTransactionByCode(transactionCode);
  };
  await performAsyncRequest(
    dispatch as AppDispatch,
    DOUBLE_ENDER_TRANSACTION_NAME,
    saveDoubleEnderTransaction,
    fetchInfo,
    { skipAuthDatadog: true },
  );
};

export const fetchDoubleEnderAgent = (
  agentId: string,
): AppThunk<Promise<AgentResponse | undefined>> => async (dispatch) => {
  const fetch = () =>
    new AgentControllerApi(getYentaConfiguration()).getAgentById(agentId);
  const res = await performAsyncRequest(
    dispatch as AppDispatch,
    'double ender agent',
    saveDoubleEnderAgent,
    fetch,
  );
  return res?.data;
};

export const linkDoubleEnderTransaction = (
  transactionId: string,
  req: DoubleEnderLinkRequest,
): AppThunk<Promise<DoubleEnderResponse | undefined>> => async (dispatch) => {
  try {
    const { data } = await new DoubleEnderControllerApi(
      getArrakisConfiguration(),
    ).linkDoubleEnderTransactions(transactionId, req);
    await dispatch(fetchTransactionDetails(transactionId, false));
    return data;
  } catch (e) {
    ErrorService.notify('Error linking the double ender transaction', e, {
      transaction: { id: transactionId, code: req.transactionCode },
    });
    dispatch(
      showErrorToast(
        ErrorService.getErrorMessage(e) ||
          'We had a problem linking the double ender transaction',
        ErrorService.getErrorMessage(e)
          ? 'Please check the transaction code and try again.'
          : 'Please try again in a few moments.',
      ),
    );
    return undefined;
  }
};

export const unlinkDoubleEnderTransaction = (
  doubleEnderTxId: string,
  transactionId: string,
  options: Options = { silent: false, refreshTx: false },
): AppThunk<Promise<boolean>> => async (dispatch) => {
  try {
    await new DoubleEnderControllerApi(
      getArrakisConfiguration(),
    ).unlinkDoubleEnderTransactions(doubleEnderTxId);
    if (options.refreshTx) {
      await dispatch(fetchTransactionDetails(transactionId, false));
    }
    dispatch(
      saveDoubleEnderTransaction({
        loading: false,
        name: DOUBLE_ENDER_TRANSACTION_NAME,
      }),
    );
    if (!options.silent) {
      dispatch(
        showSuccessToast('Successfully removed the double ender transaction.'),
      );
    }
    return true;
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Error unlinking the double ender transaction', e, {
      transaction: { id: transactionId },
    });
    dispatch(
      showErrorToast(
        'We had a problem unlinking the double ender transaction',
        'Please try again in a few moments.',
      ),
    );
    return false;
  }
};

export const addDoubleEnderAgent = (
  transactionId: string,
  agentId: string,
): AppThunk<Promise<boolean>> => async (dispatch) => {
  try {
    await new DoubleEnderControllerApi(
      getArrakisConfiguration(),
    ).addDoubleEnderAgent(transactionId, {
      agentId: agentId,
    });
    await dispatch(fetchTransactionDetails(transactionId, false));
    dispatch(showSuccessToast('Successfully added the double ender agent'));
    return true;
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Error adding the double ender agent', e, {
      data: {
        transaction: { id: transactionId },
        agent: { id: agentId },
      },
    });
    dispatch(
      showErrorToast(
        'We had a problem adding the double ender agent.',
        'Please try again in a few moments.',
      ),
    );
    return false;
  }
};

export const deleteDoubleEnderAgent = (
  transactionId: string,
): AppThunk<Promise<boolean>> => async (dispatch) => {
  try {
    await new DoubleEnderControllerApi(
      getArrakisConfiguration(),
    ).deleteDoubleEnderAgent(transactionId);
    dispatch(
      saveDoubleEnderAgent({ name: 'double ender agent', loading: false }),
    );
    await dispatch(fetchTransactionDetails(transactionId, false));
    dispatch(showSuccessToast('Successfully deleted the double ender agent'));
    return true;
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Error deleting the double ender agent', e, {
      data: { transactionId: transactionId },
    });
    dispatch(
      showErrorToast(
        'We had a problem deleting the double ender agent.',
        'Please try again in a few moments.',
      ),
    );
    return false;
  }
};

export const updateFMLSInfo = (
  transactionId: string,
  fmlsInfo: FmlsInfoRequest,
): AppThunk => async (dispatch) => {
  try {
    await new TransactionControllerApi(
      getArrakisConfiguration(),
    ).updateFmlsInfo(transactionId, fmlsInfo);
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to update FMLS info', e, {
      transactionId: { transactionId },
      fmlsInfo: { fmlsInfo },
    });
    dispatch(
      showErrorToast(
        'We were unable to update the FMLS info.',
        'Please try again in a few moments',
      ),
    );
  }
};

export const updateTransactionTaxRate = (
  id: string,
  req: UpdateTransactionTaxesRequest,
): AppThunk<Promise<void>> => async (dispatch) => {
  try {
    const { data } = await new TransactionControllerApi(
      getArrakisConfiguration(),
    ).updateTaxes(id, req);
    dispatch(
      saveTransactionDetailResponse({
        name: 'transactionDetail',
        loading: false,
        data,
      }),
    );
    dispatch(
      showSuccessToast(
        'Successfully updated the tax rate for this transaction',
      ),
    );
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Error updating transaction tax rate', e, {
      transaction: { id },
      request: req,
    });
  }
};

export const fetchTransactionCommentParticipants = (
  transactionId: string,
  agentId: string,
): AppThunk<Promise<void>> => async (dispatch) => {
  try {
    const { data } = await new TransactionControllerApi(
      getArrakisConfiguration(),
    ).getTransactionCommentParticipants(transactionId, agentId);
    dispatch(saveCommentsMentionUsers(data));
  } catch (e) {
    dispatch(
      showErrorToast(
        'We had a problem fetching transaction comment participants',
        'Please try again in a few moments.',
      ),
    );
    ErrorService.notify('Unable to fetch transaction comment participants', e, {
      re: { transactionId, agentId },
    });
  }
};

export const updateTaxPaidFromCommission = (
  id: string,
  req: UpdateOfficeTaxPaidFromCommissionRequest,
): AppThunk => async (dispatch) => {
  try {
    const { data } = await new TransactionControllerApi(
      getArrakisConfiguration(),
    ).updateTaxPaidFromCommission(id, req);
    dispatch(
      saveTransactionDetailResponse({
        name: 'transactionDetail',
        loading: false,
        data,
      }),
    );
    dispatch(showSuccessToast('Updated tax paid from commission status'));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify('Unable to update tax paid from commission status', e, {
      taxExempt: { id, req },
    });
    dispatch(
      showErrorToastForErrorCode(
        'We encountered an error while updating tax paid from commission status. Please try again in a few moments.',
        ErrorService.getErrorCode(e),
      ),
    );
  }
};

export const updateProTeammateCommissionSplits = (
  transactionId: string,
  participantId: string,
  splitData: LeaderSplitDto[],
): AppThunk => async (dispatch) => {
  try {
    await new TransactionControllerApi(
      getArrakisConfiguration(),
    ).updateProTeammateCommissionSplits(transactionId, participantId, {
      transactionId: transactionId,
      splits: splitData,
    });
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify(
      "We encountered an error while updating the leader's commission splits in transaction page.",
      e,
      {
        transaction: { transactionId, participantId, splitData },
      },
    );
    dispatch(
      showErrorToastForErrorCode(
        "We encountered an error while updating the leader's commission splits. Please try again in a few moments.",
        ErrorService.getErrorCode(e),
      ),
    );
  }
};

export const updateProTeammateTeamFees = (
  transactionId: string,
  participantId: string,
  fees: UpdateProTeammateTeamFeesRequest,
): AppThunk => async (dispatch) => {
  try {
    await new TransactionControllerApi(
      getArrakisConfiguration(),
    ).updateProTeammateTeamFees(transactionId, participantId!, fees);
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify(
      'We encountered an error while updating the team fees transaction page.',
      e,
      {
        transaction: { transactionId, participantId, fees },
      },
    );
    dispatch(
      showErrorToastForErrorCode(
        'We encountered an error while updating the team fees. Please try again in a few moments.',
        ErrorService.getErrorCode(e),
      ),
    );
  }
};

export const updateProTeammateSplitFees = (
  transactionId: string,
  participantId: string,
  fees: UpdateProTeammateFeeSplitsRequest,
): AppThunk => async (dispatch) => {
  try {
    await new TransactionControllerApi(
      getArrakisConfiguration(),
    ).updateProTeammateFeeSplits(transactionId, participantId!, fees);
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notify(
      'Unable to update the teammates split fees transaction page.',
      e,
      {
        transaction: { transactionId, participantId, fees },
      },
    );
    dispatch(
      showErrorToastForErrorCode(
        'We encountered an error while updating the split fees. Please try again in a few moments.',
        ErrorService.getErrorCode(e),
      ),
    );
  }
};

export const setTransactionTeamId = (
  transactionId: string,
  teamId: string,
): AppThunk<Promise<void>> => async (dispatch) => {
  try {
    const { data } = await new TransactionControllerApi(
      getArrakisConfiguration(),
    ).updateTransactionTeamId(transactionId, teamId);
    dispatch(
      saveTransactionDetailResponse({
        name: 'transactionDetail',
        loading: false,
        data,
      }),
    );
    dispatch(showSuccessToast('Transaction team id set successfully.'));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notifyIgnoreAuthErrors(
      'Unable to set transaction team id',
      e,
      { teamId: { teamId }, transactionId: { transactionId } },
    );
    dispatch(
      showErrorToast(
        'We had a problem setting the transaction team id.',
        'Please try again in a few moments.',
      ),
    );
  }
};

export const clearTransactionTeamId = (
  transactionId: string,
): AppThunk => async (dispatch) => {
  try {
    const { data } = await new TransactionControllerApi(
      getArrakisConfiguration(),
    ).clearTransactionTeamId(transactionId);
    dispatch(
      saveTransactionDetailResponse({
        name: 'transactionDetail',
        loading: false,
        data,
      }),
    );
    dispatch(showSuccessToast('Transaction team reseted successfully.'));
  } catch (e) {
    dispatch(showApiErrorModal(e));
    ErrorService.notifyIgnoreAuthErrors(
      'Unable to clear transaction team id',
      e,
      { transactionId: { transactionId } },
    );
    dispatch(
      showErrorToast(
        'We had a problem clearing the transaction team id.',
        'Please try again in a few moments.',
      ),
    );
  }
};

export default TransactionSlice.reducer;
