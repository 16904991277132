import { CopyButton } from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckDouble, faCopy } from '@fortawesome/pro-light-svg-icons';
import { capitalize } from 'lodash';
import { useState } from 'react';
import { TextInput } from '../commons/TextInput';
import { capitalizeEnum } from '../../utils/StringUtils';
import { cn } from '../../utils/classUtils';
import { Select } from '../commons/Select';
import { TextArea } from '../commons/TextArea';
import { Button } from '../commons/Button';
import { UpdateFailedMessageStatusBatchStatusEnum } from '../../openapi/hermes';
import ZenSidebarModal from '../Zen/ZenSidebarModal';
import { useBulkStatusUpdate } from '../../query/kafkaDashboard/useKafkaDashboard';
import { KafkaController, KafkaFailedMessage } from './KafkaControllerTypes';
import { KafkaServiceType } from './KafkaTypes';

type KafkaEditMessageModalProps = {
  opened: boolean;
  onClose(refresh?: boolean): void;
  failedMessage?: KafkaFailedMessage;
  service?: string;
  type?: KafkaServiceType;
  serviceController: KafkaController;
  handleRetryFailedMessage(failedMessage: KafkaFailedMessage): void;
};

export const KafkaEditMessageModal: React.FC<KafkaEditMessageModalProps> = ({
  opened,
  onClose,
  failedMessage,
  service,
  type,
  serviceController,
  handleRetryFailedMessage,
}) => {
  const [
    status,
    setStatus,
  ] = useState<UpdateFailedMessageStatusBatchStatusEnum>();

  const createdAt = failedMessage?.createdAt
    ? new Date(failedMessage.createdAt).toLocaleString()
    : 'N/A';
  const updatedAt = failedMessage?.updatedAt
    ? new Date(failedMessage.updatedAt).toLocaleString()
    : 'N/A';

  const { isLoading, mutateAsync: handleUpdate } = useBulkStatusUpdate({
    status,
    messageIds: [failedMessage?.id!],
    controller: serviceController,
    onSuccess() {
      onClose();
    },
  });

  return (
    <ZenSidebarModal
      isOpen={opened}
      onClose={onClose}
      title={`[${capitalize(service)} ${capitalize(type)}] ${capitalizeEnum(
        failedMessage?.kafkaMessageLocation.topic || '',
      )}`}
      // @ts-ignore
      subtitle={
        failedMessage && (
          <div className='flex gap-2 items-center text-xs text-grey-500'>
            <div>Created {createdAt}</div>
            <div>•</div>
            <div>Last update {updatedAt}</div>
          </div>
        )
      }
    >
      {failedMessage && (
        <div className='relative font-inter font-light px-5'>
          <div className='flex flex-col gap-2 mb-20'>
            <div className='gap-2 flex mt-4 items-baseline'>
              <TextInput
                value={failedMessage.id!}
                disabled
                label={
                  <>
                    ID{' '}
                    <CopyButton value={failedMessage.id!}>
                      {({ copied, copy }) => (
                        <FontAwesomeIcon
                          icon={copied ? faCheckDouble : faCopy}
                          onClick={copy}
                          className={cn(
                            'text-xl',
                            copied
                              ? 'text-green-500'
                              : 'text-grey-500 cursor-pointer',
                          )}
                        />
                      )}
                    </CopyButton>
                  </>
                }
              />
            </div>
            <div>
              <TextInput
                value={failedMessage.eventId}
                disabled
                label='Event ID'
                size='sm'
              />
            </div>
            <div>
              <TextInput
                value={failedMessage.clientType}
                disabled
                label='Client Type'
                size='sm'
              />
            </div>
            <div>
              <Select
                data={Object.values(UpdateFailedMessageStatusBatchStatusEnum)
                  .sort()
                  .map((value) => ({
                    label: capitalizeEnum(value),
                    value,
                  }))}
                label='Status'
                defaultValue={failedMessage.status}
                onChange={(value: UpdateFailedMessageStatusBatchStatusEnum) =>
                  setStatus(value ?? undefined)
                }
              />
            </div>
            <div>
              <TextArea
                value={JSON.stringify(
                  failedMessage.kafkaMessageLocation,
                  null,
                  2,
                )}
                disabled
                label='Kafka Message Location'
                autosize
              />
            </div>
            <div>
              <TextInput
                value={failedMessage.eventId}
                disabled
                label='Event ID'
              />
            </div>
            <div>
              <TextInput value={failedMessage.key} disabled label='Key' />
            </div>
            <div>
              <TextArea
                value={JSON.stringify(failedMessage.payload, null, 2)}
                disabled
                label='Payload'
                autosize
              />
            </div>
            <div>
              <TextInput
                value={failedMessage.errorMessage}
                disabled
                label='Error Message'
              />
            </div>
            <div>
              <TextInput
                value={failedMessage.reprocessingAttempts}
                disabled
                label='Reprocessing Attempts'
              />
            </div>
            <div>
              <TextInput
                value={failedMessage.shortenedId}
                disabled
                label='Shortened ID'
              />
            </div>
          </div>
          <div className='sticky bottom-0 w-full bg-primary-light py-3 flex gap-3 justify-end items-center'>
            <Button
              variant='outline'
              classNames={{
                label: '!px-5',
              }}
              onClick={() => {
                handleRetryFailedMessage(failedMessage);
                onClose();
              }}
            >
              Retry
            </Button>
            <Button
              onClick={handleUpdate}
              classNames={{
                label: '!px-5',
              }}
              disabled={
                !status || isLoading || status === `${failedMessage.status}`
              }
            >
              Update
            </Button>
          </div>
        </div>
      )}
    </ZenSidebarModal>
  );
};
