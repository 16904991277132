import { Autocomplete } from '@react-google-maps/api';
import { useCallback, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/pro-light-svg-icons';
import {
  Controller,
  FieldPath,
  FieldValues,
  UseControllerProps,
} from 'react-hook-form-v7';
import { GOOGLE_MAPS_REQUESTED_FIELDS } from '../../../constants/GoogleMapsConstants';
import { useGoogleMapsAPILoader } from '../../../hooks/useGoogleMapsAPILoader';
import { FormFieldTooltipIndexProps } from '../../../types';
import { cn } from '../../../utils/classUtils';
import ZenFormErrorMessage from './ZenFormErrorMessage';

export interface GooglePlaceLocationType {
  formatted_address: string;
  place_id: string;
  geometry: {
    location: {
      lat: number | undefined;
      lng: number | undefined;
    };
  };
  address_components?:
    | { long_name: string; short_name: string; types: string[] }[]
    | undefined;
}

export type GooglePlaceType = Record<string, GooglePlaceLocationType>;

interface ControlledGoogleAutocompleteSearchBar<
  TFieldValues extends FieldValues | GooglePlaceType =
    | FieldValues
    | GooglePlaceType,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends UseControllerProps<TFieldValues, TName>,
    FormFieldTooltipIndexProps {
  label?: string;
  subLabel?: string;
  placeholder?: string;
  isRequired?: boolean;
  disabled?: boolean;
  onChangeSpy?: (value: string | undefined) => void;
  onPlaceChangedSpy?: (value: string | undefined) => void;
}

const ZenControlledGoogleAutocompleteSearchInput = <
  TFieldValues extends FieldValues | GooglePlaceType =
    | FieldValues
    | GooglePlaceType,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  label,
  subLabel,
  placeholder,
  name,
  tooltipIndex,
  shouldUnregister = true,
  isRequired = false,
  disabled,
  onChangeSpy,
  onPlaceChangedSpy,
  ...rest
}: ControlledGoogleAutocompleteSearchBar<TFieldValues, TName>) => {
  const [
    autocomplete,
    setAutocomplete,
  ] = useState<google.maps.places.Autocomplete>();
  const isLoaded = useGoogleMapsAPILoader();
  const [focus, setFocus] = useState<boolean>(false);
  const onLoad = useCallback(
    (autocomplete) => {
      setAutocomplete(autocomplete);
    },
    [setAutocomplete],
  );

  return (
    <Controller
      shouldUnregister={shouldUnregister}
      name={name}
      {...rest}
      render={({
        field: { onBlur, onChange, value },
        fieldState: { error, invalid },
      }) => {
        return (
          <div className='w-full space-y-1'>
            {!!label && (
              <label className='inline-block' htmlFor={name}>
                <span
                  className={cn(
                    'font-zen-body font-semibold text-base',
                    invalid ? 'text-zen-danger' : 'text-zen-dark-9',
                  )}
                >
                  {label}
                </span>
                {!!subLabel && (
                  <span className='font-zen-body text-sm text-zen-dark-12 ml-1'>
                    {subLabel}
                  </span>
                )}
                {isRequired && <span className='text-zen-danger'>*</span>}
              </label>
            )}
            {isLoaded && (
              <Autocomplete
                className={cn('w-full', {
                  'bg-gray-50': disabled,
                })}
                onLoad={onLoad}
                onPlaceChanged={() => {
                  const selectedPlace = autocomplete?.getPlace();
                  onChange({
                    formatted_address: selectedPlace?.formatted_address,
                    place_id: selectedPlace?.place_id,
                    geometry: {
                      location: {
                        lat: selectedPlace?.geometry?.location?.lat(),
                        lng: selectedPlace?.geometry?.location?.lng(),
                      },
                    },
                    address_components: selectedPlace?.address_components,
                  });
                  if (onChangeSpy) {
                    onChangeSpy(selectedPlace?.formatted_address);
                  }
                  if (onPlaceChangedSpy) {
                    onPlaceChangedSpy(selectedPlace?.formatted_address);
                  }
                }}
                restrictions={{
                  country: ['us', 'ca'],
                }}
                options={{
                  fields: GOOGLE_MAPS_REQUESTED_FIELDS,
                }}
              >
                <div
                  className={cn(
                    'flex flex-row items-center border rounded-lg overflow-hidden pl-3',
                    !!value?.formatted_address && 'text-zen-dark-9',
                    invalid && '!border-zen-danger',
                    focus ? 'border-zen-dark-9' : 'border-zen-dark-5',
                  )}
                >
                  <span>
                    <FontAwesomeIcon
                      icon={faLocationDot}
                      size='1x'
                      className='text-primary-blue mt-1.5'
                    />
                  </span>
                  <input
                    type='text'
                    value={value?.formatted_address}
                    onChange={(place) => {
                      onChange(place);
                      if (onChangeSpy) {
                        onChangeSpy(place?.target?.value);
                      }
                    }}
                    placeholder={placeholder}
                    className={cn(
                      'appearance-none p-2 w-full border-none focus:outline-none focus:ring-0 font-zen-body font-normal',
                      {
                        'bg-gray-50': disabled,
                      },
                    )}
                    onFocus={() => setFocus(true)}
                    onBlur={() => {
                      setFocus(false);
                      onBlur();
                    }}
                    data-tooltip-index={tooltipIndex}
                    disabled={disabled}
                  />
                </div>
              </Autocomplete>
            )}

            {error?.message && <ZenFormErrorMessage message={error.message} />}
          </div>
        );
      }}
    />
  );
};

export default ZenControlledGoogleAutocompleteSearchInput;
