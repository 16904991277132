import React, { useCallback, useMemo, useState } from 'react';
import capitalize from 'lodash/capitalize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faRotateRight } from '@fortawesome/pro-light-svg-icons';
import ZenRoute from '../Zen/ZenRoute';
import ZenResourceIndexContainer from '../Zen/Containers/ZenResourceIndexContainer';
import { useRefresh } from '../../hooks/useRefresh';
import { useFailedMessages } from '../../query/kafkaDashboard/useKafkaDashboard';
import { getListFailedMessagesArgs } from './KafkaUtils';
import { KafkaControllers, KafkaFailedMessage } from './KafkaControllerTypes';
import {
  producerControllers,
  ProducerService,
  ProducerServiceKeys,
} from './ProducerControllers';
import {
  consumerControllers,
  ConsumerService,
  ConsumerServiceKeys,
} from './ConsumerControllers';
import { getKafkaColumns } from './KafkaColumns';
import { KafkaEditMessageModal } from './KafkaEditMessageModal';
import { KafkaServiceType } from './KafkaTypes';
import { KafkaBulkMessageUpdateModal } from './KafkaBulkMessageUpdateModal';

const ControllersMap: Record<KafkaServiceType, KafkaControllers<string>> = {
  [KafkaServiceType.Producer]: producerControllers,
  [KafkaServiceType.Consumer]: consumerControllers,
};

const ServicesMap = {
  [KafkaServiceType.Producer]: ProducerService,
  [KafkaServiceType.Consumer]: ConsumerService,
};

type KafkaRetryDashboardProps = {
  type: KafkaServiceType;
};

const KafkaRetryDashboard: React.FC<KafkaRetryDashboardProps> = ({ type }) => {
  const [selectedService, setSelectedService] = useState<
    ConsumerServiceKeys | ProducerServiceKeys
  >(ConsumerService.arrakis);
  const [selectedMessage, setSelectedMessage] = useState<KafkaFailedMessage>();
  const [selectedMessageIds, setSelectedMessageIds] = useState<string[]>([]);
  const [showBulkEditModal, setShowBulkEditModal] = useState(false);
  const [showEditSidebar, setShowEditSidebar] = useState(false);
  const { key, refresh } = useRefresh();

  const kafkaServices = useMemo(() => {
    return ServicesMap[type];
  }, [type]);

  const kafkaController = useMemo(() => {
    const controllers = ControllersMap[type];
    return controllers[selectedService];
  }, [selectedService, type]);

  const {
    isLoading,
    mutateAsync: handleRetryFailedMessage,
  } = useFailedMessages({
    controller: kafkaController,
    refresh,
    setSelectedMessage,
  });

  const handleRetryFailedMessages = useCallback(async () => {
    throw new Error('Not implemented');
  }, []);

  const handleBulkEditFailedMessages = useCallback(
    async (failedMessages: KafkaFailedMessage[]) => {
      setSelectedMessageIds(failedMessages.map((message) => message.id!));
      setShowBulkEditModal(true);
    },
    [],
  );

  const handleEditFailedMessage = useCallback(
    async (failedMessage: KafkaFailedMessage) => {
      setSelectedMessage(failedMessage);
      setShowEditSidebar(true);
    },
    [],
  );

  const columns = useMemo(
    () =>
      getKafkaColumns(
        handleRetryFailedMessage,
        handleEditFailedMessage,
        selectedMessage,
        isLoading,
      ),
    [
      handleEditFailedMessage,
      handleRetryFailedMessage,
      isLoading,
      selectedMessage,
    ],
  );

  return (
    <div>
      <ZenRoute title='Kafka Dashboard'>
        <div className='px-4 lg:pt-5'>
          <ZenResourceIndexContainer<KafkaFailedMessage>
            stickyHeader
            key={key}
            columns={columns}
            pageSize={50}
            tabs={Object.keys(kafkaServices)
              .sort()
              .map((microservice) => ({
                name: capitalize(microservice),
                isActive: selectedService === microservice,
                TabComponent: null,
              }))}
            allowSelection
            showSelectionActionBar
            actionBarButtons={[
              {
                icon: (
                  <FontAwesomeIcon
                    icon={faRotateRight}
                    className='cursor-default text-grey-400'
                  />
                ),
                onClick: handleRetryFailedMessages,
                hoverText: 'Retry messages (Waiting on API)',
              },
              {
                icon: (
                  <FontAwesomeIcon icon={faPen} className='text-primary-dark' />
                ),
                onClick: handleBulkEditFailedMessages,
                hoverText: 'Update Status',
              },
            ]}
            onTabChange={(key) =>
              setSelectedService(
                key.toLowerCase() as ConsumerServiceKeys | ProducerServiceKeys,
              )
            }
            resourceName={`${selectedService} failed messages`}
            fetchData={async (req) => {
              const requestArgs = getListFailedMessagesArgs(req);

              const response = await kafkaController.getFailedMessages(
                ...requestArgs,
              );

              return {
                data: response.data.results as KafkaFailedMessage[],
                total: response.data.totalCount ?? 0,
              };
            }}
          />
        </div>
        <KafkaBulkMessageUpdateModal
          selectedMessageIds={selectedMessageIds}
          showBulkEditModal={showBulkEditModal}
          onClose={(refreshTable?: boolean) => {
            setShowBulkEditModal(false);
            if (refreshTable) {
              refresh();
            }
          }}
          selectedController={kafkaController}
        />
        <KafkaEditMessageModal
          opened={showEditSidebar}
          onClose={(refreshTable?: boolean) => {
            setShowEditSidebar(false);
            setSelectedMessage(undefined);
            if (refreshTable) {
              refresh();
            }
          }}
          handleRetryFailedMessage={handleRetryFailedMessage}
          failedMessage={selectedMessage}
          service={selectedService}
          serviceController={kafkaController}
          type={type}
        />
      </ZenRoute>
    </div>
  );
};

export default KafkaRetryDashboard;
