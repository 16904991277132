import { Column } from 'react-table';
import { values } from 'lodash';
import { faPen, faRotateRight } from '@fortawesome/pro-light-svg-icons';
import { Pill, PillColor } from '../commons/Pill';
import { capitalizeEnum } from '../../utils/StringUtils';
import TextColumnFilter from '../table/Filters/TextColumnFilter';
import DateColumnFilter from '../table/Filters/DateColumnFilter';
import SelectColumnFilter, {
  SelectColumnFilterProps,
} from '../table/Filters/Base/SelectColumnFilter';
import { ActionIcon } from '../commons/ActionIcon';
import { cn } from '../../utils/classUtils';
import {
  KafkaFailedMessage,
  KafkaFailedMessageStatus,
  KafkaFailedMessageStatusEnum,
} from './KafkaControllerTypes';

export interface FailedMessageStatusSelectColumnFilterProps<D extends object>
  extends SelectColumnFilterProps<D> {}

const FailedMessageStatusSelectColumnFilter = <D extends object>(
  props: FailedMessageStatusSelectColumnFilterProps<D>,
) => {
  return (
    <SelectColumnFilter
      {...props}
      options={values(KafkaFailedMessageStatus)
        .sort()
        .map((value) => ({
          label: capitalizeEnum(value),
          value,
        }))}
    />
  );
};

const statusToPillColor: Record<KafkaFailedMessageStatusEnum, PillColor> = {
  [KafkaFailedMessageStatus.Blocked]: 'orange',
  [KafkaFailedMessageStatus.Failed]: 'red',
  [KafkaFailedMessageStatus.ManualOverride]: 'light-blue',
  [KafkaFailedMessageStatus.NoActionRequired]: 'grey',
  [KafkaFailedMessageStatus.Processed]: 'light-green',
  [KafkaFailedMessageStatus.Unprocessed]: 'yellow',
};

const ClickableCell = ({
  children,
  onClick,
}: {
  children: React.ReactNode;
  onClick: () => void;
}) => (
  <div
    className='font-inter font-light cursor-pointer max-w-[400px]'
    onClick={onClick}
  >
    {children}
  </div>
);

export const getKafkaColumns = (
  onRetry: (failedMessage: KafkaFailedMessage) => void,
  onEdit: (failedMessage: KafkaFailedMessage) => void,
  selectedMessage: KafkaFailedMessage | undefined,
  isLoading: boolean,
): Array<Column<KafkaFailedMessage>> => [
  {
    Header: 'Actions',
    accessor: 'id',
    id: 'actions',
    Cell: ({ row }) => (
      <div className='flex gap-2'>
        <ActionIcon
          icon={faRotateRight}
          className={cn(
            'cursor-pointer',
            selectedMessage?.id === row.original.id &&
              isLoading &&
              'cursor-default animate-spin',
          )}
          disabled={selectedMessage?.id === row.original.id && isLoading}
          label='Retry'
          onClick={() => onRetry(row.original)}
        />
        <ActionIcon
          icon={faPen}
          label='Edit'
          onClick={() => onEdit(row.original)}
        />
      </div>
    ),
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'ID',
    accessor: 'id',
    Cell: ({ value, row }) => (
      <ClickableCell onClick={() => onEdit(row.original)}>
        {value}
      </ClickableCell>
    ),
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Status',
    accessor: 'status',
    id: 'status',
    Cell: ({ value, row }) => (
      <ClickableCell onClick={() => onEdit(row.original)}>
        <Pill color={statusToPillColor[value]}>{capitalizeEnum(value)}</Pill>
      </ClickableCell>
    ),
    Filter: FailedMessageStatusSelectColumnFilter,
  },
  {
    Header: 'Topic',
    accessor: 'kafkaMessageLocation',
    Cell: ({ value, row }) => (
      <ClickableCell onClick={() => onEdit(row.original)}>
        {' '}
        {value.topic}
      </ClickableCell>
    ),
    Filter: TextColumnFilter,
    disableSortBy: true,
  },
  {
    Header: 'Key',
    accessor: 'key',
    Cell: ({ value, row }) => (
      <ClickableCell onClick={() => onEdit(row.original)}>
        {value}
      </ClickableCell>
    ),
    Filter: TextColumnFilter,
    disableSortBy: true,
  },
  {
    Header: 'Error Message',
    accessor: 'errorMessage',
    Cell: ({ value, row }) => (
      <ClickableCell onClick={() => onEdit(row.original)}>
        {value}
      </ClickableCell>
    ),
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Created',
    accessor: 'createdAt',
    id: 'createdAt',
    Cell: ({ value, row }) => (
      <ClickableCell onClick={() => onEdit(row.original)}>
        <div>{value ? new Date(value).toLocaleDateString() : 'N/A'}</div>
        <div>{value ? new Date(value).toLocaleTimeString() : 'N/A'}</div>
      </ClickableCell>
    ),
    Filter: DateColumnFilter,
  },
  {
    Header: 'Updated',
    accessor: 'updatedAt',
    Cell: ({ value, row }) => (
      <ClickableCell onClick={() => onEdit(row.original)}>
        <div>{value ? new Date(value).toLocaleDateString() : 'N/A'}</div>
        <div>{value ? new Date(value).toLocaleTimeString() : 'N/A'}</div>
      </ClickableCell>
    ),
    disableFilters: true,
    disableSortBy: true,
  },
];
