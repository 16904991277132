import * as Hermes from '../../openapi/hermes';

export type KafkaControllerOptions<T> = {
  getFailedMessages: keyof T;
  retryFailedMessage: keyof T;
  batchUpdateFailedMessageStatus: keyof T;
  retryFailedMessages: keyof T;
};

export interface KafkaController {
  getFailedMessages: (
    ...params: GetFailedMessagesArgs
  ) => GetFailedMessagesResponse;
  retryFailedMessage: (
    ...params: RetryFailedMessageArgs
  ) => RetryFailedMessageResponse;
  retryFailedMessages: (
    ...params: RetryFailedMessagesArgs
  ) => RetryFailedMessagesResponse;
  batchUpdateFailedMessageStatus: (
    ...params: UpdateFailedMessageArgs
  ) => UpdateFailedMessageResponse;
}

export type KafkaControllers<T extends keyof any> = Record<T, KafkaController>;

export type GetFailedMessagesArgs = Parameters<
  Hermes.KafkaRetryProducerControllerApi['getFailedMessages']
>;
export type GetFailedMessagesResponse = ReturnType<
  Hermes.KafkaRetryProducerControllerApi['getFailedMessages']
>;

export type RetryFailedMessageArgs = Parameters<
  Hermes.KafkaRetryProducerControllerApi['retryFailedMessage']
>;
export type RetryFailedMessageResponse = ReturnType<
  Hermes.KafkaRetryProducerControllerApi['retryFailedMessage']
>;

export type UpdateFailedMessageArgs = Parameters<
  Hermes.KafkaRetryProducerControllerApi['batchUpdateFailedMessageStatus']
>;
export type UpdateFailedMessageResponse = ReturnType<
  Hermes.KafkaRetryProducerControllerApi['batchUpdateFailedMessageStatus']
>;

export type RetryFailedMessagesArgs = Parameters<
  Hermes.KafkaRetryProducerControllerApi['retryFailedMessages']
>;
export type RetryFailedMessagesResponse = ReturnType<
  Hermes.KafkaRetryProducerControllerApi['retryFailedMessages']
>;

export type KafkaFailedMessage = Hermes.FailedMessage;

export type KafkaUpdateMessageStatusEnum = Hermes.UpdateFailedMessageStatusBatchStatusEnum;
export const KafkaUpdateMessageStatus =
  Hermes.UpdateFailedMessageStatusBatchStatusEnum;

export type KafkaFailedMessageStatusEnum = Hermes.FailedMessageStatusEnum;
export const KafkaFailedMessageStatus = Hermes.FailedMessageStatusEnum;
