import { EnumMap, IPaginateReq, SearchParam } from '../../types';
import {
  GetFailedMessagesArgs,
  KafkaFailedMessage,
  KafkaFailedMessageStatusEnum,
} from './KafkaControllerTypes';

const FailedMessageSortByTypeEnum: EnumMap<string, 'CREATED_AT' | 'STATUS'> = {
  status: 'STATUS',
  createdAt: 'CREATED_AT',
};

const FailedMessageSortDirectionTypeEnum: EnumMap<string, 'ASC' | 'DESC'> = {
  asc: 'ASC',
  desc: 'DESC',
};

export const getListFailedMessagesArgs = (
  req: IPaginateReq<KafkaFailedMessage>,
): GetFailedMessagesArgs => {
  const sortKeys = Object.keys(req.sortBy || {});
  const sortType = Object.values(req.sortBy || {})[0];
  const createAtFilter = (req.filter?.createdAt || []) as SearchParam[];
  const createdAtFrom = createAtFilter.find((f) => f.operator === 'GTE')?.value;
  const createdAtTo = createAtFilter.find((f) => f.operator === 'LTE')?.value;
  const statusFilter = req.filter?.status as SearchParam;
  const status = statusFilter?.value;
  const topic = req.filter?.kafkaMessageLocation as string;
  const key = req.filter?.key as string;

  return [
    req.page,
    req.pageSize,
    FailedMessageSortDirectionTypeEnum[sortType ?? 'asc'],
    sortKeys.map((key) => FailedMessageSortByTypeEnum[key]),
    topic,
    key,
    status as KafkaFailedMessageStatusEnum,
    createdAtFrom ? Number(createdAtFrom) : undefined,
    createdAtTo ? Number(createdAtTo) : undefined,
  ];
};
