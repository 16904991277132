import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { faLocationDot } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmpty, values } from 'lodash';
import React, { useMemo, useRef, useState } from 'react';
import { useForm, useFormContext } from 'react-hook-form-v7';
import useDidUpdateEffect from '../../hooks/useDidUpdateEffect';
import {
  AdministrativeAreaRequestCountryEnum,
  AdministrativeAreaRequestStateOrProvinceEnum,
  AdministrativeAreaResponseCountryEnum,
} from '../../openapi/yenta';
import {
  LeoTableHeaderFormData,
  TABS_ENUM,
} from '../../routes/LeoQuestionManagerRoute';
import { ISelectOption } from '../../types';
import { getISelectOptionDefaultValue } from '../../utils/FormUtils';
import {
  CANADIAN_STATES,
  COUNTRY_ABBREVIATIONS,
  STATE_OR_PROVINCE_ABBREVIATIONS,
  US_STATES,
} from '../../utils/StateUtils';
import { capitalizeEnum } from '../../utils/StringUtils';
import GeminiFeatureFlagButton from '../GeminiFeatureFlagButton';
import ZenControlledMultiSelectInput from '../Zen/Input/ZenControlledMultiSelectInput';
import ZenControlledSelectInput from '../Zen/Input/ZenControlledSelectInput';
import { CountryAll } from './LQMSidebarModalForm';

interface LQMGeoLocationFilterProps {
  tabName: TABS_ENUM;
}

export interface LQMGeoLocationFilterFormData {
  country: ISelectOption;
  states: ISelectOption[];
}

const LQMGeoLocationFilter: React.FC<LQMGeoLocationFilterProps> = ({
  tabName,
}) => {
  const didMountRef = useRef<boolean>(false);
  const {
    setValue: parentSetValue,
    getValues: parentGetValues,
  } = useFormContext<LeoTableHeaderFormData>();
  const getDefaultValues = () => {
    const values = parentGetValues();
    const countryValue =
      tabName === TABS_ENUM.WORK_QUEUE
        ? values.workQueueCountryFilter
        : tabName === TABS_ENUM.ASSIGNED_QUEUE
        ? values.assignedQueueCountryFilter
        : tabName === TABS_ENUM.COMPLETED_QUEUE
        ? values.completedQueueCountryFilter
        : undefined;

    const statesValue =
      tabName === TABS_ENUM.WORK_QUEUE
        ? values.workQueueStatesFilter
        : tabName === TABS_ENUM.ASSIGNED_QUEUE
        ? values.assignedQueueStatesFilter
        : tabName === TABS_ENUM.COMPLETED_QUEUE
        ? values.completedQueueStatesFilter
        : undefined;

    const formDefaultValues = {
      country: getISelectOptionDefaultValue(countryValue || CountryAll.All),
      states:
        statesValue?.map((state) => getISelectOptionDefaultValue(state)) || [],
    };
    return formDefaultValues;
  };

  const { control, watch, setValue } = useForm<LQMGeoLocationFilterFormData>({
    defaultValues: getDefaultValues(),
  });
  const [open, setOpen] = useState(false);

  const [country, states] = watch(['country', 'states']);

  const filterLabel = useMemo(() => {
    if (states?.length > 0) {
      let statesLabel = states
        .slice(0, 3)
        .map(
          ({ value }) =>
            STATE_OR_PROVINCE_ABBREVIATIONS[
              value as AdministrativeAreaRequestStateOrProvinceEnum
            ],
        )
        .join(', ');

      if (states.length > 3) {
        statesLabel += ` +${states.length - 3}`;
      }

      return statesLabel;
    } else if (country?.value && country?.value !== CountryAll.All) {
      return COUNTRY_ABBREVIATIONS[
        country.value as AdministrativeAreaRequestCountryEnum
      ];
    } else {
      return 'Geo Location';
    }
  }, [country, states]);

  const stateOptions = useMemo(() => {
    const stateValues =
      ((country?.value as unknown) as AdministrativeAreaRequestCountryEnum) ===
      AdministrativeAreaRequestCountryEnum.UnitedStates
        ? US_STATES
        : CANADIAN_STATES;

    return values(stateValues)
      .sort((a, z) => a.localeCompare(z))
      .map<ISelectOption>((state) => ({
        value: state,
        label: capitalizeEnum(state),
      }));
  }, [country]);

  useDidUpdateEffect(() => {
    if (didMountRef.current) {
      setValue('states', []);
    } else {
      didMountRef.current = true;
    }
  }, [country]);

  const handleSubmitFilter = () => {
    const countryFilterValue = country.value;
    const statesFilterValue = isEmpty(states)
      ? undefined
      : states.map(({ value }) => value);

    if (tabName === TABS_ENUM.WORK_QUEUE) {
      parentSetValue('workQueueCountryFilter', countryFilterValue!);
    } else if (tabName === TABS_ENUM.ASSIGNED_QUEUE) {
      parentSetValue('assignedQueueCountryFilter', countryFilterValue!);
    } else if (tabName === TABS_ENUM.COMPLETED_QUEUE) {
      parentSetValue('completedQueueCountryFilter', countryFilterValue!);
    }

    if (tabName === TABS_ENUM.WORK_QUEUE) {
      parentSetValue('workQueueStatesFilter', statesFilterValue!);
    } else if (tabName === TABS_ENUM.ASSIGNED_QUEUE) {
      parentSetValue('assignedQueueStatesFilter', statesFilterValue!);
    } else if (tabName === TABS_ENUM.COMPLETED_QUEUE) {
      parentSetValue('completedQueueStatesFilter', statesFilterValue!);
    }
    setOpen(false);
  };

  return (
    <section
      className='relative w-full md:w-auto md:ml-4'
      title='geo-location-filter-options'
    >
      <GeminiFeatureFlagButton
        LeftIconComponent={
          <FontAwesomeIcon
            icon={faLocationDot}
            className='text-zen-dark-7 mr-0.5'
            aria-label='calender-icon'
          />
        }
        leftIcon={faLocationDot}
        isFullWidth
        RightIconComponent={
          <FontAwesomeIcon
            icon={solid('chevron-down')}
            className='text-zen-dark-7'
            aria-label='calender-icon'
          />
        }
        rightIcon={solid('chevron-down')}
        label={filterLabel}
        zenVariant='secondary-gray-text-outline'
        geminiVariant='secondary-outline'
        onClick={() => setOpen(!open)}
      />

      {open && (
        <div className='absolute right-0 z-10 mt-1 bg-white rounded-lg shadow-lg w-full md:w-64 ring-2 ring-gray-200 p-3'>
          <ZenControlledSelectInput<LQMGeoLocationFilterFormData, 'country'>
            name='country'
            control={control}
            label='Country'
            placeholder='Select Country'
            options={[
              ...values({
                ...CountryAll,
                ...AdministrativeAreaResponseCountryEnum,
              }).map((country) => ({
                value: country,
                label: capitalizeEnum(country),
              })),
            ]}
            customClassName='w-full'
            shouldUnregister={false}
          />
          <div className='mt-2'>
            <ZenControlledMultiSelectInput<
              LQMGeoLocationFilterFormData,
              'states'
            >
              name='states'
              control={control}
              label='States'
              placeholder='Select States'
              options={stateOptions}
              closeMenuOnSelect={false}
              customClassName='w-full'
              disabled={country?.value === CountryAll.All}
              shouldUnregister={false}
            />
          </div>
          <div className='mt-2.5'>
            <GeminiFeatureFlagButton
              label='Apply'
              isFullWidth
              onClick={handleSubmitFilter}
            />
          </div>
        </div>
      )}
    </section>
  );
};

export default React.memo(LQMGeoLocationFilter);
