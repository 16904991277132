import { YesNoType } from '../types';
import { clearUnitTokens } from '../utils/WalletUtils';

declare var window: any;
export default class MenuService {
  static readonly MENU_SERVICE_KEY = 'MENU_STATUS';
  static readonly UNIT_CUSTOMER_TOKEN = 'unitCustomerToken';
  static readonly UNIT_VERIFIED_CUSTOMER_TOKEN = 'unitVerifiedCustomerToken';

  static setOpen(value: boolean) {
    return window.localStorage.setItem(
      MenuService.MENU_SERVICE_KEY,
      value ? YesNoType.YES : YesNoType.NO,
    );
  }

  static clear() {
    clearUnitTokens();
    return window.localStorage.removeItem(MenuService.MENU_SERVICE_KEY);
  }

  static isMenuCollapsed(): boolean {
    const status = window.localStorage.getItem(MenuService.MENU_SERVICE_KEY);
    return status === YesNoType.NO;
  }
}
