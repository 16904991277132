import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ThunkAction } from '@reduxjs/toolkit';
import { ReactDatePickerProps } from 'react-datepicker';
import { RegisterOptions } from 'react-hook-form-v6/dist/types/validator';
import { Control, UnpackNestedValue } from 'react-hook-form-v7';
import { Action } from 'redux';
import { TaxAlert } from './components/Agent/TaxFormAlert';
import { ModalVariantType } from './components/ConfirmationModal';
import { PageAnnouncementVariant } from './components/PageAnnouncement';
import { TCInfo } from './components/TransactionCoordinator/TCInfoSection';
import { EarningType } from './components/WeathManagement/WealthManagementTypes';
import {
  DateFilterEnum,
  DateRange,
} from './components/Zen/Calendars/LQMDateFilter';
import { CurrentChat } from './components/Zen/Leo/Chat/LeoChatIndex';
import { SessionHistory } from './components/Zen/Leo/ChatHistory/LeoChatHistory';
import { ZenModalVariantType } from './components/Zen/Modal/ZenSimpleConfirmationModal';
import { ZenButtonVariant } from './components/Zen/ZenButton';
import {
  InitialSort,
  TableResourceFilter,
} from './containers/ResourceIndexContainer';
import {
  AgentDebtsResponse,
  AgentLiteResponse,
  AllTransactionsResponse,
  AgentControllerApi as ArrakisAgentControllerApi,
  AgentResponse as ArrakisAgentResponse,
  AttachedFeeValueFeeTypeEnum,
  BankAccountDto,
  CheckDepositDto,
  CheckDepositTokenResponse,
  CheckDepositUploadResponse,
  CommissionAdvanceListPagedResponse,
  CommissionDocumentResponse,
  CommissionPayerDisplayValue,
  ConveyanceLetterRevisionResponse,
  ConveyanceResponse,
  EquityOverviewResponse,
  EscrowResponse,
  FeaturesResponse,
  FrontLineAgentsResponse,
  GenericSearchResponseCreditResponse,
  IncomeOverviewResponse,
  IncomeTotalsResponse,
  InstantPaymentDetailResponse,
  InstantPaymentEligibleItemsResponse,
  InstantPaymentsSummary,
  InvoiceControllerApi,
  MoneyTransferResponse,
  OutgoingPaymentWithBankInfoResponse,
  PagedTransactionResponse,
  ParticipantValueRoleEnum,
  PaymentParticipantValue,
  PublicTransactionResponse,
  RefundResponse,
  RevShareHistoryResponse,
  RevShareOverviewResponse,
  RevSharePaymentPreviewResponse,
  RevSharePaymentResponse,
  RevSharePaymentsResponse,
  RevshareByTierResponse,
  SentimentDisplayResponse,
  TaxDocumentListResponse,
  TaxDocumentResponse,
  TitleProgress,
  TransactionBuilderResponse,
  TransactionCommentResponse,
  TransactionControllerApi,
  TransactionError,
  TransactionLifecycleStateValue,
  TransactionLifecycleStateValueStateEnum,
  TransactionLiteResponse,
  TransactionPermissionsResponse,
  TransactionPreviewResponseTransactionTypeEnum,
  TransactionResponse,
  TransactionsOverviewResponse,
} from './openapi/arrakis';
import {
  ApplicationProgressResponse,
  BankCustomerAccounts,
  BorrowerApplicationDto,
  BorrowerForCommentDto,
  LoanDto,
  LoansApi,
} from './openapi/atlantis';
import { InviteResponse, JointVentureResponse } from './openapi/avalon';
import {
  FututeMaintenanceResponse,
  MaintenanceResponse,
  OfficialPlanAndAwardResponse,
} from './openapi/bff';
import { DropboxResponse } from './openapi/dropbox';
import {
  GlobalNotificationPreferencesDto,
  PublisherNotificationPreferencesDto,
} from './openapi/hermes';
import {
  AgentBadgeResponse,
  AgentBadgesResponse,
  BadgeResponse,
  BadgeResponseNameEnum,
  BadgesResponse,
} from './openapi/insignia';
import {
  ApiKeyResponse,
  IdentitySummaryResponse,
  JwtAuthenticationResponse,
  RoleResponse,
} from './openapi/keymaker';
import { ActivityResponse } from './openapi/memento';
import {
  AnnouncementDefControllerApi,
  AnnouncementDefResponse,
  AnnouncementResponse,
  MediaInfoResponse,
} from './openapi/mercury';
import {
  AttractingStockAwardsEarningsResponse,
  CappingAwardEarningsResponse,
  CommissionEarningsResponse,
  CurrentWealthResponse,
  EarningsOverviewResponse,
  EliteAgentCulturalEarningsResponse,
  EliteAgentProductionEarningsResponse,
  GetCommsPreferencesResponse,
  PostCapSppEarningsResponse,
  PreCapSPPEarningsResponse,
  ProspectiveAgentInfo,
  ProspectiveReferralRateLimitInfo,
  RevenueShareEarningsResponse,
  WealthGoalsResponse,
  WebhookDto,
  WebhookEventsDto,
} from './openapi/plutus';
import {
  ChecklistDefinitionDto,
  ChecklistProgressResponse,
  ChecklistResponse,
  ItemDefinitionDto,
  ItemResponse,
  TriggerDefinitionDto,
} from './openapi/sherlock';
import {
  CommentDto,
  CommentTemplateResponse,
  MentionBlock,
  MessageDto,
  QueryCommentsRs,
  VoiceCallBlock,
} from './openapi/yada';
import {
  AddressRequestStateOrProvinceEnum,
  AddressStateOrProvinceEnum,
  AdministrativeAreaRequestCountryEnum,
  AgentCommentDetails,
  AgentControllerApi,
  AgentDocumentsResponse,
  AgentInfo,
  AgentNationalIdsResponse,
  AgentPublicInfo,
  AgentResponse,
  AgentResponseAgentStatusEnum,
  AgentWebsiteResponse,
  AgreementDefinitionResponse,
  AgreementDefinitionWithAmendmentsResponse,
  AgreementResponse,
  ApplicationResponse,
  BankAccountResponse,
  BoardResponse,
  BoundExperimentResponse,
  CalendarCountOverviewResponse,
  CalendarEventInviteePaginatedResponse,
  CalendarEventResponse,
  CalendarInfosResponse,
  CalendarInviteesCountResponse,
  CalendarResponse,
  DownLineAgentsResponse,
  ExperimentResponse,
  FlexTeamDto,
  FrontLineAgentInfo,
  GenericSearchResponseAgreementDefinitionResponse,
  GenericTeamInvitationApplicationResponseStatusEnum,
  GetCountriesResponse,
  JobExecutionResponse,
  JobExecutionsResponse,
  LearnworldsSsoResponse,
  MetabaseDashboardUrlResponse,
  MlsControllerApi,
  MlsGetPropertyTypeResponse,
  MlsResponse,
  MoneyValue,
  NetworkSizeByTierResponse,
  OfficeControllerApi,
  OfficeGroupResponse,
  OfficePreviewResponseTransactionTypeEnum,
  OfficeResponse,
  PayableInfoResponse,
  PayeeDetailsResponse,
  PaymentMethodsResponse,
  SponsorTreeResponse,
  TaxFormsResponse,
  TeamControllerApi,
  TeamInvitationSimplifiedDtoInvitationStatusEnum,
  TeamResponse,
  WillBeneficiaryAndTypeResponse,
  ChecklistDefinitionDto as YentaChecklistDefinitionDto,
} from './openapi/yenta';
import { ModifiedRefundAttachmentResponse } from './slices/ReleaseFundsSlice';
import store from './slices/store';
import {
  ContextualPrompt,
  FollowUpItem,
  LeoNotification,
  LeoResponseMessageContext,
  SessionWithDisclaimer,
} from './utils/LeoUtils';

export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export type EnumMap<E extends string, T> = {
  [key in E]: T;
};

export type PartialEnumMap<E extends string, T> = {
  [key in E]?: T;
};

export interface Mapping<T> {
  [key: string]: T | undefined;
}

// https://bobbyhadz.com/blog/typescript-make-single-property-optional
// Makes a key or set of keys optional for a particular type.
export type OptionalKey<Type, Key extends keyof Type> = Omit<Type, Key> &
  Partial<Pick<Type, Key>>;

export type ThenArg<T> = T extends PromiseLike<infer U> ? U : T;

export type ButtonType = 'primary' | 'secondary' | 'danger' | 'outline';

export type ZenLinkVariant =
  | 'primary'
  | 'secondary'
  | 'danger'
  | 'primary-with-no-hover-effect';

export type ZenLinkSizeVariant = 'small' | 'medium' | 'large';

export type ButtonSizeType = 'xs' | 'sm' | 'lg' | 'xl';

export type AvatarSizeType = 'xxs' | 'xs' | 'sm' | 'lg' | 'xl';

export type ZenAvatarVariantType = 'circle' | 'rounded';

export type ZenAvatarSizeType =
  | 'xxs'
  | 'xs'
  | 'sm'
  | 'md'
  | 'lg'
  | 'xl'
  | 'xxl';

export type GeminiAvatarSizeType =
  | 'xs'
  | 'sm'
  | 'md'
  | 'lg'
  | 'xl'
  | 'xxl'
  | '3xl';

export type ZenAvatarStatus = 'online' | 'offline' | 'away';

export type ZenAvatarStatusPosition = 'top' | 'bottom';

export interface CardItem {
  name: string;
  rightActionComponent?: React.ReactElement;
  subtitle?: string | number;
  url?: string;
  value?: string | number | React.ReactElement;
  ValueWrapper?: React.ComponentType;
  tooltipInfo?: string;
  tooltipIcon?: IconProp;
}

export enum OnboardingType {
  SelfServiceTeamInvitation = 'SELF_SERVICE_TEAM_INVITATION',
}

export interface RootState {
  activityList: ActivityState;
  agentDetail: AgentDetailState;
  agentWebsiteOnboarding: AgentWebsiteOnboardingState;
  announcement: AnnouncementState;
  applicationDetail: ApplicationState;
  auth: AuthState;
  bank: BankState;
  board: BoardState;
  brokerQueue: BrokerQueueState;
  calendar: CalendarState;
  cdaForm: CDAFormState;
  checkDeposits: CheckDepositState;
  checkListDefinition: ChecklistDefinitionState;
  checklist: CheckListState;
  clientApiKey: ClientApiKeyState;
  conveyanceDocument: ConveyanceDocumentState;
  cronJobs: CronJobsState;
  demoMode: DemoModeSliceState;
  dropbox: DropboxState;
  errorDetails: ErrorState;
  experiment: ExperimentSliceState;
  instantPayment: InstantPaymentState;
  jointVenture: JointVentureState;
  learnworlds: LearnworldsState;
  leo: LeoState;
  mainMenu: MainMenuState;
  maintenanceDetail: MaintenanceState;
  mls: MLSState;
  moneyTransfer: MoneyTransferState;
  mortgage: MortgageState;
  network: NetworkState;
  office: OfficeState;
  paymentMethod: PaymentMethodState;
  quickAction: QuickActionState;
  revenue: RevenueState;
  roles: RoleSliceState;
  support: SupportWidgetState;
  taxDoc: TaxDocState;
  taxInformation: TaxInformationState;
  team: TeamState;
  toastNotification: ToastNotificationState;
  transaction: TransactionState;
  transactionBuilder: TransactionBuilderState;
  userIds: UserIdsState;
  releaseFunds: ReleaseFundsState;
  kBar: KBarState;
  wealthManagement: WealthManagementSliceState;
  webhooks: WebhooksState;
  referralCentral: ReferralState;
  transactionCoordinator: TransactionCoordinatorState;
}

export type ToastNotificationType = 'info' | 'warning' | 'success' | 'error';

export interface ToastNotificationData {
  type: ToastNotificationType;
  title: string;
  subtitle?: string;
}

export enum AgentWebsiteOnboardingSiteInfoAccordionName {
  CONTACT_INFO = 'Contact Info',
  ABOUT_CONTENT = 'About Content',
  MAIN_CONTENT = 'Main Content',
  FEATURED_CONTENT = 'Featured Content',
}

export enum AdminRoles {
  ROLE_ADMIN = 'ROLE_ADMIN',
  SUPPORT1 = 'SUPPORT1',
  SUPPORT2 = 'SUPPORT2',
  FINANCE1 = 'FINANCE1',
  FINANCE2 = 'FINANCE2',
  GLOBAL_VIEW = 'GLOBAL_VIEW',
  TRANSACTIONS = 'TRANSACTIONS',
  ONBOARDING = 'ONBOARDING',
  BROKER_OPS = 'BROKER_OPS',
  AGENT_EXP_ASSOCIATE = 'AGENT_EXP_ASSOCIATE',
  ENGINEERING = 'ENGINEERING',
  REAL_TITLE_GRANTOR = 'REAL_TITLE_GRANTOR',
  LEO_EXPERT = 'LEO_EXPERT',
}

export enum UserRoles {
  AGENT = 'AGENT',
  BROKER = 'BROKER',
  ANNOUNCER = 'ANNOUNCER',
  SUPER_ADMIN = 'SUPER_ADMIN',
  LOAN_OFFICER = 'LOAN_OFFICER',
  MORTGAGE_ADMIN = 'MORTGAGE_ADMIN',
  LOAN_PROCESSOR = 'LOAN_PROCESSOR',
  REAL_ORIGINATE_LOAN_OFFICER = 'REAL_ORIGINATE_LOAN_OFFICER',
  REAL_TITLE = 'REAL_TITLE',
  LEO_EXPERT = 'LEO_EXPERT',
  LEGAL = 'LEGAL',
}

export interface AgentWebsiteOnboardingState {
  defaultAccordionOpenType: AgentWebsiteOnboardingSiteInfoAccordionName | null;
}

export interface ToastNotificationState {
  visible: boolean;
  toastData: ToastNotificationData | undefined;
}

export interface ApiErrorResponse {
  'com.real.commons.apierror.ApiError': ApiError;
}

export interface ApiSubError {
  object: string;
  field: string;
  rejectedValue: any;
  message: string;
}

export interface ApiError {
  errors?: [{ message: string }];
  debugMessage?: string;
  message?: string;
  status: number;
  subErrors?: ApiSubError[];
  timestamp?: string;
  reportable?: boolean;
}

export enum KBarActionEnum {
  NETWORK = 'NETWORK',
  TAX_FORM = 'TAX_FORM',
  INCOME = 'INCOME',
  CURRENT_CAP_CYCLE = 'CURRENT_CAP_CYCLE',
  UPLINE = 'UPLINE',
  MY_EQUITY = 'MY_EQUITY',
  MY_COMMISSION = 'MY_COMMISSION',
  EDIT_TRANSACTION = 'EDIT_TRANSACTION',
  TERMINATE_TRANSACTION = 'TERMINATE_TRANSACTION',
  EDIT_LISTING = 'EDIT_LISTING',
  MARK_LISTING_IN_CONTRACT = 'MARK_LISTING_IN_CONTRACT',
  NOTIFICATION_SETTINGS = 'NOTIFICATION_SETTINGS',
}

export enum BreakRoomEnum {
  SNAKE_GAME = 'SNAKE_GAME',
}

export interface kBarContextSearchResults {
  transactions: TransactionLiteResponse[];
  listings: TransactionLiteResponse[];
}

export enum KBarThemeEnum {
  CLASSIC = 'CLASSIC',
  OCEAN = 'OCEAN',
}

export interface KBarState {
  theme: KBarThemeEnum;
  cardInFocus: KBarActionEnum | undefined;
  sidebarToOpen: KBarActionEnum | undefined;
  isContextSearching: boolean;
  contextSearchResults: kBarContextSearchResults;
  breakRoomType: BreakRoomEnum | undefined;
}

export interface ErrorState {
  visible: boolean;
  errorData: ApiError | undefined;
}

export interface AuthState {
  loadingUserDetail: boolean;
  userDetail: AgentResponse | null;
  isSuperAdmin: boolean;
  isAdmin: boolean;
  isBroker: boolean;
  isAnnouncer: boolean;
  isLoanOfficer: boolean;
  isMortgageAdmin: boolean;
  isLoanProcessor: boolean;
  isROMA: boolean;
  isMortgageUserRole: boolean;
  hasMortgagePermission: boolean;
  isRealTitleOfficer: boolean;
  isLeoExpert: boolean;
  tipaltiStatus: PayeeDetailsResponse | null;
  tipaltiLoading: boolean;
  keymakerCurrentUser: IdentitySummaryResponse | null;
  identityByKeymakerIdResponse: AsyncResponse<Mapping<IdentitySummaryResponse>>;
  availableCountries: GetCountriesResponse;
  currentUserJwtResponse: JwtAuthenticationResponse | null;
  isLegal: boolean;
}

export interface QuickActionState {
  showAdditionalFeeCreateModal: AddAttachedFeeQuickAction | undefined;
  showEditTransactionAddressMlsEscrow: boolean;
  showCommissionParticipantSidebar: boolean;
  addCommissionParticipantSidebar: AddParticipantsQuickAction | undefined;
  showRecalculateCommissionModal: boolean;
  activeTransition: TransactionLifecycleStateValue | undefined;
  showChangeFirmDateModal: boolean;
}

export interface SupportWidgetState {
  supportModal: boolean;
}

export interface MaintenanceState {
  maintenance: MaintenanceResponse;
  futureMaintenanceDetail: FututeMaintenanceResponse;
}

export interface PaymentMethodState {
  paymentMethodsResponseByAgentId: Mapping<
    AsyncResponse<PaymentMethodsResponse>
  >;
}

export interface TaxDocState {
  taxDocumentsResponse: AsyncResponse<TaxDocumentListResponse>;
  taxAlert: TaxAlert | null;
  taxDocumentsDetailResponse: AsyncResponse<TaxDocumentResponse>;
  taxAlertV2: Mapping<TaxAlert> | null;
  taxDocumentsDetailResponseV2: AsyncResponse<TaxDocumentResponse[]>;
}
export interface CDAFormState {
  cdaFormDetailResponse: AsyncResponse<CommissionDocumentResponse>;
  approvedCDADetail: CommissionDocumentResponse | null;
}

export interface AgentDetailState {
  detailResponse: AsyncResponse<AgentResponse>;
  priorAgentDetailResponse: AsyncResponse<AgentResponse>;
  commissionAdvancesResponse: AsyncResponse<CommissionAdvanceListPagedResponse>;
  performanceResponse: AsyncResponse<ArrakisAgentResponse>;
  incomeOverviewResponse: AsyncResponse<IncomeOverviewResponse>;
  incomeChart: IncomeTotalsResponse | undefined;
  equityResponse: AsyncResponse<EquityOverviewResponse>;
  revShareOverview: RevShareOverviewResponse | undefined;
  allTransactionsLoading: boolean;
  allTransactions: AllTransactionsResponse | undefined;
  fetchAllTransactionsErrorCode: ErrorCode | null;
  nationalIdentificationsResponse: AsyncResponse<AgentNationalIdsResponse>;
  agentFilesResponse: AsyncResponse<AgentDocumentsResponse>;
  loadingAgentWebsite: boolean;
  agentWebsite: AgentWebsiteResponse | undefined;
  agentLiteById: Mapping<AgentLiteResponse>;
  notificationSetting: GlobalNotificationPreferencesDto | null;
  agentUplineInfoById: Mapping<AsyncResponse<SponsorTreeResponse>>;
  paginatedAgentDebtsResponse: AsyncResponse<AgentDebtsResponse>;
  debtCreateInvoiceLoading: Mapping<boolean>;
  publicTransactionResponseById: Mapping<PublicTransactionResponse>;
  publicTransactionResponseByIdLoading: boolean;
  publicTransactionResponseByIdErrorCode: ErrorCode | null;
  willBeneficiaryAndTypeResponse: AsyncResponse<WillBeneficiaryAndTypeResponse>;
  willableRevshareAgreementResponse: AgreementResponse | undefined;
  signAgentAgreement: boolean;
  agentAgreementLoading: boolean;
  agentAgreementDefinitionResponse: AgreementDefinitionResponse[] | undefined;
  agentAgreementDefinitionWithAmendmentsResponse: AsyncResponse<AgreementDefinitionWithAmendmentsResponse>;
  agentAgreementsResponse: AgreementResponse[] | undefined;
  agentNonSignedAgreementsResponse: AgreementResponse | undefined;
  allLatestAgreementsNotSigned: AgreementResponse[] | undefined;
  agentPayableInfo: AsyncResponse<PayableInfoResponse>;
  agentCredits: AsyncResponse<GenericSearchResponseCreditResponse>;
  activeAgreementDefinitionsResponse: AsyncResponse<GenericSearchResponseAgreementDefinitionResponse>;
  createdAgreementDefinitionsResponse: AsyncResponse<GenericSearchResponseAgreementDefinitionResponse>;
  archivedAgreementDefinitionsResponse: AsyncResponse<GenericSearchResponseAgreementDefinitionResponse>;
}

export interface AsyncResponse<T, P extends Record<string, any> = {}> {
  data?: T;
  error?: ErrorCode | null;
  loading: boolean;
  name: string;
  additionalProps?: P;
}

export interface RoleSliceState {
  rolesResponse: AsyncResponse<RoleResponse[]>;
  grantorRolesResponse: AsyncResponse<RoleResponse[]>;
}

export interface TaxInformationState {
  taxFormResponseByAgentId: Mapping<AsyncResponse<TaxFormsResponse>>;
}

export interface ApplicationState {
  loadingDetail: boolean;
  fetchDetailErrorCode: null | ErrorCode;
  applicationDetail: ApplicationResponse | undefined;
}

export interface MLSState {
  loadingDetail: boolean;
  detail: MlsResponse | undefined;
  fetchDetailErrorCode: null | ErrorCode;
  loadingMlsPropertyTypes: boolean;
  fetchMlsPropertyTypesErrorCode: ErrorCode | null;
  mlsPropertyTypes: MlsGetPropertyTypeResponse | null;
  mlsPropertyTypesWanderer: MlsPropertyTypesWandererType;
}

export interface BoardState {
  loadingDetail: boolean;
  detail: BoardResponse | undefined;
  fetchDetailErrorCode: null | ErrorCode;
}

export interface BankState {
  loadingDetail: boolean;
  bankDetail: BankAccountResponse | null;
  errorCode: ErrorCode | null;
  pendingCount: number;
  reconciledCount: number;
}

export interface OfficeState {
  loadingDetail: boolean;
  officeDetail: OfficeResponse | undefined;
  fetchDetailErrorCode: null | ErrorCode;
  officeDetailById: Mapping<OfficeResponse>;
  officeGroupsById: Mapping<OfficeGroupResponse>;
}

export interface WebhooksState {
  webhooksLoading: boolean;
  webhooks: WebhookDto[] | undefined;
  webhooksByUserIdErrorCode: ErrorCode | null;
  webhookEvents: WebhookEventsDto;
  webhookDetails: WebhookDto;
}

export interface TransactionState {
  loadingTransactionOverview: boolean;
  fetchTransactionOverviewErrorCode: null | ErrorCode;
  loadingTransactionDetail: boolean;
  transactionDetailResponse: AsyncResponse<TransactionResponse>;
  fetchTransactionDetailErrorCode: null | ErrorCode;
  loadingCommissionParticipants: boolean;
  fetchCommissionParticipantsErrorCode: null | ErrorCode;
  escrowDetails: EscrowResponse | undefined;
  currentTransactionOverview: TransactionsOverviewResponse | undefined;
  transactionPermissions: TransactionPermissionsResponse | undefined;
  pagedOpenTransactions: PagedTransactionResponse | undefined;
  pagedClosedTransactions: PagedTransactionResponse | undefined;
  pagedTerminatedTransactions: PagedTransactionResponse | undefined;
  pagedOpenListingTransactions: PagedTransactionResponse | undefined;
  pagedClosedListingTransactions: PagedTransactionResponse | undefined;
  pagedTerminatedListingTransactions: PagedTransactionResponse | undefined;
  hasPaymentInvoices: boolean;
  transactionOutgoingPayments: OutgoingPaymentWithBankInfoResponse[];
  sentimentDetails: SentimentDisplayResponse | undefined;
  brokerSentimentDetails: SentimentDisplayResponse | undefined;
  showClosedModal: boolean;
  notificationSetting: PublisherNotificationPreferencesDto | undefined;
  features: FeaturesResponse | undefined;
  loadingTitleProgress: boolean;
  titleProgress: TitleProgress | undefined;
  tokenTransactionDetail: CheckDepositTokenResponse | undefined;
  transactionsCountByLifecycleGroup: TransactionsCountByLifecycleGroupType;
  listingCountByLifecycleGroup: ListingCountsByLifecycleGroupType;
  doubleEnderTransaction: AsyncResponse<TransactionResponse>;
  doubleEnderAgent: AsyncResponse<AgentResponse>;
  commentsMentionUsers: TransactionCommentResponse | undefined;
  transactionPaginatedResultsByIdWithFilters:
    | PaginatedResultsByIdWithFiltersResponse<TransactionResponse>
    | undefined;
  recordingDataById: Mapping<{
    recordingBlock?: VoiceCallBlock;
    mentionBlock?: MentionBlock;
  }>;
  commentTemplates: CommentTemplateResponse[];
}

export interface ReferralState {
  loadingAllReferrals: boolean;
  myReferrals: any[] | undefined;
  errorFetchingMyReferrals: ErrorCode | null;
}

export type PaginatedResultsByIdWithFiltersResponse<T> = {
  results: {
    [key: number]: string;
  };
  hasNext?: boolean;
  totalCount?: number;
  filters?: IPaginateReq<T>;
  officeIds?: string[];
  agentId?: string;
  lifecycleGroup?: 'OPEN' | 'CLOSED' | 'TERMINATED';
  transactionTypes?: TransactionType[];
};

export type TransactionsCountByLifecycleGroupType = EnumMap<
  LifecycleGroupEnum,
  number
>;

export type ListingCountsByLifecycleGroupType = EnumMap<
  LifecycleGroupEnum,
  number
>;

export interface TeamState {
  loading: boolean;
  teamDetailOverview: TeamResponse | null;
  fetchErrorCode: null | ErrorCode;
  teamsById: Mapping<TeamResponse>;
  flexTeamDetailOverview: null | FlexTeamDto;
}

export enum PublisherTypeEnum {
  TRANSACTION = 'TRANSACTION',
  REFERRAL = 'REFERRAL',
  LISTING = 'LISTING',
  CHECKLIST_ITEM = 'CHECKLIST_ITEM',
}

export interface NetworkState {
  networksByIdResponse: AsyncResponse<Mapping<FrontLineAgentInfo[]>>;
  loading: boolean;
  zenLoading: boolean;
  zenNetworksById: Mapping<FrontLineAgentsResponse>;
  fetchErrorCode: null | ErrorCode;
  zenFetchErrorCode: null | ErrorCode;
  downLineAgentsById: Mapping<AsyncResponse<DownLineAgentsResponse>>;
}

export interface JointVentureState {
  loading: boolean;
  showInviteResponse: AsyncResponse<InviteResponse>;
  modalToggle: boolean;
  jointVenturesResponse: AsyncResponse<JointVentureResponse[]>;
  invitedJointVentures: AsyncResponse<InviteResponse[]>;
}

export interface UserIdsState {
  loading: boolean;
  usersById: Mapping<AgentPublicInfo>;
  fetchUsersByIdErrorCode: null | ErrorCode;
  agentById: Mapping<AgentInfo>;
  agentByKeymakerId: Mapping<AgentCommentDetails>;
}

export interface CronJobsState {
  cronJobsResponse: AsyncResponse<JobExecutionsResponse>;
  cronJobHistoryLoading: boolean;
  cronJobHistory: JobExecutionResponse[] | undefined;
  fetchCronJobHistoryErrorCode: ErrorCode | null;
}

export interface RevenueState {
  pendingPaymentsOverview: RevSharePaymentPreviewResponse | null;
  pendingPaymentsLoading: boolean;
  pendingPaymentsError: ErrorCode | null;
  previousPayments: RevSharePaymentsResponse | null;
  previousPaymentsLoading: boolean;
  previousPaymentsError: ErrorCode | null;
  contributors: RevSharePaymentResponse | null;
  contributorsLoading: boolean;
  contributorsError: ErrorCode | null;
  networkSizeByTierResponse: AsyncResponse<NetworkSizeByTierResponse>;
  revShareByTierResponse: AsyncResponse<RevshareByTierResponse>;
  revshareHistoryResponse: AsyncResponse<RevShareHistoryResponse>;
  agentBadgesResponse: AsyncResponse<AgentBadgesResponse>;
  generalBadgesResponse: AsyncResponse<BadgesResponse>;
  agentBadgeByName: Partial<Record<BadgeResponseNameEnum, AgentBadgeResponse>>;
  generalBadgeByName: Partial<Record<BadgeResponseNameEnum, BadgeResponse>>;
}

export interface InstantPaymentState {
  instantPaymentsSummaryResponse: AsyncResponse<InstantPaymentsSummary>;
  feeCalculation: InstantPaymentEligibleItemsResponse | undefined;
  isFeeCalculationLoading: boolean;
  feeCalculationError: ErrorCode | null;
  instantPayment: InstantPaymentDetailResponse | undefined;
  instantPaymentLoading: boolean;
  instantPaymentErrorCode: ErrorCode | null;
}

export interface ExperimentSliceState {
  experiments: BoundExperimentResponse[];
  experimentDetail: ExperimentResponse | undefined;
  loadingDetail: boolean;
  fetchDetailErrorCode: null | ErrorCode;
  isUpdatingExperiment: boolean;
}
export interface ReleaseFundsState {
  refunds: AsyncResponse<RefundResponse[]>;
  refundAttachments: ModifiedRefundAttachmentResponse[];
}

export interface DemoModeSliceState {
  enabled: boolean;
}

export interface AnnouncementState {
  loading: boolean;
  topAnnouncement: AnnouncementResponse | undefined;
  topAnnouncementLoading: boolean;
  showEditModal: boolean;
  announcementDefinitionDetail: AnnouncementDefResponse | undefined;
  editAnnouncementId: string | undefined;
  previewAnnouncementId: string | undefined;
  announcementDefinitionMediaInfo: AsyncResponse<MediaInfoResponse>;
}

export enum PageContextEnum {
  CONTEXT_TRANSACTION = 'context_transaction',
  CONTEXT_PROFILE = 'context_profile',
  CONTEXT_NONE = 'context_none',
}
export interface LeoState {
  loading: boolean;
  isChatLoading: boolean;
  isHistoryLoading: boolean;
  deletingSessionId: string | undefined;
  showIntro: boolean;
  isLeoThinking: boolean;
  isLeoGenerating: boolean;
  isLeoModalVisible: boolean;
  session: SessionWithDisclaimer | undefined;
  isSessionError: boolean;
  chatHistory: SessionHistory[];
  currentChat: CurrentChat[];
  selectedItems: FollowUpItem[];
  defaultPrompts: ContextualPrompt[];
  msgContext: LeoResponseMessageContext | undefined;
  isCreatingTicket: boolean;
  isTicketCreated: boolean;
  isNudgeEnabled: boolean;
  leoQuestionManager: {
    workQueue: {
      dateFilterType: DateFilterEnum;
      dateRange: DateRange;
    };
    assignedQueue: {
      dateFilterType: DateFilterEnum;
      dateRange: DateRange;
    };
    completedQueue: {
      dateFilterType: DateFilterEnum;
      dateRange: DateRange;
    };
  };
  assigneeUserIds: string[];
  checklistItemDocumentErrorLoading: boolean;
  checklistItemDocumentErrorResponseById: Mapping<any>;
  checklistErrorDetectionModalData: any | undefined;
  notifications: LeoNotification[];
}

export interface MortgageState {
  loanResponse: AsyncResponse<LoanDto>;
  uploadingPreApprovalLetter: boolean;
  borrowerProgress: Record<string, AsyncResponse<ApplicationProgressResponse>>;
  borrowerApplications: Record<string, AsyncResponse<BorrowerApplicationDto>>;
  borrowerAccountsResponse: AsyncResponse<BankCustomerAccounts>;
  formValidityById: Record<string, boolean> | undefined;
  showApplicationError: boolean;
  borrowerDetailsForInbox: AsyncResponse<BorrowerForCommentDto>;
}

export interface CheckDepositState {
  loading: boolean;
  uploadedCheckDeposits: CheckDepositUploadResponse | undefined;
  uploadedCheckDepositsErrorCode: ErrorCode | null;
  uploadedCheckDepositsErrorMessages: string | undefined;
  checkDepositsDetails: CheckDepositDto | undefined;
  confirmationId: number | null;
  submitCheckDepositsErrorCode: ErrorCode | null;
  checkDepositsListLoading: boolean;
  checkDepositsList: CheckDepositDto[] | undefined;
  checkDepositsListErrorCode: ErrorCode | null;
  requestCounter: CheckDepositsCounter[];
}

export interface CheckListState {
  checklistLoading: boolean;
  checklistsById: Mapping<AsyncResponse<ChecklistResponse>>;
  latestCommentsByChecklistItemId: AsyncResponse<Record<string, CommentDto>>;
  checklistErrorCode: ErrorCode | null;
  checklistItem: ItemResponse | null;
  isChecklistItemLoading: boolean;
  documentUploadLoading: Record<string, boolean>;
  journey: ChecklistResponse | null;
  journeyProgressById: Mapping<ChecklistProgressResponse>;
  checklistProgressById: Mapping<ChecklistProgressResponse>;
  checklistCommentsById: Mapping<QueryCommentsRs>;
  checklistItemCountById: Mapping<number>;
  isAgentOnboardingCompleteModalShown: boolean;
}
export interface ActivityState {
  loadingActivities: boolean;
  activities: ActivityResponse[];
  activitiesErrorCode: ErrorCode | null;
}

export interface MoneyTransferState {
  moneyTransferLoading: boolean;
  moneyTransferErrorCode: ErrorCode | null;
  moneyTransfers: MoneyTransferResponse[];
  bankAccounts: BankAccountDto[];
}

export interface ConveyanceDocumentState {
  conveyancesLoading: boolean;
  conveyances: ConveyanceLetterRevisionResponse[];
  conveyancesErrorCode: ErrorCode | null;
  conveyanceDocument: ConveyanceResponse | null;
  conveyanceDocumentErrorCode: ErrorCode | null;
  conveyanceDocumentLoading: boolean;
}

export interface TransactionBuilderState {
  transactionBuilderIdLoading: boolean;
  transactionBuilderId: string | undefined;
  transactionBuilderIdErrorCode: ErrorCode | null;
  transactionBuilderLoading: boolean;
  transactionBuilder: TransactionBuilderResponse | undefined;
  transactionBuilderErrorCode: ErrorCode | null;
  isErrorModalVisible: boolean;
  transactionErrors: Array<TransactionError> | undefined;
  commissionPayerRoles: CommissionPayerDisplayValue[] | undefined;
  loadingCommissionPayerRoles: boolean;
  builderFeatures: FeaturesResponse | undefined;
}

export interface BrokerQueueState {
  isLoading: boolean;
  markingReadUnreadLoading: boolean;
  nextPage: string | undefined;
  messages: MessageDto[];
  unreadMessageCount: number | undefined;
  metabaseUrl: AsyncResponse<MetabaseDashboardUrlResponse | undefined>;
}

export interface ChecklistDefinitionState {
  checklistDefinitionById: Mapping<AsyncResponse<ChecklistDefinitionDto>>;
  itemDefinitionById: Mapping<AsyncResponse<ItemDefinitionDto>>;
  checklistDefinitionByOfficeId: Mapping<
    AsyncResponse<YentaChecklistDefinitionDto[]> | undefined
  >;
  documentUploadLoading: Record<string, boolean>;
  showCreateTriggerModal: boolean;
  showEditTriggerModal: boolean;
  selectedTrigger: TriggerDefinitionDto;
}

export interface CalendarState {
  calendars: CalendarResponse[];
  calendarLoading: boolean;
  calendarErrorCode: ErrorCode | null;
  agentCalendarsResponse: AsyncResponse<CalendarInfosResponse>;
  eventsByTab: Mapping<CalendarEventResponse[]>;
  eventDetail: CalendarEventResponse | undefined;
  allEventsResponse: AsyncResponse<CalendarEventResponse[]>;
  eventsCountResponse: AsyncResponse<CalendarCountOverviewResponse>;
  eventInviteesCount: CalendarInviteesCountResponse | undefined;
  pagedEventInviteesByStatus: Mapping<CalendarEventInviteePaginatedResponse>;
  eventInviteesLoading: boolean;
  eventInviteesErrorCode: ErrorCode | null;
}

export interface ClientApiKeyState {
  clientApiKeys: ApiKeyResponse[];
}

export interface BreadCrumbsPath {
  title: string;
  url: string;
}

export interface ReduxStateFactory<T> {
  getState(state: Partial<T>): T;
}

export type TabsVariant =
  | 'default'
  | 'spread'
  | 'material'
  | 'material_lg'
  | 'material_full_border';

export type ZenTabsVariant = 'default' | 'secondary';

export type VerticalAlignmentVariant =
  | 'align-top'
  | 'align-middle'
  | 'align-bottom';

export interface Agent {
  id: number;
  name: string;
  title: string;
  cap: number;
  isActive: boolean;
  imageUrl: string;
}

export interface AgentTransactionCard {
  title: number;
  subtitle: string;
}

export enum AgentTransactionStatus {
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
  CLOSED = 'CLOSED',
}

export interface IAgent {
  id: number;
  name: string;
  realID: number;
  phone: string;
  address: string;
  primaryEmail: string;
  secondaryEmail: string;
  status: string;
  accountPlan: string;
  licenseNumber: string;
  expiryDate: string;
  imageUrl: string;
  roles?: UserType[];
}

export enum ActiveInactiveType {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum TeamType {
  DOMESTIC = 'DOMESTIC',
  NORMAL = 'NORMAL',
  MEGA = 'MEGA',
}

export interface People {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  imageUrl: string;
  capResetOnDate: string;
  status: ActiveInactiveType;
  licenseState: string;
  licenseCity: string;
  createdAt: number;
  updatedAt: number;
}

export interface OfficeIndexResponse {
  id: number;
  principalBroker: PrincipleBroker;
  companyName: string;
  ein: number;
  transactionDisbursementType: OfficePreviewResponseTransactionTypeEnum;
  state: StateAbbreviation;
  address: string;
  totalAgents: number;
}

export interface PrincipleBroker {
  id: number;
  name: string;
  imageUrl: string;
}

export enum TransactionDisbursementType {
  SPLIT_CHECK = 'SPLIT_CHECK',
  SINGLE_CHECK = 'SINGLE_CHECK',
}

export interface TransactionAgent {
  id: number;
  name: string;
  imageUrl: string;
}

export interface Transaction {
  id: number;
  name: string;
  status: TransactionLifecycleStateValueStateEnum;
  type: TransactionPreviewResponseTransactionTypeEnum;
  closingDate: string;
  salesPrice: MoneyValue;
  totalCommission: MoneyValue;
  participantsSplit: MoneyValue;
  participantsSplitPercentage: number;
  totalRealSplit: MoneyValue;
  totalRealSplitPercentage: number;
  agents: TransactionAgent[];
  state: StateAbbreviation;
  teamId: number;
  transactionCode: string;
}

export interface TransactionCDA {
  id: number;
  revisionNumber: number;
  status: TransactionLifecycleStateValueStateEnum;
  closingDate: string;
  createdDate: string;
}

export interface Team {
  id: number;
  name: string;
  type: TeamType;
  totalMembers: number;
  leader: Leader;
  members: Member[];
  status: ActiveInactiveType;
}

export interface Leader {
  id: number;
  name: string;
  imageUrl: string;
}

export interface Member {
  id: number;
  name: string;
  imageUrl: string;
}

export interface IPaginateReq<D> {
  filter?: TableResourceFilter<D>;
  sortBy?: InitialSort<D>;
  page: number;
  pageSize: number;
  search?: string;
  name?: string;
  country?: SearchActiveAgentCountryType;
  stateOrProvince?: SearchActiveAgentStateProvinceType;
}

export interface IPaginateRes<D> {
  data: Array<D>;
  total: number;
}

export interface Avatar {
  imageUrl?: string;
  text?: string;
}

export interface ColoredProgressBarItem {
  value: number;
  className?: string;
}

export interface TransactionOverview {
  id: number;
  title: string;
  closingDate: string;
  commission: MoneyValue;
}

export interface AvatarPillCellItem {
  id: number;
  name: string;
  imageUrl: string;
}

export enum StateAbbreviation {
  AK = 'AK',
  CA = 'CA',
  CO = 'CO',
  CT = 'CT',
  DC = 'DC',
  FL = 'FL',
  GA = 'GA',
  IL = 'IL',
  LA = 'LA',
  MA = 'MA',
  MD = 'MD',
  MO = 'MO',
  NC = 'NC',
  NJ = 'NJ',
  NY = 'NY',
  OH = 'OH',
  OK = 'OK',
  PA = 'PA',
  RI = 'RI',
  SC = 'SC',
  TN = 'TN',
  TX = 'TX',
  UT = 'UT',
  VA = 'VA',
  WA = 'WA',
}

export interface MLS {
  id: number;
  name: string;
  logo: string;
  code: string;
  state: StateAbbreviation;
  brokerImage: string;
  brokerName: string;
}

export interface Board {
  id: number;
  name: string;
  logo: string;
  code: string;
  state: StateAbbreviation;
  brokerImage: string;
  brokerName: string;
}

export interface PrimaryBrokerDetail {
  id: number;
  name: string;
  imageUrl: string;
  license: string;
  phone: string;
  email: string;
  bio: string;
  dob: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: StateAbbreviation;
  zip: string;
}

export interface OfficeDetail {
  id: number;
  name: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: StateAbbreviation;
  zip: string;
  phone: string;
  fax: string;
  agentsCount: number;
  activeListingsCount: number;
  hudNumber: string;
  mlsOfficeId: string;
  brokerageLicenseId: string;
  primaryBroker: PrimaryBrokerDetail;
  announcementText?: string;
  announcementType?: PageAnnouncementVariant;
}

export interface OfficeDetailResponse {
  office: OfficeDetail;
}
export interface IAgentRow {
  id: string;
  name: string;
  email: string;
  phone: string;
  imageUrl: string;
  zip: string;
}

export interface OfficeAgentsResponse {
  agents: IAgentRow[];
}

export interface ITransactionsOverviewResponse {
  activeListings: number;
  pending: number;
  closed: number;
  openTransactions: TransactionOverview[];
  waitingTransactions: TransactionOverview[];
}

export interface TeamDetailOverviewResponse {
  id: number;
  name: string;
  type: TeamType;
  totalMember: number;
  recentTransactions: TransactionOverview[];
  createdAt: string;
}

export enum RoleType {
  LEADER = 'LEADER',
  MEMBER = 'MEMBER',
}

export interface TeamAgents {
  id: number;
  name: string;
  imageUrl: string;
  role: RoleType;
  status: ActiveInactiveType;
  transactionDisbursementType: TransactionDisbursementType;
  commissionSplit: number;
  joinedAt: string;
}

export enum CountryName {
  US = 'United States',
  CA = 'Canada',
}

export enum StateName {
  AL = 'Alabama',
  AK = 'Alaska',
  AZ = 'Arizona',
  AR = 'Arkansas',
  CA = 'California',
  CO = 'Colorado',
  CT = 'Connecticut',
  DE = 'Delaware',
  DC = 'District of Columbia',
  FL = 'Florida',
  GA = 'Georgia',
  GU = 'Guam',
  HI = 'Hawaii',
  ID = 'Idaho',
  IL = 'Illinois',
  IN = 'Indiana',
  IA = 'Iowa',
  KS = 'Kansas',
  KY = 'Kentucky',
  LA = 'Louisiana',
  ME = 'Maine',
  MI = 'Michigan',
  MN = 'Minnesota',
  MA = 'Massachusetts',
  MD = 'Maryland',
  MS = 'Mississippi',
  MO = 'Missouri',
  MT = 'Montana',
  NE = 'Nebraska',
  NV = 'Nevada',
  NH = 'New Hampshire',
  NC = 'North Carolina',
  NJ = 'New Jersey',
  NM = 'New Mexico',
  NY = 'New York',
  ND = 'North Dakota',
  OH = 'Ohio',
  OK = 'Oklahoma',
  OR = 'Oregon',
  PA = 'Pennsylvania',
  PR = 'Puerto Rico',
  RI = 'Rhode Island',
  SC = 'South Carolina',
  SD = 'South Dakota',
  TN = 'Tennessee',
  TX = 'Texas',
  UT = 'Utah',
  VT = 'Vermont',
  VA = 'Virginia',
  WA = 'Washington',
  WV = 'West Virginia',
  WI = 'Wisconsin',
  WY = 'Wyoming',
}

export const AdministrativeAreaStateEnum: EnumMap<
  AddressStateOrProvinceEnum,
  string
> = {
  ALABAMA: 'US-AL',
  ALASKA: 'US-AK',
  ARIZONA: 'US-AZ',
  ARKANSAS: 'US-AR',
  CALIFORNIA: 'US-CA',
  COLORADO: 'US-CO',
  CONNECTICUT: 'US-CT',
  DELAWARE: 'US-DE',
  DISTRICT_OF_COLUMBIA: 'US-DC',
  FLORIDA: 'US-FL',
  GEORGIA: 'US-GA',
  HAWAII: 'US-HI',
  IDAHO: 'US-ID',
  ILLINOIS: 'US-IL',
  INDIANA: 'US-IN',
  IOWA: 'US-IA',
  KANSAS: 'US-KS',
  KENTUCKY: 'US-KY',
  LOUISIANA: 'US-LA',
  MAINE: 'US-ME',
  MARYLAND: 'US-MD',
  MASSACHUSETTS: 'US-MA',
  MICHIGAN: 'US-MI',
  MINNESOTA: 'US-MN',
  MISSISSIPPI: 'US-MS',
  MISSOURI: 'US-MO',
  MONTANA: 'US-MT',
  NEBRASKA: 'US-NE',
  NEVADA: 'US-NV',
  NEW_HAMPSHIRE: 'US-NH',
  NEW_JERSEY: 'US-NJ',
  NEW_MEXICO: 'US-NM',
  NEW_YORK: 'US-NY',
  NORTH_CAROLINA: 'US-NC',
  NORTH_DAKOTA: 'US-ND',
  OHIO: 'US-OH',
  OKLAHOMA: 'US-OK',
  OREGON: 'US-OR',
  PENNSYLVANIA: 'US-PA',
  PUERTO_RICO: 'US-PR',
  RHODE_ISLAND: 'US-RI',
  SOUTH_CAROLINA: 'US-SC',
  SOUTH_DAKOTA: 'US-SD',
  TENNESSEE: 'US-TN',
  TEXAS: 'US-TX',
  UTAH: 'US-UT',
  VERMONT: 'US-VT',
  VIRGINIA: 'US-VA',
  WASHINGTON: 'US-WA',
  WEST_VIRGINIA: 'US-WV',
  WISCONSIN: 'US-WI',
  WYOMING: 'US-WY',
  ALBERTA: 'CA-AB',
  BRITISH_COLUMBIA: 'CA-BC',
  MANITOBA: 'CA-MB',
  NEWFOUNDLAND_AND_LABRADOR: 'CA-NL',
  NEW_BRUNSWICK: 'CA-NB',
  NOVA_SCOTIA: 'CA-NS',
  ONTARIO: 'CA-ON',
  PRINCE_EDWARD_ISLAND: 'CA-PE',
  QUEBEC: 'CA-QC',
  SASKATCHEWAN: 'CA-SK',
};

export interface Income {
  month: string;
  revenue: number;
}

export interface Commission {
  name: string;
  value: number;
}

export interface RevenueShare {
  name: string;
  value: number;
}

export interface Equity {
  name: string;
  value: number;
}

export interface TeamCardStats {
  id: number;
  name: string;
  split: number;
}

export interface BasicPrimaryBroker {
  id: number;
  name: string;
  imageUrl: string;
  phone: string;
  transactionDisbursementType: TransactionDisbursementType;
}

export interface OfficeCard {
  id: number;
  state: StateName;
  primaryBroker: BasicPrimaryBroker;
}

export interface CommissionCap {
  resetDate: string;
  amount: number;
}

export interface ProfileScore {
  percentage: number;
  incomplete: string[];
}

export interface Language {
  name: string;
}

export interface AgentProfileCard {
  doj: string;
  dob: string;
  desc: string;
  address: string;
  language: Language[];
  bio: string;
}

export interface RealEstateInfo {
  title: string;
  about: string;
  worksWith: WorksWithType;
  licensedStates: StateAbbreviation[];
  zipCodes: string[];
  zipCodeLeads: boolean;
  smsLeads: boolean;
  mls: string;
  priorJoinDateGCI: number;
}

export enum TicketStatus {
  ACTIVE = 'ACTIVE',
  CLOSED = 'CLOSED',
}

export interface Ticket {
  id: number;
  title: string;
  description: string;
  createdBy: { id: number; name: string };
  assignedTo: { id: number; name: string };
  status: TicketStatus;
  createdAt: string;
}

export interface Referral {
  id: number;
  type: ReferralType;
  agentName: string;
  agentEmail: string;
  clientName: string;
  clientEmail: string;
  closingDate: string;
  referralAgreement: string[];
  status: AgentReferralStatus;
}

export interface BasicInfo {
  dob: string;
  description: string;
  joinedDate: string;
  address: string;
  languages: string[];
  bio: string;
  facebook: string;
  twitter: string;
  instagram: string;
  youtube: string;
  website: string;
}

export interface MLSDetail {
  id: number;
  name: string;
  logo: string;
  state: StateAbbreviation;
  phone: string;
  email: string;
  agentsCount: number;
  activeListingsCount: number;
  code: string;
  registrationFee: number;
  joinDate: string;
  status: ActiveInactiveType;
  primaryBroker: PrimaryBrokerDetail;
}

export interface BoardDetail {
  id: number;
  name: string;
  logo: string;
  state: StateAbbreviation;
  phone: string;
  email: string;
  agentsCount: number;
  activeListingsCount: number;
  code: string;
  registrationFee: number;
  joinDate: string;
  status: ActiveInactiveType;
  primaryBroker: PrimaryBrokerDetail;
}

export interface MLSDetailResponse {
  mls: MLSDetail;
}

export interface BoardDetailResponse {
  boards: BoardDetail;
}

export interface MlsAndBoardAgentsResponse {
  agents: IAgentRow[];
}

export enum ParticipantType {
  AGENT = 'AGENT',
  EXTERNAL_ENTITY = 'EXTERNAL_ENTITY',
  OP_CITY = 'opCity',
}

export enum ParticipantRole {
  SELLERS_AGENT = 'SELLERS_AGENT',
  BUYERS_AGENT = 'BUYERS_AGENT',
  BUYER = 'BUYER',
  SELLER = 'SELLER',
  CLOSER = 'CLOSER',
}

export interface Participant {
  id: string;
  name: string;
  imageUrl: string;
  status: TransactionLifecycleStateValueStateEnum;
  type: ParticipantType;
  role: ParticipantRole;
  payment: Payment | null;
}

export interface Payment {
  commissionSplit: number;
  totalCommission: number;
  realSplit: number;
  realStocksValue: number;
  realPersonalDealFee: number;
  transactionFee: number;
  brokerageFee: number;
  mlsFee: number;
  clientRebate: number;
  netPayout: number;
}

export interface CommissionSplit {
  name: string;
  commission: number;
  subtitle: number;
}

export interface CoordinatorInformation {
  id: number;
  name: string;
  email: string;
  phone: string;
}
export interface TransactionsDetail {
  id: number;
  participants: Participant[];
}

export interface EscrowAccountDetails {
  escrowAccountDetail: string;
  escrowAccountNumber: number;
  escrowRoutingNumber: number;
}

export enum AccountPlanType {
  STANDARD = 'STANDARD',
  POWER = 'POWER',
  ENTERPRICE = 'ENTERPRICE',
}

export interface ISelectOption<T extends string = string, K = string> {
  value: T;
  label: K;
  subLabel?: string;
  disabled?: boolean;
}

export enum DateFilterType {
  inTheLast = 'inTheLast',
  equals = 'equals',
  between = 'between',
  isAfter = 'isAfter',
  isAfterOrOn = 'isAfterOrOn',
  isBefore = 'isBefore',
  isBeforeOrOn = 'isBeforeOrOn',
}

export enum DateFilterTypeLabel {
  inTheLast = 'is in the last',
  equals = 'is equal to',
  between = 'is between',
  isAfter = 'is after',
  isAfterOrOn = 'is on or after',
  isBefore = 'is before',
  isBeforeOrOn = 'is before or on',
}

export enum NumberFilterType {
  equals = 'equals',
  between = 'between',
  greaterThan = 'greaterThan',
  greaterThanOrEqual = 'greaterThanOrEqual',
  lessThan = 'lessThan',
  lessThanOrEqual = 'lessThanOrEqual',
}

export enum NumberFilterTypeLabel {
  equals = 'is equal to',
  between = 'is between',
  greaterThan = 'is greater than',
  greaterThanOrEqual = 'is greater than or equal to',
  lessThan = 'is less than',
  lessThanOrEqual = 'is less than  or equal to',
}

export enum FilterColumnsToProcess {
  DATE = 'DATE',
  NUMBER = 'NUMBER',
  ENUM = 'ENUM',
  MULTI_ENUM = 'MULTI_ENUM',
  STRICT_CASE = 'STRICT_CASE',
  BOOLEAN = 'BOOLEAN',
  BOOLEAN_NULLABLE = 'BOOLEAN_NULLABLE',
}

export interface CalendarItem {
  title: string;
  startDate: string;
  actionText: string;
}

export interface CalendarGroup {
  day: string;
  events: CalendarItem[];
}

export interface GetCalendarEventsResponse {
  events: CalendarItem[];
}

export interface AsyncSelectOption {
  label: string;
  value: string;
  color?: string;
}

export interface AsyncSelectOptionReactElement {
  label: string | React.ReactElement | {};
  value: string;
  color?: string;
}
export interface AsyncSelectAdditional {
  page: number;
}

export enum FilterOperation {
  gt = 'gt',
  gte = 'gte',
  lt = 'lt',
  lte = 'lte',
  eq = 'eq',
}

export interface BillingInformation {
  name: string;
  address: string;
  email: string;
  phoneNumber: string;
}

export interface ShippingInformation {
  name: string;
  address: string;
  email: string;
  phoneNumber: string;
}

export interface PaymentDetails {
  description: string;
  quantity: number;
  unitPrice: number;
  amount: number;
}

export interface BankInformation {
  bankName: string;
  accountName: string;
  routingNumber: string;
  accountNumber: string;
  swiftCode?: string;
}

export interface InvoiceResponse {
  id: number;
  invoiceNumber: string;
  issuedAt: string;
  dueAt: string;
  propertyAddress: string;
  billingInformation: BillingInformation;
  shippingInformation: ShippingInformation;
  tenantName: string;
  monthlyRent: number;
  moveInAt: string;
  paymentDetails: PaymentDetails[];
  subtotal: number;
  amountDue: number;
  bankInformation: BankInformation;
}

export enum PayeeRole {
  AGENT = 'AGENT',
  BROKER = 'BROKER',
}

export interface Payees {
  name: string;
  role: PayeeRole;
  license: string;
  email: string;
  phoneNumber: string;
  paymentInstructions: string;
  bankInformation: BankInformation | null;
  payout: number;
  officeAddress: string;
}

export enum RepresentingRole {
  BUYER = 'BUYER',
  SELLER = 'SELLER',
}

export interface CDAForm {
  transactionId: number;
  propertyAddress: string;
  estimatedClosingDate: string;
  salesPrice: number;
  representingRole: RepresentingRole;
  buyerName: string;
  sellerName: string;
  buyerCommissionAmount: number;
  sellerCommissionAmount: number;
  totalCommission: number;
  payees: Array<Payees>;
  createdAt: string;
  revisionNumber: number;
  approvedAt: string;
  comment: string;
  qrCodeUrl: string;
}

export interface CDAFormResponse {
  cdaForm: CDAForm;
}

export interface FormFieldConfig {
  label?: string;
  secondaryLabel?: string | React.ReactElement;
  helperText?: string;
  placeholder?: string;
  type: InputFieldType;
  name: string;
  reviewLabel?: string;
  row?: number;
  options?: Array<ISelectOption>;
  defaultValue?: string;
  naMessage?: string;
  showConfirmNext?: boolean;
  getConfirmationModalOpenStatus?: (values: any) => any;
  confirmationVariant?: string;
  confirmationTitle?: string;
  confirmationSubtitle?: string;
  extraFields?: FormFieldConfig[];
  shouldDisplayField?: (value: any) => any;
  rules?: Exclude<
    RegisterOptions,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs'
  >;
  datePickerConfig?: Omit<
    ReactDatePickerProps,
    'name' | 'onChange' | 'selected' | 'onBlur'
  >;
  resolveFieldDisplayValue?: (value: any) => any;
  convertValueBeforeSend?: (value: any, formData: any) => any;
  fetchData?: (
    search: string,
    page?: number | undefined,
  ) => Promise<AsyncSelectOption[]>;
  isRequired?: boolean;
}

export enum InputFieldType {
  TEXT = 'TEXT',
  DATE = 'DATE',
  SELECT = 'SELECT',
  MULTISELECT = 'MULTISELECT',
  RADIO = 'RADIO',
  CHECKBOX = 'CHECKBOX',
  PHONE = 'PHONE',
  UPLOAD = 'UPLOAD',
  TEXTAREA = 'TEXTAREA',
  ASYNC_CREATABLE_SELECT = 'ASYNC_CREATABLE_SELECT',
  IMAGE_SELECT = 'IMAGE_SELECT',
  IMAGE_UPLOAD = 'IMAGE_UPLOAD',
}

export enum YesNoType {
  YES = 'YES',
  NO = 'NO',
}

export interface onBoardingStepType {
  name: string;
  subtitle?: string;
  action?: {
    buttonText: string;
    onClick(): void;
  };
}

export interface DownloadStateLicenseResponse {
  id: number;
  url: string;
}

export interface FAQType {
  question: string;
  answer: string;
}

export enum WorksWithType {
  BUYERS = 'BUYERS',
  SELLERS = 'SELLERS',
  BUYERS_SELLERS = 'BUYERS_SELLERS',
}

export enum LanguageType {
  ALBANIAN = 'ALBANIAN',
  AMERICAN_SIGN_LANGUAGE = 'AMERICAN_SIGN_LANGUAGE',
  ARABIC = 'ARABIC',
  ARMENIAN = 'ARMENIAN',
  BENGALI = 'BENGALI',
  BOSNIAN = 'BOSNIAN',
  CANTONESE = 'CANTONESE',
  CROATIAN = 'CROATIAN',
  ENGLISH = 'ENGLISH',
  FARSI = 'FARSI',
  FRENCH = 'FRENCH',
  GERMAN = 'GERMAN',
  GREEK = 'GREEK',
  GUJARATI = 'GUJARATI',
  HAITIAN_CREOLE = 'HAITIAN_CREOLE',
  HEBREW = 'HEBREW',
  HINDI = 'HINDI',
  HMONG = 'HMONG',
  ITALIAN = 'ITALIAN',
  JAPANESE = 'JAPANESE',
  KHMER = 'KHMER',
  KOREAN = 'KOREAN',
  LATVIAN = 'LATVIAN',
  LITHUANIAN = 'LITHUANIAN',
  MANDARIN = 'MANDARIN',
  MONTENEGRIN = 'MONTENEGRIN',
  NAVAJO = 'NAVAJO',
  NORWEGIAN = 'NORWEGIAN',
  POLISH = 'POLISH',
  PORTUGUESE = 'PORTUGUESE',
  PUNJABI = 'PUNJABI',
  ROMANIAN = 'ROMANIAN',
  RUSSIAN = 'RUSSIAN',
  SERBIAN = 'SERBIAN',
  SPANISH = 'SPANISH',
  SWAHILI = 'SWAHILI',
  TAGALOG = 'TAGALOG',
  TAI_KADAI = 'TAI_KADAI',
  TAMIL = 'TAMIL',
  TELUGU = 'TELUGU',
  UKRAINIAN = 'UKRAINIAN',
  URDU = 'URDU',
  VIETNAMESE = 'VIETNAMESE',
  YIDDISH = 'YIDDISH',
}

export type CountryMapStateOptions = {
  [c in AdministrativeAreaRequestCountryEnum]: ISelectOption[];
};

export type ParticipantUpdateCommissionRequest = PaymentParticipantValue &
  SearchAgentResponse;

export type Transition = TransactionLifecycleStateValueStateEnum;

export interface Escrow {
  id: number;
  amount: number;
  date: string;
  status: TransactionLifecycleStateValueStateEnum;
  updatedBy: {
    name: string;
    photo: string;
  };
  info: string;
}

export enum ErrorCode {
  UNAUTHORIZED = 'UNAUTHORIZED',
  FORBIDDEN = 'FORBIDDEN',
  NOT_FOUND = 'NOT_FOUND',
  SERVER_ERROR = 'SERVER_ERROR',
}

export enum AgentReferralStatus {
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
  CLOSED = 'CLOSED',
}

export enum ReferralType {
  REAL_AGENT = 'REAL_AGENT',
  EXTERNAL_AGENT = 'EXTERNAL_AGENT',
}

export enum AgentCommissionAdvanceStatus {
  PENDING = 'PENDING',
  PAID = 'PAID',
  REPAID = 'REPAID',
}

export enum DepositReleaseRequestBankAccountTypeEnum {
  Trust = 'TRUST',
  Commission = 'COMMISSION',
}
export interface AgentCommissionAdvance {
  id: number;
  amount: MoneyValue;
  nameOfCompany: string;
  email: string;
  agreementUrl: string;
  status: AgentCommissionAdvanceStatus;
}

export interface AgentCommissionAdvancesResponse {
  commissionAdvances: AgentCommissionAdvance[];
}

export interface NetworkResponse {
  id: string;
  firstName: string;
  lastName: string;
  avatar: string | null;
  status: AgentResponseAgentStatusEnum;
  sizeOfNetwork: number;
}

export interface BreadCrumbsNoUrlType {
  title: string;
  onClick?(): void;
}

export interface SearchAgentResponse {
  id: string;
  firstName: string;
  lastName: string;
  avatar: string | null;
  emailAddress: string;
  phoneNumber: string;
}

export type SearchAPIType = 'yenta' | 'arrakis' | 'sherlock';

export enum FeatureFlagTypeEnum {
  // Keep these for testing purposes. We filter them out otherwise.
  EXAMPLE_BASIC_FEATURE = 'EXAMPLE_BASIC_FEATURE',
  EXAMPLE_ADMIN_FEATURE = 'EXAMPLE_ADMIN_FEATURE',
  INSTANT_PAYMENTS_TOGGLE = 'INSTANT_PAYMENTS_TOGGLE',
  LOGIN_AS_AGENT = 'LOGIN_AS_AGENT',
  UNDO_TRANSACTION_CLOSING = 'UNDO_TRANSACTION_CLOSING',
  APPROVE_ZERO_COMMISSION_DEAL = 'APPROVE_ZERO_COMMISSION_DEAL',
  REVENUE_SHARE_REPORT = 'REVENUE_SHARE_REPORT',
  MS_DYNAMICS_ONBOARDING = 'MS_DYNAMICS_ONBOARDING_BOLT',
  REAL_TITLE = 'REAL_TITLE',
  COPY_EVENT_LINK = 'COPY_EVENT_LINK',
  DOUBLE_ENDER = 'DOUBLE_ENDER',
  WEBHOOKS = 'WEBHOOKS',
  WALLET = 'WALLET',
  ARCHIVE_CHECKLIST_DROPBOX_FILES = 'ARCHIVE_CHECKLIST_DROPBOX_FILES',
  OFFICE_DROPBOX_FOLDERS = 'OFFICE_DROPBOX_FOLDERS',
  NEW_POWER_AUDIT_IMPROVEMENTS = 'NEW_POWER_AUDIT_IMPROVEMENTS',
  INTERNAL_REFERRAL = 'INTERNAL_REFERRAL',
  LEO_QUESTION_MANAGER = 'LEO_QUESTION_MANAGER',
  GEMINI_REDESIGN = 'GEMINI_REDESIGN',
  SELF_SERVICE_TEAM = 'SELF_SERVICE_TEAM',
  CHECKLIST_DYNAMICS_PROPERTY_TYPES = 'CHECKLIST_DYNAMICS_PROPERTY_TYPES',
  ROAR = 'ROAR',
  ROAR_VOICE_MEMO = 'ROAR_VOICE_MEMO',
  ROAR_DELETE_RECORDING_BOLT = 'ROAR_DELETE_RECORDING_BOLT',
  REAL_TITLE_PAGE_IMPROVEMENTS = 'REAL_TITLE_PAGE_IMPROVEMENTS',
  REFERRAL_CENTRAL = 'REFERRAL_CENTRAL',
  TEMPLATE_COMMENTS = 'TEMPLATE_COMMENTS',
  AGENT_TASK_MANAGEMENT = 'AGENT_TASK_MANAGEMENT',
  BOARD_AND_MLS_IMPROVEMENTS = 'BOARD_AND_MLS_IMPROVEMENTS',
  REVENUE_SHARE_PROJECTIONS = 'REVENUE_SHARE_PROJECTIONS',
  PAYOUT_EXPLANATION = 'PAYOUT_EXPLANATION',
  ICA_AGREEMENTS = 'ICA_AGREEMENTS',
  UPDATE_AGENT_COMMISSION_PLAN = 'UPDATE_AGENT_COMMISSION_PLAN',
  NEO_LEO = 'NEO_LEO',
  AUTOMATED_BROKER_REVIEW = 'AUTOMATED_BROKER_REVIEW',
}

export interface FeatureFlagRow {
  name: string;
  subtitle: string;
}

export enum EventStatus {
  SUCCEEDED = 'SUCCEEDED',
  FAILED = 'FAILED',
}

export enum InvestigationStatus {
  UNSTARTED = 'UNSTARTED',
  STARTED = 'STARTED',
  GAVE_UP = 'GAVE_UP',
  DONE = 'DONE',
}
export interface MonitorEventsResponse {
  status: EventStatus;
  investigationStatus: InvestigationStatus;
  eventType: string;
  objectType: string;
  objectId: string;
  retryCount: number;
  triggeredBy: string;
  message: string;
  notes: string;
}

export enum CanadianStates {
  Alberta = 'ALBERTA',
  BritishColumbia = 'BRITISH_COLUMBIA',
  Manitoba = 'MANITOBA',
  NewfoundlandAndLabrador = 'NEWFOUNDLAND_AND_LABRADOR',
  NewBrunswick = 'NEW_BRUNSWICK',
  NovaScotia = 'NOVA_SCOTIA',
  Ontario = 'ONTARIO',
  PrinceEdwardIsland = 'PRINCE_EDWARD_ISLAND',
  Quebec = 'QUEBEC',
  Saskatchewan = 'SASKATCHEWAN',
}

export enum UnitedStates {
  Alabama = 'ALABAMA',
  Alaska = 'ALASKA',
  Arizona = 'ARIZONA',
  Arkansas = 'ARKANSAS',
  California = 'CALIFORNIA',
  Colorado = 'COLORADO',
  Connecticut = 'CONNECTICUT',
  Delaware = 'DELAWARE',
  DistrictOfColumbia = 'DISTRICT_OF_COLUMBIA',
  Florida = 'FLORIDA',
  Georgia = 'GEORGIA',
  Hawaii = 'HAWAII',
  Idaho = 'IDAHO',
  Illinois = 'ILLINOIS',
  Indiana = 'INDIANA',
  Iowa = 'IOWA',
  Kansas = 'KANSAS',
  Kentucky = 'KENTUCKY',
  Louisiana = 'LOUISIANA',
  Maine = 'MAINE',
  Maryland = 'MARYLAND',
  Massachusetts = 'MASSACHUSETTS',
  Michigan = 'MICHIGAN',
  Minnesota = 'MINNESOTA',
  Mississippi = 'MISSISSIPPI',
  Missouri = 'MISSOURI',
  Montana = 'MONTANA',
  Nebraska = 'NEBRASKA',
  Nevada = 'NEVADA',
  NewHampshire = 'NEW_HAMPSHIRE',
  NewJersey = 'NEW_JERSEY',
  NewMexico = 'NEW_MEXICO',
  NewYork = 'NEW_YORK',
  NorthCarolina = 'NORTH_CAROLINA',
  NorthDakota = 'NORTH_DAKOTA',
  NovaScotia = 'NOVA_SCOTIA',
  Ohio = 'OHIO',
  Oklahoma = 'OKLAHOMA',
  Oregon = 'OREGON',
  Pennsylvania = 'PENNSYLVANIA',
  PuertoRico = 'PUERTO_RICO',
  RhodeIsland = 'RHODE_ISLAND',
  SouthCarolina = 'SOUTH_CAROLINA',
  SouthDakota = 'SOUTH_DAKOTA',
  Tennessee = 'TENNESSEE',
  Texas = 'TEXAS',
  Utah = 'UTAH',
  Vermont = 'VERMONT',
  Virginia = 'VIRGINIA',
  Washington = 'WASHINGTON',
  WestVirginia = 'WEST_VIRGINIA',
  Wisconsin = 'WISCONSIN',
  Wyoming = 'WYOMING',
}

export interface FormFieldTooltipIndexProps {
  tooltipIndex?: number;
}

export interface AddParticipantsQuickAction {
  agentType?: ParticipantType;
  role?: ParticipantValueRoleEnum;
  isOpen: boolean;
}

export interface AddAttachedFeeQuickAction {
  preselectedFeeType?: AttachedFeeValueFeeTypeEnum;
  isOpen: boolean;
}

export enum EmailSupportType {
  MY_APPLICATION = 'MY_APPLICATION',
  MY_TRANSACTION = 'MY_TRANSACTION',
  TECHNICAL_SUPPORT = 'TECHNICAL_SUPPORT',
  TAX_SUPPORT = 'TAX_SUPPORT',
  OTHERS = 'OTHERS',
  MY_BOARDS = 'MY_BOARDS',
  MY_MLS = 'MY_MLS',
  MY_LICENSES = 'MY_LICENSES',
  VOICE_RECORDING = 'VOICE_RECORDING',
}

export enum TransactionPrintTypeEnum {
  INVOICE = 'INVOICE',
  CDA = 'CDA',
}

export type RowSelectionConfirmType<D extends object> = {
  title: string;
  description: string;
  primaryActionTitle?: string;
  secondaryActionTitle?: string;
  modalType?: ModalVariantType;
  primaryActionType?: ButtonType;
  secondaryActionType?: ButtonType;
  childJSX?(control: Control<D, object>): void;
};

export type ZenRowSelectionConfirmType<D extends object> = {
  title: string;
  description: string;
  primaryActionTitle?: string;
  secondaryActionTitle?: string;
  modalType?: ZenModalVariantType;
  primaryActionType?: ZenButtonVariant;
  secondaryActionType?: ZenButtonVariant;
  childJSX?(control: Control<D, object>): void;
};

export interface RowsSelectionAction<D extends object> {
  label: string;
  confirm?: RowSelectionConfirmType<D>;
  onAction(data: D[], formData?: UnpackNestedValue<D>): void;
  disabled?: boolean;
}

export interface ZenRowsSelectionAction<D extends object> {
  label: string;
  variant?: ZenButtonVariant;
  confirm?: ZenRowSelectionConfirmType<D>;
  onAction(data: D[], formData?: UnpackNestedValue<D>): void;
  disabled?: boolean;
  hideCount?: boolean;
}

export enum UserType {
  SUPER_ADMIN = 'SUPER_ADMIN',
  ADMIN = 'ADMIN',
  AGENT = 'AGENT',
  BROKER = 'BROKER',
  LOAN_OFFICER = 'LOAN_OFFICER',
  MORTGAGE_ADMIN = 'MORTGAGE_ADMIN',
  LOAN_PROCESSOR = 'LOAN_PROCESSOR',
  REAL_ORIGINATE_LOAN_OFFICER = 'REAL_ORIGINATE_LOAN_OFFICER',
  REAL_TITLE = 'REAL_TITLE',
}

export interface Tax {
  id: string;
  name: string;
  percentage: string;
  exemptionReason: string;
}

export type KeyValuePair<KeyType, ValueType> = {
  key: KeyType;
  value: ValueType;
};

export interface MainMenuState {
  isCollapsed: boolean;
  openItems: string[];
}

export enum PlanNameEnum {
  US_COMMERCIAL_STANDARD_V1_PLAN = 'US_COMMERCIAL_STANDARD_V1',
  US_RESIDENTIAL_STANDARD_V1_PLAN = 'US_RESIDENTIAL_STANDARD_V1',
}

export enum AgentWebsiteOnboardingStepEnum {
  THEME = 0,
  SITE_INFO = 1,
  TESTIMONIALS = 2,
  LISTINGS = 3,
  REVIEW = 4,
}

export interface CoverPhotoSet {
  small: string;
  medium: string;
  large: string;
  cloudinaryFragment: string;
}

export interface CheckDepositsCounter {
  transactionId: string;
  counter: number;
}

export interface MlsPropertyTypesWandererType {
  [key: string]: string[];
}

export interface JourneyStepType {
  name: string;
  completedBy?: string;
  status: 'incomplete' | 'pending' | 'waiting' | 'complete';
  updatedAt: number;
}

export type EditTransactionButtonType =
  | 'primary_outline'
  | 'danger_outline'
  | 'danger_bordered'
  | 'disabled_outline';

export type ZenEditTransactionButtonType =
  | 'primary_outline'
  | 'disabled_outline'
  | 'danger_no_outline';

export enum CreateTransactionParticipantType {
  PERSON = 'PERSON',
  COMPANY = 'COMPANY',
}

export type BreadcrumbMatcherType = {
  title: string;
  url: string;
};

export interface dealTypeOptions {
  value: string;
  label: string;
  disabled?: boolean;
}
export enum dealType {
  Sale = 'Sale',
  Lease = 'Lease',
}

export enum DepositHeldEnum {
  REAL = 'REAL',
  OTHER_BROKERAGE = 'OTHER_BROKERAGE',
}

export type AddressComponentType = {
  short_name: string;
  long_name: string;
  types: string[];
};

export type GooglePlaceAddressType = {
  streetAddress1: string;
  streetAddress2?: string;
  city: string;
  county?: string;
  stateOrProvince: AddressRequestStateOrProvinceEnum;
  country: string;
  zipOrPostalCode: string;
};

declare global {
  interface Window {
    announcekit: any;
  }
}

export enum ReportType {
  CLOSED = 'CLOSED',
  PENDING = 'PENDING',
  TERMINATED = 'TERMINATED',
  REVENUE = 'REVENUE',
}

export interface DropboxState {
  loadingById: Record<string, boolean>;
  dropboxById: Record<string, DropboxResponse | undefined>;
  deletingById: Record<string, boolean>;
  updatingFileById: Record<string, boolean>;
  splitPdfUrl?: AsyncResponse<string>;
  globalDropbox: DropboxResponse | undefined;
}

export interface LearnworldsState {
  signOnResponse: AsyncResponse<LearnworldsSsoResponse>;
}

export interface WealthManagementSliceState {
  attractingStockAwardResponse: AsyncResponse<AttractingStockAwardsEarningsResponse>;
  cappingAwardResponse: AsyncResponse<CappingAwardEarningsResponse>;
  commissionIncomeResponse: AsyncResponse<CommissionEarningsResponse>;
  currentWealthResponse: AsyncResponse<CurrentWealthResponse>;
  defaultWealthGoalsResponse: AsyncResponse<WealthGoalsResponse>;
  earningsOverviewResponse: AsyncResponse<EarningsOverviewResponse>;
  rateLimitResponse: AsyncResponse<ProspectiveReferralRateLimitInfo>;
  eliteAgentCulturalAwardResponse: AsyncResponse<EliteAgentCulturalEarningsResponse>;
  eliteAgentProductionAwardResponse: AsyncResponse<EliteAgentProductionEarningsResponse>;
  postCapSppResponse: AsyncResponse<PostCapSppEarningsResponse>;
  preCapSppResponse: AsyncResponse<PreCapSPPEarningsResponse>;
  revenueShareIncomeResponse: AsyncResponse<RevenueShareEarningsResponse>;
  setWealthGoalsResponse: AsyncResponse<WealthGoalsResponse>;
  wealthGoalsResponse: AsyncResponse<WealthGoalsResponse>;
  officialPlanAndAwardResponse: AsyncResponse<OfficialPlanAndAwardResponse>;
  tempKnowlegeLandingPage?: EarningType;
  selectedEarningsOverviewType: EarningType[];
  wealthPlanReferralToken?: string;
  readonlyMode?: boolean;
  referralInfo: AsyncResponse<ProspectiveAgentInfo>;
  commsSettings: AsyncResponse<GetCommsPreferencesResponse>;
  wealthPlanEvents: Mapping<CalendarEventResponse[]>;
}

export enum ActivityType {
  JOURNEY_ITEM_COMPLETED = 'JOURNEY_ITEM_COMPLETED',
  JOURNEY_ITEM_INCOMPLETE = 'JOURNEY_ITEM_INCOMPLETE',
  CHECKLIST_ITEM_UPDATED = 'CHECKLIST_ITEM_UPDATED',
  CHECKLIST_ITEM_ADDED = 'CHECKLIST_ITEM_ADDED',
  CHECKLIST_ITEM_DELETED = 'CHECKLIST_ITEM_DELETED',
  CHECKLIST_ITEM_LABEL_ADDED = 'CHECKLIST_ITEM_LABEL_ADDED',
  CHECKLIST_ITEM_LABEL_REMOVED = 'CHECKLIST_ITEM_LABEL_REMOVED',
  CHECKLIST_ITEM_NAME_UPDATED = 'CHECKLIST_ITEM_NAME_UPDATED',
  CHECKLIST_ITEM_DESCRIPTION_UPDATED = 'CHECKLIST_ITEM_DESCRIPTION_UPDATED',
  DOCUMENT_UPLOADED = 'DOCUMENT_UPLOADED',
  DOCUMENT_DELETED = 'DOCUMENT_DELETED',
  DOCUMENT_ATTACHED = 'DOCUMENT_ATTACHED',
  DOCUMENT_REMOVED = 'DOCUMENT_REMOVED',
  NEW_VERSION_UPLOADED = 'NEW_VERSION_UPLOADED',
  DROPBOX_RECEIVED_EMAIL = 'DROPBOX_RECEIVED_EMAIL',
  DROPBOX_FILE_RECEIVED = 'DROPBOX_FILE_RECEIVED',
  DROPBOX_FILE_UPLOADED = 'DROPBOX_FILE_UPLOADED',
  DROPBOX_FILE_VERSION_ADDED = 'DROPBOX_FILE_VERSION_ADDED',
  DROPBOX_FILE_DELETED = 'DROPBOX_FILE_DELETED',
  DROPBOX_FILE_NAME_UPDATED = 'DROPBOX_FILE_NAME_UPDATED',
  DROPBOX_FILE_FROM_REAL_SIGNATURE = 'DROPBOX_FILE_FROM_REAL_SIGNATURE',
  COMMENT_CREATED = 'COMMENT_CREATED',
  COMMENT_EDITED = 'COMMENT_EDITED',
  COMMENT_DELETED = 'COMMENT_DELETED',
  COMMENT_READ = 'COMMENT_READ',
  COMMISSION_PAYER_ASSIGNED = 'COMMISSION_PAYER_ASSIGNED',
  PAYMENT_PARTICIPANT_ADDED = 'PAYMENT_PARTICIPANT_ADDED',
  PAYMENT_PARTICIPANT_REMOVED = 'PAYMENT_PARTICIPANT_REMOVED',
  OTHER_PARTICIPANT_ADDED = 'OTHER_PARTICIPANT_ADDED',
  OTHER_PARTICIPANT_REMOVED = 'OTHER_PARTICIPANT_REMOVED',
  TRANSACTION_FEE_ADDED = 'TRANSACTION_FEE_ADDED',
  TRANSACTION_FEE_REMOVED = 'TRANSACTION_FEE_REMOVED',
  TRANSACTION_ADDRESS_UPDATED = 'TRANSACTION_ADDRESS_UPDATED',
  TRANSACTION_YEAR_BUILT_UPDATED = 'TRANSACTION_YEAR_BUILT_UPDATED',
  TRANSACTION_MLS_NUMBER_UPDATED = 'TRANSACTION_MLS_NUMBER_UPDATED',
  TRANSACTION_ESCROW_NUMBER_UPDATED = 'TRANSACTION_ESCROW_NUMBER_UPDATED',
  TRANSACTION_SALE_PRICE_UPDATED = 'TRANSACTION_SALE_PRICE_UPDATED',
  TRANSACTION_LISTING_COMMISSION_UPDATED = 'TRANSACTION_LISTING_COMMISSION_UPDATED',
  TRANSACTION_SALE_COMMISSION_UPDATED = 'TRANSACTION_SALE_COMMISSION_UPDATED',
  TRANSACTION_CONTRACT_ACCEPTANCE_DATE_UPDATED = 'TRANSACTION_CONTRACT_ACCEPTANCE_DATE_UPDATED',
  TRANSACTION_ACTUAL_CLOSING_DATE_UPDATED = 'TRANSACTION_ACTUAL_CLOSING_DATE_UPDATED',
  TRANSACTION_ESTIMATED_CLOSING_DATE_UPDATED = 'TRANSACTION_ESTIMATED_CLOSING_DATE_UPDATED',
  TRANSACTION_CREATED = 'TRANSACTION_CREATED',
  LISTING_CREATED = 'LISTING_CREATED',
  REFERRAL_CREATED = 'REFERRAL_CREATED',
  TRANSACTION_CLOSED = 'TRANSACTION_CLOSED',
  TRANSACTION_APPROVED_FOR_CLOSING = 'TRANSACTION_APPROVED_FOR_CLOSING',
  TRANSACTION_PAYMENT_ACCEPTED = 'TRANSACTION_PAYMENT_ACCEPTED',
  TRANSACTION_PAYMENT_SCHEDULED = 'TRANSACTION_PAYMENT_SCHEDULED',
  TRANSACTION_SETTLED = 'TRANSACTION_SETTLED',
  COMMISSION_VALIDATED = 'COMMISSION_VALIDATED',
  COMMISSION_DOCUMENT_GENERATED = 'COMMISSION_DOCUMENT_GENERATED',
  COMMISSION_DOCUMENT_APPROVED = 'COMMISSION_DOCUMENT_APPROVED',
  COMMISSION_DOCUMENT_SENT = 'COMMISSION_DOCUMENT_SENT',
  TRANSACTION_PARTICIPANT_UPDATED = 'TRANSACTION_PARTICIPANT_UPDATED',
  TRUST_DEPOSIT_SUBMITTED = 'TRUST_DEPOSIT_SUBMITTED',
  TRUST_DEPOSIT_INSTALLMENT_SUBMITTED = 'TRUST_DEPOSIT_INSTALLMENT_SUBMITTED',
  TRUST_DEPOSIT_INSTALLMENT_CONFIRMED = 'TRUST_DEPOSIT_INSTALLMENT_CONFIRMED',
  COMMISSION_DEPOSIT_SUBMITTED = 'COMMISSION_DEPOSIT_SUBMITTED',
  COMMISSION_DEPOSIT_INSTALLMENT_SUBMITTED = 'COMMISSION_DEPOSIT_INSTALLMENT_SUBMITTED',
  COMMISSION_DEPOSIT_INSTALLMENT_CONFIRMED = 'COMMISSION_DEPOSIT_INSTALLMENT_CONFIRMED',
  EXCESS_FUNDS_RELEASED = 'EXCESS_FUNDS_RELEASED',
  TRANSACTION_TERMINATED = 'TRANSACTION_TERMINATED',
  DOUBLE_ENDER_TRANSACTION_LINKED = 'DOUBLE_ENDER_TRANSACTION_LINKED',
  DOUBLE_ENDER_TRANSACTION_UNLINKED = 'DOUBLE_ENDER_TRANSACTION_UNLINKED',
  TRANSACTION_TAX_EXEMPT_UPDATED = 'TRANSACTION_TAX_EXEMPT_UPDATED',
  TRANSACTION_TAX_PAID_FROM_COMMISSION_UPDATED = 'TRANSACTION_TAX_PAID_FROM_COMMISSION_UPDATED',
  TRANSACTION_TAX_RATES_UPDATED = 'TRANSACTION_TAX_RATES_UPDATED',
  TRANSACTION_TAX_AMOUNT_UPDATED = 'TRANSACTION_TAX_AMOUNT_UPDATED',
  TRANSACTION_TERMINATION_REQUESTED = 'TRANSACTION_TERMINATION_REQUESTED',
  TRANSACTION_FIRM_DATE_UPDATED = 'TRANSACTION_FIRM_DATE_UPDATED',
  PAYMENT_SETTINGS_UPDATED = 'Payment Settings Updated',
}

export enum InvitationStatusEnum {
  PART_OF_JV = 'PART_OF_JV',
  NOT_IN_JV = 'NOT_IN_JV',
  INVITED = 'INVITATION_SENT',
  AWAITING_SIGNATURE = 'AWAITING_SIGNATURE',
}

export enum AgentAddressTypeEnum {
  OFFICE = 'office',
  HOME = 'home',
  MAILING = 'mailing',
}

export enum OfficeAddressTypeEnum {
  BRANCH = 'BRANCH',
  STATE = 'STATE',
  OFFICE = 'OFFICE',
}

export enum CapLevelsEnum {
  '$4k' = '$4k',
  '$6k' = '$6k',
  '$12k' = '$12k',
}

export const CapLevelsToAmountMap: EnumMap<CapLevelsEnum, string> = {
  $4k: '4000',
  $6k: '6000',
  $12k: '12000',
};

export interface CapCardISelection {
  capLevel: CapLevelsEnum;
  capped?: YesNoType;
}

export enum UpdateCommissionAdvanceRequestAdvanceStatusEnum {
  Approved = 'APPROVED',
  AgreementReceived = 'AGREEMENT_RECEIVED',
  Rejected = 'REJECTED',
  Repaid = 'REPAID',
}

export enum AnalyticsEventEnum {
  // General
  PAGE_VIEW = 'page_view',

  //Demo mode
  DEMO_MODE_ENABLED = 'demo_mode_enabled',
  DEMO_MODE_DISABLED = 'demo_mode_disabled',

  // Toggle Commission Documents Sent or Invoiced
  CDA_SENT_OR_INVOICED_TOGGLE_TRUE = 'cda_sent_or_invoiced_toggle_true',
  CDA_SENT_OR_INVOICED_TOGGLE_FALSE = 'cda_sent_or_invoiced_toggle_false',

  // Conveyance Documents Validated or Invalidated
  CONVEYANCE_DOCUMENT_VALIDATED = 'conveyance_document_validated',
  CONVEYANCE_DOCUMENT_INVALIDATED = 'conveyance_document_invalidated',

  //unban email
  UNBAN_USER_EMAIL = 'unban_user_email',

  // Willable Revenue Share
  WILLABLE_REVSHARE_SIGNUP_BUTTON_CLICK = 'willable_revshare_signup_button_click',
  WILLABLE_REVSHARE_AGREE_TO_ADDENDUM = 'willable_revshare_agree_to_addendum',
  WILLABLE_REVSHARE_BENEFICIARY_ADD = 'willable_revshare_beneficiary_add',
  WILLABLE_REVSHARE_BENEFICIARY_UPDATE = 'willable_revshare_beneficiary_update',
  WILLABLE_REVSHARE_TRUST_TYPE_SELECTED = 'willable_revshare_trust_type_selected',
  WILLABLE_REVSHARE_INTRO_POPUP_OPEN = 'willable_revshare_intro_popup_open',
  WILLABLE_REVSHARE_INTRO_POPUP_CLOSE = 'willable_revshare_intro_popup_close',
  WILLABLE_REVSHARE_BENEFICIARY_ADDED = 'willable_revshare_beneficiary_added',
  WILLABLE_REVSHARE_BENEFICIARY_UPDATED = 'willable_revshare_beneficiary_updated',
  WILLABLE_REVSHARE_ADDENDUM_NEXT_BTN_CLK = 'willable_revshare_addendum_next_btn_clk',

  // Agent Owes Real
  AGENT_OWES_REAL_VIEW_ALL_BUTTON_CLICK = 'agent_owes_real_view_all_button_click',
  AGENT_OWES_REAL_ALL_DEBTS_POPUP_BACK_CLICK = 'agent_owes_real_all_debts_popup_back_clk',
  AGENT_OWES_REAL_CARD_EDIT_BUTTON_CLICK = 'agent_owes_real_card_edit_button_click',
  AGENT_OWES_REAL_ALL_DEBTS_POPUP_EDIT = 'agent_owes_real_all_debts_popup_edit',
  AGENT_OWES_REAL_ADD_DEBT_BUTTON_CLICK = 'agent_owes_real_add_debt_button_click',
  AGENT_OWES_REAL_DEBT_TYPE_SELECTED = 'agent_owes_real_debt_type_selected',
  AGENT_OWES_REAL_DEBT_DESCRIPTION_ENTERED = 'agent_owes_real_debt_description_entered',
  AGENT_OWES_REAL_DEBT_DEDUCTION_ENTERED = 'agent_owes_real_debt_deduction_entered',
  AGENT_OWES_REAL_DEBT_DUE_DATE_SELECTED = 'agent_owes_real_debt_due_date_selected',
  AGENT_OWES_REAL_AUTO_DEDUCTION_SELECTED = 'agent_owes_real_auto_deduction_selected',
  AGENT_OWES_REAL_MNL_DEDUCTION_SELECTED = 'agent_owes_real_mnl_deduction_selected',
  AGENT_OWES_REAL_ADD_DEBT_POPUP_ADD_CLICK = 'agent_owes_real_add_debt_popup_add_click',
  AGENT_OWES_REAL_ADD_DEBT_POPUP_CXL_CLICK = 'agent_owes_real_add_debt_popup_cxl_click',
  AGENT_OWES_REAL_ADD_DEBT_POPUP_CLOSED = 'agent_owes_real_add_debt_popup_closed',
  AGENT_OWES_REAL_EDIT_DEBT_BUTTON_CLICK = 'agent_owes_real_edit_debt_button_click',
  AGENT_OWES_REAL_ADD_DEBT_POPUP_SAVED = 'agent_owes_real_add_debt_popup_saved',
  AGENT_OWES_REAL_VOID_DEBT_BUTTON_CLICK = 'agent_owes_real_void_debt_button_click',
  AGENT_OWES_REAL_VOID_DEBT_POPUP_VOID = 'agent_owes_real_void_debt_popup_void',
  AGENT_OWES_REAL_VOID_DEBT_POPUP_CANCEL = 'agent_owes_real_void_debt_popup_cancel',
  AGENT_OWES_REAL_DRAG_AND_DROP_DEBT = 'agent_owes_real_drag_and_drop_debt',
  AGENT_OWES_REAL_ALL_DEBTS_POPUP_OPEN = 'agent_owes_real_all_debts_popup_open',
  AGENT_OWES_REAL_ADD_DEBT_POPUP_OPEN = 'agent_owes_real_add_debt_popup_open',
  AGENT_OWES_REAL_EDIT_DEBT_POPUP_OPEN = 'agent_owes_real_edit_debt_popup_open',
  AGENT_OWES_REAL_VOID_DEBT_POPUP_OPEN = 'agent_owes_real_void_debt_popup_open',
  AGENT_OWES_REAL_ALL_DEBTS_POPUP_CLOSED = 'agent_owes_real_all_debts_popup_closed',
  AGENT_OWES_REAL_EDIT_DEBT_POPUP_CLOSED = 'agent_owes_real_edit_debt_popup_closed',
  AGENT_OWES_REAL_VOID_DEBT_POPUP_CLOSED = 'agent_owes_real_void_debt_popup_closed',
  AGENT_OWES_REAL_PAY_NOW_CLICKED = 'agent_owes_real_pay_now_clicked',
  AGENT_OWES_REAL_START_DATE_SELECTED = 'agent_owes_real_start_date_selected',
  AGENT_OWES_REAL_CREATE_INVOICE_CLICKED = 'agent_owes_real_create_invoice_clicked',
  AGENT_OWES_REAL_INVOICE_LINK_CLICKED = 'agent_owes_real_invoice_link_clicked',
  AGENT_OWES_REAL_DEBT_ITEM_CLICKED = 'agent_owes_real_debt_item_clicked',
  AGENT_OWES_REAL_REPAY_ITEMS_POPUP_OPEN = 'agent_owes_real_repay_items_popup_open',
  AGENT_OWES_REAL_REPAY_ITEMS_POPUP_CLOSED = 'agent_owes_real_repay_items_popup_closed',

  //Split Pdf
  SPLIT_PDF_CONVERTED_PDF_TO_IMAGES = 'split_pdf_converted_pdf_to_images',
  SPLIT_PDF_CONVERTED_PDF_TO_IMAGES_FROM_URL = 'split_pdf_convert_pdf_to_images_from_url',
  SPLIT_PDF_ASSIGN_BUTTON_CLICKED = 'split_pdf_assign_button_clicked',
  SPLIT_PDF_PROCESSED_PDF_SPLITTING = 'split_pdf_processed_pdf_splitting',
  SPLIT_PDF_UPLOAD_ANOTHER = 'split_pdf_upload_another',
  SPLIT_PDF_FINISHED_BUTTON_CLICKED = 'split_pdf_finished_button_clicked',
  SPLIT_PDF_CLOSED = 'split_pdf_closed',

  //FMLS Fee
  FMLS_CLICKED_YES_IN_TRANSACTION_BUILDER = 'fmls_clicked_yes_in_transaction_builder',
  FMLS_CLICKED_NO_IN_TRANSACTION_BUILDER = 'fmls_clicked_no_in_transaction_builder',
  FMLS_TOGGLED_TO_YES_IN_EDIT = 'fmls_toggled_to_yes_in_edit',
  FMLS_TOGGLED_TO_NO_IN_EDIT = 'fmls_toggled_to_no_in_edit',
  FMLS_AMOUNT_EDITED_FOR_PMT_PARTICIPANT = 'fmls_amount_edited_for_pmt_participant',
  FMLS_AMOUNT_DELETED_WHICH_CREATED_BY_SYS = 'fmls_amount_deleted_which_created_by_sys',
  FMLS_ADDED_MLS_FEE_ON_TRANSACTION = 'fmls_added_mls_fee_on_transaction',

  //ONE APP STRATEGY
  ONE_APP_TITLE_BOOK_A_MEETING_CLICKED = 'one_app_title_book_a_meeting_clicked',
  ONE_APP_TITLE_VIEWED_JV_VIDEO = 'one_app_title_viewed_jv_video',
  ONE_APP_TITLE_VIEWED_JV_LEARN_MORE_VIDEO = 'one_app_title_viewed_jv_learn_more_video',
  ONE_APP_TITLE_CLICKED_GET_QUOTE = 'one_app_title_clicked_get_quote',
  ONE_APP_TITLE_CLICKED_ADD_TRANSACTION = 'one_app_title_clicked_add_transaction',
  ONE_APP_TITLE_CLICKED_SEND_TRANSACTION = 'one_app_title_clicked_send_transaction',
  ONE_APP_TITLE_CLICKED_CHECK_OUR_MAP = 'one_app_title_clicked_check_our_map',
  ONE_APP_TITLE_SELECTED_LOCATION_IN_CONTACT_US = 'one_app_title_selected_location',
  ONE_APP_TITLE_CLICKED_FAQ = 'one_app_title_clicked_faq',
  ONE_APP_TITLE_CLICKED_GENERAL_QUESTION = 'one_app_title_clicked_general_question',

  ONE_APP_MORTGAGE_BOOK_A_MEETING_CLICKED = 'one_app_mortgage_book_a_meeting_clicked',
  ONE_APP_MORTGAGE_SCROLLED_CAROUSEL = 'one_app_mortgage_scrolled_carousel',
  ONE_APP_MORTGAGE_CLICKED_LEARN_MORE = 'one_app_mortgage_clicked_learn_more',
  ONE_APP_MORTGAGE_CLICKED_GET_PRE_APPROVE = 'one_app_mortgage_clicked_get_pre_approve',
  ONE_APP_MORTGAGE_CLICKED_LOCATION = 'one_app_mortgage_clicked_location',
  ONE_APP_MORTGAGE_CLICKED_PHONE_NUMBER = 'one_app_mortgage_clicked_phone_number',
  ONE_APP_MORTGAGE_CLICKED_NEWS_AND_PROMO = 'one_app_mortgage_clicked_news_and_promo',
  ONE_APP_MORTGAGE_CLICKED_FAQ = 'one_app_mortgage_clicked_faq',

  // Tax Collection
  TAX_COLLECTION_TOGGLE_TAX_EXEMPT_YES_ON_TRANSACTION = 'toggle_tax_exempt_yes_on_transaction',
  TAX_COLLECTION_TOGGLE_TAX_EXEMPT_NO_ON_TRANSACTION = 'toggle_tax_exempt_no_on_transaction',
  TAX_COLLECTION_EDIT_TAX_PERCENTAGE_ON_TRANSACTION = 'edit_tax_percentage_on_transaction',
  TAX_COLLECTION_TOGGLE_TAX_WITHHELD_ON_PAYMENT_PARTICIPANT = 'toggle_tax_withheld_on_pmt_participant',
  TAX_COLLECTION_EDIT_TAX_PAYMENT_DEFAULT_ON_OFFICE = 'edit_tax_payment_default_on_office',
  TAX_COLLECTION_CLICK_AGENT_REMITS_ON_OFFICE = 'click_agent_remits_on_office',
  TAX_COLLECTION_CLICK_REAL_HOLDS_AND_REMITS_ON_OFFICE = 'click_real_holds_and_remits_on_office',
  TAX_COLLECTION_EDIT_TAX_RATE_ON_OFFICE = 'edit_tax_rate_on_office',
  TAX_COLLECTION_EDIT_TAX_TYPE_ON_OFFICE = 'edit_tax_type_on_office',
  TAX_COLLECTION_EDIT_TAX_ID_ON_OFFICE = 'edit_tax_id_on_office',
  TAX_COLLECTION_CLICK_TAX_PAID_FROM_COMMISSION_ON_OFFICE = 'click_tax_paid_from_commission_on_office',
  TAX_COLLECTION_CLICK_TAX_PAID_FROM_COMMISSION_ON_TRANSACTION = 'click_tax_paid_from_commission_on_tx',

  // Refund or Release Request
  EXCESS_FUNDS_CLICKED_ADD_REQUEST = 'excess_funds_clicked_add_request',
  EXCESS_FUNDS_CLICKED_SAVE_REQUEST = 'excess_funds_clicked_save_request',
  EXCESS_FUNDS_CLICKED_CANCEL_REQUEST = 'excess_funds_clicked_cancel_request',
  EXCESS_FUNDS_CLICKED_APPROVE_REQUEST = 'excess_funds_clicked_approve_request',
  EXCESS_FUNDS_CLICKED_MARK_REVIEW_REQUEST = 'excess_funds_clicked_mark_review_request',
  EXCESS_FUNDS_CLICKED_RESUBMIT_REQUEST = 'excess_funds_clicked_resubmit_request',
  EXCESS_FUNDS_CLICKED_RELEASE_REQUEST = 'excess_funds_clicked_release_request',
  EXCESS_FUNDS_CLICKED_VIEW_REQUEST = 'excess_funds_clicked_view_request',
  EXCESS_FUNDS_CLICKED_EDIT_REQUEST = 'excess_funds_clicked_edit_request',
  EXCESS_FUNDS_UPLOADED_DOC = 'excess_funds_uploaded_doc',
  EXCESS_FUNDS_ENTERED_NOTE = 'excess_funds_entered_note',
  EXCESS_FUNDS_CLICKED_ACH_EFT = 'excess_funds_clicked_ach_eft',
  EXCESS_FUNDS_CLICKED_CHECK = 'excess_funds_clicked_check',
  EXCESS_FUNDS_CLICKED_WIRE = 'excess_funds_clicked_wire',
  EXCESS_FUNDS_ENTERED_ACCOUNT_INFO = 'excess_funds_entered_account_info',
  EXCESS_FUNDS_ENTERED_PAYABLE_TO = 'excess_funds_entered_payable_to',
  EXCESS_FUNDS_EDITED_REQUEST = 'excess_funds_edited_request',

  //Double Ender
  DOUBLE_ENDER_CHOOSE_REAL_AGENT_AS_OTHER_AGENT = 'double_ender_real_agent_as_other_agent',
  DOUBLE_ENDER_LINKED_TRANSACTION = 'double_ender_linked_transaction',
  DOUBLE_ENDER_UNLINKED_TRANSACTION = 'double_ender_unlinked_transaction',
  DOUBLE_ENDER_TERMINATE_TRANSACTION = 'double_ender_terminate_transaction',
  DOUBLE_ENDER_APPROVED_FOR_CLOSING_TRANSACTION = 'double_ender_approved_for_closing_tx',
  DOUBLE_ENDER_CLOSED_TRANSACTION = 'double_ender_closed_transaction',

  // Leo
  LEO_BAR_CLICK = 'leo_bar_click',
  LEO_BAR_CLOSE = 'leo_bar_close',
  LEO_PROMPT_CLICK = 'leo_prompt_click',
  LEO_MESSAGE_SEND_CLICK = 'leo_message_send_click',
  LEO_MESSAGE_HISTORY_CLICK = 'leo_message_history_click',
  LEO_TRANSACTION_CHECKBOX_CLICK = 'leo_transaction_checkbox_click',
  LEO_ACADEMY_OR_EVENT_LINK_CLICK = 'leo_academy_or_event_link_click',
  LEO_LABEL_LINK_CLICK = 'leo_label_link_click',
  LEO_RESPONSE_LINK_CLICK = 'leo_response_link_click',
  LEO_RESPONSE_COPY_CLICK = 'leo_response_copy_click',
  LEO_RESPONSE_THUMBS_UP_CLICK = 'leo_response_thumbs_up_click',
  LEO_RESPONSE_THUMBS_DOWN_CLICK = 'leo_response_thumbs_down_click',

  // User Onboarding Checklist
  ONBOARDING_CHECKLIST_TAB_OPENED = 'onboarding_checklist_tab_opened',
  ONBOARDING_CHECKLIST_MARK_ITEM_AS_DONE = 'onboarding_checklist_mark_item_as_done',
  ONBOARDING_CHECKLIST_UNMARK_ITEM_AS_DONE = 'onboarding_checklist_unmark_item_as_done',
  ONBOARDING_CHECKLIST_CLICKED_LINK = 'onboarding_checklist_clicked_link',

  // RevShare Insights
  RSI_OPEN_PENDING_PAYMENT = 'rsi_open_pending_payment',
  RSI_OPEN_PREVIOUS_PAYMENT = 'rsi_open_previous_payment',
  RSI_COPIES_REFER_NOW_LINK = 'rsi_copies_refer_now_link',
  RSI_OPEN_BADGE = 'rsi_open_badge',
  RSI_OPEN_TOOLTIP_TOTAL_TIER_1_AGENTS = 'rsi_open_tooltip_total_tier_1_agents',
  RSI_OPEN_TIER_1_NETWORK = 'rsi_open_tier_1_network',
  RSI_OPEN_TIER_2_NETWORK = 'rsi_open_tier_2_network',
  RSI_OPEN_TIER_3_NETWORK = 'rsi_open_tier_3_network',
  RSI_OPEN_TIER_4_NETWORK = 'rsi_open_tier_4_network',
  RSI_OPEN_TIER_5_NETWORK = 'rsi_open_tier_5_network',
  RSI_CLICK_TAB_IN_MY_NETWORK = 'rsi_click_tab_in_my_network',
  RSI_FILTER_CONTRIBUTION_BY_DAY = 'rsi_filter_contributions_by_day',
  RSI_FILTER_CONTRIBUTION_BY_WEEK = 'rsi_filter_contributions_by_week',
  RSI_FILTER_CONTRIBUTION_BY_MONTH = 'rsi_filter_contributions_by_month',
  RSI_FILTER_CONTRIBUTION_BY_YEAR = 'rsi_filter_contributions_by_year',
  RSI_OPEN_TIER_1_CONTRIBUTORS = 'rsi_open_tier_1_contributors',
  RSI_OPEN_TIER_2_CONTRIBUTORS = 'rsi_open_tier_2_contributors',
  RSI_OPEN_TIER_3_CONTRIBUTORS = 'rsi_open_tier_3_contributors',
  RSI_OPEN_TIER_4_CONTRIBUTORS = 'rsi_open_tier_4_contributors',
  RSI_OPEN_TIER_5_CONTRIBUTORS = 'rsi_open_tier_5_contributors',
  RSI_OPEN_TIER_1_CONTRIBUTIONS = 'rsi_open_tier_1_contributions',
  RSI_OPEN_TIER_2_CONTRIBUTIONS = 'rsi_open_tier_2_contributions',
  RSI_OPEN_TIER_3_CONTRIBUTIONS = 'rsi_open_tier_3_contributions',
  RSI_OPEN_TIER_4_CONTRIBUTIONS = 'rsi_open_tier_4_contributions',
  RSI_OPEN_TIER_5_CONTRIBUTIONS = 'rsi_open_tier_5_contributions',

  // Wealth Management
  // Welcome Screen
  WEALTH_PLAN_WELCOME_SCREEN_5_YEAR_GOAL_INPUT = 'wp_welcome_screen_5_year_goal_input',
  WEALTH_PLAN_WELCOME_SCREEN_CLICK_GET_STARTED = 'wp_welcome_screen_click_get_started',

  // Goal Screen
  WEALTH_PLAN_GOAL_SCREEN_LEARN_MORE_COMMISSION = 'wp_goal_screen_learn_more_commission',
  WEALTH_PLAN_GOAL_SCREEN_TRANSACTION_INPUT = 'wp_goal_screen_transaction_input',
  WEALTH_PLAN_GOAL_SCREEN_TRANSACTION_SLIDER = 'wp_goal_screen_transaction_slider',
  WEALTH_PLAN_GOAL_SCREEN_VOLUME_INPUT = 'wp_goal_screen_volume_input',
  WEALTH_PLAN_GOAL_SCREEN_VOLUME_SLIDER = 'wp_goal_screen_volume_slider',
  WEALTH_PLAN_GOAL_SCREEN_LEARN_MORE_SPP = 'wp_goal_screen_learn_more_spp',
  WEALTH_PLAN_GOAL_SCREEN_SPP_TO_YES = 'wp_goal_screen_spp_to_yes',
  WEALTH_PLAN_GOAL_SCREEN_SPP_TO_NO = 'wp_goal_screen_spp_to_no',
  WEALTH_PLAN_GOAL_SCREEN_LEARN_MORE_REV_SHARE = 'wp_goal_screen_learn_more_rev_share',
  WEALTH_PLAN_GOAL_SCREEN_AGENTS_REFERRED_INPUT = 'wp_goal_screen_agents_tier1_input',
  WEALTH_PLAN_GOAL_SCREEN_AGENTS_REFERRED_SLIDER = 'wp_goal_screen_agents_tier1_slider',
  WEALTH_PLAN_GOAL_SCREEN_AGENTS_AGENTS_REFERRED_INPUT = 'wp_goal_screen_agents_tier2_input',
  WEALTH_PLAN_GOAL_SCREEN_AGENTS_AGENTS_REFERRED_SLIDER = 'wp_goal_screen_agents_tier2_slider',
  WEALTH_PLAN_GOAL_SCREEN_REVSHARE_TO_YES = 'wp_goal_screen_revshare_to_yes',
  WEALTH_PLAN_GOAL_SCREEN_REVSHARE_TO_NO = 'wp_goal_screen_revshare_to_no',
  WEALTH_PLAN_GOAL_SCREEN_EXTERNAL_REAX_SHARES_TO_YES = 'wp_goal_screen_ext_reax_shares_to_yes',
  WEALTH_PLAN_GOAL_SCREEN_EXTERNAL_REAX_SHARES_TO_NO = 'wp_goal_screen_ext_reax_shares_to_no',
  WEALTH_PLAN_GOAL_SCREEN_EXTERNAL_SHARES = 'wp_goal_screen_external_shares',
  WEALTH_PLAN_GOAL_SCREEN_CLICK_MY_PLAN = 'wp_goal_screen_click_my_plan',
  WEALTH_PLAN_GOAL_SCREEN_X_GO_BACK = 'wp_goal_screen_x_go_back',
  WEALTH_PLAN_GOAL_SCREEN_CANCEL = 'wp_goal_screen_cancel',
  WEALTH_PLAN_GOAL_SCREEN_UPDATE_PLAN = 'wp_goal_screen_update_plan',

  // Earning Progress Card
  WEALTH_PLAN_OVERVIEW_COMMISSION_INCOME = 'wp_overview_commission_income',
  WEALTH_PLAN_OVERVIEW_PRE_CAP_SPP = 'wp_overview_pre_cap_spp',
  WEALTH_PLAN_OVERVIEW_CAPPING_AWARD = 'wp_overview_capping_award',
  WEALTH_PLAN_OVERVIEW_POST_CAP_SPP = 'wp_overview_post_cap_spp',
  WEALTH_PLAN_OVERVIEW_ELITE_CULTURAL_AWARD = 'wp_overview_elite_cultural_award',
  WEALTH_PLAN_OVERVIEW_ELITE_PRODUCTION_AWARD = 'wp_overview_elite_production_award',
  WEALTH_PLAN_OVERVIEW_REV_SHARE = 'wp_overview_rev_share',
  WEALTH_PLAN_OVERVIEW_ATTRACTING_AWARD = 'wp_overview_attracting_award',

  // Overview Page
  WEALTH_PLAN_OVERVIEW_SCREEN_EDIT_GOALS = 'wp_overview_edit_goals',
  WEALTH_PLAN_OVERVIEW_SCREEN_GO_TO_SHARRAN_PLAYBOOK = 'wp_overview_go_to_sharran_playbook',
  WEALTH_PLAN_OVERVIEW_SCREEN_GO_TO_REAL_ACADEMY = 'wp_overview_go_to_real_academy',
  WEALTH_PLAN_OVERVIEW_SCREEN_OVERVIEW_INFO_TOOLTIP = 'wp_overview_overview_info_tooltip',
  WEALTH_PLAN_OVERVIEW_SCREEN_SHARES_EDIT_GOALS = 'wp_overview_shares_edit_goals',
  WEALTH_PLAN_OVERVIEW_SCREEN_SHARES_TOOLTIP = 'wp_overview_shares_tooltip',

  // Commission Income
  WEALTH_PLAN_COMMISSION_INCOME_5_YEAR_PROJ_TOOLTIP = 'wp_commission_5_year_proj_tooltip',
  WEALTH_PLAN_COMMISSION_INCOME_AVERAGE_HOME_SALES_PRICE = 'wp_commission_average_home_sales_price',
  WEALTH_PLAN_COMMISSION_INCOME_TRANSACTION_INPUT = 'wp_commission_transaction_input',
  WEALTH_PLAN_COMMISSION_INCOME_TRANSACTION_SLIDER = 'wp_commission_transaction_slider',
  WEALTH_PLAN_COMMISSION_INCOME_VOLUME_INPUT = 'wp_commission_volume_input',
  WEALTH_PLAN_COMMISSION_INCOME_VOLUME_SLIDER = 'wp_commission_volume_slider',
  WEALTH_PLAN_COMMISSION_INCOME_GRAPH_INFO_TOOLTIP = 'wp_commission_graph_info_tooltip',
  WEALTH_PLAN_COMMISSION_INCOME_USE_AVERAGE_FIXED_COMMISSION_TOGGLED_ON = 'wp_average_fixed_commission_toggled_on',
  WEALTH_PLAN_COMMISSION_INCOME_USE_AVERAGE_FIXED_COMMISSION_TOGGLED_OFF = 'wp_average_fixed_commission_toggled_off',
  WEALTH_PLAN_COMMISSION_INCOME_AVERAGE_TRANSACTION_COMMISSION_PERCENTAGE = 'wp_average_transaction_commission_pct',
  WEALTH_PLAN_COMMISSION_INCOME_AVERAGE_FIXED_COMMISSION = 'wp_commission_average_fixed_commission',
  WEALTH_PLAN_COMMISSION_INCOME_TEAM_USE_AVERAGE_FIXED_COMMISSION_TOGGLED_ON = 'wp_average_fixed_commission_toggled_on',
  WEALTH_PLAN_COMMISSION_INCOME_TEAM_USE_AVERAGE_FIXED_COMMISSION_TOGGLED_OFF = 'wp_average_fixed_commission_toggled_off',
  WEALTH_PLAN_COMMISSION_INCOME_TEAM_LEAD_TOGGLED_ON = 'wp_commission_team_lead_toggled_on',
  WEALTH_PLAN_COMMISSION_INCOME_TEAM_LEAD_TOGGLED_OFF = 'wp_commission_team_lead_toggled_off',

  // Pre Cap SPP
  WEALTH_PLAN_PRE_CAP_SPP_TO_YES = 'wp_pre_cap_spp_to_yes',
  WEALTH_PLAN_PRE_CAP_SPP_TO_NO = 'wp_pre_cap_spp_to_no',
  WEALTH_PLAN_PRE_CAP_EDIT_VOLUME = 'wp_pre_cap_edit_volume',
  WEALTH_PLAN_PRE_CAP_5_YEAR_PROJ_TOOLTIP = 'wp_pre_cap_5_year_proj_tooltip',
  WEALTH_PLAN_PRE_CAP_SPP_TO_DATE_TOOLTIP = 'wp_pre_cap_spp_to_date_tooltip',
  WEALTH_PLAN_PRE_CAP_BONUS_TO_DATE_TOOLTIP = 'wp_pre_cap_bonus_to_date_tooltip',

  // Post Cap SPP
  WEALTH_PLAN_POST_CAP_SPP_TO_YES = 'wp_post_cap_spp_to_yes',
  WEALTH_PLAN_POST_CAP_SPP_TO_NO = 'wp_post_cap_spp_to_no',
  WEALTH_PLAN_POST_CAP_EDIT_VOLUME = 'wp_post_cap_edit_volume',
  WEALTH_PLAN_POST_CAP_5_YEAR_PROJ_TOOLTIP = 'wp_post_cap_5_year_proj_tooltip',
  WEALTH_PLAN_POST_CAP_SPP_TO_DATE_TOOLTIP = 'wp_post_cap_spp_to_date_tooltip',
  WEALTH_PLAN_POST_CAP_BONUS_TO_DATE_TOOLTIP = 'wp_post_cap_bonus_to_date_tooltip',

  // Elite Cultural Award
  WEALTH_PLAN_ELITE_CULTURAL_AWARD_5_YEAR_PROJ_TOOLTIP = 'wp_elite_cultural_5_year_proj_tooltip',
  WEALTH_PLAN_ELITE_CULTURAL_AWARD_PARTICIPATION_TO_NO = 'wp_elite_cultural_award_part_to_no',

  // Elite Production Award
  WEALTH_PLAN_ELITE_PRODUCTION_AWARD_5_YEAR_PROJ_TOOLTIP = 'wp_elite_prod_5_year_proj_tooltip',

  // Revenue Share Income
  WEALTH_PLAN_REV_SHARE_5_YEAR_PROJ_TOOLTIP = 'wp_rev_share_5_year_proj_tooltip',
  WEALTH_PLAN_REV_SHARE_VIEW_DETAILS = 'wp_rev_share_view_details',
  WEALTH_PLAN_REV_SHARE_AGENTS_REFERRED_TOOLTIP = 'wp_rev_share_agents_tier1_tooltip',
  WEALTH_PLAN_REV_SHARE_AGENTS_REFERRED_INPUT = 'wp_rev_share_agents_tier1_input',
  WEALTH_PLAN_REV_SHARE_AGENTS_REFERRED_SLIDER = 'wp_rev_share_agents_tier1_slider',
  WEALTH_PLAN_REV_SHARE_AGENTS_AGENTS_REFERRED_TOOLTIP = 'wp_rev_share_agents_tier2_tooltip',
  WEALTH_PLAN_REV_SHARE_AGENTS_AGENTS_REFERRED_INPUT = 'wp_rev_share_agents_tier2_input',
  WEALTH_PLAN_REV_SHARE_AGENTS_AGENTS_REFERRED_SLIDER = 'wp_rev_share_agents_tier2_slider',

  // Stock Award for Attracting
  WEALTH_PLAN_ATTRACTING_EDIT_AGENTS = 'wp_attracting_edit_agents',

  // Learn more cards
  WEALTH_PLAN_LP_LEARN_MORE_CLICK_LEARN_MORE_COMMISSION_INCOME = 'wp_lp_learn_more_click_commission_income',
  WEALTH_PLAN_LP_LEARN_MORE_CLICK_LEARN_MORE_PRE_CAP = 'wp_lp_learn_more_click_pre_cap',
  WEALTH_PLAN_LP_LEARN_MORE_CLICK_LEARN_MORE_CAPPING_AWARD = 'wp_lp_learn_more_click_capping_award',
  WEALTH_PLAN_LP_LEARN_MORE_CLICK_LEARN_MORE_POST_CAP = 'wp_lp_learn_more_click_post_cap',
  WEALTH_PLAN_LP_LEARN_MORE_CLICK_LEARN_MORE_ELITE_AGENT_PRODUCTION_AWARD = 'wp_lp_learn_more_click_elite_production',
  WEALTH_PLAN_LP_LEARN_MORE_CLICK_LEARN_MORE_ELITE_AGENT_CULTURAL_AWARD = 'wp_lp_learn_more_click_elite_cultural',
  WEALTH_PLAN_LP_LEARN_MORE_CLICK_LEARN_MORE_REVENUE_SHARE_INCOME = 'wp_lp_learn_more_click_rev_share',
  WEALTH_PLAN_LP_LEARN_MORE_CLICK_LEARN_MORE_STOCK_AWARD_FOR_ATTRACTING = 'wp_lp_learn_more_click_attracting',

  // Unlocking popup
  WEALTH_PLAN_JOIN_SPP_PRE_YES = 'wp_join_spp_pre_yes',
  WEALTH_PLAN_JOIN_SPP_PRE_NO = 'wp_join_spp_pre_no',
  WEALTH_PLAN_JOIN_SPP_PRE_LEARN_MORE = 'wp_join_spp_pre_learn_more',
  WEALTH_PLAN_JOIN_SPP_PRE_CLOSE = 'wp_join_spp_pre_close',

  WEALTH_PLAN_JOIN_SPP_POST_YES = 'wp_join_spp_post_yes',
  WEALTH_PLAN_JOIN_SPP_POST_NO = 'wp_join_spp_post_no',
  WEALTH_PLAN_JOIN_SPP_POST_LEARN_MORE = 'wp_join_spp_post_learn_more',
  WEALTH_PLAN_JOIN_SPP_POST_CLOSE = 'wp_join_spp_post_close',

  WEALTH_PLAN_JOIN_ELITE_CULTURAL_YES = 'wp_join_elite_cultural_yes',
  WEALTH_PLAN_JOIN_ELITE_CULTURAL_NO = 'wp_join_elite_cultural_no',
  WEALTH_PLAN_JOIN_ELITE_CULTURAL_LEARN_MORE = 'wp_join_elite_cultural_learn_more',
  WEALTH_PLAN_JOIN_ELITE_CULTURAL_CLOSE = 'wp_join_elite_cultural_close',

  WEALTH_PLAN_JOIN_ELITE_PRODUCTION_YES = 'wp_join_elite_prod_yes',
  WEALTH_PLAN_JOIN_ELITE_PRODUCTION_NO = 'wp_join_elite_prod_no',
  WEALTH_PLAN_JOIN_ELITE_PRODUCTION_LEARN_MORE = 'wp_join_elite_prod_learn_more',
  WEALTH_PLAN_JOIN_ELITE_PRODUCTION_CLOSE = 'wp_join_elite_prod_close',

  WEALTH_PLAN_JOIN_REV_SHARE_YES = 'wp_join_rev_share_yes',
  WEALTH_PLAN_JOIN_REV_SHARE_NO = 'wp_join_rev_share_no',
  WEALTH_PLAN_JOIN_REV_SHARE_LEARN_MORE = 'wp_join_rev_share_learn_more',
  WEALTH_PLAN_JOIN_REV_SHARE_CLOSE = 'wp_join_rev_share_close',

  WEALTH_PLAN_JOIN_ATTRACTING_YES = 'wp_join_rev_share_attract_yes',
  WEALTH_PLAN_JOIN_ATTRACTING_NO = 'wp_join_rev_share_attract_no',
  WEALTH_PLAN_JOIN_ATTRACTING_LEARN_MORE = 'wp_join_rev_share_attract_learn_more',
  WEALTH_PLAN_JOIN_ATTRACTING_CLOSE = 'wp_join_rev_share_attract_close',

  // WealthPlan Landing Pages
  // Commission Income
  WEALTH_PLAN_LP_COMMISSION_INCOME_PLAY_VIDEO = 'wp_lp_commission_play_video',
  WEALTH_PLAN_LP_COMMISSION_INCOME_CLICK_ELITE_AWARD_DETAILS = 'wp_lp_commission_click_elite_award',
  WEALTH_PLAN_LP_COMMISSION_INCOME_CLICK_6K_CAP = 'wp_lp_commission_click_6k_cap',
  WEALTH_PLAN_LP_COMMISSION_INCOME_CLICK_4K_CAP = 'wp_lp_commission_click_4k_cap',
  WEALTH_PLAN_LP_COMMISSION_INCOME_CLICK_12K_CAP = 'wp_lp_commission_click_12k_cap',
  WEALTH_PLAN_LP_COMMISSION_INCOME_SCROLL_YOUR_JOURNEY = 'wp_lp_commission_scroll_your_journey',
  // Pre cap spp
  WEALTH_PLAN_LP_PRE_CAP_SPP_PLAY_VIDEO = 'wp_lp_pre_cap_spp_play_video',
  WEALTH_PLAN_LP_PRE_CAP_SPP_CLICK_ON_SEE_EXAMPLE = 'wp_lp_pre_cap_spp_click_on_see_example',
  WEALTH_PLAN_LP_PRE_CAP_SPP_CLICK_ON_POST_CAP_SPP = 'wp_lp_pre_cap_spp_click_on_post_cap_spp',
  WEALTH_PLAN_LP_PRE_CAP_SPP_CLICK_ON_SUPPORT = 'wp_lp_pre_cap_spp_click_on_support',
  WEALTH_PLAN_LP_PRE_CAP_SPP_EXPAND_EXAMPLE = 'wp_lp_pre_cap_spp_expand_example',
  WEALTH_PLAN_LP_PRE_CAP_SPP_EXPAND_TERMS_AND_CONDITIONS = 'wp_lp_pre_cap_spp_expand_terms',
  WEALTH_PLAN_LP_PRE_CAP_SPP_EXPAND_IMPORTANT_DETAILS = 'wp_lp_pre_cap_spp_expand_import_details',
  WEALTH_PLAN_LP_PRE_CAP_SPP_ADD_TO_PLAN = 'wp_lp_pre_cap_spp_add_to_plan',
  // Capping
  WEALTH_PLAN_LP_CAPPING_AWARD_PLAY_VIDEO = 'wp_lp_capping_award_play_video',
  WEALTH_PLAN_LP_CAPPING_AWARD_CLICK_ON_VIEW_COMMISSION_INCOME = 'wp_lp_capping_award_click_commission',
  WEALTH_PLAN_LP_CAPPING_AWARD_EXPAND_TERMS_AND_CONDITIONS = 'wp_lp_capping_award_expand_terms',
  // Post cap spp
  WEALTH_PLAN_LP_POST_CAP_SPP_PLAY_VIDEO = 'wp_lp_post_spp_play_video',
  WEALTH_PLAN_LP_POST_CAP_SPP_CLICK_ON_SEE_EXAMPLE = 'wp_lp_post_spp_click_on_see_example',
  WEALTH_PLAN_LP_POST_CAP_SPP_CLICK_ON_PRE_CAP_SPP = 'wp_lp_post_spp_click_on_pre_cap_spp',
  WEALTH_PLAN_LP_POST_CAP_SPP_CLICK_ON_SUPPORT = 'wp_lp_post_spp_click_on_support',
  WEALTH_PLAN_LP_POST_CAP_SPP_EXPAND_EXAMPLE = 'wp_lp_post_spp_expand_example',
  WEALTH_PLAN_LP_POST_CAP_SPP_EXPAND_TERMS_AND_CONDITIONS = 'wp_lp_post_spp_expand_terms',
  WEALTH_PLAN_LP_POST_CAP_SPP_EXPAND_IMPORTANT_DETAILS = 'wp_lp_post_spp_expand_import_details',
  WEALTH_PLAN_LP_POST_CAP_SPP_ADD_TO_PLAN = 'wp_lp_post_spp_add_to_plan',
  // Elite agent production award
  WEALTH_PLAN_LP_ELITE_PRODUCTION_AWARD_PLAY_VIDEO = 'wp_lp_elite_prod_play_video',
  WEALTH_PLAN_LP_ELITE_PRODUCTION_AWARD_CLICK_ON_SEE_DETAILS = 'wp_lp_elite_prod_click_on_see_details',
  WEALTH_PLAN_LP_ELITE_PRODUCTION_AWARD_EXPAND_TERMS_AND_CONDITIONS = 'wp_lp_elite_prod_expand_terms',
  WEALTH_PLAN_LP_ELITE_PRODUCTION_AWARD_ADD_TO_PLAN = 'wp_lp_elite_prod_add_to_plan',
  // ELite agent cultural award
  WEALTH_PLAN_LP_ELITE_CULTURAL_AWARD_PLAY_VIDEO = 'wp_lp_elite_cultural_play_video',
  WEALTH_PLAN_LP_ELITE_CULTURAL_AWARD_CLICK_ON_APPLY_NOW = 'wp_lp_elite_cultural_click_on_apply_now',
  WEALTH_PLAN_LP_ELITE_CULTURAL_AWARD_EXPAND_TERMS_AND_CONDITIONS = 'wp_lp_elite_cultural_expand_terms',
  WEALTH_PLAN_LP_ELITE_CULTURAL_AWARD_ADD_TO_PLAN = 'wp_lp_elite_cultural_add_to_plan',
  // Revenue Share income
  WEALTH_PLAN_LP_REVENUE_SHARE_PLAY_VIDEO = 'wp_lp_rev_share_play_video',
  WEALTH_PLAN_LP_REVENUE_SHARE_CLICK_TIER_0 = 'wp_lp_rev_share_click_tier_0',
  WEALTH_PLAN_LP_REVENUE_SHARE_CLICK_TIER_1 = 'wp_lp_rev_share_click_tier_1',
  WEALTH_PLAN_LP_REVENUE_SHARE_CLICK_TIER_2 = 'wp_lp_rev_share_click_tier_2',
  WEALTH_PLAN_LP_REVENUE_SHARE_CLICK_TIER_3 = 'wp_lp_rev_share_click_tier_3',
  WEALTH_PLAN_LP_REVENUE_SHARE_CLICK_TIER_4 = 'wp_lp_rev_share_click_tier_4',
  WEALTH_PLAN_LP_REVENUE_SHARE_CLICK_TIER_5 = 'wp_lp_rev_share_click_tier_5',
  WEALTH_PLAN_LP_REVENUE_SHARE_EXPAND_GRACE_PERIOD = 'wp_lp_rev_share_expand_grace_period',
  WEALTH_PLAN_LP_REVENUE_SHARE_EXPAND_AGENT_CAP = 'wp_lp_rev_share_expand_agent_cap',
  WEALTH_PLAN_LP_REVENUE_SHARE_EXPAND_PARTICIPATION_FEE = 'wp_lp_rev_share_expand_participation_fee',
  WEALTH_PLAN_LP_REVENUE_SHARE_ADD_TO_PLAN = 'wp_lp_rev_share_add_to_plan',
  // Stock award for attracting
  WEALTH_PLAN_LP_STOCK_AWARD_ATTRACTING_PLAY_VIDEO = 'wp_lp_attracting_play_video',
  WEALTH_PLAN_LP_STOCK_AWARD_ATTRACTING_CLICK_ON_VIEW_COMMISSION_INCOME = 'wp_lp_attracting_click_commission',
  WEALTH_PLAN_LP_STOCK_AWARD_ATTRACTING_EXPAND_TERMS_AND_CONDITIONS = 'wp_lp_attracting_expand_terms',
  WEALTH_PLAN_LP_STOCK_AWARD_ATTRACTING_ADD_TO_PLAN = 'wp_lp_attracting_add_to_plan',
  // REAX future price picker
  WEALTH_PLAN_FUTURE_STOCK_PRICE = 'wp_future_stock_price',

  // Create Transaction Manual Address
  CREATE_TX_SELLER_ADDRESS_ENTERED_MANUALLY = 'create_tx_seller_address_manual',
  CREATE_TX_BUYER_ADDRESS_ENTERED_MANUALLY = 'create_tx_buyer_address_manual',
  CREATE_TX_OTHER_AGENT_ADDRESS_ENTERED_MANUALLY = 'create_tx_other_agent_address_manual',
  CREATE_TX_SELLER_LAWYER_ADDRESS_ENTERED_MANUALLY = 'create_tx_seller_lawyer_address_manual',
  CREATE_TX_BUYER_LAWYER_ADDRESS_ENTERED_MANUALLY = 'create_tx_buyer_lawyer_address_manual',
  // Create Transaction Google Search Address
  CREATE_TX_SELLER_ADDRESS_ENTERED_W_GOOGLE_SEARCH = 'create_tx_seller_address_google',
  CREATE_TX_BUYER_ADDRESS_ENTERED_W_GOOGLE_SEARCH = 'create_tx_buyer_address_google',
  CREATE_TX_OTHER_AGENT_ADDRESS_ENTERED_W_GOOGLE_SEARCH = 'create_tx_other_agent_address_google',
  CREATE_TX_SELLER_LAWYER_ADDRESS_ENTERED_W_GOOGLE_SEARCH = 'create_tx_seller_lawyer_address_google',
  CREATE_TX_BUYER_LAWYER_ADDRESS_ENTERED_W_GOOGLE_SEARCH = 'create_tx_buyer_lawyer_address_google',

  // Checklist
  CHECKLIST_CHECKED_REQUIRED = 'checklist_checked_required',
  CHECKLIST_CHECKED_URGENT = 'checklist_checked_urgent',
  CHECKLIST_SELECTED_STATUS = 'checklist_selected_status',
  CHECKLIST_SELECTED_REQUIRED_FOR = 'checklist_selected_required_for',
  CHECKLIST_APPLIED_FILTER = 'checklist_applied_filter',
  CHECKLIST_SEARCHED_ITEMS = 'checklist_searched_items',

  // Mortgage Central
  MC_WELCOME_SCREEN_VIEWED = 'mc_welcome_screen_viewed',
  MC_INVITE_BUYER_CLICKED = 'mc_invite_buyer_clicked',
  MC_LOAN_OFFICER_SELECTED = 'mc_loan_officer_selected',
  MC_LEAD_SUBMITTED = 'mc_lead_submitted',
  MC_LEADS_LIST_VIEWED = 'mc_leads_list_viewed',
  MC_APPLICATION_SUBMITTED = 'mc_application_submitted',
  MC_DOCUMENT_UPLOADED = 'mc_document_uploaded',
  MC_LOAN_VIEWED = 'mc_loan_viewed',
  MC_DASHBOARD_VIEWED = 'mc_dashboard_viewed',
  MC_COMMENT_ADDED = 'mc_comment_added',
  MC_CHECKLIST_ITEM_ASSIGNED = 'mc_checklist_item_assigned',

  // Directory
  DIRECTORY_SEARCH = 'dir_search',
  DIRECTORY_QUICK_FILTER = 'dir_quick_filter',
  DIRECTORY_REAL_VERIFIED_YES = 'dir_real_verified_yes',
  DIRECTORY_REAL_VERIFIED_NO = 'dir_real_verified_no',
  DIRECTORY_FILTERS_OPENED = 'dir_filters_opened',
  DIRECTORY_FILTERS_CLEARED = 'dir_filters_cleared',
  DIRECTORY_FILTERS_APPLIED = 'dir_filters_applied',
  DIRECTORY_FILTERS_CLOSED = 'dir_filters_closed',
  DIRECTORY_FILTERS_ADD_ROLE = 'dir_filters_add_role',
  DIRECTORY_FILTERS_ID_ENTERED = 'dir_filters_id_entered',
  DIRECTORY_FILTERS_NAME_ENTERED = 'dir_filters_name_entered',
  DIRECTORY_FILTERS_EMAIL_ENTERED = 'dir_filters_email_entered',
  DIRECTORY_FILTERS_PHONE_ENTERED = 'dir_filters_phone_entered',
  DIRECTORY_ADD_CONTACT_OPENED = 'dir_add_contact_opened',
  DIRECTORY_CARD_CLICK = 'dir_card_click',
  DIRECTORY_HOVER_ON_EMAIL = 'dir_hover_on_email',
  DIRECTORY_HOVER_ON_PHONE_NUMBER = 'dir_hover_on_phone_number',
  DIRECTORY_HOVER_ON_ADDRESS = 'dir_hover_on_address',
  DIRECTORY_PAGE_SIZE_CHANGED = 'dir_page_size_changed',
  DIRECTORY_PAGE_CHANGED = 'dir_page_changed',

  // Directory -> Add Contact
  DIRECTORY_CONTACT_SELECT_ROLE = 'dir_ct_select_role',
  DIRECTORY_CONTACT_FIRST_NAME_OR_COMPANY_ENTERED = 'dir_ct_first_name_or_company_entered',
  DIRECTORY_CONTACT_LAST_NAME_OR_COMPANY_ENTERED = 'dir_ct_last_name_or_company_entered',
  DIRECTORY_CONTACT_EMAIL_ENTERED = 'dir_ct_email_address_entered',
  DIRECTORY_CONTACT_PHONE_ENTERED = 'dir_ct_phone_number_entered',
  DIRECTORY_CONTACT_ADDRESS_ENTERED = 'dir_ct_address_entered',
  DIRECTORY_CONTACT_ASSOCIATED_W_BUSINESS_NO = 'dir_ct_associated_w_business_no',
  DIRECTORY_CONTACT_ASSOCIATED_W_BUSINESS_YES = 'dir_ct_associated_w_business_yes',
  DIRECTORY_CONTACT_BUSINESS_NAME_ENTERED = 'dir_ct_business_name_entered',
  DIRECTORY_CONTACT_BUSINESS_PRIMARY_EMAIL_ENTERED = 'dir_ct_business_primary_email_entered',
  DIRECTORY_CONTACT_BUSINESS_SECONDARY_EMAIL_ENTERED = 'dir_ct_business_secondary_email_entered',
  DIRECTORY_CONTACT_BUSINESS_PHONE_ENTERED = 'dir_ct_business_phone_entered',
  DIRECTORY_CONTACT_BUSINESS_ADDRESS_ENTERED = 'dir_ct_business_address_entered',
  DIRECTORY_CONTACT_EIN_NUMBER_ENTERED = 'dir_ct_ein_number_entered',
  DIRECTORY_CONTACT_W9_FORM_SELECTED = 'dir_ct_w9_form_selected',
  DIRECTORY_CONTACT_BUSINESS_NUMBER_ENTERED = 'dir_ct_business_number_entered',
  DIRECTORY_CONTACT_GST_NUMBER_ENTERED = 'dir_ct_gst_number_entered',
  DIRECTORY_CONTACT_HST_NUMBER_ENTERED = 'dir_ct_hst_number_entered',
  DIRECTORY_CONTACT_QST_NUMBER_ENTERED = 'dir_ct_qst_number_entered',
  DIRECTORY_CONTACT_CLICK_SAVE = 'dir_ct_click_save',
  DIRECTORY_CONTACT_CLICK_CANCEL = 'dir_ct_click_cancel',
  DIRECTORY_CONTACT_CLICK_X = 'dir_ct_click_x',

  // Directory Contact List Sidebar
  DIRECTORY_CONTACT_LIST_OPENED = 'dir_ct_list_opened',
  DIRECTORY_CONTACT_LIST_SEARCH = 'dir_ct_list_search',
  DIRECTORY_CONTACT_LIST_REAL_VERIFIED_YES = 'dir_ct_list_real_verified_yes',
  DIRECTORY_CONTACT_LIST_REAL_VERIFIED_NO = 'dir_ct_list_real_verified_no',
  DIRECTORY_CONTACT_LIST_FILTERS_OPENED = 'dir_ct_list_filters_opened',
  DIRECTORY_CONTACT_LIST_FILTERS_APPLIED = 'dir_ct_list_filters_applied',
  DIRECTORY_CONTACT_LIST_FILTERS_CLEARED = 'dir_ct_list_filters_cleared',
  DIRECTORY_CONTACT_LIST_FILTERS_CLOSED = 'dir_ct_list_filters_closed',
  DIRECTORY_CONTACT_LIST_FILTERS_ADD_ROLE = 'dir_ct_list_filters_add_role',
  DIRECTORY_CONTACT_LIST_FILTERS_REMOVE_ROLE = 'dir_ct_list_filters_remove_role',
  DIRECTORY_CONTACT_LIST_FILTERS_NAME_ENTERED = 'dir_ct_list_filters_name_entered',
  DIRECTORY_CONTACT_LIST_FILTERS_EMAIL_ENTERED = 'dir_ct_list_filters_email_entered',
  DIRECTORY_CONTACT_LIST_FILTERS_PHONE_ENTERED = 'dir_ct_list_filters_phone_entered',
  DIRECTORY_CONTACT_LIST_SELECT_CONTACT = 'dir_ct_list_select_contact',
  DIRECTORY_CONTACT_LIST_CLICK_SAVE = 'dir_ct_list_click_save',
  DIRECTORY_CONTACT_LIST_CLICK_CANCEL = 'dir_ct_list_click_cancel',
  DIRECTORY_CONTACT_LIST_CLICK_X = 'dir_ct_list_click_x',

  // New Transaction > Referral Fee > External Participant
  NEW_TRANSACTION_REFERRAL_FEE_EXTERNAL_PARTICIPANT_SAVE = 'new_tx_ref_fee_ext_participant_save',
  NEW_TRANSACTION_REFERRAL_FEE_EXTERNAL_PARTICIPANT_CANCEL = 'new_tx_ref_fee_ext_participant_cancel',
  NEW_TRANSACTION_REFERRAL_FEE_EXTERNAL_PARTICIPANT_CLICK_X = 'new_tx_ref_fee_ext_participant_click_x',

  // External Agent Info Confirmation
  VERIFY_ADDRESS_AND_TAX_ID_CONFIRM = 'verify_address_and_tax_id_confirm',
  VERIFY_ADDRESS_AND_TAX_ID_CANCEL = 'verify_address_and_tax_id_cancel',
  VERIFY_ADDRESS_AND_TAX_ID_CLICK_X = 'verify_address_and_tax_id_click_x',

  // Self Service Team
  SELF_SERVICE_TEAM_CLICK_INVITE_AGENT_BTN = 'sst_click_invite_agent_btn',

  // Self Service Team - Invite Via Email
  SELF_SERVICE_TEAM_INVITE_VIA_EMAIL_INPUT_FIRST_NAME = 'sst_email_type_first_name',
  SELF_SERVICE_TEAM_INVITE_VIA_EMAIL_INPUT_LAST_NAME = 'sst_email_type_last_name',
  SELF_SERVICE_TEAM_INVITE_VIA_EMAIL_INPUT_EMAIL = 'sst_email_type_email',
  SELF_SERVICE_TEAM_INVITE_VIA_EMAIL_SELECT_4K_CAP = 'sst_email_select_4k_cap',
  SELF_SERVICE_TEAM_INVITE_VIA_EMAIL_SELECT_6K_CAP = 'sst_email_select_6k_cap',
  SELF_SERVICE_TEAM_INVITE_VIA_EMAIL_SELECT_12K_CAP = 'sst_email_select_12k_cap',
  SELF_SERVICE_TEAM_INVITE_VIA_EMAIL_WAIVE_JOIN_FEE_ON = 'sst_email_waive_fees_on',
  SELF_SERVICE_TEAM_INVITE_VIA_EMAIL_WAIVE_JOIN_FEE_OFF = 'sst_email_waive_fees_off',
  SELF_SERVICE_TEAM_INVITE_VIA_EMAIL_X_CLOSE_SIDEBAR = 'sst_email_x_close_sidebar',
  SELF_SERVICE_TEAM_INVITE_VIA_EMAIL_CLICK_CANCEL = 'sst_email_click_cancel',
  SELF_SERVICE_TEAM_INVITE_VIA_EMAIL_CLICK_INVITE = 'sst_email_click_invite',

  SELF_SERVICE_TEAM_CLICK_INVITE_VIA_LINK_TAB = 'sst_click_invite_via_link_tab',
  SELF_SERVICE_TEAM_CLICK_INVITE_VIA_EMAIL_TAB = 'sst_click_invite_via_email_tab',

  // Self Service Team - Invite Via Link
  SELF_SERVICE_TEAM_INVITE_VIA_LINK_WAIVE_JOIN_FEE_ON = 'sst_link_waive_fee_on',
  SELF_SERVICE_TEAM_INVITE_VIA_LINK_WAIVE_JOIN_FEE_OFF = 'sst_link_waive_fee_off',
  SELF_SERVICE_TEAM_INVITE_VIA_LINK_SELECT_4K_CAP = 'sst_link_select_4k_cap',
  SELF_SERVICE_TEAM_INVITE_VIA_LINK_SELECT_6K_CAP = 'sst_link_select_6k_cap',
  SELF_SERVICE_TEAM_INVITE_VIA_LINK_SELECT_12K_CAP = 'sst_link_select_12k_cap',
  SELF_SERVICE_TEAM_INVITE_VIA_LINK_CLICK_INSIDE_SHARE_INVITE_LINK_BOX = 'sst_link_click_inside_share_invite_link',
  SELF_SERVICE_TEAM_INVITE_VIA_LINK_CLICK_COPY_LINK = 'sst_link_click_copy_link',
  SELF_SERVICE_TEAM_INVITE_VIA_LINK_CLICK_CANCEL = 'sst_link_click_cancel',
  SELF_SERVICE_TEAM_INVITE_VIA_LINK_X_CLOSE_SIDEBAR = 'sst_link_x_close_sidebar',

  SELF_SERVICE_TEAM_CLICK_COPY_INVITATION_LINK_BTN = 'sst_click_copy_invitation_link',
  SELF_SERVICE_TEAM_REVOKE_POPUP_CLICK_REVOKE_INVITATION = 'sst_popup_revoke_invitation_click',
  SELF_SERVICE_TEAM_REVOKE_POPUP_CLICK_CANCEL = 'sst_revoke_popup_click_cancel',
  SELF_SERVICE_TEAM_REVOKE_POPUP_CLICK_X = 'sst_revoke_popup_click_x',
  SELF_SERVICE_TEAM_REVOKE_INVITATION_BTN = 'sst_click_revoke_invitation_btn',

  // Wallet
  WALLET_SIGN_UP_FOR_DEBIT_CLICKED = 'wallet_sign_up_for_debit_clicked',
  WALLET_AGREED_TO_PRIVACY_POLICY = 'wallet_agreed_to_privacy_policy',
  WALLET_CONTINUE_IN_LEGAL_PRIVACY_CLICKED = 'wallet_continue_in_legal_privacy_clicked',
  WALLET_SIGN_UP_STEP_1_NEXT_CLICKED = 'wallet_sign_up_step_1_next_clicked',
  WALLET_SIGN_UP_STEP_2_NEXT_CLICKED = 'wallet_sign_up_step_2_next_clicked',
  WALLET_SUBMIT_APPLICATION = 'wallet_submit_application',
  WALLET_CONTINUE_TO_UNIT_FORM_CLICKED = 'wallet_continue_to_unit_form_clicked',
}

export interface ImageContent {
  pageNumber: number;
  path: string;
}

export interface ImageResponse {
  images: ImageContent[];
}

export enum IFrameEventEnum {
  DOCUMENT_SIGNED = 'DOCUMENT_SIGNED',
  DOCUMENT_ALREADY_SIGNED = 'DOCUMENT_ALREADY_SIGNED',
  OPEN_DOCUMENT_IN_BROWSER = 'OPEN_DOCUMENT_IN_BROWSER',
}

export interface OnDocumentSignedEventData {
  documentId: string;
}

export interface OnDocumentAlreadySignedEventData {}

export interface IFrameEvent {
  type: IFrameEventEnum;
  data: any;
}

export enum SignatureWidgetModeEnum {
  SIGN = 'sign',
  VIEW = 'view',
  PREVIEW = 'preview',
}
export type announcementDefinitionSortByType = Parameters<
  typeof AnnouncementDefControllerApi.prototype.getDefinitions
>[3];

export type announcementDefinitionSortDirectionType = Parameters<
  typeof AnnouncementDefControllerApi.prototype.getDefinitions
>[4];

export type SearchTransactionSortDirectionType = Parameters<
  typeof TransactionControllerApi.prototype.getTransactions
>[3];

export type SearchTransactionSortByType = Parameters<
  typeof TransactionControllerApi.prototype.getTransactions
>[4];

export type SearchTransactionStateOrProvincesType = Parameters<
  typeof TransactionControllerApi.prototype.getTransactions
>[9];

export type SearchTransactionTransactionTypes = Parameters<
  typeof TransactionControllerApi.prototype.getTransactions
>[22];

export type SearchTransactionStatusType = Parameters<
  typeof TransactionControllerApi.prototype.getTransactions
>[23];

export type ZenInvoiceTableSortDirectionType = Parameters<
  typeof InvoiceControllerApi.prototype.getInvoices
>[2];

export type ZenInvoiceTableSortByType = Parameters<
  typeof InvoiceControllerApi.prototype.getInvoices
>[3];

/* FOR searchForAgents */
export type SearchAgentSortDirectionType = Parameters<
  typeof AgentControllerApi.prototype.searchAgents
>[2];

export type SearchAgentSortByType = Parameters<
  typeof AgentControllerApi.prototype.searchAgents
>[3];

export type SearchAgentStatusType = Parameters<
  typeof AgentControllerApi.prototype.searchAgents
>[11];

export type SearchAgentCountryType = Parameters<
  typeof AgentControllerApi.prototype.searchAgents
>[12];

export type SearchAgentStateOrProvinceType = Parameters<
  typeof AgentControllerApi.prototype.searchAgents
>[13];

export type SearchAgentOfficeIdType = Parameters<
  typeof AgentControllerApi.prototype.searchAgents
>[14];

export type SearchAgentMLSIdType = Parameters<
  typeof AgentControllerApi.prototype.searchAgents
>[15];

export type SearchAgentBoardIdType = Parameters<
  typeof AgentControllerApi.prototype.searchAgents
>[16];

/* FOR searchActiveAgents */
export type SearchActiveAgentSortDirectionType = Parameters<
  typeof AgentControllerApi.prototype.searchActiveAgents
>[2];

export type SearchActiveAgentSortByType = Parameters<
  typeof AgentControllerApi.prototype.searchActiveAgents
>[3];

export type SearchActiveAgentCountryType = Parameters<
  typeof AgentControllerApi.prototype.searchActiveAgents
>[6];

export type SearchActiveAgentStateProvinceType = Parameters<
  typeof AgentControllerApi.prototype.searchActiveAgents
>[7];

export type CommissionAgentSortByType = Parameters<
  typeof ArrakisAgentControllerApi.prototype.getCommissionAdvances1
>[4];

export type SearchLiteMlsStateType = Parameters<
  typeof MlsControllerApi.prototype.searchLiteMls
>[4];

export interface SearchAgentsRequestType {
  pageNumber?: number;
  pageSize?: number;
  sortDirection?: SearchAgentSortDirectionType;
  sortBy?: SearchAgentSortByType;
  searchText?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  nonReportable?: boolean;
  id?: string;
  status?: SearchAgentStatusType;
  country?: SearchAgentCountryType;
  stateOrProvince?: SearchAgentStateOrProvinceType;
  officeId?: string[];
  mlsId?: string[];
  boardId?: string[];
  divisionIds?: string[];
}

export type SearchTeamSortDirectionType = Parameters<
  typeof TeamControllerApi.prototype.search
>[2];

export type SearchTeamSortByType = Parameters<
  typeof TeamControllerApi.prototype.search
>[3];

export type SearchTeamStatusType = Parameters<
  typeof TeamControllerApi.prototype.search
>[7];

export type SearchTeamType = Parameters<
  typeof TeamControllerApi.prototype.search
>[10];

export type SearchOfficesSortDirectionType = Parameters<
  typeof OfficeControllerApi.prototype.searchOffices
>[2];

export type SearchOfficesSortByType = Parameters<
  typeof OfficeControllerApi.prototype.searchOffices
>[3];

export type SearchOfficesTransactionType = Parameters<
  typeof OfficeControllerApi.prototype.searchOffices
>[8];

export type UpdateLoanRequestStatusEnum = Parameters<
  typeof LoansApi.prototype.updateLoanStatus
>[1];

export enum SessionStorageKeyEnum {
  STOP_PAGE_REDIRECTS = 'STOP_PAGE_REDIRECTS',
}

export enum FlexTeamRoles {
  Teammate = 'TEAMMATE',
  Leader = 'LEADER',
  Admin = 'ADMIN',
}

export enum LifecycleGroupEnum {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
  DRAFT = 'DRAFT',
  TERMINATED = 'TERMINATED',
}

export type LifecycleGroup = 'OPEN' | 'CLOSED' | 'TERMINATED';

export type TransactionType =
  | 'COMPENSATING'
  | 'REFERRAL'
  | 'SALE'
  | 'LEASE'
  | 'COMMERCIAL_LEASE'
  | 'PLOT'
  | 'OTHER'
  | 'INTERNAL_REFERRAL';

export type LifeCycleState =
  | 'NEW'
  | 'CALCULATE_LEDGER'
  | 'NEEDS_COMMISSION_VALIDATION'
  | 'COMMISSION_VALIDATED'
  | 'READY_FOR_COMMISSION_DOCUMENT_GENERATION'
  | 'COMMISSION_DOCUMENT_GENERATED'
  | 'COMMISSION_DOCUMENT_APPROVED'
  | 'COMMISSION_DOCUMENT_SENT'
  | 'APPROVED_FOR_CLOSING'
  | 'CLOSED'
  | 'WAITING_ON_PAYMENT'
  | 'PAYMENT_ACCEPTED'
  | 'PAYMENT_SCHEDULED'
  | 'SETTLED'
  | 'TERMINATION_REQUESTED'
  | 'TERMINATED'
  | 'LISTING_ACTIVE'
  | 'LISTING_IN_CONTRACT'
  | 'LISTING_CLOSED';

export interface TeamAgentsInviteTableDataType {
  firstName: string;
  lastName: string;
  emailAddress: string;
  capLevel: number;
  waiveFees?: boolean;
  invitationId?: string;
  invitationSentAt?: number;
  invitationPending: boolean;
  invitationStatus:
    | TeamInvitationSimplifiedDtoInvitationStatusEnum
    | GenericTeamInvitationApplicationResponseStatusEnum;
}

// Temp types - Delete once RC swagger is used
export enum ReferralTransitionStatusEnum {
  PUBLISHED = 'PUBLISHED',
  PAUSED = 'PAUSED',
  APPLIED = 'APPLIED',
  LISTING_CREATED = 'LISTING_CREATED',
  LISTING_NOT_CREATED = 'LISTING_NOT_CREATED',
  PENDING_SIGNATURE = 'PENDING_SIGNATURE',
}

export enum MyReferralType {
  CREATED = 'CREATED',
  APPLIED = 'APPLIED',
  RECEIVED = 'RECEIVED',
}

export enum Timeline {
  ASAP = 'As Soon As Possible',
  ZERO_TO_THREE_MONTHS = '0 - 3 Months',
  SIX_TO_TWELVE_MONTHS = '6 - 12 Months',
  NOT_SURE = 'Not Sure',
}

export enum ReferralClientRole {
  BUYER = 'BUYER',
  SELLER = 'SELLER',
  TENANT = 'TENANT',
  LANDLORD = 'LANDLORD',
}

export type TransactionsMainTabType = 'all' | 'sale' | 'lease' | 'referrals';

export type TransactionsSubTabType =
  | 'active'
  | 'closed'
  | 'terminated'
  | 'draft';

export interface TransactionsRouteUrlParams {
  type: TransactionsMainTabType;
  lifecycle: TransactionsSubTabType;
}

export interface SearchParam {
  operator?: SearchParamOperatorEnum;
  column?: string;
  value?: string;
  values?: Array<string>;
  joinColumn?: string;
  stringStrictCase?: boolean;
}

export enum SearchParamOperatorEnum {
  Like = 'LIKE',
  In = 'IN',
  Eq = 'EQ',
  Lt = 'LT',
  Gt = 'GT',
  Lte = 'LTE',
  Gte = 'GTE',
  IsNull = 'IS_NULL',
  IsNotNull = 'IS_NOT_NULL',
}

export interface TransactionCoordinatorState {
  tcDataById: Mapping<TCInfo>;
  isLoading: boolean;
}

export enum TransactionCoordinatorAccessEnum {
  ALL_OPEN_LISTINGS_AND_TRANSACTIONS = 'ALL_OPEN_LISTINGS_AND_TRANSACTIONS',
  CREATED_AFTER = 'CREATED_AFTER',
  CREATED_BY_THEM_OR_MANUALLY_ADDED = 'CREATED_BY_THEM_OR_MANUALLY_ADDED',
}

export type ManageTCMainTabsType = 'CONNECTIONS' | 'ACTIVITY_LOG';

export type ManageTCConnectionTabsType =
  | 'ACTIVE'
  | 'PENDING'
  | 'PAST_CONNECTIONS';
