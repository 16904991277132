import { TextInput as MTextInput, TextInputProps } from '@mantine/core';
import React from 'react';
import { cn } from '../../utils/classUtils';
import { combineDefaultClassnamesWithCustomClassnames } from './utils';

const defaultStyles: TextInputProps['classNames'] = {
  icon: 'absolute top-1/2 -translate-y-1/2 text-primary-blue pl-4 pt-1',
  rightSection: 'absolute right-0 mr-4 top-1/2 -translate-y-1/2 text-sm pt-1',
  label: 'font-inter font-light text-[15px] text-primary-dark leading-6',
  root: 'w-full space-y-1',
  error: '!text-red-600 !text-sm',
  required: 'text-red-600 text-sm',
  input: cn(
    'rounded-md w-full font-inter font-light bg-primary-light py-2.5',
    'placeholder:text-[15px] placeholder:font-inter placeholder:font-light placeholder:text-grey-500',
    'border border-regent-400 outline-none !ring-0',
    'focus:!border-primary-dark',
    'disabled:bg-grey-100 disabled:cursor-not-allowed',
  ),
  wrapper: 'flex items-stretch relative',
} as const;

export const TextInput: React.FC<
  TextInputProps & React.RefAttributes<HTMLInputElement>
> = (props) => {
  const hasError = !!props.error;

  return (
    <MTextInput
      unstyled
      {...props}
      classNames={combineDefaultClassnamesWithCustomClassnames(
        defaultStyles,
        {
          input: cn(
            props.icon ? 'pl-10' : 'pl-4',
            props.rightSection ? 'pr-10' : 'pr-4',
            hasError && 'border-coral-red',
          ),
          icon: cn(hasError && 'text-coral-red'),
        },
        props.classNames,
      )}
    />
  );
};
