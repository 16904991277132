import { GoalsFormInput } from '../components/WeathManagement/V2/SetGoalsSideBarV2';
import {
  Earning,
  EarningType,
  PrimaryColor,
  WealthPlanEventEnum,
} from '../components/WeathManagement/WealthManagementTypes';
import {
  GoalDetails,
  MoneyValueCurrencyEnum,
  RevShareTierInputsDto,
  WealthGoalsResponse,
} from '../openapi/plutus';
import { CalendarEventResponse } from '../openapi/yenta';
import { AnalyticsEventEnum, Mapping } from '../types';

export const getEarningsOverviewCardGradient = (earningType: EarningType) => {
  return `linear-gradient(144deg, rgba(255, 255, 255, 0.00) 16.44%, ${PrimaryColor[earningType]} 206.7%), #FAFCFE`;
};

export const earningTypeToEditEventMap: Record<
  EarningType,
  AnalyticsEventEnum
> = {
  [Earning.COMMISSION_INCOME]:
    AnalyticsEventEnum.WEALTH_PLAN_OVERVIEW_COMMISSION_INCOME,
  [Earning.PRE_CAP_SPP]: AnalyticsEventEnum.WEALTH_PLAN_OVERVIEW_PRE_CAP_SPP,
  [Earning.POST_CAP_SPP]: AnalyticsEventEnum.WEALTH_PLAN_OVERVIEW_POST_CAP_SPP,
  [Earning.ELITE_PRODUCTION_AWARD]:
    AnalyticsEventEnum.WEALTH_PLAN_OVERVIEW_ELITE_PRODUCTION_AWARD,
  [Earning.ELITE_CULTURAL_AWARD]:
    AnalyticsEventEnum.WEALTH_PLAN_OVERVIEW_ELITE_CULTURAL_AWARD,
  [Earning.STOCK_AWARD_FOR_CAPPING]:
    AnalyticsEventEnum.WEALTH_PLAN_OVERVIEW_CAPPING_AWARD,
  [Earning.STOCK_AWARD_FOR_ATTRACTING]:
    AnalyticsEventEnum.WEALTH_PLAN_OVERVIEW_ATTRACTING_AWARD,
  [Earning.REVENUE_SHARE_INCOME]:
    AnalyticsEventEnum.WEALTH_PLAN_OVERVIEW_REV_SHARE,
};

export const getLockedEarnings = (goalDetails: GoalDetails | undefined) => {
  const {
    sppParticipation,
    eliteAgentProductionAwardParticipation,
    eliteAgentCulturalAwardParticipation,
    revShareParticipation,
  } = goalDetails || {};

  const isLockedEarningTypeEnumMap: Record<EarningType, boolean> = {
    COMMISSION_INCOME: false,
    PRE_CAP_SPP: !sppParticipation,
    STOCK_AWARD_FOR_CAPPING: false,
    POST_CAP_SPP: !sppParticipation,
    ELITE_PRODUCTION_AWARD: !eliteAgentProductionAwardParticipation,
    ELITE_CULTURAL_AWARD: !eliteAgentCulturalAwardParticipation,
    REVENUE_SHARE_INCOME: !revShareParticipation,
    STOCK_AWARD_FOR_ATTRACTING: !revShareParticipation,
  };

  const totalLockedEarnings = Object.values(isLockedEarningTypeEnumMap).filter(
    (isLocked) => isLocked,
  ).length;

  return { isLockedEarningTypeEnumMap, totalLockedEarnings };
};

export const extraEarningPercentage = (
  goalValue: number,
  currentValue: number,
) => {
  const percentage = (currentValue / goalValue) * 100 - 100;
  return `${percentage.toFixed(2)}%`;
};

export const safeTypeConToNum = (value: string | number | undefined) => {
  if (!value) return 0;
  return parseFloat(value.toString());
};

export const wealthPlanCalenderEvents = (
  wealthPlanEvents: Mapping<CalendarEventResponse[]>,
) => {
  const attractionAccelerator =
    wealthPlanEvents[WealthPlanEventEnum.ATTRACTION_ACCELERATOR];
  const salesAccelerator =
    wealthPlanEvents[WealthPlanEventEnum.SALES_ACCELERATOR];

  const attractionAcceleratorEvent = attractionAccelerator
    ? attractionAccelerator[0]
    : undefined;
  const salesAcceleratorEvent = salesAccelerator
    ? salesAccelerator[0]
    : undefined;

  const CalendarEventByTypeMap: Record<
    EarningType,
    CalendarEventResponse | undefined
  > = {
    [Earning.COMMISSION_INCOME]: salesAcceleratorEvent,
    [Earning.PRE_CAP_SPP]: salesAcceleratorEvent,
    [Earning.STOCK_AWARD_FOR_CAPPING]: salesAcceleratorEvent,
    [Earning.POST_CAP_SPP]: salesAcceleratorEvent,
    [Earning.ELITE_PRODUCTION_AWARD]: salesAcceleratorEvent,
    [Earning.ELITE_CULTURAL_AWARD]: salesAcceleratorEvent,
    [Earning.REVENUE_SHARE_INCOME]: attractionAcceleratorEvent,
    [Earning.STOCK_AWARD_FOR_ATTRACTING]: attractionAcceleratorEvent,
  };

  return CalendarEventByTypeMap;
};

export const getTeamInfo = (
  formData: GoalsFormInput,
  currency: MoneyValueCurrencyEnum,
  forPreview: boolean = false,
) => {
  const { teamLead, teamUseAverageFixedCommission } = formData;

  const teamInfo =
    teamLead || forPreview
      ? {
          teamLead,
          teamNumberCount: formData.teamNumberCount,
          teamAverageSalesPrice: {
            amount: formData.teamAverageSalesPrice,
            currency,
          },
          teamTransactionsPerMember: formData.teamTransactionsPerMember,
          teamLeaderCommissionPercentagePerTransaction:
            formData.teamLeaderCommissionPercentagePerTransaction,
          teamUseAverageFixedCommission,
          teamAverageFixedCommission: teamUseAverageFixedCommission
            ? { amount: formData.teamAverageFixedCommission, currency }
            : undefined,
          teamAverageTransactionCommissionPercentage: !teamUseAverageFixedCommission
            ? formData.teamAverageTransactionCommissionPercentage
            : undefined,
        }
      : {
          teamLead: false,
          teamNumberCount: getTeamCount(formData.teamNumberCount),
          teamAverageSalesPrice: undefined,
          teamTransactionsPerMember: undefined,
          teamLeaderCommissionPercentagePerTransaction: undefined,
          teamUseAverageFixedCommission: false,
          teamAverageFixedCommission: undefined,
          teamAverageTransactionCommissionPercentage: undefined,
        };

  return teamInfo;
};

export const getAverageFixedCommissionEndOfDeal = (
  formData: GoalsFormInput,
  currency: MoneyValueCurrencyEnum,
) => {
  const { useAverageFixedCommission } = formData;

  const averageCommissionEndOfDeal = useAverageFixedCommission
    ? {
        useAverageFixedCommission,
        averageFixedCommission: {
          amount: formData.averageFixedCommission,
          currency,
        },
        averageTransactionCommissionPercentage: undefined,
      }
    : {
        useAverageFixedCommission: false,
        averageFixedCommission: undefined,
        averageTransactionCommissionPercentage:
          formData.averageTransactionCommissionPercentage,
      };

  return averageCommissionEndOfDeal;
};

export const getUpdateWealthPlanRequest = (
  formData: GoalsFormInput,
  currency: MoneyValueCurrencyEnum,
  wealthGoalsResponse: WealthGoalsResponse | undefined,
  isRevenueShareProjectionsFeatureFlagEnabled: boolean,
): GoalDetails => {
  const request: GoalDetails = {
    ...getAverageFixedCommissionEndOfDeal(formData, currency),
    ...getTeamInfo(formData, currency),
    additionalRealShares: formData.additionalRealShares,
    agentReferredPerYear: formData.agentReferredPerYear,
    agentsAgentReferredPerYear: formData.agentsAgentReferredPerYear,
    averageHomeSalePrice: {
      amount: formData.averageHomeSalePrice,
      currency,
    },
    earningsGoalValue: {
      amount: formData.earningsGoalValue,
      currency,
    },
    eliteAgentCulturalAwardParticipation:
      wealthGoalsResponse?.goalDetails.eliteAgentCulturalAwardParticipation ??
      false,
    eliteAgentProductionAwardParticipation:
      wealthGoalsResponse?.goalDetails.eliteAgentProductionAwardParticipation ??
      false,
    planId: '',
    planStartDate: new Date().toISOString(),
    projectionYears: 5,
    realStockFuturePrice: {
      amount: formData.realStockFuturePrice,
      currency: MoneyValueCurrencyEnum.Usd,
    },
    realTitleJVParticipation: false,
    realTitleJVShares: 0,
    revShareParticipation: !isRevenueShareProjectionsFeatureFlagEnabled
      ? formData.agentReferredPerYear > 0 ||
        formData.agentsAgentReferredPerYear > 0
      : formData.revShareParticipation,
    seenEliteCulturalPage:
      wealthGoalsResponse?.goalDetails?.seenEliteCulturalPage ?? false,
    seenEliteProductionPage:
      wealthGoalsResponse?.goalDetails?.seenEliteProductionPage ?? false,
    sppParticipation: formData.sppParticipation,
    transactionsPerYear: formData.transactionsPerYear,
    volumePerYear: {
      amount: formData.volumePerYear,
      currency,
    },
    ...(isRevenueShareProjectionsFeatureFlagEnabled && {
      agentCountsInTiers: !!formData?.revShareParticipation
        ? parseAgentCountsInTiers(formData?.agentCountsInTiers)
        : undefined,
      alwaysRefreshCurrentNetworkTierCounts: !!formData?.revShareParticipation
        ? formData?.alwaysRefreshCurrentNetworkTierCounts
        : undefined,
      percentageOfCappingNetworkAgents: !!formData?.revShareParticipation
        ? +formData?.percentageOfCappingNetworkAgents!
        : undefined,
    }),
  };

  return request;
};

export const getTeamDefaultValues = (goalDetails: GoalDetails | undefined) => {
  const {
    teamLead,
    teamUseAverageFixedCommission,
    teamAverageFixedCommission,
    teamAverageTransactionCommissionPercentage,
    teamAverageSalesPrice,
    teamTransactionsPerMember,
    teamLeaderCommissionPercentagePerTransaction,
    teamNumberCount,
  } = goalDetails || {};
  return {
    teamLead: teamLead || false,
    teamNumberCount: getTeamCount(teamNumberCount),
    teamAverageSalesPrice: Math.max(
      teamAverageSalesPrice?.amount || 10000,
      10000,
    ),
    teamTransactionsPerMember: Math.max(teamTransactionsPerMember || 1, 1),
    teamLeaderCommissionPercentagePerTransaction: Math.max(
      teamLeaderCommissionPercentagePerTransaction || 1,
      1,
    ),
    teamUseAverageFixedCommission: teamUseAverageFixedCommission || false,
    teamAverageFixedCommission: Math.max(
      teamAverageFixedCommission?.amount || 0,
      0,
    ),
    teamAverageTransactionCommissionPercentage: Math.max(
      teamAverageTransactionCommissionPercentage || 0.1,
      0.1,
    ),
  };
};

export const getGoalUpdateDefaultValues = (
  goalDetails: GoalDetails | undefined,
) => {
  const {
    useAverageFixedCommission,
    averageFixedCommission,
    averageTransactionCommissionPercentage,
    teamLead,
    teamUseAverageFixedCommission,
    teamAverageFixedCommission,
    teamAverageTransactionCommissionPercentage,
    teamAverageSalesPrice,
    teamTransactionsPerMember,
    teamLeaderCommissionPercentagePerTransaction,
    teamNumberCount,
  } = goalDetails || {};

  return {
    useAverageFixedCommission: useAverageFixedCommission || false,
    averageFixedCommission: {
      amount: Math.max(averageFixedCommission?.amount || 0, 0),
      currency: teamAverageFixedCommission?.currency,
    },
    averageTransactionCommissionPercentage: Math.max(
      averageTransactionCommissionPercentage || 0.1,
      0.1,
    ),
    teamLead: teamLead || false,
    teamNumberCount: getTeamCount(teamNumberCount),
    teamAverageSalesPrice: {
      amount: Math.max(teamAverageSalesPrice?.amount || 10000, 10000),
      currency: teamAverageSalesPrice?.currency,
    },
    teamTransactionsPerMember: Math.max(teamTransactionsPerMember || 1, 1),
    teamLeaderCommissionPercentagePerTransaction: Math.max(
      teamLeaderCommissionPercentagePerTransaction || 1,
      1,
    ),
    teamUseAverageFixedCommission: teamUseAverageFixedCommission || false,
    teamAverageFixedCommission: {
      amount: Math.max(teamAverageFixedCommission?.amount || 0, 0),
      currency: teamAverageFixedCommission?.currency,
    },
    teamAverageTransactionCommissionPercentage: Math.max(
      teamAverageTransactionCommissionPercentage || 0.1,
      0.1,
    ),
  };
};

export const getTeamCount = (teamCount: number | undefined) => {
  // set default value to 5 if no value is provided
  if (!!teamCount && teamCount > 0 && teamCount <= 1000) {
    return teamCount;
  }
  return 5;
};

export const parseAgentCountsInTiers = (
  agentCountsInTiers: RevShareTierInputsDto[] | undefined,
) => {
  const agentCountsInTiersParsed = (agentCountsInTiers || [])?.map((tier) => ({
    currentNetworkCount: safeTypeConToNum(tier?.currentNetworkCount),
    yearlyJoiningAgentCount: safeTypeConToNum(tier?.yearlyJoiningAgentCount),
  }));

  return agentCountsInTiersParsed;
};
