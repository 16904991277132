import * as Arrakis from '../../openapi/arrakis';
import * as Atlantis from '../../openapi/atlantis';
import * as Dropbox from '../../openapi/dropbox';
import * as Hermes from '../../openapi/hermes';
import * as Memento from '../../openapi/memento';
import * as Plutus from '../../openapi/plutus';
import * as Sherlock from '../../openapi/sherlock';
import * as Signature from '../../openapi/signature-api';
import * as Wallet from '../../openapi/wallet';
import * as Yada from '../../openapi/yada';
import * as Yenta from '../../openapi/yenta';
import {
  getArrakisConfiguration,
  getAtlantisConfiguration,
  getDropboxConfiguration,
  getHermesConfiguration,
  getMementoConfiguration,
  getSherlockConfiguration,
  getSignatureApiConfiguration,
  getWalletConfiguration,
  getWealthManagementConfiguration,
  getYadaConfiguration,
  getYentaConfiguration,
} from '../../utils/OpenapiConfigurationUtils';
import { KafkaControllers } from './KafkaControllerTypes';
import { KafkaControllerWrapper } from './KafkaController';

export const ProducerService = {
  arrakis: 'arrakis',
  atlantis: 'atlantis',
  dropbox: 'dropbox',
  hermes: 'hermes',
  memento: 'memento',
  plutus: 'plutus',
  sherlock: 'sherlock',
  'signature-api': 'signature-api',
  wallet: 'wallet',
  yada: 'yada',
  yenta: 'yenta',
} as const;
export type ProducerServiceKeys = keyof typeof ProducerService;

export const producerControllers: KafkaControllers<ProducerServiceKeys> = {
  arrakis: new KafkaControllerWrapper(
    new Arrakis.KafkaRetryProducerControllerApi(getArrakisConfiguration()),
  ),
  atlantis: new KafkaControllerWrapper(
    new Atlantis.KafkaRetryProducerControllerApi(getAtlantisConfiguration()),
  ),
  dropbox: new KafkaControllerWrapper(
    new Dropbox.KafkaRetryProducerControllerApi(getDropboxConfiguration()),
  ),
  hermes: new KafkaControllerWrapper(
    new Hermes.KafkaRetryProducerControllerApi(getHermesConfiguration()),
  ),
  memento: new KafkaControllerWrapper(
    new Memento.KafkaRetryProducerControllerApi(getMementoConfiguration()),
  ),
  plutus: new KafkaControllerWrapper(
    new Plutus.KafkaRetryProducerControllerApi(
      getWealthManagementConfiguration(),
    ),
  ),
  sherlock: new KafkaControllerWrapper(
    new Sherlock.KafkaRetryProducerControllerApi(getSherlockConfiguration()),
  ),
  'signature-api': new KafkaControllerWrapper(
    new Signature.KafkaRetryProducerControllerApi(
      getSignatureApiConfiguration(),
    ),
  ),
  wallet: new KafkaControllerWrapper(
    new Wallet.KafkaRetryProducerControllerApi(getWalletConfiguration()),
  ),
  yada: new KafkaControllerWrapper(
    new Yada.KafkaRetryProducerControllerApi(getYadaConfiguration()),
  ),
  yenta: new KafkaControllerWrapper(
    new Yenta.KafkaRetryProducerControllerApi(getYentaConfiguration()),
  ),
};
