import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';
import {
  DateFilterEnum,
  DateRange,
} from '../components/Zen/Calendars/LQMDateFilter';
import {
  CurrentChat,
  SENDER_RECEIVER_ENUM,
} from '../components/Zen/Leo/Chat/LeoChatIndex';
import { SessionHistory } from '../components/Zen/Leo/ChatHistory/LeoChatHistory';
import {
  DEFAULT_CHAT_PROMPTS,
  DONE_TOKEN,
  START_TOKEN,
} from '../constants/LeoConstants';
import {
  ChatControllerApi,
  LeoQuestionManagerApi,
  PromptLibraryRequest,
  DocumentReviewApi,
  NotificationsApi,
  ChatMessageTicket,
  VoteType,
} from '../openapi/leo';
import AnalyticsService from '../services/AnalyticsService';
import ErrorService from '../services/ErrorService';
import {
  AnalyticsEventEnum,
  AppThunk,
  LeoState,
  Mapping,
  RootState,
} from '../types';
import { getAuthCookie } from '../utils/AuthUtils';
import { getLeoResponseSteamUrl } from '../utils/BrowserUtils';
import {
  ContextualPrompt,
  FollowUp,
  FollowUpItem,
  LeoNotification,
  LeoNotificationAction,
  LeoNotificationCreate,
  LeoResponseMessageContext,
  SessionWithDisclaimer,
} from '../utils/LeoUtils';
import Logger from '../utils/Logger';
import { getLeoConfiguration } from '../utils/OpenapiConfigurationUtils';
import { performFetch } from '../utils/FetchUtil';
import { showErrorToast, showSuccessToast } from './ToastNotificationSlice';

export const initialState: LeoState = {
  loading: false,
  isChatLoading: false,
  isHistoryLoading: false,
  deletingSessionId: undefined,
  showIntro: true,
  isLeoThinking: false,
  isLeoGenerating: false,
  isLeoModalVisible: false,
  session: undefined,
  isSessionError: false,
  chatHistory: [],
  currentChat: [],
  selectedItems: [],
  defaultPrompts: [],
  msgContext: undefined,
  isCreatingTicket: false,
  isTicketCreated: false,
  isNudgeEnabled: false,
  leoQuestionManager: {
    workQueue: {
      dateFilterType: DateFilterEnum.ALL_TIME,
      dateRange: {},
    },
    assignedQueue: {
      dateFilterType: DateFilterEnum.ALL_TIME,
      dateRange: {},
    },
    completedQueue: {
      dateFilterType: DateFilterEnum.ALL_TIME,
      dateRange: {},
    },
  },
  assigneeUserIds: [],
  checklistItemDocumentErrorLoading: false,
  checklistItemDocumentErrorResponseById: {},
  checklistErrorDetectionModalData: undefined,
  notifications: [],
};

const LeoSlice = createSlice({
  name: 'leo',
  initialState,
  reducers: {
    changeLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    changeIsChatLoading(state, action: PayloadAction<boolean>) {
      state.isChatLoading = action.payload;
    },
    changeIsHistoryLoading(state, action: PayloadAction<boolean>) {
      state.isHistoryLoading = action.payload;
    },
    saveDeletingSessionId(state, action: PayloadAction<string | undefined>) {
      state.deletingSessionId = action.payload;
    },
    saveIsLeoModalVisible(state, action: PayloadAction<boolean>) {
      state.isLeoModalVisible = action.payload;
    },
    saveShowIntro(state, action: PayloadAction<boolean>) {
      state.showIntro = action.payload;
    },
    saveIsLeoThinking(state, action: PayloadAction<boolean>) {
      state.isLeoThinking = action.payload;
    },
    saveIsLeoGenerating(state, action: PayloadAction<boolean>) {
      state.isLeoGenerating = action.payload;
    },
    saveIsNudgeEnabled(state, action: PayloadAction<boolean>) {
      state.isNudgeEnabled = action.payload;
    },
    saveSession(
      state,
      action: PayloadAction<SessionWithDisclaimer | undefined>,
    ) {
      state.session = action.payload;
    },
    saveIsSessionError(state, action: PayloadAction<boolean>) {
      state.isSessionError = action.payload;
    },
    saveSessionHistory(state, action: PayloadAction<SessionHistory[]>) {
      state.chatHistory = action.payload;
    },
    saveSelectedItems(state, action: PayloadAction<FollowUpItem[]>) {
      state.selectedItems = action.payload;
    },
    saveDefaultPrompts(state, action: PayloadAction<ContextualPrompt[]>) {
      state.defaultPrompts = action.payload;
    },
    saveMsgContext(
      state,
      action: PayloadAction<LeoResponseMessageContext | undefined | null>,
    ) {
      state.msgContext = action.payload || undefined;
    },
    updateSessionHistoryTitle(
      state,
      action: PayloadAction<{ title: string; session_id: string }>,
    ) {
      const chatHistoryIndex = state.chatHistory.findIndex(
        (el) => el.id === action.payload.session_id,
      )!;
      if (chatHistoryIndex >= 0) {
        state.chatHistory![chatHistoryIndex] = {
          ...state.chatHistory![chatHistoryIndex],
          title: action.payload.title,
        };
      } else {
        state.chatHistory = [
          ...state.chatHistory,
          {
            title: action.payload.title,
            agent_id: state.session?.agent_id! as string,
            id: action.payload.session_id,
            start_time: new Date().toISOString(),
            system_message: '',
          },
        ];
      }
    },
    deleteChatHistory(state, action: PayloadAction<string>) {
      state.chatHistory = state.chatHistory?.filter(
        (el) => el.id !== action.payload,
      );
    },
    saveCurrentChat(state, action: PayloadAction<CurrentChat[]>) {
      state.currentChat = action.payload;
    },
    updateCurrentChat(state, action: PayloadAction<CurrentChat>) {
      const chatIndex = state.currentChat?.findIndex(
        (el) => el.id === action.payload.id,
      );
      if (chatIndex < 0) {
        state.currentChat = [...state.currentChat, action.payload];
      } else {
        state.currentChat[chatIndex] = action.payload;
      }
    },
    saveIsCreatingTicket(state, action: PayloadAction<boolean>) {
      state.isCreatingTicket = action.payload;
    },
    saveIsTicketCreated(state, action: PayloadAction<boolean>) {
      state.isTicketCreated = action.payload;
    },
    saveWorkQueueDateFilterType(state, action: PayloadAction<DateFilterEnum>) {
      state.leoQuestionManager = {
        ...state.leoQuestionManager,
        workQueue: {
          ...state.leoQuestionManager.workQueue,
          dateFilterType: action.payload,
        },
      };
    },
    saveWorkQueueDateFilterRange(state, action: PayloadAction<DateRange>) {
      state.leoQuestionManager = {
        ...state.leoQuestionManager,
        workQueue: {
          ...state.leoQuestionManager.workQueue,
          dateRange: action.payload,
        },
      };
    },
    saveAssignedQueueDateFilterType(
      state,
      action: PayloadAction<DateFilterEnum>,
    ) {
      state.leoQuestionManager = {
        ...state.leoQuestionManager,
        assignedQueue: {
          ...state.leoQuestionManager.assignedQueue,
          dateFilterType: action.payload,
        },
      };
    },
    saveAssignedQueueDateFilterRange(state, action: PayloadAction<DateRange>) {
      state.leoQuestionManager = {
        ...state.leoQuestionManager,
        assignedQueue: {
          ...state.leoQuestionManager.assignedQueue,
          dateRange: action.payload,
        },
      };
    },
    saveCompletedQueueDateFilterType(
      state,
      action: PayloadAction<DateFilterEnum>,
    ) {
      state.leoQuestionManager = {
        ...state.leoQuestionManager,
        completedQueue: {
          ...state.leoQuestionManager.completedQueue,
          dateFilterType: action.payload,
        },
      };
    },
    saveCompletedQueueDateFilterRange(state, action: PayloadAction<DateRange>) {
      state.leoQuestionManager = {
        ...state.leoQuestionManager,
        completedQueue: {
          ...state.leoQuestionManager.completedQueue,
          dateRange: action.payload,
        },
      };
    },
    saveLeoQuestionAssignees(state, action: PayloadAction<string[]>) {
      state.assigneeUserIds = action.payload;
    },
    saveChecklistDocumentErrorLoading(state, action: PayloadAction<boolean>) {
      state.checklistItemDocumentErrorLoading = action.payload;
    },
    saveChecklistItemDocumentErrorResponseById(
      state,
      action: PayloadAction<Mapping<any>>,
    ) {
      state.checklistItemDocumentErrorResponseById = action.payload;
    },
    saveLeoErrorDetectionModalData(state, action: PayloadAction<any>) {
      state.checklistErrorDetectionModalData = action.payload;
    },
    resetLeoErrorDetectionModalData(state) {
      state.checklistErrorDetectionModalData = undefined;
    },
    saveResetLeo(state, _action: PayloadAction<undefined>) {
      state.currentChat = [];
      state.session = undefined;
      state.currentChat = [];
      state.showIntro = true;
    },
    saveNotifications(state, action: PayloadAction<LeoNotification[]>) {
      state.notifications = action.payload;
    },
  },
});

export const {
  changeLoading,
  changeIsChatLoading,
  changeIsHistoryLoading,
  saveDeletingSessionId,
  saveIsLeoModalVisible,
  saveShowIntro,
  saveIsLeoThinking,
  saveIsLeoGenerating,
  saveSession,
  saveIsSessionError,
  saveSessionHistory,
  saveSelectedItems,
  saveDefaultPrompts,
  saveMsgContext,
  updateSessionHistoryTitle,
  deleteChatHistory,
  saveCurrentChat,
  updateCurrentChat,
  saveIsCreatingTicket,
  saveIsTicketCreated,
  saveIsNudgeEnabled,
  saveWorkQueueDateFilterType,
  saveWorkQueueDateFilterRange,
  saveAssignedQueueDateFilterType,
  saveAssignedQueueDateFilterRange,
  saveCompletedQueueDateFilterType,
  saveCompletedQueueDateFilterRange,
  saveLeoQuestionAssignees,
  saveChecklistDocumentErrorLoading,
  saveChecklistItemDocumentErrorResponseById,
  saveLeoErrorDetectionModalData,
  resetLeoErrorDetectionModalData,
  saveResetLeo,
  saveNotifications,
} = LeoSlice.actions;

const ChatController = ChatControllerApi;

export const createChatSession = (
  agent_id: string,
): AppThunk<Promise<string | undefined>> => async (dispatch) => {
  dispatch(changeLoading(true));
  try {
    const { data: session } = await new ChatController(
      await getLeoConfiguration(),
    ).createChatSessionChatSessionPost({ agent_id });
    const id = session.id as string;
    dispatch(saveSession({ ...session, id }));
    if (session?.disclaimer) {
      dispatch(updateChatResponse(id, uuid(), session?.disclaimer!));
    }
    dispatch(fetchSessionHistory(agent_id));
    dispatch(saveIsSessionError(false));
    return id;
  } catch (e) {
    dispatch(saveIsSessionError(true));
    dispatch(
      showErrorToast(
        'We had a problem creating the chat session.',
        'Please try again in a few moments.',
      ),
    );
    ErrorService.notifyIgnoreAuthErrors('unable to create chat sesion', e, {
      createChatSessionRequestParams: { agent_id },
    });
    return;
  } finally {
    dispatch(changeLoading(false));
  }
};

export const openLeo = (
  agent_id: string,
  prompt?: ContextualPrompt,
  followUps: FollowUp[] = [],
): AppThunk => async (dispatch) => {
  if (!agent_id) {
    return;
  }
  dispatch(saveIsLeoModalVisible(true));
  dispatch(saveIsNudgeEnabled(false));
  const sessionId = await dispatch(createChatSession(agent_id));
  AnalyticsService.instance().logEvent(AnalyticsEventEnum.LEO_BAR_CLICK);
  if (!sessionId || !prompt) {
    return;
  }
  dispatch(saveShowIntro(false));
  dispatch(chatReply(sessionId, prompt, true, followUps));
};

export const fetchSessionHistory = (agent_id: string): AppThunk => async (
  dispatch,
) => {
  dispatch(changeIsHistoryLoading(true));
  try {
    const { data } = await new ChatController(
      await getLeoConfiguration(),
    ).getAllAgentSessionsChatSessionGet(agent_id);
    dispatch(saveSessionHistory((data as any) as SessionHistory[]));
  } catch (e) {
    dispatch(
      showErrorToast(
        'We had a problem fetching session history.',
        'Please try again in a few moments.',
      ),
    );
    ErrorService.notify('unable to fetch sesion history', e, {
      getAllAgentSessionsChatSessionGet: { agent_id },
    });
  } finally {
    dispatch(changeIsHistoryLoading(false));
  }
};

export const fetchSessionChat = (
  session_id: string,
  agent_id: string,
): AppThunk => async (dispatch) => {
  dispatch(changeIsChatLoading(true));
  try {
    const { data } = await new ChatController(
      await getLeoConfiguration(),
    ).getMessagesChatMessagesGet(session_id, agent_id);
    dispatch(
      saveSession({ id: session_id, agent_id } as SessionWithDisclaimer),
    );
    dispatch(saveCurrentChat((data as any) as CurrentChat[]));
  } catch (e) {
    dispatch(
      showErrorToast(
        'We had a problem fetching the chat session.',
        'Please try again in a few moments.',
      ),
    );
    ErrorService.notify('unable to fetch chat sesion', e, {
      getMessagesChatMessagesGet: { agent_id, session_id },
    });
  } finally {
    dispatch(changeIsChatLoading(false));
  }
};

export const deleteSessionHistory = (
  id: string,
): AppThunk<Promise<void>> => async (dispatch) => {
  dispatch(saveDeletingSessionId(id));
  try {
    await new ChatController(
      await getLeoConfiguration(),
    ).deleteSessionChatSessionSessionIdDelete(id);
    dispatch(deleteChatHistory(id));
  } catch (e) {
    dispatch(
      showErrorToast(
        'We had a problem deleting this session.',
        'Please try again in a few moments.',
      ),
    );
    ErrorService.notify('unable to delete message', e, {
      deleteSessionChatSessionDelete: { id },
    });
  } finally {
    dispatch(saveDeletingSessionId(undefined));
  }
};

export const chatReply = (
  session_id: string,
  prompt: ContextualPrompt,
  updateSessionTitle: boolean = false,
  followUps?: FollowUp[],
): AppThunk => async (dispatch, getState) => {
  const {
    leo: { isLeoGenerating },
  }: RootState = getState();
  const jwt = getAuthCookie();
  if (!jwt) {
    dispatch(showErrorToast('You must be logged in to chat with Leo.'));
    ErrorService.notify(
      'JWT not found. Unable to chat with leo',
      { name: 'jwtError', message: 'JWT undefined' },
      { data: { session_id } },
    );
  }
  dispatch(saveIsLeoThinking(true));
  dispatch(saveIsTicketCreated(false));
  dispatch(updateChatQuestion(session_id, prompt.title));
  dispatch(saveMsgContext(undefined));

  try {
    const streamUrl = getLeoResponseSteamUrl(
      prompt.prompt,
      session_id,
      followUps,
      prompt,
    );

    const streamResponse = await performFetch(streamUrl, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });

    //@ts-ignore
    const reader = streamResponse.body
      .pipeThrough(new TextDecoderStream())
      .getReader();

    let hasMessageStarted = false;
    let isMessageDone = false;
    let msgContext: LeoResponseMessageContext | undefined = undefined;
    let responseId = '';
    let response = '';

    while (true) {
      const { value, done } = await reader.read();
      if (done) {
        console.log('done', done);
        break;
      }

      const messages = value
        ?.split('\n')
        .filter((message) => message.indexOf('data: ') === 0)
        .map((message) => message.replace('data: ', '').trim());

      if (!messages) {
        continue;
      }

      for (let message of messages) {
        if (message === START_TOKEN) {
          hasMessageStarted = true;
          continue;
        } else if (message === DONE_TOKEN) {
          console.log(DONE_TOKEN);
          isMessageDone = true;
          dispatch(saveIsLeoThinking(false));
          dispatch(saveIsLeoGenerating(false));
          if (updateSessionTitle) {
            dispatch(
              updateSessionHistoryTitle({ session_id, title: prompt.title }),
            );
          }
          continue;
        } else if (!responseId) {
          responseId = JSON.parse(message).bot_message_id;
          continue;
        }

        if (isMessageDone) {
          msgContext = JSON.parse(message) as LeoResponseMessageContext;
          console.log({ msgContext });
          dispatch(saveMsgContext(msgContext));
          dispatch(saveSelectedItems([]));
          continue;
        }
        if (hasMessageStarted) {
          response = `${response}${message
            .slice(1, -1)
            .split(/\\n/g)
            .join('\n')
            .replace('\\"', '"')}`;
        }
        if (hasMessageStarted && !isLeoGenerating) {
          dispatch(saveIsLeoGenerating(true));
        }
      }

      if (response) {
        dispatch(updateChatResponse(session_id, responseId, response));
      }
    }
  } catch (e: any) {
    dispatch(
      showErrorToast(
        'We had a problem getting a response from Leo.',
        'Please try again in a few moments.',
      ),
    );
    if (!e) {
      Logger.debug('[Event Stream]: no error specified. Skipping...', e);
      return;
    }
    ErrorService.notify('unable to send/receive chat reply', e, {
      chatReplyRequestParams: { session_id, prompt },
    });
    dispatch(saveSelectedItems([]));
  } finally {
    dispatch(saveIsLeoThinking(false));
  }
};

export const updateMessageVote = (
  message: CurrentChat,
  voteType: VoteType,
): AppThunk<Promise<void>> => async (dispatch) => {
  try {
    dispatch(
      updateCurrentChat({
        ...message,
        vote_type: voteType === message.vote_type ? undefined : voteType,
      }),
    );

    const { data } = await new ChatController(
      await getLeoConfiguration(),
    ).voteChatMessageMessageIdVoteTypePost(message.id!, voteType);

    dispatch(updateCurrentChat((data as any) as CurrentChat));
  } catch (e) {
    dispatch(updateCurrentChat(message));
    dispatch(
      showErrorToast(
        'We had a problem upvoting/downvoting this message.',
        'Please try again in a few moments.',
      ),
    );
    ErrorService.notify('unable to upvote/downvote message', e, {
      voteChatMessageMessageIdVoteTypePost: { message },
    });
  }
};

export const updateChatQuestion = (
  session_id: string,
  question: string,
): AppThunk => (dispatch) => {
  const created_at = new Date().toISOString();
  dispatch(
    updateCurrentChat({
      id: uuid(),
      session_id,
      message: question,
      sender: SENDER_RECEIVER_ENUM.USER,
      created_at,
    }),
  );
};

export const updateChatResponse = (
  session_id: string,
  responseId: string,
  message: string,
): AppThunk => (dispatch) => {
  dispatch(
    updateCurrentChat({
      id: responseId,
      session_id,
      message,
      created_at: new Date().toISOString(),
      sender: SENDER_RECEIVER_ENUM.SYSTEM,
    }),
  );
};

export const fetchNudge = (
  agent_id: string,
): AppThunk<Promise<boolean>> => async (dispatch) => {
  try {
    const { data } = await new ChatController(
      await getLeoConfiguration(),
    ).checkIsAgentNewChatIsNewAgentIdGet(agent_id);
    return data.is_new;
  } catch (e) {
    dispatch(showErrorToast('We had a problem fetching leo nudge state'));
    ErrorService.notify('unable to fetch nudge state', e, {
      getAllAgentSessionsChatSessionGet: { agent_id },
    });
    return false;
  }
};

export const createTicket = (
  ticket: ChatMessageTicket,
): AppThunk<Promise<void>> => async (dispatch) => {
  dispatch(saveIsCreatingTicket(true));
  try {
    await new ChatController(
      await getLeoConfiguration(),
    ).createTicketChatMessageTicketPost(ticket);
    dispatch(saveIsTicketCreated(true));
    dispatch(showSuccessToast('Ticket created successfully'));
  } catch (e) {
    dispatch(
      showErrorToast('We had a problem creating a ticket. Pleas try again.'),
    );
    ErrorService.notify('[LEO] - unable to create ticket', e, { ticket });
  } finally {
    dispatch(saveIsCreatingTicket(false));
  }
};

export const fetchPrompts = (
  promptsReq: PromptLibraryRequest,
): AppThunk<Promise<ContextualPrompt[] | undefined>> => async (dispatch) => {
  dispatch(changeLoading(true));
  try {
    const { data } = await new ChatController(
      await getLeoConfiguration(),
    ).getPromptSuggestionsChatPromptLibraryPost(promptsReq);
    if (!data.items || !data.items.length) {
      throw new Error('No prompts found');
    }
    dispatch(saveDefaultPrompts(data.items));
    return data.items;
  } catch (e) {
    ErrorService.notifyIgnoreAuthErrors(
      '[LEO] - Failed to fetch leo prompts.',
      e,
    );
    dispatch(saveDefaultPrompts(DEFAULT_CHAT_PROMPTS));
    return undefined;
  } finally {
    dispatch(changeLoading(false));
  }
};

export const fetchLeoQuestionAssignees = (): AppThunk => async (dispatch) => {
  try {
    const { data } = await new LeoQuestionManagerApi(
      await getLeoConfiguration(),
    ).getExpertsLqmQapairExpertsGet();

    dispatch(saveLeoQuestionAssignees(data));
  } catch (e) {
    ErrorService.notifyIgnoreAuthErrors(
      '[LEO] - Failed to fetch leo question assignees.',
      e,
    );
  }
};

export const fetchNotifications = (): AppThunk<
  Promise<LeoNotificationAction[]>
> => async (dispatch) => {
  try {
    const { data } = await new NotificationsApi(
      getLeoConfiguration(),
    ).listNotificationsNotificationsGet();
    if (!data.items || !data.items.length) {
      throw new Error('No notifications found');
    }
    dispatch(saveNotifications(data.items as LeoNotification[]));
    return data.items;
  } catch (e) {
    ErrorService.notify('[LEO] - Failed to fetch leo notifications.', e);
    return [];
  }
};

export const dismissNotification = (id: string): AppThunk => async (
  dispatch,
) => {
  try {
    await new NotificationsApi(
      getLeoConfiguration(),
    ).dismissNotificationsNotificationIdDismissPost(id);
    await dispatch(fetchNotifications());
  } catch (e) {
    ErrorService.notify('[LEO] - Failed to dismuss leo notification', e);
  }
};

export const callNotificationAction = (
  id: string,
  text: string,
): AppThunk => async (dispatch) => {
  try {
    await new NotificationsApi(
      getLeoConfiguration(),
    ).actionNotificationsNotificationIdActionPost(id, { text });
    await dispatch(fetchNotifications());
  } catch (e) {
    ErrorService.notify('[LEO] - Failed to call leo notification action', e);
  }
};

export const createNotifications = (
  notifications: LeoNotificationCreate[],
): AppThunk => async () => {
  try {
    await Promise.all(
      notifications.map(
        async (notification) =>
          await new NotificationsApi(
            getLeoConfiguration(),
          ).createNotificationNotificationsCreatePost(notification),
      ),
    );
  } catch (e) {
    ErrorService.notify('[LEO] - Failed to call leo notification action', e);
  }
};

export const getChecklistItemDocumentErrors = (
  checklistItemIds: string[],
): AppThunk<Promise<void>> => async (dispatch) => {
  try {
    const { data } = await new DocumentReviewApi(
      getLeoConfiguration(),
    ).checklistItemsErrorsDocumentReviewChecklistItemsErrorsPost({
      checklist_item_ids: checklistItemIds,
    });
    const checklistItemIdErrorMapping: Mapping<any> = data?.checklist_items?.reduce(
      (acc: any, item: any) => {
        acc[item?.id!] = item?.files?.reduce((subacc: any, subitem: any) => {
          subacc[subitem?.id!] = subitem;
          return subacc;
        }, {});
        return acc;
      },
      {},
    );
    dispatch(
      saveChecklistItemDocumentErrorResponseById(checklistItemIdErrorMapping),
    );
  } catch (e) {
    dispatch(
      showErrorToast(
        'We had a problem fetching checklist item document errors.',
        'Please try again in a few moments.',
      ),
    );
    ErrorService.notifyIgnoreAuthErrors(
      '[LEO] - Failed to fetch checklist item document errors.',
      e,
    );
  }
};

export default LeoSlice.reducer;
