import { IconDefinition } from '@fortawesome/pro-light-svg-icons';
import {
  ActionIcon as MActionIcon,
  ActionIconProps as MActionIconProps,
  Tooltip,
} from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ButtonHTMLAttributes } from 'react';
import { cn } from '../../utils/classUtils';
import { forceImportant } from './utils';

type Size = 'sm' | 'md';
type Color = 'grey' | 'red' | 'green' | 'blue';
type ColorClasses = {
  background: string;
  text: string;
};
const colorStyles: Record<Color, ColorClasses> = {
  green: {
    background: 'bg-green-100 hover:!bg-green-600',
    text: 'text-green-600 hover:!text-white',
  },
  grey: {
    background: 'bg-regent-300 hover:!bg-grey-300',
    text: 'text-gray-600 hover:!text-primary-dark',
  },
  red: {
    background: 'bg-red-100 hover:!bg-red-600',
    text: 'text-red-600 hover:!text-white',
  },
  blue: {
    background: 'bg-rezen-blue-100 hover:!bg-rezen-blue-600',
    text: 'text-rezen-blue-600 hover:!text-white',
  },
};

const sizeStyles: Record<Size, string> = {
  sm: 'w-6 h-6',
  md: 'w-9 h-9',
};

const tooltipStyles: Record<Size, string> = {
  sm: 'text-xs px-1.5 py-1',
  md: 'text-[15px] px-2.5 py-2',
};

const getSizeClasses = (size: Size) => sizeStyles[size];
const getColorClasses = (color: Color) => Object.values(colorStyles[color]);

type ActionIconProps = Omit<
  MActionIconProps,
  'size' | 'color' | 'radius' | 'children'
> &
  ButtonHTMLAttributes<HTMLButtonElement> & {
    size?: Size;
    color?: Color;
    label?: string;
    icon: IconDefinition;
    labelClassName?: string;
  };

export const ActionIcon: React.FC<ActionIconProps> = ({
  label,
  size,
  color,
  labelClassName,
  icon,
  disabled,
  ...props
}) => {
  return (
    <Tooltip
      label={label}
      disabled={disabled}
      classNames={{
        tooltip: forceImportant(
          cn(
            'rounded-md font-inter font-light text-sm bg-primary-light text-primary-dark',
            'border border-primary-dark box-shadow-md',
            tooltipStyles[size ?? 'md'],
          ),
        ),
      }}
    >
      <MActionIcon
        unstyled
        {...props}
        onClick={(e) => {
          if (disabled) return;
          props.onClick?.(e);
        }}
        radius='50%'
        className={cn(
          'rounded-full transition-colors duration-1000',
          getColorClasses(color ?? 'grey'),
          getSizeClasses(size ?? 'md'),
          'transition duration-150',
          'active:translate-y-[1px]',
          'relative',
          disabled &&
            'cursor-default bg-grey-200 text-grey-300 hover:!bg-grey-200 hover:!text-grey-300',
          props.className,
        )}
      >
        <FontAwesomeIcon
          icon={icon}
          className='absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 w-1/2 h-1/2'
        />
      </MActionIcon>
    </Tooltip>
  );
};
