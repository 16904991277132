import { capitalize } from 'lodash';
import { useHistory, useParams } from 'react-router-dom';
import ZenRoute from '../components/Zen/ZenRoute';
import PageLayout from '../components/PageLayout';
import { KafkaServiceType } from '../components/KafkaDashboard/KafkaTypes';
import { Pill } from '../components/commons/Pill';
import KafkaRetryDashboard from '../components/KafkaDashboard/KafkaRetryDashboard';

type Match = {
  type: KafkaServiceType;
};

const KafkaDashboardRoute = () => {
  const { type } = useParams() as Match;
  const history = useHistory();

  return (
    <ZenRoute title='Kafka Consumer Dashboard'>
      <PageLayout
        path={[
          { title: 'Engineering', url: '/engineering' },
          { title: 'Kafka Dashboard', url: '/kafka-dashboard' },
        ]}
      >
        <ZenRoute title='Kafka Dashboard'>
          <div className='px-4 lg:pt-5'>
            <div className='flex gap-3 mx-5'>
              {Object.values(KafkaServiceType).map((key) => (
                <Pill
                  key={key}
                  color={key === type ? 'blue' : 'grey'}
                  onClick={() => history.push(`/kafka-dashboard/${key}`)}
                  className='cursor-pointer'
                >
                  {capitalize(key)}
                </Pill>
              ))}
            </div>
            <KafkaRetryDashboard key={type} type={type} />
          </div>
        </ZenRoute>
      </PageLayout>
    </ZenRoute>
  );
};

export default KafkaDashboardRoute;
