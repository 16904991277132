import {
  GetFailedMessagesArgs,
  GetFailedMessagesResponse,
  KafkaController,
  KafkaControllerOptions,
  RetryFailedMessageArgs,
  RetryFailedMessageResponse,
  RetryFailedMessagesArgs,
  RetryFailedMessagesResponse,
  UpdateFailedMessageArgs,
  UpdateFailedMessageResponse,
} from './KafkaControllerTypes';

export class KafkaControllerWrapper<T> implements KafkaController {
  private readonly _getFailedMessages: keyof T;
  private readonly _retryFailedMessage: keyof T;
  private readonly _batchUpdateFailedMessageStatus: keyof T;
  private readonly _retryFailedMessages: keyof T;
  private readonly controller: T;

  constructor(controller: T, methodNames?: KafkaControllerOptions<T>) {
    this.controller = controller;
    this._getFailedMessages =
      methodNames?.getFailedMessages ?? ('getFailedMessages' as keyof T);
    this._retryFailedMessage =
      methodNames?.retryFailedMessage ?? ('retryFailedMessage' as keyof T);
    this._retryFailedMessages =
      methodNames?.retryFailedMessages ?? ('retryFailedMessages' as keyof T);
    this._batchUpdateFailedMessageStatus =
      methodNames?.batchUpdateFailedMessageStatus ??
      ('batchUpdateFailedMessageStatus' as keyof T);
  }

  getFailedMessages(...args: GetFailedMessagesArgs): GetFailedMessagesResponse {
    const func = this.controller[
      this._getFailedMessages
    ] as KafkaController['getFailedMessages'];
    const boundedFunc = func.bind(this.controller);
    return boundedFunc(...args);
  }

  retryFailedMessage(
    ...args: RetryFailedMessageArgs
  ): RetryFailedMessageResponse {
    const func = this.controller[
      this._retryFailedMessage
    ] as KafkaController['retryFailedMessage'];
    const boundedFunc = func.bind(this.controller);
    return boundedFunc(...args);
  }

  retryFailedMessages(
    ...args: RetryFailedMessagesArgs
  ): RetryFailedMessagesResponse {
    const func = this.controller[
      this._retryFailedMessages
    ] as KafkaController['retryFailedMessages'];
    const boundedFunc = func.bind(this.controller);
    return boundedFunc(...args);
  }

  batchUpdateFailedMessageStatus(
    ...args: UpdateFailedMessageArgs
  ): UpdateFailedMessageResponse {
    const func = this.controller[
      this._batchUpdateFailedMessageStatus
    ] as KafkaController['batchUpdateFailedMessageStatus'];
    const boundedFunc = func.bind(this.controller);
    return boundedFunc(...args);
  }
}
