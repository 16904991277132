import {
  faArrowUpFromArc,
  faArrowUpToArc,
  faFileLines,
} from '@fortawesome/pro-light-svg-icons';
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import { useParams } from 'react-router-dom';
import { Column } from 'react-table';
import { formatMoneyValue } from '../../../utils/CurrencyUtils';
import { isToday } from '../../../utils/DateUtils';
import {
  ActivityTypeEnum,
  CreditActivityDefaultResponse,
  CreditActivityResponse,
} from '../../../utils/WalletUtils';
import ZenResourceIndexContainer from '../../Zen/Containers/ZenResourceIndexContainer';
import ZenButton from '../../Zen/ZenButton';
import ZenPageLayout from '../../Zen/ZenPageLayout';
import WalletMyCreditDetailsCard from '../WalletDebitDashboard/WalletMyCreditDetailsCard';

export const columns: Array<Column<CreditActivityResponse>> = [
  {
    Header: 'Description',
    accessor: 'borrowingId',
    Cell: ({ value, row }) => {
      const date = row?.original?.createdAt;
      const type = row?.original?.activityType;

      const isYesterday = (date: number) => {
        return (
          DateTime.now().minus({ day: 1 }).toISODate() ===
          DateTime.fromISO(new Date(date).toISOString()).toISODate()
        );
      };

      return (
        <div className='flex items-center'>
          <div className='h-9 w-9 rounded-full flex flex-row items-center justify-center border border-regent-400'>
            <FontAwesomeIcon
              icon={
                type === ActivityTypeEnum.Credit
                  ? faArrowUpFromArc
                  : faArrowUpToArc
              }
              className='text-primary-dark text-base'
            />
          </div>

          <div className='ml-3'>
            <p className='font-inter text-sm text-primary-dark font-normal'>
              Borrowing ID: {value}
            </p>
            {date && (
              <p className='font-inter text-sm text-regent-600 font-light'>
                <span>{DateTime.fromMillis(date).toFormat('MMM dd')}</span>
                <span className='ml-1'>
                  {isToday(new Date(date).toISOString()) && '• Today'}
                </span>
                <span className='ml-1'>
                  {isYesterday(new Date(date).getTime()) && '• Yesterday'}
                </span>
              </p>
            )}
          </div>
        </div>
      );
    },
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Amount',
    accessor: 'amount',
    Cell: ({ value, row }) => {
      const type = row?.original?.activityType;
      return (
        <p className='font-inter text-sm font-medium'>
          {type === ActivityTypeEnum.Debit && '-'}
          {formatMoneyValue(value) || 'N/A'}
        </p>
      );
    },
    disableSortBy: true,
    cardColSize: 12,
  },
  {
    Header: 'Balance',
    accessor: 'balance',
    Cell: ({ value }) => (
      <p className='font-inter text-sm text-right'>
        {formatMoneyValue(value) || 'N/A'}
      </p>
    ),
    disableSortBy: true,
    headerContentClassName: 'justify-end',
  },
];

interface Params {
  accountId: string;
}

interface WalletMyCreditRouteProps {}

const WalletMyCreditRoute: React.FC<WalletMyCreditRouteProps> = () => {
  const { accountId } = useParams<Params>();

  return (
    <ZenPageLayout
      path={[
        { title: 'Home', url: '/' },
        { title: 'Real Wallet', url: '/wallet' },
        { title: 'My Credit', url: `/wallet/new/${accountId}/credit` },
      ]}
      textClassNames='font-inter'
    >
      <div className='py-4.5 px-10 h-full bg-regent-100'>
        {/* Header */}
        <div className='mb-6 flex justify-between items-center'>
          <p className='font-medium text-lg text-primary-dark font-poppins'>
            My Credit
          </p>

          <ZenButton
            label='Statements'
            variant='primary-outline'
            className='font-inter font-medium text-base text-primary-dark border-2 border-grey-300'
            LeftIconComponent={
              <FontAwesomeIcon icon={faFileLines} className='mr-1' />
            }
          />
        </div>

        {/* Note */}
        <div className='mb-6 flex items-center px-6 py-3 bg-yellow-100 rounded-xl'>
          <FontAwesomeIcon
            icon={faCircleInfo}
            className='text-lg text-primary-dark mr-3'
            title='info-icon'
          />
          <p className='font-inter font-medium text-base text-primary-dark'>
            <span>
              The due date: 5 days. Please ensure you pay on time to avoid
              Penalty APR and Late Fees according to our
            </span>
            <span className='text-rezen-blue-600 cursor-pointer ml-px'>
              Credit Terms
            </span>
            .
          </p>
        </div>

        <div className='grid grid-cols-6 gap-7'>
          {/* Left Column */}
          <div className='col-span-6 md:col-span-4'>
            <div className='px-6 pt-6 rounded-xl shadow-pop-over bg-white'>
              <ZenResourceIndexContainer<CreditActivityResponse>
                header='Credit Activity'
                resourceName='activity'
                columns={columns}
                data={CreditActivityDefaultResponse?.results || []}
                standalone
              />
            </div>
          </div>

          {/* Right Column */}
          <div className='col-span-6 md:col-span-2'>
            <WalletMyCreditDetailsCard accountId={accountId} />
          </div>
        </div>
      </div>
    </ZenPageLayout>
  );
};

export default WalletMyCreditRoute;
