import { Badge, BadgeProps } from '@mantine/core';
import { ButtonHTMLAttributes } from 'react';
import { cn } from '../../utils/classUtils';
import { combineDefaultClassnamesWithCustomClassnames } from './utils';

export type PillColor =
  | 'light-green'
  | 'yellow'
  | 'orange'
  | 'red'
  | 'grey'
  | 'blue'
  | 'green'
  | 'purple'
  | 'sangria'
  | 'light-blue';

type PillSize = 'sm' | 'md';

type PillProps = Omit<BadgeProps, 'color' | 'size' | 'variant' | 'gradient'> & {
  color: PillColor;
  size?: PillSize;
} & ButtonHTMLAttributes<HTMLDivElement>;

type ColorStyles = {
  background: string;
  color: string;
  [key: string]: string;
};
const colorStyles: Record<PillColor, ColorStyles> = {
  'light-green': {
    background: 'bg-green-100',
    color: 'text-midnight-green-900',
  },
  yellow: {
    background: 'bg-yellow-100',
    color: 'text-yellow-900',
  },
  orange: {
    background: 'bg-orange-100',
    color: 'text-orange-600',
  },
  red: {
    background: 'bg-red-100',
    color: 'text-red-600',
  },
  grey: {
    background: 'bg-grey-100',
    color: 'text-grey-600',
  },
  'light-blue': {
    background: 'bg-rezen-light-blue-100',
    color: 'text-primary-navy',
  },
  blue: {
    background: 'bg-primary-blue',
    color: 'text-white',
  },
  green: {
    background: 'bg-midnight-green-300',
    color: 'text-midnight-green-900',
  },
  purple: {
    background: 'bg-purple-300',
    color: 'text-purple-900',
  },
  sangria: {
    background: 'bg-sangria-300',
    color: 'text-sangria-900',
  },
} as const;

type SizeStyles = {
  px: string;
  py: string;
  fontSize: string;
  gap: string;
  height: string;
  [key: string]: string;
};
const sizeStyles: Record<PillSize, SizeStyles> = {
  sm: {
    px: 'px-3',
    py: 'py-1',
    fontSize: 'text-xs',
    gap: 'gap-1',
    height: 'h-6',
  },
  md: {
    px: 'px-2.5',
    py: 'py-1',
    fontSize: 'text-sm',
    gap: 'gap-1',
    height: 'h-8',
  },
} as const;

const getColorSizeStyles = (
  color: PillColor,
  size: PillSize,
): BadgeProps['classNames'] => {
  return {
    root: cn(
      colorStyles[color].background,
      sizeStyles[size].px,
      sizeStyles[size].py,
      sizeStyles[size].gap,
      sizeStyles[size].height,
      sizeStyles[size].fontSize,
    ),
    inner: cn(sizeStyles[size].fontSize, colorStyles[color].color),
    leftSection: cn(colorStyles[color].color),
    rightSection: cn(colorStyles[color].color),
  };
};

const defaultStyles: BadgeProps['classNames'] = {
  root:
    'flex justify-center items-center rounded-full font-normal whitespace-nowrap overflow-hidden text-ellipsis w-max',
  inner: 'font-inter',
  leftSection: 'flex items-center w-3',
  rightSection: 'flex items-center w-3',
};

export const Pill: React.FC<PillProps> = (props) => {
  return (
    <Badge
      unstyled
      {...props}
      classNames={combineDefaultClassnamesWithCustomClassnames(
        defaultStyles,
        getColorSizeStyles(props.color, props.size ?? 'md'),
        props.classNames,
      )}
    >
      {props.children}
    </Badge>
  );
};
